import { ArraySchema } from '@colyseus/schema';
import { Spark, SparkType } from '../redux/models/colyseumSchemas/Spark';

import aboutmeImage from '../images/sparks/aboutme.svg';
import aboutyouImage from '../images/sparks/aboutyou.svg';
import courageImage from '../images/sparks/courage.svg';
import godeeperImage from '../images/sparks/godeeper.svg';
import highfiveImage from '../images/sparks/highfive.svg';
import hugImage from '../images/sparks/hug.svg';

import aboutmeIcon from '../images/sparks/icons/aboutme.svg';
import aboutyouIcon from '../images/sparks/icons/aboutyou.svg';
import courageIcon from '../images/sparks/icons/courage.svg';
import godeeperIcon from '../images/sparks/icons/godeeper.svg';
import highfiveIcon from '../images/sparks/icons/highfive.svg';
import hugIcon from '../images/sparks/icons/hug.svg';

import { exhaustiveCheck } from './exhaustiveCheck';

export type SparkProps = Pick<Spark, 'id' | 'toPlayerId' | 'fromPlayerId' | 'sparkType' | 'phase'>;
type GetSparkPropsArray = (sparks: ArraySchema<Spark> | undefined) => SparkProps[];

export const getSparksArray: GetSparkPropsArray = (sparks) => {
  if (!sparks) {
    return [];
  }

  return sparks as SparkProps[];
};

export const getSparkImage = (sparkType: SparkType) => {
  switch (sparkType) {
    case 'aboutme':
      return aboutmeImage;
    case 'aboutyou':
      return aboutyouImage;
    case 'courage':
      return courageImage;
    case 'godeeper':
      return godeeperImage;
    case 'highfive':
      return highfiveImage;
    case 'hug':
      return hugImage;

    default:
      exhaustiveCheck(sparkType);
  }
};

export const getSparkIcon = (sparkType: SparkType) => {
  switch (sparkType) {
    case 'aboutme':
      return aboutmeIcon;
    case 'aboutyou':
      return aboutyouIcon;
    case 'courage':
      return courageIcon;
    case 'godeeper':
      return godeeperIcon;
    case 'highfive':
      return highfiveIcon;
    case 'hug':
      return hugIcon;

    default:
      exhaustiveCheck(sparkType);
  }
};

export const getSparkName = (sparkType: SparkType) => {
  switch (sparkType) {
    case 'aboutme':
      return 'What About Me?';
    case 'aboutyou':
      return 'What About You?';
    case 'courage':
      return 'Courage';
    case 'godeeper':
      return 'Go Deeper';
    case 'highfive':
      return 'High Five';
    case 'hug':
      return 'Hug';
    default:
      exhaustiveCheck(sparkType);
  }
};

export const getSparkPoints = (sparkType: SparkType) => {
  switch (sparkType) {
    case 'aboutme':
      return 20;
    case 'aboutyou':
      return 20;
    case 'courage':
      return 30;
    case 'godeeper':
      return 20;
    case 'highfive':
      return 20;
    case 'hug':
      return 30;
    default:
      exhaustiveCheck(sparkType);
  }
};

export const getSparkDescription = (sparkType: SparkType) => {
  switch (sparkType) {
    case 'aboutme':
      return 'Play this when you want to answer the question that was just answered and award yourself 20 points.';
    case 'aboutyou':
      return 'Play this to ask another player the same question that was just answered and award them 20 points.';
    case 'courage':
      return 'Play this anytime to recognize a player for getting really real and award them 30 points.';
    case 'godeeper':
      return 'Play this anytime to ask a player one or two follow-up questions and award them 20 points.';
    case 'highfive':
      return 'Play this anytime to appreciate another player and award them 20 points.';
    case 'hug':
      return 'Play this anytime you feel a player deserves a hug and award them 30 points.';
    default:
      exhaustiveCheck(sparkType);
  }
};

export const getSparkTitle = (sparkType: SparkType) => {
  switch (sparkType) {
    case 'aboutme':
      return 'WHAT ABOUT ME? CARD (20 points)';
    case 'aboutyou':
      return 'WHAT ABOUT YOU? CARD (20 points)';
    case 'courage':
      return 'COURAGE CARD (30 points)';
    case 'godeeper':
      return 'GO DEEPER CARD (20 points)';
    case 'highfive':
      return 'HIGH FIVE CARD (20 points)';
    case 'hug':
      return 'HUG CARD (30 points)';
    default:
      exhaustiveCheck(sparkType);
  }
};

export const getSparkExplanation = (sparkType: SparkType) => {
  switch (sparkType) {
    case 'aboutme':
      return 'When YOU want to answer the same question that was just answered.';
    case 'aboutyou':
      return 'Ask another player to answer the same question that was just answered.';
    case 'courage':
      return 'Thank another player for sharing something that took courage.';
    case 'godeeper':
      return 'Ask a player 1-2 follow-up questions, like “What happened next?”';
    case 'highfive':
      return 'Appreciate another player for something they’ve shared.';
    case 'hug':
      return 'Award a player with a good old hug.';
    default:
      exhaustiveCheck(sparkType);
  }
};
