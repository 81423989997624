import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, Heading, Tooltip } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import { useNavigate } from 'react-router-dom';
import { BackButtom } from '../../basicComponents/backButtom/BackButtom';
import { DeleteAccountButton } from '../../basicComponents/deleteAccount/DeleteAccountButton';
import { buttonStyle, colors } from '../../theme/theme';
import { KICKSTARTER_PLAN, MY_GAMES_PATH, PLANS_PATH } from '../../utils/constants';
import { Bar } from '../../basicComponents/bar/Bar';
import { useListConfigsQuery, usePlanNamesQuery } from '../../redux/apis/config';
import { useGetChildrenQuery, useGetUserQuery } from '../../redux/apis/user.api';
import speech from '../../images/speech.svg';

import { useWindowLocation } from '../../hooks/useWindowLocation';
import {
  useGetSubscriptionStatusQuery,
  useCheckoutMutation,
  useReactivateSubscriptionMutation,
  SubscriptionStatus,
} from '../../redux/apis/payment';
import styled from 'styled-components';
import { PlansBox } from '../../components/plansAndBilling/PlansBox';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useAppDispatch } from '../../redux/hooks';
import { showModal } from '../../redux/slices/modal.slice';
import ActiveAddonsSection from '../../components/addOnCards/ActiveAddonSection';
import AvailableAddonSection from '../../components/addOnCards/AvailableAddonSection';

const RowBox = styled('div')`
  display: flex;
  flex-direction: row;
`;

const MOBILE_WIDTH = 950;

const MainTitle = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 30px;
`;

export const BillingStatusPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { setHref } = useWindowLocation();
  const dispatch = useAppDispatch();
  const addOnRef = useRef(null);
  const largeGroupsRef = useRef(null);
  const largeEventsnRef = useRef(null);
  const allEditionsRef = useRef(null);
  const allHandsnRef = useRef(null);
  const customizedGamesRef = useRef(null);
  const facilitatorRef = useRef(null);

  const sub = useGetSubscriptionStatusQuery(undefined, { refetchOnMountOrArgChange: true });
  const getConfigs = useListConfigsQuery(undefined);
  const getUserQuery = useGetUserQuery(undefined);
  const [mainSub, setMainSub] = useState<SubscriptionStatus>({
    plan: 'FREE',
    isSubscriptionActive: false,
    isTemporaryAddOnSubscription: false,
    allowChildren: false,
    allowedAddOns: [],
    playedThisMonth: 0,
    maxNoOfChildren: 0,
    endOfCurrentBillingPeriod: 0,
    fromParent: false,
    paymentCard: '',
  });
  const [parentSub, setParentSub] = useState<SubscriptionStatus | undefined>(undefined);
  const getKids = useGetChildrenQuery(undefined);

  const [cancellationDate, setCancellationDate] = useState<string>('');
  const [parentCancellationDate, setParentCancellationDate] = useState<string>('');
  const [reactivateSubscription, reactivatedSubscription] = useReactivateSubscriptionMutation();
  const [monthlyDate, setMonthlyDate] = useState<Date>(new Date());
  const planNames = usePlanNamesQuery(undefined);
  const [listOfPlans, setListOfPlans] = useState<string>('');

  const [checkout, session] = useCheckoutMutation();

  useEffect(() => {
    if (session.isSuccess) setHref(session.data.url);
  }, [session]);

  useEffect(() => {
    if (reactivatedSubscription.isSuccess) {
      dispatch(showModal({ type: 'success', message: 'Subscription reactivated!' }));
    }
  }, [reactivatedSubscription]);

  useEffect(() => {
    if (sub.isSuccess) {
      getConfigs.refetch();
      getUserQuery.refetch();
      let mainSub = sub.data.find(
        (x) => x.isSubscriptionActive && !x.isTemporaryAddOnSubscription && !x.fromParent
      );
      mainSub ??= sub.data.find((x) => x.isSubscriptionActive && x.plan == 'KICKSTARTER');
      if (mainSub) setMainSub(mainSub);

      let billingDay = 1;
      if (mainSub?.endOfCurrentBillingPeriod) {
        const endOfBilling = new Date(mainSub!.endOfCurrentBillingPeriod * 1000);
        billingDay = endOfBilling.getDate();
      }
      const now = new Date(Date.now());
      let year = now.getFullYear();
      let nextMonthAux: number;
      if (now.getDate() >= billingDay) {
        nextMonthAux = now.getMonth() + 1;
        if (nextMonthAux > 11) {
          nextMonthAux = 0;
          year++;
        }
      } else {
        nextMonthAux = now.getMonth();
      }
      setMonthlyDate(new Date(year, nextMonthAux, billingDay, 0, 0, 0, 0));

      if (mainSub?.validTo) {
        setCancellationDate(
          new Date(mainSub.validTo! * 1000).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        );
      } else {
        setCancellationDate('');
      }

      const parentSub = sub.data.find(
        (x) => x.isSubscriptionActive && !x.isTemporaryAddOnSubscription && x.fromParent
      );
      if (parentSub) setParentSub(parentSub);
      if (parentSub?.validTo) {
        setParentCancellationDate(
          new Date(parentSub.validTo! * 1000).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        );
      } else {
        setParentCancellationDate('');
      }
    }
  }, [sub]);

  useEffect(() => {
    if (sub.isSuccess && planNames.isSuccess) {
      const planNamesList = sub
        .data!.filter(
          (x) =>
            x.isSubscriptionActive || (x.plan == 'FREE' && !sub.data!.find((y) => y.fromParent))
        )
        .sort((a) => (a.isTemporaryAddOnSubscription ? 1 : -1))
        .map((p) => `${planNames.data?.[p.plan]}`);
      setListOfPlans(
        planNamesList.slice(0, -1).join(', ') +
          (planNamesList.length > 1 ? ' and ' : '') +
          planNamesList.slice(-1)
      );
    }
  }, [sub, planNames]);

  useEffect(() => {
    if (window.location.hash === '#add-ons' && addOnRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#large-groups' && largeGroupsRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#large-events' && largeEventsnRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#all-editions' && allEditionsRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#all-hands' && allHandsnRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#custom' && customizedGamesRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#facilitator' && facilitatorRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);

  if (!sub.data || !getConfigs.data || !getUserQuery.data) return <Layout bgWatermark />;

  return (
    <Layout bgWatermark>
      <Layout.Panel style={{ width: '100%', maxWidth: '414px', position: 'relative' }}>
        <Heading as="h1" sx={{ fontSize: 3, mb: 3, textAlign: 'center' }}>
          Billing & Plans
        </Heading>
        <BackButtom goBack={() => navigate(MY_GAMES_PATH)} />
        <Box>
          Hey {getUserQuery.data?.firstName}, you’re currently on the plan(s): <b>{listOfPlans}</b>.
        </Box>

        {cancellationDate ? (
          <Box sx={{ mt: 4, color: colors.real.medium }}>
            Cancellation effective on {cancellationDate}.{' '}
            <a
              onClick={() => reactivateSubscription({ plan: mainSub.plan })}
              style={{ textDecoration: 'underline', fontWeight: 700, cursor: 'pointer' }}
            >
              Click here to reactivate.
            </a>
          </Box>
        ) : null}

        {parentCancellationDate && getUserQuery.isSuccess && parentSub ? (
          <Box sx={{ mt: 4, color: colors.real.medium }}>
            {planNames.data?.[parentSub.plan]} plan ends on {parentCancellationDate}.<br />
            <Tooltip aria-label={getUserQuery.data!.parentEmail} direction="n">
              <a
                onClick={() => {
                  window.location.href = 'mailto:' + getUserQuery.data!.parentEmail;
                }}
                style={{ textDecoration: 'underline', fontWeight: 700, cursor: 'pointer' }}
              >
                Questions? Contact the account admin.
              </a>
            </Tooltip>
          </Box>
        ) : null}

        <Box sx={{ mt: 4 }}>
          <b>
            Your{' '}
            {`${parentSub ? planNames.data?.[parentSub!.plan] : ''}${
              parentSub && mainSub.plan && mainSub.plan !== 'FREE' ? ' and ' : ''
            }${mainSub.plan == 'FREE' ? '' : planNames.data?.[mainSub.plan]} `}{' '}
            plan includes
          </b>
          :{' '}
        </Box>

        {getConfigs.data && mainSub && getConfigs.data![mainSub.plan] ? (
          <>
            {mainSub.allowChildren ? (
              <Box sx={{ ml: 2 }}>
                - {mainSub.maxNoOfChildren + 1} licenses ({(getKids.data?.children.length ?? 0) + 1}{' '}
                allocated)
              </Box>
            ) : null}
            <Box sx={{ ml: 2 }}>
              -{' '}
              {getConfigs.data![mainSub.plan].MaxNoOfGamesPerMonth +
                (parentSub ? getConfigs.data![parentSub.plan].MaxNoOfGamesPerMonth : 0) >
              100
                ? 'Unlimited'
                : getConfigs.data![mainSub.plan].MaxNoOfGamesPerMonth +
                  (parentSub ? getConfigs.data![parentSub.plan].MaxNoOfGamesPerMonth : 0)}{' '}
              game room
              {getConfigs.data![mainSub.plan].MaxNoOfGamesPerMonth > 1 ? 's' : ''} a month{' '}
            </Box>
            <Box sx={{ ml: 2 }}>
              - Open up to{' '}
              {Math.max(
                getConfigs.data![mainSub.plan].MaxNoOfConcurrentRooms,
                parentSub ? getConfigs.data![parentSub.plan].MaxNoOfConcurrentRooms : 0
              )}{' '}
              room
              {Math.max(
                getConfigs.data![mainSub.plan].MaxNoOfConcurrentRooms,
                parentSub ? getConfigs.data![parentSub.plan].MaxNoOfConcurrentRooms : 0
              ) > 1
                ? 's'
                : ''}{' '}
              at a time{' '}
            </Box>
            <Box sx={{ ml: 2 }}>
              - Up to{' '}
              {Math.max(
                getConfigs.data![mainSub.plan].MaxNoOfPlayersPerGame,
                parentSub ? getConfigs.data![parentSub.plan].MaxNoOfPlayersPerGame : 0
              )}{' '}
              players in a game room{' '}
            </Box>

            <Box sx={{ mt: 4, mb: '20px' }}>
              <b>
                You've used {mainSub.playedThisMonth + (parentSub ? parentSub.playedThisMonth : 0)}{' '}
                /{' '}
                {getConfigs.data![mainSub.plan].MaxNoOfGamesPerMonth +
                  (parentSub ? getConfigs.data![parentSub.plan].MaxNoOfGamesPerMonth : 0)}{' '}
                game
                {getConfigs.data![mainSub.plan].MaxNoOfGamesPerMonth +
                  (parentSub ? getConfigs.data![parentSub.plan].MaxNoOfGamesPerMonth : 0) >
                1
                  ? 's'
                  : ''}{' '}
                this month - renews{' '}
                {monthlyDate.toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
                .
              </b>
            </Box>

            {sub.data!.find(
              (s) =>
                s.isSubscriptionActive && s.isTemporaryAddOnSubscription && s.plan != 'KICKSTARTER'
            ) ? (
              <Box sx={{ mb: '20px', color: colors.spark.btn, width: '100%', display: 'flex' }}>
                <div style={{ margin: 'auto' }}>
                  ✨{' '}
                  <a
                    onClick={() =>
                      (addOnRef.current! as HTMLElement).scrollIntoView({ behavior: 'smooth' })
                    }
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    <b>Add-on(s) active - see details</b>
                  </a>{' '}
                  ✨
                </div>
              </Box>
            ) : null}
          </>
        ) : null}

        {getUserQuery.data && sub.data && (sub.data.length > 1 || sub.data[0].plan != 'FREE') ? (
          <Box sx={{ mb: '50px', fontSize: '15px' }}>
            {parentSub ? (
              <>
                The admin of this account is {getUserQuery.data!.parentFullname} – you can contact
                them at{' '}
                <a
                  href={'mailto:' + getUserQuery.data!.parentEmail}
                  style={{ color: '#FF7300', fontWeight: 700 }}
                >
                  {getUserQuery.data!.parentEmail}
                </a>
                .{' '}
              </>
            ) : (
              ''
            )}
            {!parentSub ? (
              <>To change your payment method or update your billing information, </>
            ) : (
              <>To update your payment information for add-on purchases, </>
            )}{' '}
            <a
              href={getUserQuery.data?.stripeCustomerPortal}
              style={{ color: '#FF7300', fontWeight: 700 }}
            >
              click here
            </a>
            .
          </Box>
        ) : null}

        <Bar
          style={{
            bottom: mainSub
              ? !sub.data?.find((s) => s.isSubscriptionActive && s.plan == 'KICKSTARTER') &&
                !sub.data?.find((s) => s.plan !== 'FREE' && !s.isTemporaryAddOnSubscription) &&
                !sub.data?.find((s) => s.fromParent)
                ? '165px'
                : '134px'
              : '160px',
          }}
        />
        {sub.data?.find((s) => s.plan == 'FREE') ? (
          <RowBox style={{ gap: '15px' }}>
            <Button
              onClick={() =>
                (addOnRef.current! as HTMLElement).scrollIntoView({ behavior: 'smooth' })
              }
              size="large"
              sx={{
                mr: 1,
                ...buttonStyle,
                mt: 3,
                mb: 3,
                width: '100%',
                flex: '1 0',
                color: colors.accent.emphasis,
                backgroundColor: colors.real.muted,
                borderColor: colors.accent.emphasis,
              }}
              variant="primary"
            >
              Add-Ons
            </Button>
            <Button
              onClick={() => navigate(PLANS_PATH)}
              size="large"
              sx={{ mr: 1, ...buttonStyle, mt: 3, mb: 3, width: '100%', flex: '1 0' }}
              variant="primary"
            >
              ✨ See plans ✨
            </Button>
          </RowBox>
        ) : (
          <RowBox style={{ gap: '15px' }}>
            <Button
              onClick={() => navigate(PLANS_PATH)}
              size="large"
              sx={{
                mr: 1,
                ...buttonStyle,
                mt: 3,
                mb: 3,
                width: '100%',
                flex: '1 0',
                color: colors.accent.emphasis,
                backgroundColor: colors.real.muted,
                borderColor: colors.accent.emphasis,
              }}
              variant="primary"
            >
              See plans
            </Button>
            <Button
              onClick={() =>
                (addOnRef.current! as HTMLElement).scrollIntoView({ behavior: 'smooth' })
              }
              size="large"
              sx={{ mr: 1, ...buttonStyle, mt: 3, mb: 3, width: '100%', flex: '1 0' }}
              variant="primary"
            >
              ✨ Add-Ons ✨
            </Button>
          </RowBox>
        )}

        {!sub.data?.find((s) => s.isSubscriptionActive && s.plan == 'KICKSTARTER') &&
        !sub.data?.find((s) => s.plan !== 'FREE' && !s.isTemporaryAddOnSubscription) &&
        !sub.data?.find((s) => s.fromParent) ? (
          <a
            onClick={() => checkout({ plan: KICKSTARTER_PLAN })}
            style={{
              color: colors.juicy.medium,
              display: 'block',
              width: '100%',
              textDecoration: 'underline',
              textAlign: 'center',
              padding: '0px 0px 10px 0px',
              cursor: 'pointer',
            }}
          >
            Enter Kickstarter promo code
          </a>
        ) : null}
        <DeleteAccountButton button={false} />
      </Layout.Panel>

      <div ref={addOnRef} style={{ padding: "0px", margin: "0px", width: "100%"}}>
          <ActiveAddonsSection />
          <AvailableAddonSection />
      </div>
    </Layout>
  );
};
