import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './common';
import { UserCreateUpdateDto, UserResponseDto } from './../models/user.model';

const USER_API = '/users';
export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['User', 'Children'],
  endpoints: (builder) => ({
    getUser: builder.query<UserResponseDto, undefined>({
      query: () => USER_API,
      providesTags: ['User'],
    }),

    createUser: builder.mutation<UserResponseDto, UserCreateUpdateDto>({
      query: (body) => ({
        url: USER_API,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),

    updateUser: builder.mutation<UserResponseDto, Omit<UserCreateUpdateDto, 'email'>>({
      query: (body) => ({
        url: USER_API,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['User'],
    }),

    deleteUser: builder.mutation<UserResponseDto, undefined>({
      query: () => ({
        url: USER_API,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),

    verifyEmail: builder.mutation<undefined, string | null>({
      query: (code) => ({
        url: USER_API + `/email-verify?code=${code}`,
        method: 'POST',
      }),
    }),

    sendVerificationEmail: builder.mutation<undefined, undefined>({
      query: () => ({
        url: USER_API + '/send-verification-email',
        method: 'POST',
      }),
    }),

    recoverPassword: builder.mutation<UserResponseDto, { email: string }>({
      query: (body) => ({
        url: USER_API + '/recover-password',
        method: 'POST',
        body,
      }),
    }),

    changePassword: builder.mutation<UserResponseDto, { newPassword: string; token: string }>({
      query: (body) => ({
        url: USER_API + '/change-password',
        method: 'PATCH',
        body,
      }),
    }),

    playGame: builder.mutation<UserResponseDto, { roomId: string }>({
      query: (body) => ({
        url: USER_API + '/play-game?roomId=' + body.roomId,
        method: 'POST',
      }),
    }),

    addContactToBrevoList: builder.mutation<undefined, { listId: string }>({
      query: (body) => ({
        url: USER_API + '/brevo-add-to-list?listId=' + body.listId,
        method: 'POST',
      }),
    }),

    sendContactEmail: builder.mutation<undefined, { subject: string; content: string }>({
      query: (body) => ({
        url: USER_API + '/contact-email',
        method: 'POST',
        body,
      }),
    }),

    addChild: builder.mutation<
      { code: 'USER_ADDED' | 'USER_INVITED' },
      { childEmail: string; firstName: string; lastName: string }
    >({
      query: (input) => ({
        url:
          USER_API +
          '/children?childEmail=' +
          input.childEmail +
          '&firstName=' +
          input.firstName +
          '&lastName=' +
          input.lastName,
        method: 'POST',
      }),
      invalidatesTags: ['Children'],
    }),

    resendInvitation: builder.mutation<undefined, { childEmail: string }>({
      query: (input) => ({
        url: USER_API + '/children/resend?childEmail=' + input.childEmail,
        method: 'POST',
      }),
    }),

    getChildren: builder.query<
      {
        children: {
          email: string;
          firstName: string;
          lastName: string;
          code: 'USER_ADDED' | 'USER_INVITED';
          parentAddedAtSeconds: number;
        }[];
        maxNoOfChildren: number;
      },
      undefined
    >({
      query: () => USER_API + '/children',
      providesTags: ['Children'],
    }),

    deleteChild: builder.mutation<undefined, { childEmail: string }>({
      query: (input) => ({
        url: USER_API + '/children?childEmail=' + input.childEmail,
        method: 'DELETE',
      }),
      invalidatesTags: ['Children'],
    }),

    removeParent: builder.mutation<undefined, undefined>({
      query: () => ({
        url: USER_API + '/parent',
        method: 'DELETE',
      }),
      invalidatesTags: ['Children'],
    }),
  }),
});

export const userApiReducer = userApi.reducer;

export const {
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useSendVerificationEmailMutation,
  useVerifyEmailMutation,
  useRecoverPasswordMutation,
  useChangePasswordMutation,
  usePlayGameMutation,
  useAddContactToBrevoListMutation,
  useSendContactEmailMutation,
  useAddChildMutation,
  useGetChildrenQuery,
  useDeleteChildMutation,
  useRemoveParentMutation,
  useResendInvitationMutation,
} = userApi;
