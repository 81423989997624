import { FunctionComponent, useEffect, useState } from 'react';
import { Heading, Tooltip } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CREATE_PATH, MINI_GAME_CREATE_PATH } from '../../utils/constants';
import { useGetConfigQuery } from '../../redux/apis/config';
import { useGetRoomsQuery } from '../../redux/apis/rooms';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ListRoomItem } from '../../components/gameListItem/ListRoomItem';
import plusIcon from '../../images/plus.svg';
import littleArrow from '../../images/littleArrow.svg';
import styled from 'styled-components';
import { showModal } from '../../redux/slices/modal.slice';
import { clearGameState, getGameState } from '../../redux/slices/game.slice';
import { Room } from '../../redux/models/room.model';
import { ListEventItem } from '../../components/gameListItem/ListEventItem';
import { Bar } from '../../basicComponents/bar/Bar';
import { stopConfettiInner } from '../../components/confetti/confetti';
import { AlldoneDialog } from '../../components/plansAndBilling/AlldoneDialog';

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
`;

const PlusButton = styled('div')`
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 30px;
  border-top: 1px solid #7c2000;
  border-right: 1px solid #7c2000;
  border-bottom: 6px solid #7c2000;
  border-left: 3px solid #7c2000;
  background: #ff7300;
`;

const ArrowButton = styled('div')`
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 30px;
  border-top: 1px solid #440d9b;
  border-right: 1px solid #440d9b;
  border-bottom: 6px solid #440d9b;
  border-left: 3px solid #440d9b;
  background: #896dff;
`;

const Plus = styled('img')`
  height: 24px;
  position: relative;
  top: 3px;
  left: 3px;
`;

const TextButton = styled('div')`
  color: #ff7300;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-left: 20px;
  width: 100%;
`;

const ScrollContainer = styled('div')`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
`;

const Line = styled('div')`
  height: 1px;
  width: 100%;
  border-top: 1px solid #999;
`;
export const MyGamesPage: FunctionComponent = () => {
  stopConfettiInner();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const getRooms = useGetRoomsQuery(undefined);
  const getConfig = useGetConfigQuery(undefined);
  const [showNewSub, setShowNewSub] = useState('');

  const { room } = useAppSelector(getGameState);

  useEffect(() => {
    getRooms.refetch();
  }, []);

  useEffect(() => {
    if (room) {
      room?.connection.close();
      dispatch(clearGameState(null));
    }
  }, [room]);

  useEffect(() => {
    if (queryParams.has('plan')) {
      setShowNewSub(queryParams.get('plan')!);
    }
  }, [queryParams]);

  const onNewGame = (isEvent = false) => {
    if ((getRooms.data?.length ?? 0) >= (getConfig.data?.MaxNoOfConcurrentRooms || Infinity))
      return dispatch(
        showModal({
          type: 'danger',
          message: `You can only have ${getConfig.data?.MaxNoOfConcurrentRooms} open room(s) at a time. Delete one of the rooms to create a new one.`,
        })
      );

    navigate(CREATE_PATH + `?isEvent=${isEvent}`);
  };

  return (
    <>
      <Layout>
        <Layout.Panel style={{ maxWidth: '400px' }}>
          <Heading as="h1" sx={{ fontSize: 3, textAlign: 'center' }}>
            Games
          </Heading>
          <Heading
            as="h1"
            sx={{
              fontSize: 1,
              mb: 3,
              textAlign: 'center',
              color: '#A792FF',
              fontWeight: 100,
              textDecoration: 'underline',
            }}
          >
            <Tooltip aria-label="If open room limit is reached, delete rooms below" direction="s">
              {getRooms.data && getConfig.data
                ? `Open rooms: ${getRooms.data?.length}/${getConfig.data?.MaxNoOfConcurrentRooms}`
                : ''}
            </Tooltip>
          </Heading>

          <ScrollContainer>
            {Object.entries(groupByEvent(getRooms.data?.filter((r) => r.event) ?? [])).map(
              (event) => (
                <ListEventItem eventName={event[0]} key={event[0]} rooms={event[1]} />
              )
            )}
            {(getRooms.data?.filter((r) => !r.event) ?? []).map((room) => (
              <ListRoomItem key={room.id} room={room} />
            ))}
            {getRooms.data?.length ?? 0 > 0 ? <Line /> : null}
          </ScrollContainer>

          <Bar style={{ position: 'relative', bottom: '0px' }} />

          <Row onClick={() => onNewGame()}>
            <PlusButton>
              <Plus alt="plus" src={plusIcon} />
            </PlusButton>
            <TextButton>Start a game</TextButton>
          </Row>
          {getConfig.data?.EventsFeature ? (
            <Row onClick={() => onNewGame(true)}>
              <PlusButton>
                <Plus alt="plus" src={plusIcon} />
              </PlusButton>
              <TextButton>Start multiple games</TextButton>
            </Row>
          ) : null}
          {getConfig.data?.CheckInQuestionsFeature ? (
            <Row onClick={() => navigate(MINI_GAME_CREATE_PATH)}>
              <ArrowButton>
                <Plus alt="arrowIcon" src={littleArrow} />
              </ArrowButton>
              <TextButton style={{ color: '#896dff' }}>Check-in questions</TextButton>
            </Row>
          ) : null}
        </Layout.Panel>
      </Layout>

      <AlldoneDialog
        open={!!showNewSub}
        planName={showNewSub}
        setOpen={(b: boolean) => setShowNewSub(b ? 'plan' : '')}
      />
    </>
  );
};

function groupByEvent(rooms: Room[]) {
  const groupedObjects: { [key: string]: Room[] } = {};

  for (const object of rooms) {
    const event = object.event;

    if (!groupedObjects[event]) {
      groupedObjects[event] = [];
    }

    groupedObjects[event].push(object);
  }

  return groupedObjects;
}
