import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ActiveAddon } from "./ActiveAddon";
import { useGetSubscriptionStatusQuery } from "../../redux/apis/payment";
import { allAddonsData } from "./addonData";
import { useListConfigsQuery } from "../../redux/apis/config";

interface ActiveAddonCardProps {
  title: string;
  expiryDate: string;
  usageInfo?: string;
}

const ActiveAddonsSection: React.FC = () => {
  const subs = useGetSubscriptionStatusQuery(undefined);
  const configs = useListConfigsQuery(undefined);
  const [addons, setAddons] = useState<ActiveAddonCardProps[]>([]);

  useEffect(() => {
    if (!subs.isSuccess || !configs.isSuccess) return;
    const activeAddOns = subs.data
      ?.filter((s) => s.isSubscriptionActive && s.isTemporaryAddOnSubscription && s.plan !== "KICKSTARTER")
      .map((sub) => {
        const MaxNoOfGamesPerMonth =
          configs.data?.[sub.plan].MaxNoOfGamesPerMonth;
        return {
          title: allAddonsData[sub.plan]?.title ?? sub.plan,
          usageInfo:
            MaxNoOfGamesPerMonth > 0
              ? `${sub.playedThisMonth}/${MaxNoOfGamesPerMonth} games used`
              : undefined,
          expiryDate: `Add-on expires ${new Date(
            sub.endOfCurrentBillingPeriod! * 1000
          ).toLocaleDateString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}`,
        };
      });

    setAddons(activeAddOns);
  }, [subs, configs]);

  if (addons.length === 0) return <></>;

  return (
    <SectionWrapper>
      <Container itemCount={addons.length}>
        <SectionTitle>Active add-ons</SectionTitle>
        <CardsContainer>
          <CardsGrid itemCount={addons.length}>
            {addons.map((addon, index) => (
              <CardColumn key={index}>
                <ActiveAddon {...addon} />
              </CardColumn>
            ))}
          </CardsGrid>
        </CardsContainer>
      </Container>
    </SectionWrapper>
  );
};

// Styling
const SectionWrapper = styled("section")`
  background: #ffeae4;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 60px 0px;
  position: relative;
  top: 60px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 0px;
  }
`;

const Container = styled("div")<{ itemCount: number }>`
  width: ${({ itemCount }) => (itemCount === 2 ? "800px" : "1200px")};
  max-width: 100%;
  transition: width 0.3s ease;

  @media (max-width: 991px) {
    max-width: 90%;
  }
`;

const SectionTitle = styled("h1")`
  color: #333;
  text-align: center;
  margin: 0 0 20px;
  font: 700 32px "Town 80 Text", sans-serif;
`;

const CardsContainer = styled("div")`
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CardsGrid = styled("div")<{ itemCount: number }>`
  display: grid;
  gap: 20px;

  /* Dynamically adjust columns based on the number of items */
  grid-template-columns: ${({ itemCount }) =>
    itemCount === 1
      ? "1fr"
      : itemCount === 2
      ? "repeat(2, 1fr)" // Two items, equal width
      : "repeat(3, 1fr)"};

  justify-content: ${({ itemCount }) =>
    itemCount === 2 ? "center" : "initial"};

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const CardColumn = styled("div")`
  width: 100%;
  max-width: 400px; /* Set a consistent maximum width */
  margin: 0 auto; /* Center single cards in their grid column */

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

export default ActiveAddonsSection;
