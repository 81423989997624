import { createSelector, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { RoomState } from '../models/colyseumSchemas/RoomState';
import { Room } from 'colyseus.js';
import { RootState } from '../store';
import { Player } from '../models/colyseumSchemas/Player';

type ConnectionStatus = 'initial' | 'connected' | 'error';

export type PlayerProps = { id: string; isCurrentPlayer?: boolean } & Pick<
  Player,
  'nickname' | 'avatar' | 'isConnected' | 'isReady' | 'points' | 'steps' | 'skips'
>;

type GameStoreType = Partial<RoomState> & {
  connectionStatus: ConnectionStatus;
  room?: Room<RoomState>;
  playersArr: PlayerProps[];
};

const gameSlice = createSlice<GameStoreType, SliceCaseReducers<GameStoreType>>({
  name: 'gameSlice',
  initialState: {
    connectionStatus: 'initial' as ConnectionStatus,
    playersArr: [],
  },
  reducers: {
    setConnectionStatus: (state, action: PayloadAction<ConnectionStatus>) => {
      state.connectionStatus = action.payload;
    },
    setGameState: (state, action: PayloadAction<Partial<RoomState>>) => {
      state.gameState = action.payload.gameState;
      state.deck = action.payload.deck;
      state.deckIndexes = action.payload.deckIndexes;
      state.leftStepsThisTurn = action.payload.leftStepsThisTurn;
      state.name = action.payload.name;
      state.playerIdLightning = action.payload.playerIdLightning;
      state.playerIdTurn = action.payload.playerIdTurn;
      state.players = action.payload.players;
      const playersEntries = Array.from(action.payload.players?.entries() ?? []);
      state.playersArr = playersEntries.map((p) => ({ id: p[0], ...p[1] } as PlayerProps));
      state.observers = action.payload.observers;
      state.pointsThisTurn = action.payload.pointsThisTurn;
      state.sparks = action.payload.sparks;
      state.stepsThisTurn = action.payload.stepsThisTurn;
      state.turn = action.payload.turn;
      state.turnPhase = action.payload.turnPhase;
      state.randomCardPlayed = action.payload.randomCardPlayed;
    },
    setRoom: (state, action: PayloadAction<Room<RoomState> | undefined>) => {
      state.room = action.payload;
    },
    clearGameState: (state) => {
      state.connectionStatus = 'initial';
      state.room = undefined;
      state.gameState = undefined;
      state.deck = undefined;
      state.deckIndexes = undefined;
      state.leftStepsThisTurn = undefined;
      state.name = undefined;
      state.playerIdLightning = undefined;
      state.playerIdTurn = undefined;
      state.players = undefined;
      state.playersArr = [];
      state.observers = undefined;
      state.pointsThisTurn = undefined;
      state.sparks = undefined;
      state.stepsThisTurn = undefined;
      state.turn = undefined;
      state.turnPhase = undefined;
      state.randomCardPlayed = false;
    },
  },
});
export const { setConnectionStatus, setGameState, setRoom, clearGameState } = gameSlice.actions;

export const getConnectionStatus = (state: RootState): ConnectionStatus =>
  state.game.connectionStatus;

export const getRoom = (state: RootState): Room<RoomState> | undefined => state.game.room;
export const getGameState = createSelector(
  (state: RootState) => {
    const areAllReady =
      state.game.playersArr
        .filter((player) => player.isConnected)
        .every((player) => player.isReady) || false;
    const noOfReady = state.game.playersArr.filter(
      (player) => player.isConnected && player.isReady
    ).length;
    return {
      noOfReady,
      areAllReady,
      ...state.game,
      playersArr: state.game.playersArr.map(
        (p) =>
          ({
            ...p,
            isCurrentPlayer: state.player.currentPlayerId == p.id,
          } as PlayerProps)
      ),
    };
  },
  (state) => state
);

export const gameReducer = gameSlice.reducer;
