import { Text, Box, themeGet } from '@primer/react';
import { getSparkName, SparkProps } from '../../utils/sparks';
import { SparkIcon } from '../../basicComponents/sparkIcon/SparkIcon';
import { usePlayers } from '../../hooks/usePlayers';
import styled from 'styled-components';

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const SparkIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 40px;
  margin: 0 ${themeGet('space.1')};
`;

const SparkDescription = styled(Text)`
  line-height: 1;
  font-weight: 600;
`;

export type SparkItemProps = Pick<SparkProps, 'sparkType' | 'fromPlayerId' | 'toPlayerId'>;

export const SparkItem = ({ toPlayerId, fromPlayerId, sparkType }: SparkItemProps) => {
  const { youPlayer, getPlayerNickname } = usePlayers();

  const fromPlayerNickname =
    youPlayer?.id === fromPlayerId ? 'You' : getPlayerNickname(fromPlayerId);
  const toPlayerNickname = youPlayer?.id === toPlayerId ? 'you' : getPlayerNickname(toPlayerId);

  const sparkName = getSparkName(sparkType);
  const sparkDescription =
    fromPlayerId === toPlayerId
      ? `${fromPlayerNickname} played the "${sparkName}" card!`
      : `${fromPlayerNickname} gave ${toPlayerNickname} the "${sparkName}" card!`;

  return (
    <Container>
      <SparkIconContainer>
        <SparkIcon sparkType={sparkType} />
      </SparkIconContainer>
      <SparkDescription>{sparkDescription}</SparkDescription>
    </Container>
  );
};
