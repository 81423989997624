import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { themeGet } from '@primer/react';
import spaceCJ from '../../images/board/space-CJ.svg';
import spaceCR from '../../images/board/space-CR.svg';
import spaceCJR from '../../images/board/space-CJR.svg';
import spaceJR from '../../images/board/space-JR.svg';
import { exhaustiveCheck } from '../../utils/exhaustiveCheck';
import { DeckType } from '../../redux/models/colyseumSchemas/Deck';
import { Card as CardModel } from '../../redux/models/card.model';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useCardImage } from '../../hooks/useCardImage';

type CardContainerProps = { deck?: DeckType; backgroundImage?: string; title?: string };

const CardContainer = styled('div')<CardContainerProps>`
  width: 100%;
  max-width: 400px;
  min-height: 400px;
  min-width: 200px;
  --card-text-color: ${({ deck }) => themeGet(`colors.${deck}.text`)};
  --card-border-color: ${({ deck }) => themeGet(`colors.${deck}.medium`)};
  --card-bg-color: ${({ deck }) => themeGet(`colors.${deck}.bg`)};

  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-color: white;
  background-size: cover;
  border-radius: 15px;
  color: var(--card-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px #5555;
`;

const InternalMargin = styled('div')<CardContainerProps>`
  position: absolute;
  margin: 16px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  border-radius: 15px;
  border-width: 4px;
  border-color: ${({ deck }) => themeGet(`colors.${deck}.medium`)};
  border-style: solid;
`;

const Center = styled('div')<CardContainerProps>`
  flex: 1;
  position: relative;
  top: 0px;
  margin-bottom: ${({ deck }) =>
    deck != 'lightning' ? (deck != 'random' ? '80px' : '45px') : '10px'};
  margin-top: ${({ deck }) => (deck != 'random' && deck != 'lightning' ? '70px' : '20px')};
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

const CardTitle = styled('span')<CardContainerProps>`
  font-size: 1.4em;
  font-weight: bold;
  padding-top: 30px;
  padding-right: 60px;
  padding-left: 60px;
`;

const CardInstruction1 = styled('span')<CardContainerProps>`
  margin-top: ${({ title }) => (!title ? '70px' : '10px')};
  font-size: 18px;
  font-weight: 600;
  height: 25px;
  padding-left: 40px;
  padding-right: 40px;
`;

const CardInstructionLightning = styled('span')`
  font-size: 13px;
  height: 30px;
  color: #999;
  font-weight: 700;
  max-width: 250px;
  width: 100%;
`;

const CardText = styled('span')<{ width: number }>`
  font-size: ${({ width }) => (width < 400 ? '16px' : '18px')};
  padding: 20px 30px 20px 30px;
  text-wrap: balance;
`;

const CardInstruction2 = styled('span')<CardContainerProps>`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
  text-wrap: balance;
  min-width: 200px;
  color: ${({ deck }) => themeGet(`colors.${deck}.medium`)};
  margin-bottom: 15px;
`;

const RandomNextCardsImg = styled('img')`
  width: 50px;
  margin-left: 10px;
  margin-top: -16px;
  position: absolute;
`;

type CardPointsProps = { deck: DeckType };

const CardPoints = styled('div')<CardPointsProps>`
  border-radius: 50%;
  position: absolute;
  top: 0px;
  width: 70px;
  height: 70px;
  font-weight: bold;
  font-size: 1.5em;
  color: ${({ deck }) => themeGet(`colors.${deck}.dark`)};
  line-height: 65px;
  text-align: center;
  align-self: flex-start;
  border-color: ${({ deck }) => themeGet(`colors.${deck}.medium`)};
  border-width: 4px;
  border-style: solid;
  margin: 3px;
  background-color: white;
  z-index: 3;
`;

const CardSteps = styled('span')<CardPointsProps>`
  padding: 0.3em 1em 0.4em 1em;
  position: absolute;
  bottom: calc(15% - 15px);
  border-radius: 8px;
  color: white;
  background-color: ${({ deck }) => themeGet(`colors.${deck}.medium`)};
  line-height: 20px;
  height: 30px;
`;

const CardOptions = styled('div')<{ width: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${({ width }) => (width < 400 ? '15px' : '17px')};
  line-height: 30px;
  padding: 0 45px;
  margin: 0;
  text-align: left;
`;

const CardOption = styled('div')`
  line-height: 17px;
  padding-bottom: 15px;
`;

export const getPoints = (deck: DeckType) => {
  switch (deck) {
    case 'cozy':
      return 30;
    case 'juicy':
      return 40;
    case 'real':
      return 50;
    case 'random':
      return 30;
    case 'lightning':
      return 60;
    default:
      exhaustiveCheck(deck);
  }
};

const getStepSymbolForDecks = (decks: string | undefined) => {
  switch (decks) {
    case 'cozy_juicy':
      return spaceCJ;
    case 'juicy_real':
      return spaceJR;
    case 'cozy_real':
      return spaceCR;
    case 'cozy_juicy_real':
      return spaceCJR;
    default:
      return spaceCJR;
  }
};

type CardProps = Pick<
  CardModel,
  | 'deck'
  | 'points'
  | 'title'
  | 'instruction1'
  | 'instruction2'
  | 'option1'
  | 'option2'
  | 'option3'
  | 'option4'
  | 'steps'
  | 'deckSelectionAfterRandom'
>;

export const Card: FunctionComponent<
  CardProps & { readonly children?: React.ReactNode; readonly noPoints?: boolean }
> = ({
  children,
  points: explicitPoints,
  deck,
  title,
  instruction1,
  instruction2,
  option1,
  option2,
  option3,
  option4,
  noPoints,
}) => {
  const { width } = useWindowDimensions();
  const points = explicitPoints || getPoints(deck);
  const backgroundImg = useCardImage(deck, 'pattern');

  return (
    <CardContainer backgroundImage={backgroundImg} deck={deck}>
      <InternalMargin deck={deck} />
      {!noPoints ? <CardPoints deck={deck}>{`${points}`}</CardPoints> : null}
      <Center deck={deck}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {title ? <CardTitle deck={deck}>{title}</CardTitle> : <div style={{ height: '10px' }} />}
          {instruction1 ? (
            deck == 'lightning' ? (
              <CardInstructionLightning>{instruction1}</CardInstructionLightning>
            ) : (
              <CardInstruction1 title={title}>{instruction1}</CardInstruction1>
            )
          ) : null}
        </div>
        <CardText width={width}>{children}</CardText>
        {option1 ? (
          <CardOptions width={width}>
            <CardOption key={1}>1. {option1}</CardOption>
            {option2 ? <CardOption key={2}>2. {option2}</CardOption> : null}
            {option3 ? <CardOption key={3}>3. {option3}</CardOption> : null}
            {option4 ? <CardOption key={4}>4. {option4}</CardOption> : null}
          </CardOptions>
        ) : null}
        <CardInstruction2 deck={deck}>{instruction2}</CardInstruction2>
      </Center>
    </CardContainer>
  );
};
