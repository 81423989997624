import React from 'react';
import styled from 'styled-components';
import PlanHeader from './PlanHeader';
import FeatureSection from './FeatureSection';
import FeatureRow from './FeatureRow';

interface PricingMatrixProps {
  // Add any necessary props here
}

const PricingMatrix: React.FC = () => {
  return (
      <MatrixContainer>
        <MatrixContent>
          <PlanHeader />
          <Divider />
          <FeatureSection title="Highlights">
            <FeatureRow
            companyValue="32"
            enterpriseValue="Custom"
            highlighted
            infoText="The maximum number of players that can participate in an event simultaneously."
            name="Max. players per event"
            proValue="18"
          />
          <FeatureRow
            companyValue="8"
            enterpriseValue="Custom"
            infoText="The maximum number of players that can join a single game room."
            name="Max. players in a single game room"
            proValue="6"
          />
          <FeatureRow
            companyValue="4"
            enterpriseValue="Custom"
            highlighted
            infoText="The maximum number of game rooms that can be playing at the same time."
            name="Max. simultaneous game rooms"
            proValue="3"
          />
          <FeatureRow
            companyValue="10"
            enterpriseValue="Unlimited"
            infoText="The total number of game rooms that can be played each month."
            name="Max. game rooms per month"
            proValue="6"
          />
          <FeatureRow
            companyValue="6"
            enterpriseValue="12"
            highlighted
            infoText="The total number of themed editions you can choose from. Scroll down to the ‘themed-question editions’ section to learn more."
            name="Number of editions"
            proValue="3"
          />
          <FeatureRow
            companyValue
            enterpriseValue
            infoText="Customize your plan by adding extra features like more simultaneous game rooms, larger player capacity, and themed editions to perfectly fit your team’s needs."
            name="Access to add-ons"
            proValue
          />
          <FeatureRow
            companyValue
            enterpriseValue
            highlighted
            infoText="A quick, engaging way to connect during any meeting with fun, thought-provoking questions."
            name="Check-in question game"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue="2+ /year"
            infoText="Our experienced facilitators will lead the entire event, making it stress-free for you and your team."
            name="Fully facilitated event hosted by us"
            proValue={false}
          />
        </FeatureSection>
        <FeatureSection title="Event management">
          <FeatureRow
            companyValue
            enterpriseValue
            highlighted
            infoText="Easily oversee game sessions, track progress, and guide teams as they play — all without participating directly. Perfect for facilitators and event organizers."
            name="Monitor games in real-time"
            proValue
          />
          <FeatureRow
            companyValue
            enterpriseValue
            infoText="Easily advance players along the board to keep things flowing during time-sensitive sessions."
            name="Speed up gameplay"
            proValue
          />
          <FeatureRow
            companyValue
            enterpriseValue
            highlighted
            infoText="Easily set up multiple game rooms at once to streamline event setup for larger events."
            name="Bulk create game rooms"
            proValue
          />
          <FeatureRow
            companyValue
            enterpriseValue
            infoText="Generate and copy invite links for multiple rooms in one go, making it simple to send links to participants."
            name="Bulk copy invite links"
            proValue
          />
          <FeatureRow
            companyValue
            enterpriseValue
            highlighted
            infoText="Quickly clean up after your event by bulk deleting rooms that are no longer needed."
            name="Bulk delete game rooms"
            proValue
          />
        </FeatureSection>
        <FeatureSection title="Themed-question editions">
        <FeatureRow
            companyValue
            enterpriseValue
            highlighted
            infoText="Classic team-building questions to foster connection and collaboration among team members."
            name="Team Building: Level 1"
            proValue
          />
          <FeatureRow
            companyValue
            enterpriseValue
            infoText="Intermediate team-building questions designed to deepen team relationships and build trust."
            name="Team Building: Level 2"
            proValue
          />
          <FeatureRow
            companyValue
            enterpriseValue
            highlighted
            infoText="Advanced team-building designed for teams looking to push the boundaries of trust, communication, and collaboration."
            name="Team Building: Level 3"
            proValue={false}
          />
          <FeatureRow
            companyValue
            enterpriseValue
            infoText="Engaging activities that encourage an agile mindset, helping teams adapt and collaborate more effectively."
            name="Agile Mindset"
            proValue
          />
          <FeatureRow
            companyValue
            enterpriseValue
            highlighted
            infoText="A focus on fostering conversations around diversity, inclusion, and creating a sense of belonging in the workplace."
            name="Diversity, Inclusion & Belonging"
            proValue={false}
          />
          <FeatureRow
            companyValue
            enterpriseValue
            infoText="Designed to develop leadership skills and encourage thoughtful decision-making."
            name="Leadership"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue
            highlighted
            infoText="A game experience designed for introverts, ensuring everyone feels comfortable."
            name="Introverts"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue
            infoText="Questions that help break the ice and foster meaningful connections between participants."
            name="Networking"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue
            highlighted
            infoText="Exercises that encourage out-of-the-box thinking and inspire creative problem-solving."
            name="Creativity"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue
            infoText="A focus on empowering women,with questions designed to encourage confidence."
            name="Women Empowerment"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue
            highlighted
            infoText="Game editions available in both Spanish and German, designed for teams speaking these languages."
            name="German"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue
            infoText="Game editions available in both Spanish and German, designed for teams speaking these languages."
            name="Spanish"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue
            highlighted
            infoText="Imagine a custom version of Cozy Juicy Real, with questions based on your company’s values, or another theme of your choice."
            name="Custom Edition"
            proValue={false}
          />
        </FeatureSection>
        <FeatureSection title="Support">
          <FeatureRow
            companyValue
            enterpriseValue
            highlighted
            infoText="Step-by-step video guides to help you get started quickly and easily."
            name="Onboarding 'How to' videos"
            proValue
          />
          <FeatureRow
            companyValue
            enterpriseValue
            infoText="Kick-start your team’s experience with a live demo event that shows how an event runs and lets them ask questions."
            name="Live onboarding demo event"
            proValue="cross"
          />
          <FeatureRow
            companyValue="cross"
            enterpriseValue
            highlighted
            infoText="In-depth onboarding sessions with our team to cover all aspects of the platform and ensure seamless integration."
            name="Premium onboarding sessions"
            proValue="cross"
          />
          <FeatureRow
            companyValue="cross"
            enterpriseValue
            infoText="Priority access to our support team, ensuring any issues or questions are resolved quickly."
            name="Premium support"
            proValue="cross"
          />
        </FeatureSection>
        <FeatureSection title="Add-ons">
        <FeatureRow
            companyValue
            enterpriseValue
            highlighted
            infoText="Access to a wide range of themed question sets, each crafted to target specific team-building needs like leadership, diversity, and creativity."
            name="All themed-question editions"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue
            infoText="Create a personalized version of Cozy Juicy Real with questions and themes tailored to your company’s unique culture and objectives."
            name="Custom edition"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue={false}
            highlighted
            infoText="Invite up to 12 players in each game room, making it easier to accommodate larger teams without splitting into smaller groups."
            name="Increase player capacity: 12 Players"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue={false}
            infoText="Host large-scale team-building events by adding additional game rooms and increasing the number of simultaneous games."
            name="Large events"
            proValue={false}
          />
          <FeatureRow
            companyValue={false}
            enterpriseValue="2/year included"
            highlighted
            infoText="Our expert facilitators will handle everything, ensuring a smooth and engaging event with no extra effort from your side."
            name="Facilitated events"
            proValue={false}
          />
        </FeatureSection>
      </MatrixContent>
    </MatrixContainer>
  );
};

const MatrixContainer = styled('section')`
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  align-self: center;
  display: flex;
  margin-top: 90px;
  flex-direction: column;
  overflow: visible;
  padding-bottom: 15px;
  min-height: 100%;
  width: 70%;

  @media (max-width: 991px) {
    margin-top: 40px;
    width: 100%;
  }
`;

const MatrixContent = styled('div')`
  border-radius: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Divider = styled('hr')`
  background-color: #e0dfe5;
  height: 2px;
  border: none;
  margin: 0;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default PricingMatrix;
