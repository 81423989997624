import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { authLocalReducer, authSessionReducer } from './slices/auth.slice';
import { authApi } from './apis/auth.api';
import { userApi } from './apis/user.api';
import { modalReducer } from './slices/modal.slice';
import { errorMiddleware } from './errorMiddleware';
import { paymentApi } from './apis/payment';
import { deckSlice } from './slices/deck.slice';
import { gameReducer } from './slices/game.slice';
import { configApi } from './apis/config';
import { roomsApi } from './apis/rooms';
import { colyseusApi } from './apis/colyseus';
import { playerReducer } from './slices/player.slice';
import { cardsApi } from './apis/cards';
import { menuReducer } from './slices/menu.slice';
import { playerApi } from './apis/players';

const middlewares = [
  authApi.middleware,
  userApi.middleware,
  paymentApi.middleware,
  configApi.middleware,
  roomsApi.middleware,
  colyseusApi.middleware,
  cardsApi.middleware,
  playerApi.middleware,
  errorMiddleware,
];

const allReducers = combineReducers({
  authLocal: authLocalReducer,
  authSession: authSessionReducer,
  modal: modalReducer,
  deck: deckSlice.reducer,
  game: gameReducer,
  player: playerReducer,
  menu: menuReducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [roomsApi.reducerPath]: roomsApi.reducer,
  [colyseusApi.reducerPath]: colyseusApi.reducer,
  [cardsApi.reducerPath]: cardsApi.reducer,
  [playerApi.reducerPath]: playerApi.reducer,
});

const rootReducer = (state: any, action: any) => allReducers(state, action);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
