import { Button, themeGet } from '@primer/react';
import styled from 'styled-components';
import './sparkButton.css';
import { FunctionComponent, MutableRefObject } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { getGameState } from '../../redux/slices/game.slice';
import { Size } from '@primer/react/lib/Button/types';
import React from 'react';

const SparkBtn = styled(Button)`
  color: white;
  background-color: ${themeGet('colors.spark.other')};
  border-color: ${themeGet('colors.spark.dark')};
  margin-top: 2px;
  margin-bottom: 0px;
  width: 70%;
  height: 35px;
  max-width: 200px;
  border-left-width: 4px;
  border-bottom-width: 4px;
  &:hover:not(:disabled) {
    background-color: ${themeGet('colors.spark.dark')};
  }
  &:focus-visible:not(:disabled) {
    outline: 4px solid ${themeGet('colors.spark.dark')};
  }
`;

type SparkBtnProps = {
  onClick: () => void;
  forwardedRef?: MutableRefObject<null>;
  size?: Size | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

export const SparkButton: FunctionComponent<SparkBtnProps> = ({ children, ...props }) => {
  const { turnPhase } = useAppSelector(getGameState);

  return (
    <>
      <SparkBtn
        ref={props.forwardedRef}
        style={{
          animation: turnPhase == 'score' ? 'pulse 0.5s 6' : '',
        }}
        {...props}
      >
        {children}
      </SparkBtn>
    </>
  );
};
