import { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  Button,
  Heading,
  FormControl,
  ActionMenu,
  ActionList,
  Box,
  ButtonGroup,
  Text,
} from '@primer/react';
import { TriangleDownIcon } from '@primer/octicons-react';
import { Layout } from '../../components/layout/Layout';
import { useNavigate } from 'react-router-dom';
import { MINI_GAME_CREATE_PATH, MINI_GAME_PATH, MY_GAMES_PATH } from '../../utils/constants';

import { buttonStyle, textInputStyle } from '../../theme/theme';
import { useGetAvailableExpansionsQuery } from '../../redux/apis/cards';
import { BackButtom } from '../../basicComponents/backButtom/BackButtom';
import { Bar } from '../../basicComponents/bar/Bar';
import { useGetGameCodeQuery } from '../../redux/apis/auth.api';
import { useClipboard } from '../../hooks/useClipboard';
import { useWindowLocation } from '../../hooks/useWindowLocation';

export const CreateMinigamePage: FunctionComponent = () => {
  const navigate = useNavigate();

  const [selectedExpansion, setSelectedExpansion] = useState(0);
  const [openExpansion, setOpenExpansion] = useState(false);
  const getExpansions = useGetAvailableExpansionsQuery({ miniGame: true });
  const getGameCode = useGetGameCodeQuery(
    {
      expansion: getExpansions.data?.expansions[selectedExpansion].name,
    },
    { skip: !getExpansions.isSuccess }
  );
  const [link, setLink] = useState('');
  const { copy } = useClipboard();
  const { href } = useWindowLocation();

  const anchorRef = useRef(null);

  useEffect(() => {
    if (!getGameCode.isUninitialized) getGameCode.refetch();
  }, [selectedExpansion]);

  useEffect(() => {
    if (getGameCode.isSuccess) {
      setLink(
        href.replace(MINI_GAME_CREATE_PATH, '') +
          MINI_GAME_PATH.replace(':code', getGameCode.data!.code)
      );
    }
  }, [getGameCode]);

  return (
    <Layout>
      <Layout.Panel style={{ position: 'relative' }}>
        <BackButtom goBack={() => navigate(MY_GAMES_PATH)} />
        <Heading as="h1" sx={{ fontSize: 3, mb: 3, textAlign: 'center' }}>
          Check-in questions
        </Heading>
        <FormControl sx={{ paddingTop: '15px' }}>
          <FormControl.Label>Select an edition</FormControl.Label>
          <div
            aria-expanded={openExpansion}
            onClick={() => setOpenExpansion(!openExpansion)}
            ref={anchorRef}
            style={{
              display: 'flex',
              width: '100%',
              ...textInputStyle,
              borderColor: '#A792FF',
              color: '#3B147A',
              fontWeight: 500,
              paddingTop: '6px',
              paddingBottom: '11px',
              paddingRight: '16px',
              paddingLeft: '16px',
              justifyContent: 'space-between',
              alignContent: 'center',
            }}
          >
            <>
              {getExpansions.data?.expansions[selectedExpansion].name ?? 'Select'}
              <div>
                <TriangleDownIcon fill="#A792FF" size={30} />
              </div>
            </>
          </div>
          <ActionMenu anchorRef={anchorRef} onOpenChange={setOpenExpansion} open={openExpansion}>
            <ActionMenu.Overlay align="start" width="medium">
              <ActionList>
                {(getExpansions.data?.expansions ?? []).map((expansion, index) => (
                  <div key={expansion.name}>
                    <ActionList.Item
                      disabled={expansion.disabled}
                      key={expansion.name}
                      onSelect={() => setSelectedExpansion(index)}
                      value={expansion.name}
                    >
                      {expansion.name}
                      <ActionList.TrailingVisual
                        sx={{
                          color: '#999999',
                          fontSize: '10px',
                          fontWeight: 600,
                          letterSpacing: '1px',
                        }}
                      >
                        {expansion.subtext}
                      </ActionList.TrailingVisual>
                    </ActionList.Item>
                  </div>
                ))}
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>
        </FormControl>

        <FormControl sx={{ mt: 5 }}>
          <FormControl.Label htmlFor="share-link-input">Share room link</FormControl.Label>
          <ButtonGroup sx={{ mb: 1, display: 'flex', width: '100%' }}>
            <input
              id="share-link-input"
              readOnly
              style={{
                flex: 1,
                ...textInputStyle,
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                borderColor: '#3B147A',
                borderBottom: '4px solid #3B147A',
                borderRight: '0px',
                paddingLeft: '10px',
                fontSize: '16px',
                color: '#3B147A',
                width: '100%',
              }}
              value={`${link}`}
            />
            <Button
              onClick={() => copy(`${link}`)}
              sx={{
                ...buttonStyle,
                borderBottomWidth: '4px',
                borderLeftWidth: '2px',
                borderColor: '#FF7300',
                paddingTop: '8px',
                paddingBottom: '8px',
                height: '40px',
              }}
            >
              copy
            </Button>
          </ButtonGroup>
        </FormControl>
        <Text sx={{ fontSize: '11px', color: '#896DFF', textAlign: 'left' }}>
          This link will expire in 2 hours.
          <br /> You can generate a new link anytime.
        </Text>

        <Bar />
        <div style={{ height: '40px' }} />

        <Box display="flex" flexDirection="row">
          <Button
            onClick={() => navigate(MY_GAMES_PATH)}
            size="medium"
            sx={{
              mt: 3,
              width: '100%',
              ...buttonStyle,
              borderColor: '#DB004F',
              backgroundColor: 'white',
              color: '#DB004F',
              marginRight: '7px',
              fontWeight: '100',
            }}
            type="button"
            variant="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => window.open(`${link}`, '_blank')}
            size="medium"
            sx={{ mt: 3, width: '100%', ...buttonStyle, marginLeft: '7px' }}
            type="button"
            variant="primary"
          >
            Start
          </Button>
        </Box>
      </Layout.Panel>
    </Layout>
  );
};
