import { FunctionComponent, useRef, useState } from 'react';
import { ActionList, ActionMenu, Box } from '@primer/react';
import { Player } from '../player/Player';
import { PlayerProps } from '../../redux/slices/game.slice';
import { textInputStyle } from '../../theme/theme';
import { TriangleDownIcon } from '@primer/octicons-react';

type PlayerSelectorProps = {
  players: PlayerProps[];
  value: string;
  onChange: (value: string) => void;
};

export const PlayerSelector: FunctionComponent<PlayerSelectorProps> = ({
  players,
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const selectedPlayer = players.find((player) => player.id === value);

  return (
    <>
      <div
        aria-expanded={open}
        onClick={() => setOpen(!open)}
        ref={anchorRef}
        style={{
          display: 'flex',
          width: '100%',
          ...textInputStyle,
          height: '70px',
          borderColor: '#A792FF',
          color: '#3B147A',
          fontWeight: 500,
          paddingTop: '8px',
          paddingBottom: '9px',
          paddingRight: '16px',
          paddingLeft: '16px',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        {selectedPlayer ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Player {...selectedPlayer} isShowingPoints />
          </Box>
        ) : (
          'Select a player'
        )}
        <div style={{ padding: '12px' }}>
          <TriangleDownIcon fill="#A792FF" size={30} />
        </div>
      </div>

      <ActionMenu anchorRef={anchorRef} onOpenChange={setOpen} open={open}>
        <ActionMenu.Overlay sx={{ minWidth: '200px' }} width="medium">
          <ActionList sx={{ minWidth: '200px' }}>
            {players
              .filter((p) => p.isReady)
              .map((player) => (
                <ActionList.Item key={player.id} onSelect={() => onChange(player.id)}>
                  <Player {...player} isShowingPoints />
                </ActionList.Item>
              ))}
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
    </>
  );
};
