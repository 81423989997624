import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './common';
import { Player } from '../models/player.model';

export const playerApi = createApi({
  reducerPath: 'playerApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Players'],
  endpoints: (builder) => ({
    getRoomPlayer: builder.query<Player[], { roomId: string; playerId: string }>({
      query: (i) => `/players/${i.roomId}?playerId=${i.playerId}`,
      providesTags: ['Players'],
    }),

    upsertPlayer: builder.mutation<
      Player,
      { roomId: string; reconnectionToken: string; playerId: string }
    >({
      query: (body) => ({
        url: `/players`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Players'],
    }),
  }),
});

export const playerApiReducer = playerApi.reducer;

export const { useGetRoomPlayerQuery, useUpsertPlayerMutation } = playerApi;
