import { theme as primerTheme } from '@primer/react';
import deepmerge from 'deepmerge';

export const colors = {
  accent: {
    fg: 'hsl(20, 90%, 65%)',
    emphasis: '#FF7300',
    muted: 'hsl(18, 100%, 75%)',
    subtle: 'hsl(16, 83%, 85%)',
    border: '#7C2000',
    text: '#d8651f',
  },
  cozy: {
    muted: '#f6faf1',
    bg: '#e7f1da',
    light: '#adcc63',
    medium: '#00aa98',
    dark: '#007369',
    text: '#004d46',
  },
  juicy: {
    muted: '#fffbef',
    bg: '#fff2cc',
    light: '#ffd22a',
    medium: '#f39851',
    dark: '#d8651f',
    darker: '#A53200',
    text: '#863f13',
  },
  real: {
    muted: '#fdf2ec',
    bg: '#FFF5F2',
    light: '#f68a53',
    btn: '#DB004F',
    medium: '#cd273a',
    dark: '#8b041b',
    text: '#8b041b',
  },
  random: {
    muted: '#ecf7f7',
    bg: '#dbf0f0',
    light: '#2886a7',
    medium: '#025a99',
    dark: '#154371',
    text: '#154371',
  },
  lightning: {
    muted: '#fef5f8',
    bg: '#fad1df',
    light: '#f5a1c5',
    medium: '#f5a1c5',
    dark: '#971b6d',
    text: '#971b6d',
  },
  spark: {
    muted: '#EDE9FF',
    bg: '#f2ebf5',
    light: '#e9ddee',
    medium: '#917cb0',
    new: '#BFAFFF',
    dark: '#440D9B',
    btn: '#896DFF',
    text: '#381f57',
    other: '#A792FF',
  },
};

const shadows = {
  highlight: 'inset 0 1px 0 hsla(0, 0%, 100%, 0.25)',
  inset: 'inset 0 1px 0 hsla(20, 90%, 65%, 0.2)',
  focus: '0 0 0 3px hsla(20, 90%, 65%, 0.3)',
  elevationLow: `0px 0px 0px 1px rgba(41, 48, 56, 0.06),
    0px 1px 2px rgba(41, 48, 56, 0.1),
    0px 2px 4px rgba(41, 48, 56, 0.16)`,
  elevationMedium: `0px 0px 0px 1px rgba(41, 48, 56, 0.06),
    0px 3px 6px rgba(41, 48, 56, 0.1),
    0px 5px 10px rgba(41, 48, 56, 0.12)`,
  elevationHigh: `0px 0px 0px 1px rgba(41, 48, 56, 0.06),
    0px 5px 10px rgba(41, 48, 56, 0.1),
    0px 13px 26px rgba(41, 48, 56, 0.08)`,
};

// Overrides & customises parts of Primer theme.
// See https://primer.style/react/theme-reference
export const theme = deepmerge(primerTheme, {
  fonts: {
    normal: "'Town', Helvetica, sans-serif;",
  },
  fontSizes: {
    0: '12px',
    1: '16px',
    2: '20px',
    3: '24px',
    4: '32px',
    5: '40px',
    6: '48px',
    7: '56px',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  radii: {
    1: '10px',
    2: '20px',
  },
  colorSchemes: {
    light: {
      colors: {
        fg: {
          default: '#3B147A',
        },
        selectMenu: {
          tapFocusBg: 'hsl(12, 93%, 66%)',
        },
        btn: {
          bg: 'white',
          border: colors.accent.emphasis,
          hoverBg: 'hsl(15, 100%, 90%)',
          hoverBorder: colors.accent.border,
          selectedBg: 'hsl(12, 93%, 66%)',
          activeBg: 'hsl(15, 100%, 90%)',
          disabledBg: colors.accent.muted,
          disabledBorder: colors.accent.muted,
          focusBg: colors.accent.emphasis,
          focusBorder: colors.accent.border,
          text: colors.accent.emphasis,
          primary: {
            bg: colors.accent.emphasis,
            border: colors.accent.border,
            hoverBg: 'hsl(15, 100%, 67%)',
            hoverBorder: colors.accent.border,
            selectedBg: 'hsl(12, 93%, 66%)',
            disabledBg: colors.accent.muted,
            disabledBorder: colors.accent.border,
            focusBg: colors.accent.emphasis,
            focusBorder: colors.accent.border,
          },
          outline: {
            text: colors.accent.text,
            border: colors.accent.border,
            hoverBg: '#f3f4f6',
            hoverText: colors.accent.text,
          },
        },
        ...colors,
      },
      shadows: {
        btn: {
          focusShadow: shadows.focus,
          shadowInputFocus: `0 0 0 0.2em ${colors.accent.muted}`,
          primary: {
            focusShadow: shadows.focus,
          },
        },
        card: {
          shadow: shadows.elevationLow,
        },
        cardButton: {
          shadow: shadows.elevationLow,
          focusShadow: shadows.elevationMedium,
        },
        primer: {
          shadow: shadows,
        },
      },
    },
  },
});

export const textInputStyle = {
  borderRadius: '10px',
  border: '2px solid #A792FF',
  height: '40px',
};

export const buttonStyle = {
  borderLeftWidth: '4px',
  borderBottomWidth: '4px',
  borderColor: colors.juicy.darker,
};

export const checkboxStyle = {
  borderRadius: '4px',
  borderColor: '#A792FF',
};
