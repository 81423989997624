import { FunctionComponent, useEffect, useState } from 'react';
import { Text } from '@primer/react';
import { usePlayers } from '../../hooks/usePlayers';
import { GameBoard } from '../gameBoard/GameBoard';
import { useAppSelector } from '../../redux/hooks';
import { getGameState } from '../../redux/slices/game.slice';

export const ScorePhase: FunctionComponent = () => {
  const { turnPlayer, isPlayerTurn } = usePlayers();
  const { pointsThisTurn = 0, stepsThisTurn = 0 } = useAppSelector(getGameState);

  const [diceSteps, setDiceSteps] = useState(0);

  useEffect(() => {
    let num = 0;
    const interval = setInterval(() => {
      setDiceSteps(num);
      num++;
      if (num > 6) num = 0;
    }, 50);
    setTimeout(() => {
      clearInterval(interval);
      setDiceSteps(stepsThisTurn);
    }, 2000);
  }, [stepsThisTurn]);

  const who = isPlayerTurn ? 'You' : turnPlayer?.nickname;
  return (
    <GameBoard>
      {stepsThisTurn > 0 ? (
        <Text
          as="p"
          sx={{
            maxWidth: '200px',
            width: '80%',
            borderRadius: '15px',
            border: '2px dashed #009B88',
            padding: '18px',
            background: '#FFF',
            fontSize: '14px',
            lineHeight: '22px',
            fontWeight: 600,
          }}
          textAlign="center"
        >
          {`${who} get${isPlayerTurn ? '' : 's'} ${pointsThisTurn} points and move${
            isPlayerTurn ? '' : 's'
          } `}
          <span
            style={{ fontFamily: 'monospace', fontSize: '20px', color: 'red' }}
          >{`${diceSteps}`}</span>
          {` space${stepsThisTurn > 1 ? 's' : ''}!`}
        </Text>
      ) : null}
    </GameBoard>
  );
};
