import { FunctionComponent, useState, ChangeEvent, useEffect, useRef } from 'react';
import {
  Button,
  Heading,
  Flash,
  FormControl,
  StyledOcticon,
  TextInput,
  ActionMenu,
  ActionList,
  Box,
} from '@primer/react';
import { AlertIcon, TriangleDownIcon } from '@primer/octicons-react';
import { Layout } from '../../components/layout/Layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useColyseus } from '../../hooks/useColyseus';
import { LOBBY_PATH, MY_GAMES_PATH } from '../../utils/constants';
import { useGetConfigQuery } from '../../redux/apis/config';
import {
  useCreateEventMutation,
  useCreateRoomsMutation,
  useGetRoomsQuery,
} from '../../redux/apis/rooms';
import { setManualLoading, showLoading, showModal } from '../../redux/slices/modal.slice';
import { useAppDispatch } from '../../redux/hooks';
import { buttonStyle, textInputStyle } from '../../theme/theme';
import { useGetAvailableExpansionsQuery } from '../../redux/apis/cards';
import { useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { BackButtom } from '../../basicComponents/backButtom/BackButtom';
import { Bar } from '../../basicComponents/bar/Bar';
import { deckActions } from '../../redux/slices/deck.slice';
import { useDeleteColyseusRoomMutation } from '../../redux/apis/colyseus';

type ValidateRoomName = (name: string) => string | undefined;
const validateRoomName: ValidateRoomName = (name: string) => {
  if (!name) {
    return 'The room name cannot be empty';
  }
};
type ValidateNoOfRooms = (noOfRooms: number | undefined, max: number) => string | undefined;
const validateNoOfRooms: ValidateNoOfRooms = (noOfRooms, max) => {
  if (!noOfRooms) return 'Number of rooms cannot be empty';
  if (noOfRooms > max) {
    return 'You cannot create this amount of rooms';
  }
  if (noOfRooms <= 0) {
    return 'Amount of rooms must be a positive number';
  }
};

export const CreateRoomPage: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [queryParams, changeQuery] = useSearchParams();
  const [name, setName] = useState('');
  const [noOfRooms, setNoOfRooms] = useState(1);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [noOfRoomsError, setNoOfRoomsError] = useState<string>();
  const [hasCreationError, setHasCreationError] = useState(false);
  const [selectedExpansion, setSelectedExpansion] = useState(0);
  const [openExpansion, setOpenExpansion] = useState(false);
  const [createRoom, createRoomMutation] = useCreateRoomsMutation();
  const [createEvent, createEventMutation] = useCreateEventMutation();
  const [deleteColyseusRoom] = useDeleteColyseusRoomMutation();
  const [searchParams] = useSearchParams();
  const getRooms = useGetRoomsQuery(undefined);
  const getConfig = useGetConfigQuery(undefined);
  const getExpansions = useGetAvailableExpansionsQuery({});
  const getSubscription = useGetSubscriptionStatusQuery(undefined);
  const { createRoom: createColyseusRoom } = useColyseus();
  const anchorRef = useRef(null);

  const isEvent = searchParams.has('isEvent') && searchParams.get('isEvent') == 'true';

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxCharacters = 24;
    const value = event.target.value.slice(0, maxCharacters);
    setName(value);
    setErrorMessage(validateRoomName(value));
  };

  const onNoOfRoomsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxCharacters = 2;
    const value = parseInt(event.target.value.slice(0, maxCharacters));
    setNoOfRooms(value);
    const max = (getConfig.data?.MaxNoOfConcurrentRooms ?? 2) - (getRooms.data ?? []).length;
    setNoOfRoomsError(validateNoOfRooms(value, max));
  };

  const validate = () => {
    const errorMessage = validateRoomName(name);
    if (errorMessage) {
      setErrorMessage(errorMessage);
      return true;
    }
    if (!getConfig.data || !getRooms.data) {
      setErrorMessage(`Still loading. Please try again.`);
      return true;
    }
    const max = (getConfig.data?.MaxNoOfConcurrentRooms ?? 2) - (getRooms.data ?? []).length;
    const errorMessage2 = validateNoOfRooms(noOfRooms, max);
    if (errorMessage2) {
      setNoOfRoomsError(errorMessage2);
      return true;
    }
    if (getRooms.data && getRooms.data.length >= getConfig.data.MaxNoOfConcurrentRooms) {
      setErrorMessage(
        `You can only have ${getConfig.data.MaxNoOfConcurrentRooms} open room(s) at a time. Delete one of the rooms to create a new one.`
      );
      return true;
    }
    return false;
  };

  const onNext = () => {
    const hasError = validate();
    if (hasError) return;

    dispatch(setManualLoading(true));
    dispatch(showLoading());

    isEvent ? onCreateEvent() : onCreateRoom();
  };

  const onCreateRoom = async () => {
    const expansion = getExpansions.data?.expansions[selectedExpansion];
    const roomResult = await createColyseusRoom(
      name,
      getConfig.data!.MaxNoOfPlayersPerGame + 10,
      expansion?.sparkQuantities
    );

    if (roomResult.status === 'created') {
      const expansionName = expansion?.name ?? 'Taster';
      createRoom({
        name,
        roomId: roomResult.room.id,
        expansions: [expansionName],
      });
      dispatch(deckActions.setExpansion(expansionName));
    } else if (roomResult.status == 'error') {
      setHasCreationError(true);
    }
  };

  const onCreateEvent = async () => {
    const expansion = getExpansions.data?.expansions[selectedExpansion];
    const roomIds: string[] = [];
    for (let i = 0; i < noOfRooms; i++) {
      const roomResult = await createColyseusRoom(
        `${name} - Room ${i + 1}`,
        getConfig.data!.MaxNoOfPlayersPerGame + 10,
        expansion?.sparkQuantities
      );

      if (roomResult.status == 'created') {
        roomResult.room.send('joinAsObserver');
        roomIds.push(roomResult.room.id);
        roomResult.room.leave(true);
      } else {
        setHasCreationError(true);
      }
    }

    createEvent({
      name,
      roomIds,
      expansions: [expansion?.name ?? 'Taster'],
    });
  };

  useEffect(() => {
    if (createRoomMutation.isSuccess)
      navigate(LOBBY_PATH.replace(':roomId', createRoomMutation.data.roomId));
    if (createRoomMutation.isError) {
      setHasCreationError(true);
      deleteColyseusRoom(createRoomMutation.originalArgs?.roomId ?? '');
    }
  }, [createRoomMutation]);

  useEffect(() => {
    if (createEventMutation.isSuccess) navigate(MY_GAMES_PATH);
    if (createEventMutation.isError) {
      setHasCreationError(true);
      createEventMutation.originalArgs?.roomIds.map((roomId) => deleteColyseusRoom(roomId));
    }
  }, [createEventMutation]);

  useEffect(() => {
    if (queryParams.has('session_id')) {
      dispatch(showModal({ type: 'success', message: 'Successfully subscribed' }));
      changeQuery({});
    }
  }, [queryParams]);

  return (
    <Layout>
      {getRooms.isSuccess && getConfig.isSuccess ? (
        <Layout.Panel style={{ position: 'relative' }}>
          <BackButtom goBack={() => navigate(MY_GAMES_PATH)} />
          <Heading as="h1" sx={{ fontSize: 3, mb: 3, textAlign: 'center' }}>
            Start a new {isEvent ? 'event' : 'game'}
          </Heading>
          <FormControl>
            <FormControl.Label>Create your {isEvent ? 'event' : 'room'} name</FormControl.Label>
            <TextInput
              aria-describedby="input-validation"
              aria-invalid={!!errorMessage}
              onChange={onNameChange}
              sx={{ width: '100%', ...textInputStyle }}
              validationStatus={errorMessage ? 'error' : undefined}
              value={name}
            />

            {errorMessage ? (
              <FormControl.Validation id="input-validation" variant="error">
                {errorMessage}
              </FormControl.Validation>
            ) : null}
          </FormControl>
          {hasCreationError ? (
            <Flash sx={{ mt: 3 }} variant="danger">
              <StyledOcticon icon={AlertIcon} />
              {isEvent
                ? 'There was an error creating some of the rooms'
                : 'There was an error creating the room'}
            </Flash>
          ) : null}
          <FormControl sx={{ paddingTop: '15px' }}>
            <FormControl.Label>Select an edition</FormControl.Label>
            <div
              aria-expanded={openExpansion}
              onClick={() => setOpenExpansion(!openExpansion)}
              ref={anchorRef}
              style={{
                display: 'flex',
                width: '100%',
                ...textInputStyle,
                borderColor: '#A792FF',
                color: '#3B147A',
                fontWeight: 500,
                paddingTop: '6px',
                paddingBottom: '11px',
                paddingRight: '16px',
                paddingLeft: '16px',
                justifyContent: 'space-between',
                alignContent: 'center',
              }}
            >
              <>
                {getExpansions.data?.expansions[selectedExpansion].name ?? 'Select'}
                <div>
                  <TriangleDownIcon fill="#A792FF" size={30} />
                </div>
              </>
            </div>
            <ActionMenu anchorRef={anchorRef} onOpenChange={setOpenExpansion} open={openExpansion}>
              <ActionMenu.Overlay align="start" width="medium">
                <ActionList>
                  {(getExpansions.data?.expansions ?? []).map((expansion, index) => (
                    <div key={expansion.name}>
                      <ActionList.Item
                        disabled={expansion.disabled}
                        key={expansion.name}
                        onSelect={() => setSelectedExpansion(index)}
                        value={expansion.name}
                      >
                        {expansion.name}
                        <ActionList.TrailingVisual
                          sx={{
                            color: '#999999',
                            fontSize: '10px',
                            fontWeight: 600,
                            letterSpacing: '1px',
                          }}
                        >
                          {expansion.subtext}
                        </ActionList.TrailingVisual>
                      </ActionList.Item>
                    </div>
                  ))}
                </ActionList>
              </ActionMenu.Overlay>
            </ActionMenu>
          </FormControl>

          {isEvent ? (
            <FormControl sx={{ paddingTop: '15px' }}>
              <FormControl.Label>Number of rooms</FormControl.Label>
              <TextInput
                aria-describedby="input-validation"
                aria-invalid={!!noOfRoomsError}
                onChange={onNoOfRoomsChange}
                sx={{ width: '100%', ...textInputStyle }}
                type="number"
                validationStatus={noOfRoomsError ? 'error' : undefined}
                value={noOfRooms}
              />

              {noOfRoomsError ? (
                <FormControl.Validation id="input-validation" variant="error">
                  {noOfRoomsError}
                </FormControl.Validation>
              ) : null}
            </FormControl>
          ) : null}

          <Bar />
          <div style={{ height: '40px' }} />

          <Box display="flex" flexDirection="row">
            <Button
              onClick={() => navigate(MY_GAMES_PATH)}
              size="medium"
              sx={{
                mt: 3,
                width: '100%',
                ...buttonStyle,
                borderColor: '#DB004F',
                backgroundColor: 'white',
                color: '#DB004F',
                marginRight: '7px',
                fontWeight: '100',
              }}
              type="button"
              variant="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={onNext}
              size="medium"
              sx={{ mt: 3, width: '100%', ...buttonStyle, marginLeft: '7px' }}
              type="button"
              variant="primary"
            >
              Next
            </Button>
          </Box>
        </Layout.Panel>
      ) : null}
    </Layout>
  );
};
