import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { AuthData } from '../models/auth.model';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import { RootState } from '../store';

function setAuth(state: AuthData, action: PayloadAction<AuthData>) {
  state.firstName = action.payload.firstName;
  state.accessToken = action.payload.accessToken;
  state.expiresAt = action.payload.expiresAt;
  state.isGuest = action.payload.isGuest;
}

const authLocalSlice = createSlice({
  name: 'authLocal',
  initialState: {} as AuthData,
  reducers: { setAuth },
});
export const authLocalActions = authLocalSlice.actions;

const authSessionSlice = createSlice({
  name: 'authSession',
  initialState: {} as AuthData,
  reducers: { setAuth },
});
export const authSessionActions = authSessionSlice.actions;

export const selectAuth = createSelector(
  (state: RootState): AuthData => {
    const auth = state.authLocal.accessToken ? state.authLocal : state.authSession;
    return {
      ...auth,
      isAuthLoaded: state.authLocal._persist.rehydrated && state.authSession._persist.rehydrated,
      isLoggedIn:
        auth != undefined &&
        auth.accessToken != '' &&
        (auth.expiresAt || 0) > Math.floor(new Date().getTime() / 1000),
    };
  },
  (auth) => auth
);

export const authLocalReducer = persistReducer(
  { key: 'authLocal', storage: localStorage },
  authLocalSlice.reducer
);
export const authSessionReducer = persistReducer(
  { key: 'authSession', storage: sessionStorage },
  authSessionSlice.reducer
);
