import { FunctionComponent, useRef, useState } from 'react';
import { Box, Button, Dialog, Heading } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import { RoomName } from '../../basicComponents/roomName/RoomName';
import { Highscores } from '../../basicComponents/playerList/PlayerList';
import { usePlayers } from '../../hooks/usePlayers';
import { getGameState } from '../../redux/slices/game.slice';
import { useAppSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuth } from '../../redux/slices/auth.slice';
import { startConfettiInner, stopConfettiInner } from '../../components/confetti/confetti';
import { buttonStyle } from '../../theme/theme';
import styled from 'styled-components';
import { WIDTH_FOR_RESPONSIVE } from '../lobby/LobbyPage';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Bar } from '../../basicComponents/bar/Bar';
import EmailIcon from '../../images/social/email-icon.svg';
import FacebookIcon from '../../images/social/facebook-icon.svg';
import LinkedinIcon from '../../images/social/linkedin-icon.svg';
import TwitterIcon from '../../images/social/twitter-icon.svg';
import WhatsappIcon from '../../images/social/whatsapp-icon.svg';
import { showContactForm } from '../../redux/slices/modal.slice';

const WIDTH_FOR_CENTRALIZATION = 1300;

const Message = styled('div')`
  width: 100%;
  height: 50px;
  color: #3b147a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  text-align: center;
  border-radius: 100px;
  background: #ede9ff;
`;

const SmallMessage = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  margin-bottom: 20px;
`;

const SocialIcon = styled('img')`
  cursor: pointer;
  width: 65px;
`;

export const GameFinishedPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { players } = usePlayers();
  const { isGuest } = useAppSelector(selectAuth);
  const { name = '', room } = useAppSelector(getGameState);
  const [inviteBox, setInviteBox] = useState(false);
  const inviteFocusRef = useRef(null);
  startConfettiInner();

  return (
    <Layout
      alignItems={width < WIDTH_FOR_RESPONSIVE ? 'center' : 'start'}
      flexDirection={width < WIDTH_FOR_RESPONSIVE ? 'column' : 'row'}
    >
      {/* //Invisible Panel */}
      <Layout.Panel
        style={{
          height: '0px',
          padding: '0px',
          width: `${
            width > WIDTH_FOR_RESPONSIVE
              ? width < WIDTH_FOR_CENTRALIZATION
                ? '0px'
                : '270px'
              : '100%'
          }`,
        }}
      />
      <Layout.Panel
        style={{
          width: `${width > WIDTH_FOR_RESPONSIVE ? '400px' : '100%'}`,
          position: 'relative',
        }}
      >
        <RoomName name={name} />
        <Message>Woohoo! You finished the game! 🥳</Message>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '30px',
            marginTop: '15px',
          }}
        >
          <div
            style={{
              height: '1px',
              backgroundColor: '#A792FF',
              flexGrow: 1,
            }}
          />
          <p
            style={{
              color: '#3B147A',
              fontWeight: 600,
              paddingRight: '15px',
              paddingLeft: '15px',
              letterSpacing: '1px',
            }}
          >
            FINAL SCORES
          </p>
          <div
            style={{
              height: '1px',
              backgroundColor: '#A792FF',
              flexGrow: 1,
            }}
          />
        </div>
        <SmallMessage>(after deducting unused spark cards)</SmallMessage>

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '80px' }}>
          <div style={{ maxWidth: '250px', flexGrow: 1 }}>
            <Highscores players={players} />
          </div>
        </div>

        <Bar />

        <Button
          onClick={() => {
            if (isGuest) window.open('https://go.cozyjuicyreal.com/wa-signup');
            if (!isGuest) setInviteBox(true);
          }}
          size="medium"
          sx={{
            mt: 3,
            margin: 'auto',
            width: '100%',
            maxWidth: '200px',
            background: '#DB004F',
            ...buttonStyle,
            borderColor: '#7A0032',
            color: '#ffffff',
          }}
          type="button"
          variant="default"
        >
          {isGuest ? 'Sign up free' : 'Invite a friend'}
        </Button>
      </Layout.Panel>

      <Layout.Panel
        style={{
          position: 'relative',
          width: `${width > WIDTH_FOR_RESPONSIVE ? '270px' : '100%'}`,
          marginTop: width < WIDTH_FOR_RESPONSIVE ? '0px' : '100px',
        }}
      >
        <>
          <Heading as="h2" sx={{ fontSize: 3, textAlign: 'center', mb: 3, color: '#3B147A' }}>
            What next?
          </Heading>
          {isGuest ? (
            <Button
              onClick={() => {
                window.open('https://go.cozyjuicyreal.com/wa-signup');
                stopConfettiInner();
              }}
              size="medium"
              sx={whatsNextButtonStyle}
              type="button"
              variant="default"
            >
              Sign up free
            </Button>
          ) : null}
          <Button
            onClick={() => window.open('https://go.cozyjuicyreal.com/wa-teams')}
            size="medium"
            sx={whatsNextButtonStyle}
            type="button"
            variant="default"
          >
            Team building
          </Button>
          {!isGuest ? (
            <Button
              onClick={() => window.open('https://go.cozyjuicyreal.com/wa-discord')}
              size="medium"
              sx={whatsNextButtonStyle}
              type="button"
              variant="default"
            >
              Join Community
            </Button>
          ) : null}
          <Button
            onClick={() => window.open('https://go.cozyjuicyreal.com/wa-store')}
            size="medium"
            sx={whatsNextButtonStyle}
            type="button"
            variant="default"
          >
            Buy board game
          </Button>
          <Button
            onClick={() => dispatch(showContactForm())}
            size="medium"
            sx={whatsNextButtonStyle}
            type="button"
            variant="default"
          >
            Send feedback
          </Button>
        </>
      </Layout.Panel>
      <Dialog
        aria-labelledby="label"
        isOpen={inviteBox}
        onDismiss={() => setInviteBox(false)}
        returnFocusRef={inviteFocusRef}
        sx={{ height: 'initial !important' }}
      >
        <Dialog.Header>Share Cozy Juicy Real</Dialog.Header>
        <Box p={3}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" m={2}>
            <SocialIcon
              alt="Whatsapp"
              onClick={() => window.open('https://go.cozyjuicyreal.com/wa-share-whatsapp')}
              src={WhatsappIcon}
            />
            <SocialIcon
              aria-label="Email"
              onClick={() => window.open('https://go.cozyjuicyreal.com/wa-share-email')}
              src={EmailIcon}
            />
            <SocialIcon
              aria-label="LinkedIn"
              onClick={() => window.open('https://go.cozyjuicyreal.com/wa-share-linkedin')}
              src={LinkedinIcon}
            />
            <SocialIcon
              aria-label="X"
              onClick={() => window.open('https://go.cozyjuicyreal.com/wa-share-twitter')}
              src={TwitterIcon}
            />
            <SocialIcon
              aria-label="Facebook"
              onClick={() => window.open('https://go.cozyjuicyreal.com/wa-share-facebook')}
              src={FacebookIcon}
            />
          </Box>
        </Box>
      </Dialog>
    </Layout>
  );
};

const whatsNextButtonStyle = {
  mt: 3,
  margin: 'auto',
  width: '100%',
  maxWidth: '200px',
  background: '#FFFFFF',
  ...buttonStyle,
  borderColor: '#DB004F',
  color: '#DB004F',
  marginBottom: '18px',
};
