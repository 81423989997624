import { FunctionComponent, useState, ChangeEvent } from 'react';
import { Button, Box, FormControl, TextInput } from '@primer/react';
import { AvatarSelector } from '../avatarSelector/AvatarSelector';
import { PlayerProps, clearGameState, getGameState } from '../../redux/slices/game.slice';
import { buttonStyle, textInputStyle } from '../../theme/theme';
import { MY_GAMES_PATH } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { AuthData } from '../../redux/models/auth.model';
import { selectAuth } from '../../redux/slices/auth.slice';
import { useMessageSender } from '../../hooks/useMessageSender';

type NicknameErrorMessage =
  | undefined
  | 'Your nickname cannot be empty'
  | 'Your nickname is already taken';

type ValidateNickname = (nickname: string, otherNicknames: string[]) => NicknameErrorMessage;
const validateNickname: ValidateNickname = (nickname, otherNicknames) => {
  if (!nickname) {
    return 'Your nickname cannot be empty';
  }

  if (otherNicknames.includes(nickname)) {
    return 'Your nickname is already taken';
  }

  return;
};

type AvatarErrorMessage = undefined | 'You must choose an avatar' | 'Your avatar is already taken';

type ValidateAvatar = (avatar: number, otherAvatars: number[]) => AvatarErrorMessage;
const validateAvatar: ValidateAvatar = (avatar, otherAvatars) => {
  if (avatar === -1) {
    return 'You must choose an avatar';
  }

  if (otherAvatars.includes(avatar)) {
    return 'Your avatar is already taken';
  }

  return;
};

export type PlayerFormValues = Pick<PlayerProps, 'nickname' | 'avatar'>;

export type PlayerFormProps = {
  otherPlayers: PlayerProps[];
  onSubmit: (player: PlayerFormValues) => void;
  noOfPlayers: number;
};

export const PlayerForm: FunctionComponent<PlayerFormProps> = ({
  otherPlayers,
  onSubmit,
  noOfPlayers,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nickname, setNickname] = useState('');
  const [nicknameErrorMessage, setNicknameErrorMessage] = useState<NicknameErrorMessage>();
  const [avatar, setAvatar] = useState(-1);
  const [avatarErrorMessage, setAvatarErrorMessage] = useState<AvatarErrorMessage>();
  const auth: AuthData = useAppSelector(selectAuth);
  const { room } = useAppSelector(getGameState);
  const { closeRoom } = useMessageSender();

  const otherNicknames: string[] = otherPlayers.map((player) => player.nickname).filter(Boolean);
  const otherAvatars: number[] = otherPlayers
    .map((player) => player.avatar)
    .filter((avatar) => avatar != undefined);

  const onNicknameChange = (value: string) => {
    setNickname(value);
    setNicknameErrorMessage(validateNickname(value, otherNicknames));
  };

  const onAvatarChange = (value: number) => {
    setAvatar(value);
    setAvatarErrorMessage(validateAvatar(value, otherAvatars));
  };

  const validate = () => {
    const nicknameValidationError = validateNickname(nickname, otherNicknames);
    setNicknameErrorMessage(nicknameValidationError);

    const avatarValidationError = validateAvatar(avatar, otherAvatars);
    setAvatarErrorMessage(avatarValidationError);

    return !nicknameValidationError && !avatarValidationError;
  };

  const handleSubmit = () => {
    const isValid = validate();

    if (!isValid) {
      return;
    }
    onSubmit({ nickname, avatar });
  };

  return (
    <Box width="100%">
      <FormControl sx={{ mb: 3 }}>
        <FormControl.Label>Create your nickname</FormControl.Label>
        <TextInput
          aria-describedby="nickname-validation"
          aria-invalid={!!nicknameErrorMessage}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onNicknameChange(event.target.value)}
          sx={{ width: '100%', ...textInputStyle }}
          validationStatus={nicknameErrorMessage ? 'error' : undefined}
          value={nickname}
        />
        {nicknameErrorMessage ? (
          <FormControl.Validation id="nickname-validation" variant="error">
            {nicknameErrorMessage}
          </FormControl.Validation>
        ) : null}
      </FormControl>
      <AvatarSelector
        disabledValues={otherAvatars}
        errorMessage={avatarErrorMessage}
        legend="Choose your avatar"
        noOfPlayers={noOfPlayers}
        onChange={onAvatarChange}
        value={avatar}
      />

      <div style={{ height: '40px' }} />
      <Box display="flex" flexDirection="row">
        {!auth.isGuest ? (
          <Button
            onClick={() => {
              closeRoom();
              navigate(MY_GAMES_PATH);
              room?.connection.close();
              dispatch(clearGameState(null));
            }}
            size="medium"
            sx={{
              mt: 3,
              width: '100%',
              ...buttonStyle,
              marginRight: '7px',
              borderColor: '#DB004F',
              backgroundColor: 'white',
              color: '#DB004F',
              fontWeight: '100',
            }}
            type="button"
            variant="default"
          >
            Cancel
          </Button>
        ) : null}
        <Button
          onClick={handleSubmit}
          size="medium"
          sx={{ mt: 3, width: '100%', ...buttonStyle, marginLeft: '7px' }}
          type="button"
          variant="primary"
        >
          Ready!
        </Button>
      </Box>
    </Box>
  );
};
