import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { Text, themeGet } from '@primer/react';
import { AlertFillIcon } from '@primer/octicons-react';
import { Avatar } from '../avatar/Avatar';
import { VisuallyHidden } from '../../basicComponents/visuallyHidden/VisuallyHidden';

const Fieldset = styled('fieldset')`
  margin: 0;
  padding: 0;
  border: none;
`;

const Legend = styled('legend')`
  font-size: ${themeGet('fontSizes.1')};
  font-weight: ${themeGet('fontWeights.bold')};
`;

const Label = styled('label')`
  margin: ${themeGet('space.1')};
  display: block;
`;

const RadioGroup = styled('div')`
  align-self: center;
  margin-top: ${themeGet('space.2')};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Radio = styled('input')`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  & + div {
    cursor: pointer;
    border: 2px solid #412683;
  }

  &:focus + div {
    outline: 3px solid #41268360;
  }

  &:hover:not(:disabled) + div {
    border-color: #68b480;
  }

  &:checked + div {
    border-color: #31a78f;
  }

  &:checked:focus + div {
    border-color: #54ef9a !important;
    border-width: 3px;
    outline: 4px solid #31a78f60;
  }

  &[aria-invalid='true']:checked + div {
    border-color: #cf222e;
  }

  &:disabled + div {
    cursor: initial;
    border-color: #d8d8d8;

    img {
      filter: opacity(20%) grayscale(100%);
    }
  }
`;

const Validation = styled(Text)`
  color: ${themeGet('colors.danger.fg')};
  font-size: ${themeGet('fontSizes.0')};

  & > * {
    margin-right: ${themeGet('space.1')};
  }
`;

type AvatarSelectorProps = {
  legend: string;
  value: number;
  disabledValues?: number[];
  errorMessage?: string;
  onChange: (value: number) => void;
  noOfPlayers: number;
};

export const AvatarSelector: FunctionComponent<AvatarSelectorProps> = ({
  legend,
  value,
  disabledValues: disabledAvatars = Array(0),
  errorMessage,
  onChange,
  noOfPlayers,
}) => {
  const avatarVariants = Array.from(Array(noOfPlayers).keys());

  return (
    <Fieldset aria-describedby="avatar-validation" aria-invalid={!!errorMessage}>
      <Legend>{legend}</Legend>

      <RadioGroup>
        {avatarVariants.map((variant) => {
          const checked = value === variant;
          const disabled = disabledAvatars.includes(variant);

          return (
            <Label key={variant}>
              <Radio
                aria-invalid={checked ? disabled : undefined}
                checked={checked}
                disabled={disabled}
                name="avatar"
                onChange={() => onChange(variant)}
                type="radio"
                value={variant}
              />
              <Avatar.Border
                style={{
                  border: checked
                    ? '2px solid #412683 !important'
                    : '4px solid #31a78f60 !important',
                }}
              >
                <Avatar size={56} variant={variant} />
              </Avatar.Border>

              <VisuallyHidden>Avatar {variant}</VisuallyHidden>
            </Label>
          );
        })}
      </RadioGroup>
      {errorMessage ? (
        <Validation id="avatar-validation">
          <AlertFillIcon size={12} />
          {errorMessage}
        </Validation>
      ) : null}
    </Fieldset>
  );
};
