import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { TriangleUpIcon } from '@primer/octicons-react';
import { isMobile } from 'react-device-detect';
import { showContactForm } from '../../redux/slices/modal.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { selectAuth } from '../../redux/slices/auth.slice';

const ClickField = styled('div')`
  position: relative;
  color: blue;
`;

const Item = styled('button')`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px;
  color: #440d9b;
  font-size: 14px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border: 0px;
  cursor: pointer;
`;

export const MoreMenu: FunctionComponent<{
  readonly setClose: any;
  readonly leftPosition: number;
}> = ({ setClose, leftPosition }) => {
  const dispatch = useAppDispatch();
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);
  const sub = useGetSubscriptionStatusQuery(undefined, { skip: !isLoggedIn || isGuest });

  return (
    <ClickField
      onClick={(e) => {
        isMobile ? setClose() : null;
      }}
      onPointerLeave={(e) => {
        !isMobile ? setClose() : null;
      }}
      style={{
        width: '300px',
        height: '300px',
        top: isMobile ? '0px' : '40px',
        position: 'absolute',
        left: leftPosition,
        padding: '0px',
        zIndex: 100,
      }}
    >
      <div
        style={{
          top: '15px',
          paddingLeft: '14px',
          width: '180px',
          position: 'absolute',
          left: '30px',
          background: '#fff',
          borderRadius: '30px',
          padding: '22px',
          boxShadow: '0px 0px 20px 0px #5555',
          zIndex: 12,
        }}
      >
        {/* <Item
          onClick={() => {
            window.open('https://go.cozyjuicyreal.com/wa-store');
            setClose();
          }}
        >
          FAQ's
        </Item> */}
        <Item
          onClick={() => {
            dispatch(showContactForm());
            setClose();
          }}
        >
          Send feedback
        </Item>
        {!isLoggedIn ||
        isGuest ||
        sub.data?.find(
          (s) => s.isSubscriptionActive && !s.isTemporaryAddOnSubscription && s.plan == 'FREE'
        ) ? (
          <Item
            onClick={() => {
              window.open('https://go.cozyjuicyreal.com/wa-teams');
              setClose();
            }}
          >
            Team building
          </Item>
        ) : null}
        {!isLoggedIn ||
        isGuest ||
        sub.data?.find(
          (s) => s.isSubscriptionActive && !s.isTemporaryAddOnSubscription && s.plan !== 'FREE'
        ) ? (
          <Item
            onClick={() => {
              window.open(
                'https://cozyjuicyreal.trafft.com/booking?t=s&s=cozy-juicy-real-demo-20-mins'
              );
              setClose();
            }}
          >
            Contact us
          </Item>
        ) : null}
{/*         <Item
          onClick={() => {
            window.open('https://go.cozyjuicyreal.com/wa-discord');
            setClose();
          }}
        >
          Join community
        </Item> */}
      </div>
      <div
        style={{
          position: 'absolute',
          top: isMobile ? '-25px' : '-15px',
          left: '55px',
          zIndex: 12,
        }}
      >
        <TriangleUpIcon fill="white" size={50} />
      </div>
    </ClickField>
  );
};
