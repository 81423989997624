import { FunctionComponent, useEffect } from 'react';
import { Box, Heading } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import {
  useGetUserQuery,
  useSendVerificationEmailMutation,
  useUpdateUserMutation,
} from '../../redux/apis/user.api';
import { ProfileForm } from '../../basicComponents/profileForm/ProfileForm';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setManualLoading, showLoading, showModal } from '../../redux/slices/modal.slice';
import { BackButtom } from '../../basicComponents/backButtom/BackButtom';
import { DeleteAccountButton } from '../../basicComponents/deleteAccount/DeleteAccountButton';
import { stopConfettiInner } from '../../components/confetti/confetti';

export const ProfilePage: FunctionComponent = () => {
  stopConfettiInner();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUserQuery = useGetUserQuery(undefined);

  const [updateUser, updateUserRes] = useUpdateUserMutation();
  const [sendVerificationEmail] = useSendVerificationEmailMutation();

  useEffect(() => {
    if (!getUserQuery.isUninitialized) getUserQuery.refetch();
  }, []);

  useEffect(() => {
    if (updateUserRes.isSuccess)
      dispatch(showModal({ message: 'User/Password updated successfully!', type: 'success' }));
  }, [updateUserRes]);

  return (
    <Layout>
      <Layout.Panel style={{ width: '100%', maxWidth: '520px', position: 'relative' }}>
        <Heading as="h1" sx={{ fontSize: 3, mb: 3, textAlign: 'center' }}>
          My Profile
        </Heading>
        <BackButtom goBack={() => navigate(-1)} />
        <Box maxWidth="500px" width="100%">
          <ProfileForm
            handleSubmit={(user) => {
              dispatch(setManualLoading(true));
              dispatch(showLoading());
              updateUser(user);
            }}
            isUpdate
            sendVerificationEmail={() => sendVerificationEmail(undefined)}
            user={getUserQuery.data}
          />
        </Box>
        <Box maxWidth="250px" sx={{ margin: 'auto' }} width="100%">
          <DeleteAccountButton />
        </Box>
      </Layout.Panel>
    </Layout>
  );
};
