import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { closeLoading, setManualLoading, showLoading } from '../redux/slices/modal.slice';
import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../redux/store';

export const useApisLoaded = () => {
  const stateApis: any = useAppSelector(
    createSelector(
      (state: RootState) => Object.values(state),
      (stateApis) => stateApis
    )
  );
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    createSelector(
      (state: RootState) => state.modal.loading,
      (loading) => loading
    )
  );
  const manualLoading = useAppSelector(
    createSelector(
      (state: RootState) => state.modal.manualLoading,
      (loading) => loading
    )
  );

  useEffect(() => {
    const allApiStatus: string[] = [];
    stateApis.map((store: any) => {
      const queries = store.queries;
      const mutations = store.mutations;
      if (!queries && !mutations) return;
      Object.keys(queries).map((query) => {
        allApiStatus.push(queries[query].status);
      });
      Object.keys(mutations).map((mutation) => {
        allApiStatus.push(mutations[mutation].status);
      });
    });
    if (allApiStatus.some((status) => status == 'pending')) {
      dispatch(showLoading());
      dispatch(setManualLoading(false));
    }
    if (allApiStatus.every((status) => status != 'pending') && manualLoading == false)
      dispatch(closeLoading());
  }, [stateApis]);

  return loading;
};
