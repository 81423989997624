import { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { Button, Box, FormControl, TextInput, Checkbox } from '@primer/react';
import { useLoginMutation } from '../../redux/apis/auth.api';
import { authLocalActions, authSessionActions, selectAuth } from '../../redux/slices/auth.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  closeLoading,
  closeModal,
  setManualLoading,
  showLoading,
  showModal,
} from '../../redux/slices/modal.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateEmail } from '../../utils/validators';
import { MY_GAMES_PATH, REGISTRATION_PATH } from '../../utils/constants';
import { useRecoverPasswordMutation } from '../../redux/apis/user.api';
import { EyeClosedIcon, EyeIcon } from '@primer/octicons-react';
import { buttonStyle, checkboxStyle, colors, textInputStyle } from '../../theme/theme';
import styled from 'styled-components';

const Panel = styled('div')`
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 100%;
  max-width: 366px;
  background-color: white;
  border-radius: 30px;
  overflow: hidden;
  padding: 30px;
  box-shadow: 0px 0px 10px 0px #dddd;
`;

export const LoginForm: FunctionComponent = () => {
  const [doLogin, login] = useLoginMutation();
  const [recoverPassword, recover] = useRecoverPasswordMutation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>();
  const [resetPwEmailSent, setResetPwEmailSent] = useState(false);
  const [visiblePW, setVisiblePW] = useState(false);

  const [password, setPassword] = useState('');

  const [rememberMe, setRememberMe] = useState(true);

  const onEmailChange = (value: string) => {
    setEmail(value);
    setEmailErrorMessage(validateEmail(value));
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);
  };

  const validate = () => {
    const emailValidationError = validateEmail(email);
    setEmailErrorMessage(emailValidationError);

    return !emailValidationError;
  };

  const handleSubmit = () => {
    const isValid = validate();
    if (!isValid) return;
    dispatch(setManualLoading(true));
    dispatch(showLoading());

    doLogin({ email, password });
  };

  const handleRecover = () => {
    const emailValidationError = validateEmail(email);
    setEmailErrorMessage(emailValidationError);

    if (emailValidationError) return;

    recoverPassword({ email });
    setResetPwEmailSent(true);
  };

  useEffect(() => {
    if (recover.isSuccess)
      dispatch(showModal({ message: 'Reset password email sent!', type: 'success' }));
  }, [recover]);

  //const destination = (location.state as any)?.from?.pathname || '/';

  useEffect(() => {
    if (login.isSuccess) {
      const authState = { ...login.data, isGuest: false };
      if (rememberMe) {
        dispatch(authLocalActions.setAuth(authState));
        dispatch(authSessionActions.setAuth({}));
      } else {
        dispatch(authLocalActions.setAuth({}));
        dispatch(authSessionActions.setAuth(authState));
      }
      dispatch(closeModal());
    }
    if (!login.isLoading) {
      dispatch(closeLoading());
    }
  }, [login]);

  const { isLoggedIn } = useAppSelector(selectAuth);

  useEffect(() => {
    if (isLoggedIn) navigate(MY_GAMES_PATH);
  }, [isLoggedIn]);

  return (
    <Panel>
      <Box marginLeft="0px" maxWidth="500px" width="100%">
        <FormControl sx={{ mb: 3 }}>
          <FormControl.Label>Email</FormControl.Label>
          <TextInput
            aria-describedby="email-validation"
            aria-invalid={!!emailErrorMessage}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onEmailChange(event.target.value)}
            sx={{ width: '100%', ...textInputStyle }}
            validationStatus={emailErrorMessage ? 'error' : undefined}
            value={email}
          />
          {emailErrorMessage ? (
            <FormControl.Validation id="email-validation" variant="error">
              {emailErrorMessage}
            </FormControl.Validation>
          ) : null}
        </FormControl>
        <FormControl sx={{ mb: 3 }}>
          <FormControl.Label>Password</FormControl.Label>
          <TextInput
            aria-describedby="password-validation"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onPasswordChange(event.target.value)
            }
            sx={{ width: '100%', ...textInputStyle }}
            trailingAction={
              <TextInput.Action
                aria-label={visiblePW ? 'Hide password' : 'Show password'}
                icon={visiblePW ? EyeClosedIcon : EyeIcon}
                onClick={() => setVisiblePW(!visiblePW)}
              />
            }
            type={visiblePW ? 'text' : 'password'}
            value={password}
          />
        </FormControl>

        <FormControl>
          <Checkbox
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            sx={{ ...checkboxStyle }}
          />
          <FormControl.Label sx={{ fontWeight: 100 }}>Remember me</FormControl.Label>
        </FormControl>

        <Button
          onClick={handleSubmit}
          size="medium"
          sx={{ mt: 3, width: '100%', ...buttonStyle }}
          type="button"
          variant="primary"
        >
          Login
        </Button>

        <Button
          onClick={() => navigate(REGISTRATION_PATH)}
          size="medium"
          sx={{ mt: 3, width: '100%', ...buttonStyle, borderColor: colors.accent.emphasis }}
          type="button"
          variant="default"
        >
          Sign up free
        </Button>

        <Button
          disabled={resetPwEmailSent}
          onClick={handleRecover}
          size="medium"
          sx={{
            mt: 3,
            width: '100%',
            ...buttonStyle,
            fontWeight: 'light',
            borderColor: colors.accent.emphasis,
          }}
          type="button"
          variant="default"
        >
          Password recovery
        </Button>
      </Box>
    </Panel>
  );
};
