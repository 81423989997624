import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Layout } from '../../components/layout/Layout';
import { Turn } from '../../components/turn/Turn';
import { RoomName } from '../../basicComponents/roomName/RoomName';
import { TurnHeader } from '../../components/turnHeader/TurnHeader';
import { SparksList } from '../../components/sparkList/SparkList';
import { Spark } from '../../basicComponents/spark/Spark';
import { getGameState } from '../../redux/slices/game.slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessageSender } from '../../hooks/useMessageSender';
import { getCurrentPlayer } from '../../redux/slices/player.slice';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { BackButtom } from '../../basicComponents/backButtom/BackButtom';
import linkIcon from '../../images/linkicon.svg';
import { Box, Button, Dialog, Heading, Tooltip, Text } from '@primer/react';
import { useClipboard } from '../../hooks/useClipboard';
import { showModal } from '../../redux/slices/modal.slice';
import { PlayerListWithPoints } from '../../basicComponents/playerList/PlayerList';
import { usePlayers } from '../../hooks/usePlayers';
import { AlertIcon, TriangleDownIcon, TriangleUpIcon } from '@primer/octicons-react';
import { PlayerScoreBar } from '../../components/playerScoreBar/PlayerScoreBar';
import { Bar } from '../../basicComponents/bar/Bar';
import { usePlayGameMutation } from '../../redux/apis/user.api';
import usePrevious from '../../hooks/usePrevious';
import { buttonStyle } from '../../theme/theme';
import { useSparks } from '../../hooks/useSparks';
import { getSparkName } from '../../utils/sparks';
import { selectAuth } from '../../redux/slices/auth.slice';
import { useGetConfigQuery } from '../../redux/apis/config';
import { useGetRoomQuery } from '../../redux/apis/rooms';
import { ENTER_GAME_PATH, GAME_FINISHED_PATH } from '../../utils/constants';
import { useGetGameCodeQuery } from '../../redux/apis/auth.api';

const WIDTH_FOR_RESPONSIVE = 900;
const WIDTH_FOR_CENTRALIZATION = 1300;

const InvisiblePanel = (width: number, style: any = {}) => (
  <Layout.Panel
    style={{
      height: '0px',
      padding: '0px',
      width: `${
        width > WIDTH_FOR_RESPONSIVE ? (width < WIDTH_FOR_CENTRALIZATION ? '0px' : '310px') : '100%'
      }`,
      ...style,
    }}
  />
);

export const GameStartedPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);
  const { name = '', playersArr, room, gameState } = useAppSelector(getGameState);
  const previousPlayersArr = usePrevious(playersArr);
  const { isObserver } = useAppSelector(getCurrentPlayer);
  const { leaveGame, acceptSparkCard, rejectSparkCard, movePlayers } = useMessageSender();
  const { width } = useWindowDimensions();
  const { copy } = useClipboard();
  const { players, youPlayer, getPlayerNickname } = usePlayers();
  const [seeAllScores, setSeeAllScores] = useState(false);
  const [seeAdvancePlayers, setSeeAdvancePlayers] = useState(false);
  const [playGameApi] = usePlayGameMutation();
  const { roomId } = useParams();
  const getRoom = useGetRoomQuery(roomId!, { skip: roomId === undefined || roomId === ':roomId' });
  const getConfig = useGetConfigQuery(undefined);
  const [gamePlayed, setGamePlayed] = useState(false);

  const goBack = () => {
    leaveGame();
    navigate(`../lobby?paused=true`, { replace: true });
  };

  const [fetchCode, setFetchCode] = useState(false);
  const getGameCode = useGetGameCodeQuery({ roomId }, { skip: !fetchCode });

  useEffect(() => {
    if (getGameCode.isSuccess && fetchCode) {
      copy(window.location.origin + ENTER_GAME_PATH.replace(':code', getGameCode.data!.code));
      dispatch(showModal({ message: 'Link copied', type: 'success' }));
      setFetchCode(false);
    }
  }, [getGameCode.isSuccess, fetchCode]);

  useEffect(() => {
    if (youPlayer && !youPlayer.isReady && !isObserver) {
      navigate(`../lobby`, { replace: true });
    }
  }, [youPlayer?.isReady]);

  if (gameState === 'finished' && roomId && roomId != ':roomId')
    navigate(GAME_FINISHED_PATH.replace(':roomId', roomId));
  useEffect(() => {
    if (gameState === 'finished' && roomId && roomId != ':roomId')
      navigate(GAME_FINISHED_PATH.replace(':roomId', roomId));
  }, [gameState]);

  useEffect(() => {
    if (
      playersArr.find((p) => p.steps >= 10) &&
      !gamePlayed &&
      getRoom.data?.expansions &&
      !getRoom.data.expansions.includes('Demo')
    ) {
      playGameApi({ roomId: room?.id ?? '' });
      setGamePlayed(true);
    }
    if (previousPlayersArr) {
      const newP = playersArr.filter(
        (x) => x.isReady && !previousPlayersArr.find((y) => y.id == x.id)?.isReady
      );
      if (newP.length > 0)
        dispatch(
          showModal({
            message: `Welcome ${newP[0].nickname} to the game room! :)`,
            type: 'success',
          })
        );
    }
  }, [playersArr]);

  const { sparkToBeAccepted } = useSparks();
  const [acceptSparkId, setAcceptSparkId] = useState<string | undefined>();
  const [sparkDescription, setSparkDescription] = useState<string>('');
  useEffect(() => {
    setAcceptSparkId(sparkToBeAccepted?.id);
    if (sparkToBeAccepted) {
      const fromPlayerNickname =
        youPlayer?.id === sparkToBeAccepted.fromPlayerId
          ? 'You'
          : getPlayerNickname(sparkToBeAccepted.fromPlayerId);

      const sparkName = getSparkName(sparkToBeAccepted.sparkType);
      setSparkDescription(
        `<b>${fromPlayerNickname}</b> gave you the "${sparkName}" card. Do you accept?`
      );
    }
  }, [sparkToBeAccepted]);

  return (
    <>
      <Layout alignItems="center" flexDirection="column">
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: width < WIDTH_FOR_RESPONSIVE ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {InvisiblePanel(width)}

          <Layout.Panel style={{ maxWidth: '510px', position: 'relative' }}>
            <BackButtom goBack={goBack} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <RoomName name={name} />{' '}
              <Tooltip aria-label="Copy link" direction="e">
                <img
                  alt="Cozy Juicy Real"
                  onClick={() => setFetchCode(true)}
                  src={linkIcon}
                  style={{
                    marginLeft: '10px',
                    marginTop: '-3px',
                    borderRadius: '20px',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </div>
            <TurnHeader />
            <SparksList />
            <Turn />
            {isObserver ? null : (
              <>
                <div style={{ height: '15px' }} />
                <Bar />
                <Spark />
              </>
            )}
          </Layout.Panel>
          {width >= WIDTH_FOR_RESPONSIVE ? (
            <Layout.Panel
              style={{
                position: 'relative',
                width: `${width > WIDTH_FOR_RESPONSIVE ? '310px' : '100%'}`,
              }}
            >
              <Heading as="h2" sx={{ fontSize: 3, textAlign: 'center', mb: 3, color: '#3B147A' }}>
                Scores
              </Heading>
              <PlayerListWithPoints
                players={
                  seeAllScores
                    ? players
                    : youPlayer
                    ? [youPlayer]
                    : players.length > 0
                    ? [players[0]]
                    : []
                }
                roomId={roomId}
              />
              <div
                onClick={() => setSeeAllScores((v) => !v)}
                style={{
                  color: '#3B147A',
                  textAlign: 'center',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: 'normal',
                  cursor: 'pointer',
                  paddingTop: '16px',
                }}
              >
                {!seeAllScores ? (
                  <>
                    {`See all scores       `}
                    <TriangleDownIcon fill="#A792FF" size={30} verticalAlign="middle" />
                  </>
                ) : (
                  <TriangleUpIcon fill="#A792FF" size={30} verticalAlign="middle" />
                )}
              </div>
            </Layout.Panel>
          ) : (
            <PlayerScoreBar />
          )}
        </div>

        {isLoggedIn && !isGuest && getConfig.data?.AdvancePlayersFeature ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: width < WIDTH_FOR_RESPONSIVE ? 'column' : 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {InvisiblePanel(width)}
            <Layout.Panel
              style={{
                marginTop: '10px',
                maxWidth: '510px',
                position: 'relative',
                padding: '15px',
              }}
            >
              <div
                style={{
                  color: '#3B147A',
                  textAlign: 'center',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: 'normal',
                  paddingTop: '3px',
                }}
              >
                {!seeAdvancePlayers ? (
                  <div
                    onClick={() => setSeeAdvancePlayers((v) => !v)}
                    style={{ cursor: 'pointer' }}
                  >
                    {`Advance all players   `}
                    <TriangleDownIcon fill="#A792FF" size={30} verticalAlign="middle" />
                  </div>
                ) : (
                  <>
                    <div
                      onClick={() => setSeeAdvancePlayers((v) => !v)}
                      style={{ cursor: 'pointer' }}
                    >
                      {`Advance all players   `}
                      <TriangleUpIcon fill="#A792FF" size={30} verticalAlign="middle" />
                    </div>
                    <div
                      style={{
                        width: '90%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 'auto',
                        marginTop: '15px',
                        marginBottom: '10px',
                        gap: '10px',
                      }}
                    >
                      <Button
                        onClick={() => movePlayers({ steps: 1 })}
                        size="medium"
                        sx={{
                          width: '100%',
                          maxWidth: '110px',
                          ...buttonStyle,
                          borderColor: '#DB004F',
                          backgroundColor: 'white',
                          color: '#DB004F',
                          fontWeight: '600',
                        }}
                        type="button"
                        variant="default"
                      >
                        + 1
                      </Button>
                      <Button
                        onClick={() => movePlayers({ steps: 4 })}
                        size="medium"
                        sx={{
                          width: '100%',
                          maxWidth: '110px',
                          ...buttonStyle,
                          borderColor: '#DB004F',
                          backgroundColor: 'white',
                          color: '#DB004F',
                          fontWeight: '600',
                        }}
                        type="button"
                        variant="default"
                      >
                        + 4
                      </Button>
                      <Button
                        onClick={() => movePlayers({ steps: 6 })}
                        size="medium"
                        sx={{
                          width: '100%',
                          maxWidth: '110px',
                          ...buttonStyle,
                          borderColor: '#DB004F',
                          backgroundColor: 'white',
                          color: '#DB004F',
                          fontWeight: '600',
                        }}
                        type="button"
                        variant="default"
                      >
                        + 6
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Layout.Panel>
            {InvisiblePanel(width, { width: width > WIDTH_FOR_RESPONSIVE ? '310px' : '100%' })}
          </div>
        ) : null}
      </Layout>
      <Dialog
        aria-labelledby="label"
        isOpen={!!acceptSparkId}
        onDismiss={() => void 0}
        returnFocusRef={useRef(null)}
        sx={{ height: 'initial !important' }}
      >
        <Dialog.Header>
          <AlertIcon />
        </Dialog.Header>
        <Box p={4}>
          <Text>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: sparkDescription }}
            />
          </Text>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              onClick={() => rejectSparkCard({ sparkId: acceptSparkId! })}
              sx={{ mr: 1, ...buttonStyle }}
              variant="danger"
            >
              No
            </Button>
            <Button
              onClick={() => acceptSparkCard({ sparkId: acceptSparkId! })}
              sx={{ ...buttonStyle }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
