import React, { useState } from 'react';
import styled from 'styled-components';
import { CJRButton as BaseCJRButton } from '../../../basicComponents/cjrButton/cjrButton';
import { REGISTRATION_PATH } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import infoIcon from '../../../images/pricingPage/infoIcon.svg'; // Import the info icon

const TasterColumn: React.FC = () => {
  const navigate = useNavigate();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleToggleTooltip = () => {
    setIsTooltipVisible((prev) => !prev);
  };

  return (
    <TasterContainer>
      <TasterHeader>
        <TasterTitle>Taster</TasterTitle>
        <InfoIconContainer
          onMouseEnter={() => setIsTooltipVisible(true)}
          onMouseLeave={() => setIsTooltipVisible(false)}
          onClick={handleToggleTooltip}
        >
          <InfoIcon src={infoIcon} alt="Info" />
          {isTooltipVisible && (
            <Tooltip>
            <strong>Included:</strong>
            <ul>
              <li>Event for up to 8 players</li>
              <li>Up to 4 players in a game</li>
              <li>Up to 2 games simultaneously</li>
              <li>3 games /month</li>
              <li>Taster edition (5 questions per deck)</li>
            </ul>
          </Tooltip>
          )}
        </InfoIconContainer>
      </TasterHeader>
      <FreeTag>FREE</FreeTag>
      <TasterDescription>Not sure yet? Test it out with up to 4 players in a game.</TasterDescription>
      <a href="/registration" rel="noopener noreferrer">
        <CustomCJRButton onClick={() => navigate(REGISTRATION_PATH)} variant="sparkTertiary">
          Sign up for free
        </CustomCJRButton>
      </a>
    </TasterContainer>
  );
};

// Styled components

const TasterContainer = styled('article')`
  border-radius: 20px;
  background-color: rgba(237, 233, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Town 80 Text, sans-serif;
  color: var(--Dark, #333);
  font-weight: 700;
  text-align: center;
  width: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  padding: 30px 30px;
  min-height: 340px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 30px 20px;
  }
`;

const TasterHeader = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const TasterTitle = styled('h2')`
  font-size: 32px;
  line-height: 1;
  letter-spacing: -0.44px;
  margin-bottom: 15px;
`;

const FreeTag = styled('span')`
  border-radius: 16px;
  background-color: var(--Cozy-Colors-Cozy-4, #dfffed);
  color: var(--Cozy-Colors-Cozy-1, #004934);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.86px;
  padding: 5px 10px;
`;

const TasterDescription = styled('p')`
  font-size: 16px;
  font-weight: 350;
  line-height: 2;
  margin: 30px;
`;

const CustomCJRButton = styled(BaseCJRButton)`
  margin-top: auto;
  border-radius: 30px; /* Override styles */
  background-color: var(--Spark-Colors-Spark-4, #ede9ff); /* Custom background */
  color: var(--Spark-Colors-Spark-1, #440d9b); /* Custom text color */
  font-size: 18px;
  font-weight: 700;
  padding: 10px 20px;

  /* Styles for the <a> tag */
  text-decoration: none;
  display: inline-block;
  color: inherit;
`;

// Info Icon and Tooltip styling

const InfoIconContainer = styled('div')`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const InfoIcon = styled('img')`
  width: 20px;
  height: 20px;
`;

const Tooltip = styled('div')`
  position: absolute;
  top: 20px;
  left: 10%;
  transform: translateX(-10%);
  background-color: #333;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  white-space: normal;
  z-index: 1;
  opacity: 0.9;
  min-width: 280px;
  text-align: left;

  @media (max-width: 480px) {
    max-width: 100px;
    left: 50%;
    transform: translateX(-60%);
  }
`;

export default TasterColumn;
