import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { Responsive } from '../../components/layout/Responsive';
import { BoardAvatar } from './BoardAvatar';
import { BoardBackground } from './BoardBackground';
import { BoardMiddle } from './BoardMiddle';
import { BoardStep } from './BoardStep';
import { themeGet } from '@primer/react';

const LimitedWidthContainer = styled('div')`
  max-width: 500px;
  width: 100%;
`;

const ResponsiveContainer = styled(Responsive)`
  margin: ${themeGet('space.4')} 0;
  background-color: #e5e5f7;
  background-image: radial-gradient(#f4f5e3 0.5vw, #fffbf6 0.6vw);
  background-size: 2% 2%;
  color: black;
  border-radius: 15%;
`;

const Grid = styled('div')`
  display: grid;
  grid-template: 1fr / 1fr;
`;

type BoardComposition = {
  Avatar: typeof BoardAvatar;
  Background: typeof BoardBackground;
  Middle: typeof BoardMiddle;
  Step: typeof BoardStep;
};

export const Board: FunctionComponent<{ children?: React.ReactNode }> & BoardComposition = ({
  children,
}) => (
  <LimitedWidthContainer>
    <ResponsiveContainer aspectRatio="100%">
      <Grid>{children}</Grid>
    </ResponsiveContainer>
  </LimitedWidthContainer>
);

Board.Avatar = BoardAvatar;
Board.Background = BoardBackground;
Board.Middle = BoardMiddle;
Board.Step = BoardStep;
