import React, { useState } from 'react';
import styled from 'styled-components';
import speech from '../../../images/speech.svg';
import popularIcon from '../../../images/pricingPage/popularIcon.svg';
import featureIcon from '../../../images/pricingPage/featureIcon2.svg';
import infoIcon from '../../../images/pricingPage/infoIcon.svg'; // Assuming you have the i icon

interface Feature {
  text: string;
  showIcon?: boolean;
  iconSrc?: string;
  infoText?: string; // Add this line to allow custom tooltip text
  isBold?: boolean;
}

interface PricingColumnProps {
  readonly title: string;
  readonly subtitle: string;
  readonly price?: string;
  readonly period?: string;
  readonly contacticon?: string;
  readonly contactprice?: string;
  readonly btn: React.ReactElement;
  readonly features: Feature[];
  readonly isPopular: boolean;
  readonly backgroundColor: string;
  readonly onCompareClick?: () => void;
}

const PricingColumn: React.FC<PricingColumnProps> = ({
  title,
  subtitle,
  price,
  period,
  contacticon,
  contactprice,
  btn,
  features,
  isPopular,
  backgroundColor,
  onCompareClick,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [featureTooltipIndex, setFeatureTooltipIndex] = useState<number | null>(null); // Separate state for feature tooltips
  const [priceTooltip, setPriceTooltip] = useState(false); // Separate state for PricePeriod tooltip

  const handleViewAllFeaturesClick = () => {
    const compareSection = document.getElementById('compare-features-section');
    if (compareSection) {
      compareSection.scrollIntoView({ behavior: 'smooth' });
    }
    if (onCompareClick) {
      onCompareClick();
    }
  };

  return (
    <StyledColumn backgroundColor={backgroundColor} isPopular={isPopular}>
      {isPopular ? (
        <PopularTag>
          <PopularIcon src={popularIcon} />
          <span>MOST POPULAR</span>
        </PopularTag>
      ) : null}
      <ColumnTitle>{title}</ColumnTitle>
      <ColumnSubtitle>{subtitle}</ColumnSubtitle>
      <PriceContainer>
        {price ? <Price>{price}</Price> : null}
        {contactprice ? (
          <ContactPriceContainer>
            <ContactIcon src={speech} />
            <ContactPriceText>{contactprice}</ContactPriceText>
          </ContactPriceContainer>
        ) : null}
        {period ? (
          <PricePeriod>
            {period}
            {/* Separate tooltip handling for the PricePeriod */}
            {title === 'Company' && (
              <InfoIconContainer
                onMouseEnter={() => setPriceTooltip(true)}
                onMouseLeave={() => setPriceTooltip(false)}
              >
                <InfoIcon src={infoIcon} alt="Info" />
                {priceTooltip && <Tooltip>Starting from $960 /year for 4 admins.</Tooltip>}
              </InfoIconContainer>
            )}
          </PricePeriod>
        ) : null}
      </PriceContainer>
      {btn}
      <Divider />
      <FeatureList>
        {features.map((feature, index) => (
          <FeatureItem key={index}>
            {feature.showIcon && <FeatureIcon src={feature.iconSrc || featureIcon} />}
            <FeatureText isBold={feature.isBold}>{feature.text}</FeatureText>
            {feature.infoText && (
              <InfoIconContainer
                onMouseEnter={() => setFeatureTooltipIndex(index)}
                onMouseLeave={() => setFeatureTooltipIndex(null)}
              >
                <InfoIcon src={infoIcon} alt="Info" />
                {featureTooltipIndex === index && <Tooltip>{feature.infoText}</Tooltip>}
              </InfoIconContainer>
            )}
          </FeatureItem>
        ))}
      </FeatureList>
      <ViewAllFeatures onClick={handleViewAllFeaturesClick}>View all features</ViewAllFeatures>
    </StyledColumn>
  );
};

// Tooltip and Icon Styles
const InfoIconContainer = styled('div')`
  position: relative;
  display: inline-block;
  margin-left: 8px;
`;

const InfoIcon = styled('img')`
  width: 18px;
  cursor: pointer;
`;

const Tooltip = styled('div')`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  white-space: normal;
  min-width: 300px;
  max-width: 400px;
  z-index: 1;
  opacity: 0.9;

  /* Remove or comment out this media query */
  /*
  @media (max-width: 991px) {
    left: auto;
    right: -30px;
    transform: none;
  }
  */

  @media (max-width: 480px) {
    min-width: 200px;
    max-width: 300px;
  }
`;

const StyledColumn = styled('article')<{ isPopular: boolean; backgroundColor: string }>`
  border-radius: 20px;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  padding: 50px 30px;
  width: 100%;
  position: relative;
  ${(props) =>
    props.isPopular &&
    `
    border: 1px solid var(--Cozy-Colors-Cozy-3, #54ef9a);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  `}
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 40px 40px;
  }
`;

const PopularTag = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--Cozy-Colors-Cozy-3, #54ef9a);
  border-left: 1px solid var(--Cozy-Colors-Cozy-3, #54ef9a);
  border-right: 1px solid var(--Cozy-Colors-Cozy-3, #54ef9a);
  background-color: var(--Cozy-Colors-Cozy-4, #dfffed);
  color: var(--Cozy-Colors-Cozy-1, #004934);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 14px;
  padding: 10px;
  width: calc(100% + 2px);
  margin-bottom: 20px;
  position: absolute;
  top: -26px;
  left: -1px;
  right: -1px;
  border-radius: 20px 20px 0 0;
`;

const PopularIcon = styled('img')`
  width: 14px;
  margin-right: 4px;
`;

const ColumnTitle = styled('h2')`
  color: var(--Dark, #333);
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ColumnSubtitle = styled('p')`
  color: var(--Dark, #333);
  text-align: center;
  font-size: 16px;
  font-weight: 350;
  line-height: 2;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const PriceContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Price = styled('span')`
  color: var(--Dark, #333);
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -0.67px;
`;

const PricePeriod = styled('span')`
  color: var(--Text-Placeholder, #333);
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
`;

const ContactPriceContainer = styled('div')`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--contact-price-color, #333); /* Custom styling */
`;

const ContactPriceText = styled('span')`
  display: block;
  padding-right; 10px
  text-align: left; /* Align text to the left */
  line-height: 1.2; /* Adjust line height if needed */
`;

const ContactIcon = styled('img')`
  width: 80px;
  height: 70px;
  margin-right: 10px;
  margin-bottom: 0px;
  color: '#333';
`;

const Divider = styled('hr')`
  border: none;
  height: 1px;
  background-color: rgba(153, 153, 153, 0.5);
  margin: 37px 0;
`;

const FeatureList = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled('li')`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const FeatureIcon = styled('img')`
  width: 24px;
  margin-right: 10px;
`;

const FeatureText = styled('span')<{ isBold?: boolean }>`
  color: var(--Dark, #333);
  font-size: 16px;
  line-height: 2;
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};  // Apply bold or normal
`;

const ViewAllFeatures = styled('div')`
  margin-top: auto;
  padding-top: 30px;
  color: var(--Spark-Colors-Spark-2, #896dff);
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: var(--Spark-Colors-Spark-2, #440d9b);
  }
`;

export default PricingColumn;
