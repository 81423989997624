import { useCallback } from 'react';
import {
  ChangePlayerMessage,
  DrawCardMessage,
  FinishPlayPhaseMessage,
  PlaySparkCardMessage,
  RandomPointsMessage,
} from '../redux/models/colyseumSchemas/messages';
import { getRoom } from '../redux/slices/game.slice';
import { useAppSelector } from '../redux/hooks';
import { useDeleteRoomMutation } from '../redux/apis/rooms';

export const useMessageSender = () => {
  const room = useAppSelector(getRoom);
  const [deleteRoom] = useDeleteRoomMutation();

  const changePlayer = useCallback(
    (message: ChangePlayerMessage) => {
      room?.send('changePlayer', message);
    },
    [room]
  );

  const startGame = useCallback(() => {
    room?.send('startGame', {});
  }, [room]);

  const drawCard = useCallback(
    (message: DrawCardMessage) => {
      room?.send('drawCard', message);
    },
    [room]
  );
  const skipCard = useCallback(() => {
    room?.send('skipCard');
  }, [room]);

  const finishTurn = useCallback(() => {
    room?.send('finishTurn');
  }, [room]);

  const finishPlayPhase = useCallback(
    (message: FinishPlayPhaseMessage) => {
      room?.send('finishPlayPhase', message);
    },
    [room]
  );

  const playSparkCard = useCallback(
    (message: PlaySparkCardMessage) => {
      room?.send('playSparkCard', message);
    },
    [room]
  );

  const leaveGame = useCallback(() => {
    room?.send('leaveGame');
  }, [room]);

  const returnToGame = useCallback(() => {
    room?.send('returnToGame');
  }, [room]);

  const joinAsObserver = useCallback(() => {
    room?.send('joinAsObserver');
  }, [room]);

  const removePlayer = useCallback(
    (message: { playerId: string }) => {
      room?.send('removePlayer', message);
    },
    [room]
  );

  const randomPoints = useCallback(
    (message: RandomPointsMessage) => {
      room?.send('randomPoints', message);
    },
    [room]
  );

  const closeRoom = useCallback(() => {
    room?.send('closeRoom');
    if (room) deleteRoom({ id: room.id });
  }, [room]);

  const claimAllSparks = useCallback(() => {
    room?.send('claimAllSparks');
  }, [room]);

  const acceptSparkCard = useCallback(
    (message: { sparkId: string }) => {
      room?.send('acceptSparkCard', message);
    },
    [room]
  );

  const rejectSparkCard = useCallback(
    (message: { sparkId: string }) => {
      room?.send('rejectSparkCard', message);
    },
    [room]
  );

  const movePlayers = useCallback(
    (message: { steps: number }) => {
      room?.send('movePlayers', message);
    },
    [room]
  );

  return {
    changePlayer,
    startGame,
    drawCard,
    finishTurn,
    finishPlay: finishPlayPhase,
    playSparkCard,
    skipCard,
    leaveGame,
    returnToGame,
    leaveGameForever: joinAsObserver,
    removePlayer,
    randomPoints,
    closeRoom,
    claimAllSparks,
    acceptSparkCard,
    rejectSparkCard,
    movePlayers,
  };
};
