import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, Dialog, Flash, Heading, Text } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import { useMessageSender } from '../../hooks/useMessageSender';
import { PlayerForm, PlayerFormValues } from '../../basicComponents/playerForm/PlayerForm';
import { ShareRoomLink } from '../../components/shareRoomLink/ShareRoomLink';
import { RoomName } from '../../basicComponents/roomName/RoomName';
import { useAppSelector } from '../../redux/hooks';
import { selectAuth } from '../../redux/slices/auth.slice';
import { clearGameState, getGameState } from '../../redux/slices/game.slice';
import { AuthData } from '../../redux/models/auth.model';
import { useGetConfigQuery } from '../../redux/apis/config';
import { useDispatch } from 'react-redux';
import { getCurrentPlayer } from '../../redux/slices/player.slice';
import { buttonStyle, colors } from '../../theme/theme';
import { useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { MY_GAMES_PATH, PLANS_PATH } from '../../utils/constants';
import { PlayerList } from '../../basicComponents/playerList/PlayerList';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useApisLoaded } from '../../hooks/useApisLoaded';
import { BackButtom } from '../../basicComponents/backButtom/BackButtom';
import { setMenu } from '../../redux/slices/menu.slice';
import { Bar } from '../../basicComponents/bar/Bar';
import { showModal } from '../../redux/slices/modal.slice';
import { getDeckState } from '../../redux/slices/deck.slice';
import { useGetRoomQuery } from '../../redux/apis/rooms';

export const WIDTH_FOR_RESPONSIVE = 830;
const WIDTH_FOR_CENTRALIZATION = 1300;

export const LobbyPage: FunctionComponent = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth: AuthData = useAppSelector(selectAuth);
  const sub = useGetSubscriptionStatusQuery(undefined);

  const { isReady, playerId } = useAppSelector(getCurrentPlayer);
  const [characterChosen, setCharacterChosen] = useState(false);
  const {
    name = '',
    gameState,
    room,
    noOfReady,
    playersArr,
    players,
  } = useAppSelector(getGameState);

  const getConfig = useGetConfigQuery(undefined);
  const { roomId } = useParams();
  const getRoom = useGetRoomQuery(roomId!, { skip: roomId === undefined || roomId === ':roomId' });
  const { changePlayer, startGame, returnToGame, leaveGameForever, closeRoom, leaveGame } =
    useMessageSender();
  const showBar = !useApisLoaded();

  useEffect(() => {
    if (players && players.has(playerId) && players.get(playerId)!.isReady) {
      setCharacterChosen(true);
    }
  }, [players, playerId]);

  useEffect(() => {
    if (gameState === 'started' && isReady && !searchParams.has('paused'))
      navigate('../game', { replace: true });
    if (gameState === 'finished') navigate('../game', { replace: true });
  }, [gameState, isReady]);

  useEffect(() => {
    if (
      getRoom.data?.maxNoOfPlayersPerGame &&
      playersArr.filter((x) => x.isReady).length > getRoom.data.maxNoOfPlayersPerGame
    )
      dispatch(showModal({ type: 'danger', message: 'The room is full' }));
  }, [playersArr.length, getConfig.isSuccess, getRoom.isSuccess]);

  const onSubmit = (player: PlayerFormValues) => {
    setCharacterChosen(true);
    changePlayer({ ...player, isReady: true });
    if (!playersArr.find((p) => p.id == playerId)) {
      dispatch(
        showModal({ type: 'danger', message: 'You were disconnected. Please refresh the page.' })
      );
    } else if (auth.isGuest) dispatch(setMenu('howToPlay'));
  };

  const playGame = () => {
    if (gameState !== 'started') startGame();
    if (gameState == 'started') returnToGame();
    navigate('../game');
  };

  const showStartGameButton = playersArr.length > 1 && noOfReady >= 1;

  const [tasterDeckDialogue, setTasterDeckDialogue] = useState(false);
  const { expansion } = useAppSelector(getDeckState);
  useEffect(() => {
    if (expansion == 'Taster') setTasterDeckDialogue(true);
  }, []);

  return (
    <Layout
      alignItems={width < WIDTH_FOR_RESPONSIVE ? 'center' : 'center'}
      flexDirection={width < WIDTH_FOR_RESPONSIVE ? 'column' : 'row'}
    >
      {/* //Invisible Panel */}
      <Layout.Panel
        style={{
          height: '0px',
          padding: '0px',
          width: `${
            width > WIDTH_FOR_RESPONSIVE
              ? width < WIDTH_FOR_CENTRALIZATION
                ? '0px'
                : '270px'
              : '100%'
          }`,
        }}
      />
      <Layout.Panel style={{ position: 'relative' }}>
        {!auth.isGuest ? (
          <BackButtom
            goBack={() => {
              navigate(MY_GAMES_PATH);
              leaveGame();
            }}
          />
        ) : null}
        <RoomName name={name} />

        <ShareRoomLink />

        {!auth.isGuest &&
        (!isReady || !characterChosen) &&
        sub.data?.some((s) => s.isSubscriptionActive) &&
        getConfig.data &&
        getConfig.data.ObserverFeature ? (
          <>
            <Button
              onClick={() => {
                leaveGameForever();
                setCharacterChosen(true);
                if (gameState == 'started') navigate('../game', { replace: true });
              }}
              size="medium"
              sx={{
                mt: 0,
                width: '70%',
                maxWidth: '200px',
                ...buttonStyle,
                backgroundColor: '#A792FF',
                borderColor: colors.spark.dark,
                margin: 'auto',
              }}
              type="button"
              variant="primary"
            >
              Join as observer
            </Button>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div
                style={{
                  height: '1px',
                  backgroundColor: '#A792FF',
                  width: '100%',
                }}
              />
              <p style={{ color: '#A792FF', marginRight: '5px', marginLeft: '5px' }}> OR </p>
              <div
                style={{
                  height: '1px',
                  backgroundColor: '#A792FF',
                  width: '100%',
                }}
              />
            </div>
          </>
        ) : null}

        {(!isReady || !characterChosen) && getRoom.data?.maxNoOfPlayersPerGame ? (
          playersArr.filter((p) => p.isReady).length < getRoom.data.maxNoOfPlayersPerGame ? (
            <PlayerForm
              noOfPlayers={getRoom.data.maxNoOfPlayersPerGame}
              onSubmit={onSubmit}
              otherPlayers={playersArr}
            />
          ) : (
            <Flash sx={{ mt: 6, p: 1, pl: 2, textAlign: 'center' }} variant="warning">
              This game room is full
            </Flash>
          )
        ) : null}

        {isReady && characterChosen ? (
          <>
            <div style={{ height: '40px' }} />
            <Box display="flex" flexDirection="row">
              {!auth.isGuest ? (
                <Button
                  onClick={() => {
                    closeRoom();
                    navigate(MY_GAMES_PATH);
                    room?.connection.close();
                    dispatch(clearGameState(null));
                  }}
                  size="medium"
                  sx={{
                    mt: 3,
                    width: '100%',
                    ...buttonStyle,
                    marginRight: '7px',
                    borderColor: '#DB004F',
                    backgroundColor: 'white',
                    color: '#DB004F',
                    fontWeight: '100',
                  }}
                  type="button"
                  variant="default"
                >
                  Cancel
                </Button>
              ) : null}
              <Button
                disabled={!showStartGameButton && gameState !== 'started'}
                onClick={playGame}
                size="medium"
                sx={{ mt: 3, width: '100%', ...buttonStyle, marginLeft: '7px' }}
                type="button"
                variant="primary"
              >
                {gameState === 'started'
                  ? 'Enter game'
                  : !showStartGameButton
                  ? 'Awaiting players'
                  : 'Start game'}
              </Button>
            </Box>
          </>
        ) : null}

        {showBar ? <Bar /> : null}
      </Layout.Panel>
      <Layout.Panel
        style={{
          position: 'relative',
          width: `${width > WIDTH_FOR_RESPONSIVE ? '270px' : '100%'}`,
        }}
      >
        <>
          <Heading as="h2" sx={{ fontSize: 3, textAlign: 'center', mb: 3, color: '#3B147A' }}>
            Players
          </Heading>
          <PlayerList players={playersArr} />
        </>
      </Layout.Panel>

      <Dialog
        aria-labelledby="label"
        isOpen={tasterDeckDialogue}
        onDismiss={() => setTasterDeckDialogue(false)}
        returnFocusRef={useRef(null)}
        sx={{ height: 'initial !important' }}
      >
        <Dialog.Header>Heads up!</Dialog.Header>
        <Box p={4}>
          <Text id="label">
            The Taster deck has just enough cards for a small game, so questions may repeat.{' '}
            <a
              onClick={() => {
                navigate(PLANS_PATH);
                leaveGame();
              }}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Upgrade
            </a>{' '}
            to unlock new questions, challenges, activities and more features.
          </Text>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button onClick={() => setTasterDeckDialogue(false)} sx={{ mr: 1, ...buttonStyle }}>
              Ok
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Layout>
  );
};
