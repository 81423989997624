import { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { Heading, FormControl, TextInput, Box, Text } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import { useAppDispatch } from '../../redux/hooks';
import { colors, textInputStyle } from '../../theme/theme';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';
import { validateEmail, validateNonEmptyString } from '../../utils/validators';
import { UserListItem } from '../../components/userListItem/UserListItem';
import {
  useAddChildMutation,
  useGetChildrenQuery,
  useGetUserQuery,
} from '../../redux/apis/user.api';
import {
  closeLoading,
  setManualLoading,
  showLoading,
  showModal,
} from '../../redux/slices/modal.slice';
import { ChangeTeamSizeDialog } from '../../components/changeTeamSizeDialog/ChangeTeamSizeDialog';
import { useGetSubscriptionStatusQuery } from '../../redux/apis/payment';

export const UserManagementPage: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState<string>();
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState<string>();
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>();
  const [isChangeDialogOpen, setIsChangeDialogOpen] = useState(false);
  const sub = useGetSubscriptionStatusQuery(undefined);

  const [addChild, addedChild] = useAddChildMutation();
  const getKids = useGetChildrenQuery(undefined, { refetchOnMountOrArgChange: true });
  const getUserQuery = useGetUserQuery(undefined);

  const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxCharacters = 18;
    const value = event.target.value.slice(0, maxCharacters);
    setFirstName(value);
    setFirstNameErrorMsg(validateNonEmptyString(value, 'first name'));
  };

  const onLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxCharacters = 18;
    const value = event.target.value.slice(0, maxCharacters);
    setLastName(value);
    setLastNameErrorMsg(validateNonEmptyString(value, 'last name'));
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
    setEmailErrorMsg(validateEmail(value));
  };

  const isValid = () => {
    const firstNameValidationError = validateNonEmptyString(firstName, 'first name');
    setFirstNameErrorMsg(firstNameValidationError);
    const lastNameValidationError = validateNonEmptyString(lastName, 'last name');
    setLastNameErrorMsg(lastNameValidationError);
    const emailValidationError = validateEmail(email);
    setEmailErrorMsg(emailValidationError);

    return !firstNameValidationError && !lastNameValidationError && !emailValidationError;
  };

  const onNext = () => {
    if (!isValid()) return;
    dispatch(setManualLoading(true));
    dispatch(showLoading());

    addChild({ childEmail: email, firstName, lastName });
  };

  useEffect(() => {
    if (addedChild.isSuccess || addedChild.isError) {
      dispatch(closeLoading());
    }
    if (addedChild.isSuccess) {
      dispatch(
        showModal({
          type: 'success',
          message:
            addedChild.data!.code == 'USER_ADDED'
              ? 'User successfully added'
              : 'Invitation sent successfully',
        })
      );
      setFirstName('');
      setLastName('');
      setEmail('');
    }
  }, [addedChild]);

  return (
    <Layout>
      <Layout.Panel style={{ width: '100%', maxWidth: '520px', position: 'relative' }}>
        <Heading as="h1" sx={{ fontSize: 3, mb: 3, textAlign: 'center' }}>
          Invite a team member
        </Heading>

        {getKids.isSuccess ? (
          <>
            <Box
              backgroundColor="#eeeeee"
              borderRadius="5px"
              height="10px"
              marginBottom="20px"
              position="relative"
              width="100%"
            >
              <Box
                backgroundColor={
                  getKids.data!.maxNoOfChildren == getKids.data!.children.length
                    ? colors.spark.btn
                    : colors.accent.emphasis
                }
                borderRadius="5px"
                height="100%"
                left="0"
                position="absolute"
                top="0"
                width={`${
                  100 -
                  (Math.max(getKids.data!.maxNoOfChildren - getKids.data!.children.length, 0) *
                    100) /
                    getKids.data!.maxNoOfChildren
                }%`}
              />
            </Box>

            {getKids.data!.maxNoOfChildren > getKids.data!.children.length ? (
              <>
                <Text style={{ fontSize: '16px', textAlign: 'center' }}>
                  You have{' '}
                  {Math.max(getKids.data!.maxNoOfChildren - getKids.data!.children.length, 0)}{' '}
                  invitation
                  {Math.max(getKids.data!.maxNoOfChildren - getKids.data!.children.length, 0) > 1
                    ? 's'
                    : ''}{' '}
                  remaining.{' '}
                  <a
                    onClick={() => setIsChangeDialogOpen(true)}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                  >
                    Click here
                  </a>{' '}
                  to increase or decrease your team size.
                </Text>

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  marginTop="26px"
                >
                  <FormControl sx={{ marginRight: '10px' }}>
                    <FormControl.Label>First name*</FormControl.Label>
                    <TextInput
                      aria-describedby="input-validation"
                      aria-invalid={!!firstNameErrorMsg}
                      onChange={onFirstNameChange}
                      sx={{ width: '100%', ...textInputStyle }}
                      validationStatus={firstNameErrorMsg ? 'error' : undefined}
                      value={firstName}
                    />
                  </FormControl>
                  <FormControl>
                    <FormControl.Label>Last name*</FormControl.Label>
                    <TextInput
                      aria-describedby="input-validation"
                      aria-invalid={!!lastNameErrorMsg}
                      onChange={onLastNameChange}
                      sx={{ width: '100%', ...textInputStyle }}
                      validationStatus={lastNameErrorMsg ? 'error' : undefined}
                      value={lastName}
                    />
                  </FormControl>
                </Box>
                {firstNameErrorMsg || lastNameErrorMsg ? (
                  <FormControl.Validation id="input-validation" variant="error">
                    {firstNameErrorMsg ?? lastNameErrorMsg}
                  </FormControl.Validation>
                ) : null}
                <Box marginTop="16px">
                  <FormControl>
                    <FormControl.Label>Email*</FormControl.Label>
                    <TextInput
                      aria-describedby="input-validation"
                      aria-invalid={!!emailErrorMsg}
                      onChange={onEmailChange}
                      placeholder="email@example.com"
                      sx={{ width: '100%', ...textInputStyle }}
                      validationStatus={emailErrorMsg ? 'error' : undefined}
                      value={email}
                    />
                  </FormControl>
                  {emailErrorMsg ? (
                    <FormControl.Validation id="input-validation" variant="error">
                      {emailErrorMsg}
                    </FormControl.Validation>
                  ) : null}
                </Box>
                <div style={{ height: '7px' }} />
                <Text
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    color: '#999999',
                  }}
                >
                  Please note: once a team member has accepted their invitation, they’ll need to
                  remain a member for 45 days before they can be removed.
                </Text>

                <Box display="flex" flexDirection="row" justifyContent="center">
                  <CJRButton
                    onClick={onNext}
                    style={{ marginTop: '25px', width: '70%', marginLeft: '7px' }}
                    variant="sparkPrimary"
                  >
                    Send invitation
                  </CJRButton>
                </Box>
              </>
            ) : (
              <>
                <Text style={{ fontSize: '16px', textAlign: 'center' }}>
                  You have no invitations remaining.
                </Text>

                <div style={{ height: '10px' }} />

                {sub.data!.find(
                  (p) =>
                    !p.isTemporaryAddOnSubscription &&
                    p.allowChildren &&
                    p.isSubscriptionActive &&
                    !p.fromParent
                ) ? (
                  <Box display="flex" flexDirection="row" justifyContent="center">
                    <CJRButton
                      onClick={() => setIsChangeDialogOpen(true)}
                      style={{ marginTop: '25px', width: '70%', marginLeft: '7px' }}
                      variant="sparkSecondary"
                    >
                      Add more members
                    </CJRButton>
                  </Box>
                ) : null}
              </>
            )}
          </>
        ) : null}
      </Layout.Panel>

      <Layout.Panel
        style={{ width: '100%', maxWidth: '520px', position: 'relative', marginTop: '10px' }}
      >
        <Heading as="h1" sx={{ fontSize: 3, mb: 3, textAlign: 'center' }}>
          Manage members
        </Heading>

        <Text style={{ fontSize: '16px', textAlign: 'center' }}>
          You have {(getKids.data?.children.length ?? 0) + 1} member
          {(getKids.data?.children.length ?? 0) + 1 > 1 ? 's' : ''} added to your Cozy Juicy Real
          account. Manage your members below
        </Text>

        <div style={{ height: '10px' }} />

        {getKids.isSuccess && getUserQuery.isSuccess
          ? [
              {
                firstName: getUserQuery.data!.firstName,
                lastName: getUserQuery.data!.lastName,
                email: getUserQuery.data!.email,
              },
              getKids.data!.children,
            ]
              .flat()
              .map((child: any, index: number) => (
                <UserListItem
                  email={child.email}
                  firstName={child.firstName}
                  isOwner={index == 0}
                  isPending={child.code == 'USER_INVITED'}
                  key={`Child_${index}`}
                  parentAddedAtSeconds={child.parentAddedAtSeconds}
                  userName={child.firstName + ' ' + child.lastName}
                />
              ))
          : null}
      </Layout.Panel>

      <ChangeTeamSizeDialog isOpen={isChangeDialogOpen} setIsOpen={setIsChangeDialogOpen} />
    </Layout>
  );
};
