//
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
//
// GENERATED USING @colyseus/schema 1.0.34
//

import { Schema, type } from '@colyseus/schema';

export const sparkTypeValues = [
  'godeeper',
  'highfive',
  'courage',
  'hug',
  'aboutyou',
  'aboutme',
] as const;
export type SparkType = typeof sparkTypeValues[number];
export type SparkPhase = 'initial' | 'pending' | 'active' | 'claimed';

export class Spark extends Schema {
  @type('string') public id!: string;
  @type('string') public toPlayerId!: string;
  @type('string') public fromPlayerId!: string;
  @type('string') public sparkType!: SparkType;
  @type('string') public phase!: SparkPhase;
}
