import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { Box, Text, themeGet } from '@primer/react';
import { Avatar } from '../avatar/Avatar';
import { PlayerProps } from '../../redux/slices/game.slice';

type ContainerProps = Pick<PlayerProps, 'isConnected' | 'isCurrentPlayer' | 'isReady'> & {
  isShowingPoints: boolean;
};
const Container = styled('div')<ContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${({ isShowingPoints }) => (isShowingPoints ? 'space-between' : 'flex-start')};
  background-color: ${({ isCurrentPlayer, isConnected, isReady, isShowingPoints }) =>
    isShowingPoints
      ? '#EDE9FF'
      : isConnected && isReady
      ? isCurrentPlayer
        ? '#fcf3e5'
        : '#fcf7e5'
      : '#f9f8f5'};
  padding-right: ${themeGet('space.1')};
  border-radius: ${themeGet('radii.3')};
  ${({ isConnected, isReady }) =>
    (!isConnected || !isReady) &&
    `
    & > div {
      border-color: #d8d8d8;

      img {
        filter: opacity(30%) grayscale(100%)
      }
    }
  `}
`;

const AvatarNameContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Player: FunctionComponent<
  PlayerProps & { readonly isShowingPoints?: boolean; readonly withBackground?: boolean }
> = ({
  nickname,
  avatar,
  isCurrentPlayer,
  isConnected,
  isReady,
  points,
  isShowingPoints = false,
  withBackground = false,
}) => (
  <Container
    isConnected={isConnected}
    isCurrentPlayer={isCurrentPlayer}
    isReady={isReady}
    isShowingPoints={isShowingPoints}
    key={nickname}
    style={{ backgroundColor: withBackground ? 'white' : '#EDE9FF' }}
  >
    <AvatarNameContainer>
      <Avatar.Border>
        <Avatar alt={`${nickname}'s avatar`} size={48} variant={avatar} />
      </Avatar.Border>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        ml={3}
        sx={{
          lineHeight: 'condensed',
          minWidth: 0, // Needed for text-overflow see https://css-tricks.com/flexbox-truncated-text/
          maxWidth: 130,
        }}
      >
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Text fontSize={1} fontWeight="semibold">
            {nickname}
          </Text>
        </Box>
        {isCurrentPlayer || !isConnected || !isReady ? (
          <Text color="neutral.emphasis" fontSize={1}>
            {(isCurrentPlayer ? 'You ' : '') +
              (!isConnected ? 'Disconnected ' : '') +
              (!isReady ? 'Joining... ' : '')}
          </Text>
        ) : null}
      </Box>
    </AvatarNameContainer>
    {isShowingPoints ? (
      <Box
        style={{
          backgroundColor: 'white',
          height: '42px',
          width: '42px',
          borderRadius: '21px',
          textAlign: 'center',
          lineHeight: '42px',
          fontWeight: 600,
          filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))',
        }}
      >
        {points || 0}
      </Box>
    ) : null}
  </Container>
);
