import { FunctionComponent, useEffect } from 'react';
import { Layout } from '../../components/layout/Layout';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { authSessionActions, selectAuth } from '../../redux/slices/auth.slice';
import { useGetGuestTokenQuery } from '../../redux/apis/auth.api';
import { setManualLoading, showLoading } from '../../redux/slices/modal.slice';
import { LOBBY_PATH } from '../../utils/constants';

export const EnterGamePage: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector(selectAuth);

  const { code } = useParams();
  const guestTokenQuery = useGetGuestTokenQuery(
    { code: code! },
    { skip: !code || code == ':code' }
  );

  useEffect(() => {
    dispatch(setManualLoading(true));
    dispatch(showLoading());
  }, []);

  useEffect(() => {
    if (code && code != ':code' && !isLoggedIn && guestTokenQuery.isSuccess) {
      dispatch(
        authSessionActions.setAuth({
          accessToken: guestTokenQuery.data.accessToken,
          expiresAt: guestTokenQuery.data.expiresAt,
          isGuest: true,
        })
      );
    }
    if (code && code != ':code' && guestTokenQuery.isSuccess) {
      navigate(LOBBY_PATH.replace(`:roomId`, guestTokenQuery.data!.roomId!));
    }
  }, [code, isLoggedIn, guestTokenQuery.isSuccess]);

  return (
    <Layout alignItems="center" flexDirection="column">
      <Layout.Panel style={{ maxWidth: '510px', position: 'relative' }} />
    </Layout>
  );
};
