import { Dialog } from '@primer/react';
import styled from 'styled-components';
import arrowIcon from '../../images/arrowIcon.svg';

const Text = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 18px;
`;

const ScrollContainer = styled('div')`
  height: 800px;
  overflow-y: scroll;
  padding: 30px;
`;

const Item = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 18px;
  padding-bottom: 18px;
  border-top: 1px solid #999;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const Title = styled('div')`
  color: #3b147a;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 1px;
  padding-bottom: 6px;
`;

const Explanation = styled('div')`
  color: #3b147a;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3px;
`;

const Icon = styled('img')`
  min-width: 60px;
  padding-right: 10px;
`;

export const TopTips4EventsDialog = ({ setIsOpen }: { readonly setIsOpen: Function }) => {
  return (
    <Dialog
      aria-labelledby="label"
      isOpen
      onDismiss={() => setIsOpen(false)}
      sx={{
        height: '80%',
        maxHeight: '628px',
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Top tips for events
      </div>
      <ScrollContainer>
        <Text>
          Cozy Juicy Real is user-friendly, but to ensure your events go smoothly, these are our top
          tips:
        </Text>

        <Item key="1">
          <Icon src={arrowIcon} />

          <TextContainer>
            <Title>Do a Dry Run</Title>
            <Explanation>
              Before your first event, do a practice session and set up as you would for the real
              thing. If your event is virtual, log into your video-call app (Zoom, etc), invite a
              friend, and play a mini round of Cozy Juicy Real to ensure you’re ready for game day.
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="2">
          <Icon src={arrowIcon} />

          <TextContainer>
            <Title>Plan for Flexibility</Title>
            <Explanation>
              People can be unpredictable—arriving late, leaving early, or even joining while in the
              car. Prepare for the unexpected with a flexible plan. 👍
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="3">
          <Icon src={arrowIcon} />

          <TextContainer>
            <Title>Request Cameras On</Title>
            <Explanation>
              Let attendees know Cozy Juicy Real is a cameras-on event. This one detail will help
              everyone connect and get the most out of the experience.
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="4">
          <Icon src={arrowIcon} />

          <TextContainer>
            <Title>Encourage Spark Cards</Title>
            <Explanation>
              Spark Cards add to the magic of Cozy Juicy Real. Show players how to use them, and
              remind them they’re key to winning. 😜
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="5">
          <Icon src={arrowIcon} />

          <TextContainer>
            <Title>No Need to Finish</Title>
            <Explanation>
              While it’s great to complete a full game, the real fun is in the playing. Let your
              teams know it’s okay if they don’t finish in the time allotted.
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="6">
          <Icon src={arrowIcon} />

          <TextContainer>
            <Title>What to Show Attendees</Title>
            <Explanation>
              <ul style={{ margin: '0px' }}>
                <li>
                  Show{' '}
                  <a
                    href="https://vimeo.com/1000391867"
                    rel="noreferrer"
                    style={{ color: '#3B147A', textDecoration: 'subscribed' }}
                    target="_blank"
                  >
                    this video
                  </a>{' '}
                  on how to set up their screen.
                </li>
                <li>
                  If using Zoom, show players how to ask for help in Breakout rooms with{' '}
                  <a
                    href="https://go.cozyjuicyreal.com/zoom-askforhelp"
                    rel="noreferrer"
                    style={{ color: '#3B147A', textDecoration: 'subscribed' }}
                    target="_blank"
                  >
                    this button
                  </a>
                  .
                </li>
                <li>
                  The rules video pops up before players enter their game room - remind them to
                  watch it!
                </li>
              </ul>
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="7">
          <Icon src={arrowIcon} />

          <TextContainer>
            <Title>Cuddle Up to the How-To Page</Title>
            <Explanation>
              We made it to help you navigate your first event and tackle any issues that might pop
              up.
            </Explanation>
          </TextContainer>
        </Item>
      </ScrollContainer>
    </Dialog>
  );
};
