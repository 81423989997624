import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import { RootState } from '../store';

type PlayerState = {
  lastPlayed: string;
  currentPlayerId: string;
  rooms: {
    [roomId: string]: {
      playerId: string;
      reconnectionToken: string;
    };
  };
};

const playerSlice = createSlice({
  name: 'player',
  initialState: { rooms: {} } as PlayerState,
  reducers: {
    setRoomPlayer: (
      state: PlayerState,
      action: PayloadAction<{ roomId: string; playerId: string; reconnectionToken: string }>
    ) => {
      cleanup(state);
      state.rooms[action.payload.roomId] = {
        playerId: action.payload.playerId,
        reconnectionToken: action.payload.reconnectionToken,
      };
    },
    setCurrentPlayer: (state: PlayerState, action: PayloadAction<string>) => {
      state.currentPlayerId = action.payload;
    },
  },
});
export const { setRoomPlayer, setCurrentPlayer } = playerSlice.actions;

export const getRoomPlayerId =
  (roomId: string | undefined) =>
  (
    state: RootState
  ): {
    playerId: string;
    reconnectionToken: string;
  } =>
    roomId
      ? state.player.rooms[roomId]
      : {
          playerId: '',
          reconnectionToken: '',
        };

export const getCurrentPlayer = createSelector(
  (state: RootState) => {
    const playerId = state.player.currentPlayerId;
    const isObserver = state.game.observers?.has(playerId) || false;
    return {
      playerId,
      isObserver,
      isReady: isObserver ? true : state.game.players?.get(playerId)?.isReady || false,
    };
  },
  (player) => player
);

export const playerReducer = persistReducer(
  { key: 'player', storage: localStorage },
  playerSlice.reducer
);

const cleanup = (state: PlayerState) => {
  const now = Math.floor(Date.now() / 1000);

  if (state.lastPlayed) {
    const lastPlayed = parseInt(state.lastPlayed);
    const oneDay = 86400;
    if (now - lastPlayed > oneDay && state) {
      Object.keys(state).forEach((roomId) => delete state.rooms[roomId]);
    }
  }
  state.lastPlayed = now.toString();
};
