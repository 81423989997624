import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Dialog } from '@primer/react';
import { SubscriptionStatus, useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { stopConfettiInner } from '../confetti/confetti';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';

interface AlldoneDialogProps {
  readonly planName: string;
  readonly open: boolean;
  readonly setOpen: (value: boolean) => void;
}

export const AlldoneDialog: FunctionComponent<AlldoneDialogProps> = ({
  planName,
  open,
  setOpen,
}) => {
  stopConfettiInner();

  const sub = useGetSubscriptionStatusQuery(undefined);
  const [plan, setPlan] = useState<SubscriptionStatus>();

  useEffect(() => {
    if (sub.isSuccess) {
      setPlan(sub.data!.find((p) => p.plan == planName));
    }
  }, [sub]);

  if (!sub.data || !plan) return <></>;

  return (
    <Dialog
      aria-labelledby="label"
      isOpen={open}
      onDismiss={() => setOpen(false)}
      sx={{ height: 'initial !important' }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        All done!
      </div>
      <Box p={4}>
        <Box p={3}>
          {plan?.isTemporaryAddOnSubscription ? (
            <>
              Okey dokes, your{' '}
              <b>{`${planName[0]}${planName.slice(1).toLowerCase().replace('_', ' ')}`}</b> add-on
              has been unlocked. 🙌
            </>
          ) : (
            <>
              Okey dokes, you’ve successfully subscribed to the{' '}
              <b>{`${planName[0]}${planName.slice(1).toLowerCase().replace('_', ' ')}`}</b> plan. 🙌
            </>
          )}
        </Box>
        <Box p={3}>
          You’ll receive an email shortly confirming the{' '}
          {plan?.isTemporaryAddOnSubscription ? 'update' : 'subscription'}.
        </Box>
        <Box p={3}>
          Enjoy your juicy new {plan?.isTemporaryAddOnSubscription ? 'features' : 'plan'}, and as
          always, feel free to reach out if you have any questions at <b>team@cozyjuicyreal.com</b>.
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p={4}
          style={{
            gap: '15px',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <CJRButton
            onClick={() => setOpen(false)}
            style={{ maxWidth: '200px', margin: 'auto' }}
            variant="juicyPrimary"
          >
            Close
          </CJRButton>
        </Box>
      </Box>
    </Dialog>
  );
};
