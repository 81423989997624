import { FunctionComponent, useRef, useState } from 'react';
import { Box, Button, Dialog, Text, Tooltip } from '@primer/react';
import { CircleSlashIcon } from '@primer/octicons-react';
import { useMessageSender } from '../../hooks/useMessageSender';
import { usePlayers } from '../../hooks/usePlayers';
import skipIcon from '../../images/skipicon.svg';

export const SkipTurnButton: FunctionComponent = () => {
  const returnFocusRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { turnPlayer, isPlayerTurn } = usePlayers();
  const { finishTurn } = useMessageSender();

  const whose = isPlayerTurn ? 'your' : `${turnPlayer?.nickname}'s`;
  return (
    <>
      <Tooltip aria-label="Skip turn" direction="n">
        <img
          alt="Cozy Juicy Real"
          onClick={() => setIsOpen(true)}
          src={skipIcon}
          style={{ marginTop: '5px', cursor: 'pointer' }}
        />
      </Tooltip>
      <Dialog
        aria-labelledby="label"
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        returnFocusRef={returnFocusRef}
        sx={{ height: 'initial !important' }}
      >
        <Dialog.Header>
          <CircleSlashIcon />
        </Dialog.Header>
        <Box p={3}>
          <Text id="label">Are you sure you want to skip {whose} turn?</Text>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button onClick={() => setIsOpen(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
                finishTurn();
              }}
              variant="danger"
            >
              Yes, skip turn
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
