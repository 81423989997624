/**
 * This code was generated by Builder.io.
 */
import React, { useState } from 'react'; // Import useState
import styled from 'styled-components';
import Header from '../../components/_pricingPageComponents/PricingMatrix/Header';
import PricingTables from '../../components/_pricingPageComponents/PricingMatrix/PricingTables';
import LogoCarousel from '../../components/_pricingPageComponents/PricingMatrix/LogoCarousel';
import ComparisonButton from '../../components/_pricingPageComponents/PricingMatrix/ComparisonButton';
import Addons from '../../components/_pricingPageComponents/PricingMatrix/Addons';
import Rating from '../../components/_pricingPageComponents/PricingMatrix/Rating';
import FAQs from '../../components/_pricingPageComponents/PricingMatrix/FAQs';
import { stopConfettiInner } from '../../components/confetti/confetti';

const PricingMatrixPage: React.FC = () => {
  stopConfettiInner();
  const [isCompareOpen, setIsCompareOpen] = useState(false);

  return (
    <>
      <PricingMatrixWrapper>
        <Header />

        {/* Pass the onCompareClick function to PricingTables */}
        <PricingTables onCompareClick={() => setIsCompareOpen(true)} />

        <LogoCarousel />

        {/* ComparisonButton is rendered here and controlled via isOpen */}
        <ComparisonWrapper id="compare-features-section">
          <ComparisonButton isOpen={isCompareOpen} setIsOpen={setIsCompareOpen} />
        </ComparisonWrapper>

        <Rating />
        <Addons />
        <FAQs />
      </PricingMatrixWrapper>
    </>
  );
};

const PricingMatrixWrapper = styled('main')`
  background-color: #fff;
  flex-direction: column;
  padding: 0 1px;
  position: relative;
`;

const ComparisonWrapper = styled('div')`
  margin-top: 40px;
  width: 100%;
  justify-content: center;
`;

export default PricingMatrixPage;
