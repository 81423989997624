import { StrictMode } from 'react';
import { render } from 'react-dom';
import { ThemeProvider, BaseStyles } from '@primer/react';
import './theme/index.css';
import { theme } from './theme/theme';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { AlertModal } from './basicComponents/alertModal/AlertModal';
import { Loading } from './basicComponents/loading/Loading';
import { HowToPlayOverlay } from './basicComponents/howToPlay/HowToPlayOverlay';
import { ContactForm } from './basicComponents/contactForm/ContactForm';

render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BaseStyles>
          <App />
          <AlertModal />
          <Loading />
          <HowToPlayOverlay />
          <ContactForm />
        </BaseStyles>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
