import type { DeckType } from '../../redux/models/colyseumSchemas/Deck';

type StepsConfig = DeckType[][];

const stepsConfig: StepsConfig = [
  // Start
  ['cozy'],

  ['cozy', 'juicy'],
  ['random'],
  ['cozy', 'juicy'],

  ['juicy'],
  ['cozy', 'real'],
  ['cozy', 'juicy', 'real'],
  ['cozy', 'real'],
  ['random'],

  ['cozy', 'juicy', 'real'],
  ['juicy', 'real', 'random'],
  ['random'],
  ['juicy', 'real'],
  ['cozy', 'juicy', 'real'],

  ['juicy', 'real'],
  ['juicy', 'real', 'random'],
  ['cozy', 'juicy', 'real'],

  ['lightning'],
];

export const isLightningStep = (step: number): boolean => {
  return step >= stepsConfig.length - 1;
};

export const getDecksForStep = (step: number): DeckType[] => {
  if (step >= stepsConfig.length - 1) {
    return stepsConfig[stepsConfig.length - 1];
  }
  return stepsConfig[step];
};
