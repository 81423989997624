import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './common';

const GET_SUBSCRIPTION_STATUS_API = '/payments/subscription-status';
const POST_CHECKOUT_PAYMENT_API = '/payments/checkout';
const POST_SUBSCRIPTION_API = '/payments/subscription';
const DELETE_SUBSCRIPTION_API = '/payments/subscription';
const DELETE_SUBSCRIPTION_IMMEDIATELY_API = '/payments/subscription-immediately';
const PUT_REACTIVATE_SUBSCRIPTION_API = '/payments/reactivate-subscription';

export type SubscriptionStatus = {
  isSubscriptionActive: boolean;
  validTo?: number;
  plan: string;
  isTemporaryAddOnSubscription: boolean;
  allowChildren: boolean;
  allowedAddOns: string[];
  endOfCurrentBillingPeriod: number;
  paymentCard: string;
  fromParent: boolean;
  playedThisMonth: number;
  maxNoOfChildren: number;
};

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Payment', 'Config'],
  endpoints: (builder) => ({
    getSubscriptionStatus: builder.query<SubscriptionStatus[], undefined>({
      query: () => GET_SUBSCRIPTION_STATUS_API,
      providesTags: ['Payment'],
    }),

    checkout: builder.mutation<{ url: string }, { plan: string }>({
      query: (input) => ({
        url: POST_CHECKOUT_PAYMENT_API + `?plan=${input.plan}`,
        method: 'POST',
      }),
      invalidatesTags: ['Payment', 'Config'],
    }),

    addAddOnSubscription: builder.mutation<any, { plan: string; cupomCode?: string }>({
      query: (input) => ({
        url:
          POST_SUBSCRIPTION_API +
          `/${input.plan}` +
          (input.cupomCode ? `?cupomCode=${input.cupomCode}` : ''),
        method: 'POST',
      }),
      invalidatesTags: ['Payment', 'Config'],
    }),

    deleteSubscription: builder.mutation<undefined, { plan: string }>({
      query: (input) => ({
        url: DELETE_SUBSCRIPTION_API + `?plan=${input.plan}`,
        method: 'DELETE',
      }),
    }),

    deleteSubscriptionNow: builder.mutation<undefined, { plan: string }>({
      query: (input) => ({
        url: DELETE_SUBSCRIPTION_IMMEDIATELY_API + `?plan=${input.plan}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Payment', 'Config'],
    }),

    reactivateSubscription: builder.mutation<undefined, { plan: string }>({
      query: (input) => ({
        url: PUT_REACTIVATE_SUBSCRIPTION_API + `?plan=${input.plan}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Payment', 'Config'],
    }),

    getPriceChange: builder.query<
      {
        priceDiffThisMonth?: string;
        newPrice?: string;
        price?: string;
        interval: string;
        startingBalance?: string;
      },
      { plan: string; newPlan?: string; newQuantity?: number; cupomCode?: string }
    >({
      query: (input) => ({
        url:
          '/payments/subscription/' +
          input.plan +
          '?' +
          (input.newPlan ? `newPlan=${input.newPlan}&` : '') +
          (input.newQuantity ? `newQuantity=${input.newQuantity}&` : '') +
          (input.cupomCode ? `cupomCode=${input.cupomCode}&` : ''),
      }),
    }),

    upgradeSubscription: builder.mutation<
      undefined,
      { plan: string; newPlan?: string; newQuantity?: number; cupomCode?: string }
    >({
      query: (input) => ({
        url:
          '/payments/subscription/' +
          input.plan +
          '?' +
          (input.newPlan ? `newPlan=${input.newPlan}&` : '') +
          (input.newQuantity ? `newQuantity=${input.newQuantity}&` : '') +
          (input.cupomCode ? `cupomCode=${input.cupomCode}&` : ''),
        method: 'PUT',
      }),
      invalidatesTags: ['Payment', 'Config'],
    }),
  }),
});

export const paymentApiReducer = paymentApi.reducer;

export const {
  useGetSubscriptionStatusQuery,
  useCheckoutMutation,
  useAddAddOnSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useDeleteSubscriptionNowMutation,
  useReactivateSubscriptionMutation,
  useUpgradeSubscriptionMutation,
  useGetPriceChangeQuery,
} = paymentApi;
