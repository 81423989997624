import styled from 'styled-components';

export const BoardMiddle = styled('div')`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  margin: auto;
  width: 65%;
  height: 56%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  left: 4px;
  top: 4px;
`;
