export const LOGIN_PATH = '/login';
export const REGISTRATION_PATH = '/registration';
export const RECOVER_PASSWORD_PATH = '/recover-password';
export const MY_GAMES_PATH = '/my-games';
export const CREATE_PATH = '/create';
export const PROFILE_PATH = '/profile';
export const ON_BOARDING_PATH = '/on-boarding';
export const BILLING_PATH = '/billing';
export const ENTER_GAME_PATH = '/game/:code';
export const ROOMS_PATH = '/rooms/:roomId';
export const LOBBY_PATH = '/rooms/:roomId/lobby';
export const GAME_PATH = '/rooms/:roomId/game';
export const GAME_FINISHED_PATH = '/rooms/:roomId/gameFinished';
export const BOARD_PATH = '/board-experiment';
export const CARD_PATH = '/card-experiment';
export const KICKSTARTER_PLAN = 'KICKSTARTER';
export const PLANS_PATH = '/plans';
export const ADD_ONS_PATH = '/plans/add-ons';
export const USER_MANAGEMENT_PATH = '/user-management';
export const MINI_GAME_PATH = '/check-in-questions/:code';
export const MINI_GAME_CREATE_PATH = '/create-check-in-questions';
