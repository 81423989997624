import styled from 'styled-components';
import svg from '../../images/board.svg';

export const BoardBackground = styled('div')`
  background-image: url(${svg});
  background-size: 100% 100%;
  box-shadow: 0px 0px 10px 0px #999999;
  border-radius: 20px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
`;
