import styled from 'styled-components';
import { getSparkName, getSparkPoints, SparkProps } from '../../utils/sparks';
import { OverlayBox } from '../overlayBox/OverlayBox';
import { SparkIcon } from '../sparkIcon/SparkIcon';

const SparkIconBox = styled('div')`
  background-color: white;
  border-radius: 50%;
  border: 3px solid #412683;
  padding: 2px;
  width: 4rem;
  height: 4rem;
  box-shadow: #41268320 0px 1px 3px 0px, #00000050 0px 1px 2px 0px;
`;

const SparkText = styled('div')`
  display: flex;
  flex-direction: row;
  margin-left: 2em;
  padding: 0.2em 0.2em 0.2em 3em;
  color: white;
  background-color: #ede9ff;
  border-radius: 0 2em 2em 0;
  font-weight: bold;
`;
const SparkText2 = styled('div')`
  padding: 0.7em 0em 0em 0em;
  display: flex;
  flex-direction: column;
`;
const SparkName = styled('div')`
  display: flex;
  align-items: center;
  color: #3b147a;
`;

const Count = styled('div')`
  margin-left: auto;
  width: 60px;
  height: 60px;
  color: #cd3444;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
`;

type SparkSelectorItemProps = SparkProps & { count: number };

export const SparkSelectorItem = ({ sparkType, count }: SparkSelectorItemProps) => (
  <OverlayBox sx={{ alignItems: 'center' }}>
    <SparkText>
      <SparkText2>
        <SparkName style={{ textTransform: 'uppercase' }}>{getSparkName(sparkType)}</SparkName>
        <SparkName>
          <div style={{ fontWeight: 400 }}>{'Worth ' + getSparkPoints(sparkType) + ' points'}</div>
        </SparkName>
      </SparkText2>
      <Count>x{count}</Count>
    </SparkText>
    <SparkIconBox>
      <SparkIcon sparkType={sparkType} />
    </SparkIconBox>
  </OverlayBox>
);
