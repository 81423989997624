import { Dialog } from '@primer/react';
import styled from 'styled-components';

const Text = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 18px;
`;

const ScrollContainer = styled('div')`
  height: 800px;
  overflow-y: scroll;
  padding: 30px;
`;

const Item = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 18px;
  padding-bottom: 10px;
  border-top: 1px solid #999;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const Title = styled('div')`
  color: #3b147a;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 1px;
`;

const Explanation = styled('div')`
  color: #3b147a;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 9px;
  padding-bottom: 8px;
`;

const BulletPoints = styled('ul')`
  padding-left: 30px;
  margin: 0;
`;

export const BombProofingDialog = ({ setIsOpen }: { readonly setIsOpen: Function }) => {
  return (
    <Dialog
      aria-labelledby="label"
      isOpen
      onDismiss={() => setIsOpen(false)}
      sx={{
        height: '80%',
        maxHeight: '628px',
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Bomb-proofing your event
      </div>
      <ScrollContainer>
        <Text>
          Cozy Juicy Real is easy to facilitate, but just in case, be ready to troubleshoot these
          scenarios.
        </Text>

        <Item key="1">
          <TextContainer>
            <Title>A player is struggling (with anything)</Title>
            <Explanation>
              If you’re hosting a virtual game and a player needs help, bring them back to the main
              room first. This allows the rest of the group to play while you help the player
              troubleshoot.
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="2">
          <TextContainer>
            <Title>Somebody can’t open the game link</Title>
            <Explanation>
              It’s rare, but occasionally someone has trouble opening their game board link. If this
              happens:
            </Explanation>
            <Explanation>
              <BulletPoints>
                <li>Ask them to refresh the page – this usually works.</li>
                <li>
                  Ask them to close the problematic page and then click on the game invite link
                  again.
                </li>
                <li>
                  Ask them to open an ‘incognito or ‘private’ window and paste the link there.
                </li>
                <li>
                  If the block is due to a company firewall or VPN, ask them to join the game board
                  with a personal device, eg. mobile.
                </li>
              </BulletPoints>
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="3">
          <TextContainer>
            <Title>Someone arrives late</Title>
            <Explanation>As a general rule…</Explanation>
            <Explanation>
              If someone arrives to the event more than 30 mins late, then teams playing may already
              be in the “Real” round, making it uncomfortable if a new person joins the experience.
            </Explanation>
            <Explanation>
              We highly recommend you let late-comers join the experience up to the 30-minute mark.
            </Explanation>
          </TextContainer>
        </Item>

        <Item key="4">
          <TextContainer>
            <Title>What if a team finishes before others?</Title>
            <Explanation>
              If you’re hosting an event for multiple teams, you’ll find that each team will finish
              playing at a different time.
            </Explanation>
            <Explanation>
              If some teams finish before others, you can give them some down time before the other
              teams wrap up, or send them a link to a different game edition so they can start a new
              game.
            </Explanation>
            <Explanation>
              How will you know how quickly teams progress around the game board? You can use the
              ‘Observer’ feature  - more on that in the <b>’How to Observe Game Rooms’</b> video.
            </Explanation>
          </TextContainer>
        </Item>
      </ScrollContainer>
    </Dialog>
  );
};
