import { FunctionComponent, useState } from 'react';
import { Box, Dialog } from '@primer/react';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';
import { useGetUserQuery } from '../../redux/apis/user.api';

interface DialogProps {
  readonly tryAgain: (value: boolean) => void;
  readonly setOpen: (value: boolean) => void;
  readonly open: boolean;
}

export const UnsuccessfulDialog: FunctionComponent<DialogProps> = ({ tryAgain, setOpen, open }) => {
  const getUserQuery = useGetUserQuery(undefined);

  return (
    <Dialog
      aria-labelledby="label"
      isOpen={open}
      onDismiss={() => setOpen(false)}
      sx={{ height: 'initial !important' }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Payment unsuccessful
      </div>
      <Box p={4}>
        <Box p={3}>
          Oops, looks like your payment didn't go through. Check your details and try again.
        </Box>
        <Box p={3}>If that doesn't work, try using a different payment method.</Box>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p={4}
          style={{
            gap: '15px',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <CJRButton
            onClick={() =>
              getUserQuery.data ? window.open(getUserQuery.data.stripeCustomerPortal) : null
            }
            variant="juicyTertiary"
          >
            Update
          </CJRButton>
          <CJRButton onClick={tryAgain} variant="juicyPrimary">
            Try again
          </CJRButton>
        </Box>
      </Box>
    </Dialog>
  );
};
