import styled from 'styled-components';
import { FunctionComponent, useEffect } from 'react';
import { Box, themeGet } from '@primer/react';
import { usePlayers } from '../../hooks/usePlayers';
import { Avatar } from '../../basicComponents/avatar/Avatar';
import { SkipTurnButton } from './SkipTurnButton';
import { OverlayBox } from '../../basicComponents/overlayBox/OverlayBox';
import { useAppDispatch } from '../../redux/hooks';
import { showModal } from '../../redux/slices/modal.slice';
import { useMessageSender } from '../../hooks/useMessageSender';

const Panel = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 1rem;
  border-radius: 100px;
  background-color: #ede9ff;
  padding-right: 12px;
`;

const TurnInfo = styled(Box)`
  position: relative;
  padding: 0px 26px;
  margin: auto;
  left: 28px;
  color: ${themeGet('colors.spark.dark')};
  font-weight: bold;
  text-align: center;
`;

const SkipMessage: FunctionComponent<{ nickname: string | undefined }> = ({ nickname }) => (
  <>
    <p>{`${nickname} is disconnected, you may skip the turn.`}</p>
    <SkipTurnButton />
  </>
);

export const TurnHeader: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { turnPlayer, isPlayerTurn } = usePlayers();
  const { finishTurn } = useMessageSender();

  const whosTurn = isPlayerTurn ? 'your' : `${turnPlayer?.nickname}'s`;

  useEffect(() => {
    if (!turnPlayer?.isReady) finishTurn();
  }, []);

  useEffect(() => {
    if (!turnPlayer?.isReady) {
      finishTurn();
    } else if (!turnPlayer?.isConnected && !isPlayerTurn)
      dispatch(
        showModal({
          type: 'danger',
          message: <SkipMessage nickname={turnPlayer?.nickname} />,
        })
      );
  }, [turnPlayer?.isConnected]);

  return (
    <Panel>
      <OverlayBox>
        <Box alignItems="center" display="flex" height={55}>
          <TurnInfo>It's {whosTurn} turn</TurnInfo>
        </Box>
        <Box display="flex">
          <Avatar.Border>
            <Avatar size={55} variant={turnPlayer?.avatar || 0} />
          </Avatar.Border>
        </Box>
      </OverlayBox>
      <SkipTurnButton />
    </Panel>
  );
};
