import styled from 'styled-components';

type ResponsiveContainerProps = {
  aspectRatio: string;
};
export const Responsive = styled('div')<ResponsiveContainerProps>`
  width: 100%;
  position: relative;
  padding: ${(props) => props.aspectRatio} 0 0;

  & > * {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;
