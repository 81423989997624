import { Box, Button, Dialog } from '@primer/react';
import { useAppDispatch } from '../../redux/hooks';
import { buttonStyle } from '../../theme/theme';
import { Bar } from '../bar/Bar';
import { stopConfettiInner } from '../../components/confetti/confetti';
import { Responsive } from '../../components/layout/Responsive';
import { useEffect, useRef } from 'react';
import Player from '@vimeo/player';

export const VideoOverlay = ({
  setOpen,
  title,
  videoUrl, //Youtube
  videoId, //Vmeo
}: {
  readonly title: string;
  readonly setOpen: Function;
  readonly videoUrl?: string;
  readonly videoId?: number;
}) => {
  stopConfettiInner();
  const dispatch = useAppDispatch();
  const playerRef = useRef(null);

  useEffect(() => {
    if (videoId && playerRef.current) {
      const player = new Player(playerRef.current, {
        id: videoId,
        width: 540,
      });

      return () => {
        player.unload();
      };
    }
  }, [videoId]);

  return (
    <Dialog
      aria-labelledby="label"
      isOpen
      onDismiss={() => setOpen(false)}
      sx={{
        width: '100%',
        maxWidth: '540px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        {title}
      </div>

      <Responsive aspectRatio="56.8%">
        {videoUrl ? (
          // eslint-disable-next-line react/iframe-missing-sandbox
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height="315"
            sandbox="allow-scripts allow-same-origin allow-presentation"
            src={videoUrl}
            title="YouTube video player"
            width="560"
          />
        ) : (
          <div ref={playerRef} />
        )}
      </Responsive>

      <Bar />

      <Box display="flex" justifyContent="center" mt="auto" p={5}>
        <Button
          onClick={() => setOpen(false)}
          size="medium"
          sx={{ mt: 3, width: '50%', minWidth: '100px', ...buttonStyle, marginLeft: '7px' }}
          type="button"
          variant="primary"
        >
          Ready
        </Button>
      </Box>
    </Dialog>
  );
};
