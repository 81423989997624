import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import { useChangePasswordMutation } from '../../redux/apis/user.api';

import { Layout } from '../../components/layout/Layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validatePassword } from '../../utils/validators';
import { showModal } from '../../redux/slices/modal.slice';
import { useDispatch } from 'react-redux';
import { buttonStyle, textInputStyle } from '../../theme/theme';

export const ResetPasswordPage: FunctionComponent = () => {
  const [params] = useSearchParams();
  const [changePassword, changed] = useChangePasswordMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [password2, setPassword2] = useState<string | undefined>(undefined);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>();

  const onPasswordChange = (value: string) => {
    setPassword(value);
    setPasswordErrorMessage(validatePassword(value));
  };
  const onPassword2Change = (value: string) => {
    setPassword2(value);
    setPasswordErrorMessage(password == value ? validatePassword(value) : "Passwords don't match");
  };

  const isValid = () => {
    let passwordValidationError: string | undefined;
    if (password) passwordValidationError = validatePassword(password);
    if (!password) passwordValidationError = 'Your password cannot be empty';
    if (password !== password2) passwordValidationError = "Passwords don't match";
    setPasswordErrorMessage(passwordValidationError);

    return !passwordValidationError;
  };

  const onSubmit = () => {
    if (!isValid()) return;
    changePassword({
      token: params.get('token') ?? '',
      newPassword: password!,
    });
    setPassword('');
    setPassword2('');
  };

  useEffect(() => {
    if (changed.isSuccess) {
      dispatch(showModal({ message: 'Password changed successfully', type: 'success' }));
      navigate('/');
    }
  }, [changed]);

  return (
    <Layout>
      <Layout.Panel>
        <FormControl sx={{ mb: 3 }}>
          <FormControl.Label>New password</FormControl.Label>
          <TextInput
            aria-describedby="password-validation"
            aria-invalid={!!passwordErrorMessage}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onPasswordChange(event.target.value)
            }
            sx={{ width: '100%', ...textInputStyle }}
            type="password"
            validationStatus={passwordErrorMessage ? 'error' : undefined}
            value={password}
          />
        </FormControl>
        <FormControl sx={{ mb: 3 }}>
          <FormControl.Label>Password confirmation</FormControl.Label>
          <TextInput
            aria-describedby="password2-validation"
            aria-invalid={!!passwordErrorMessage}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onPassword2Change(event.target.value)
            }
            sx={{ width: '100%', ...textInputStyle }}
            type="password"
            validationStatus={passwordErrorMessage ? 'error' : undefined}
            value={password2}
          />
          {passwordErrorMessage ? (
            <FormControl.Validation id="password2-validation" variant="error">
              {passwordErrorMessage}
            </FormControl.Validation>
          ) : null}
        </FormControl>
        <Box maxWidth="500px" width="100%">
          <Button
            onClick={onSubmit}
            size="large"
            sx={{ mt: 3, width: '100%', ...buttonStyle }}
            type="button"
            variant="primary"
          >
            Reset
          </Button>
        </Box>
      </Layout.Panel>
    </Layout>
  );
};
