import { FunctionComponent } from 'react';
import { Box, FormControl, Checkbox, TextInput, ButtonGroup, IconButton } from '@primer/react';
import { DashIcon, PlusIcon } from '@primer/octicons-react';
import { Avatar } from '../../basicComponents/avatar/Avatar';
import { textInputStyle } from '../../theme/theme';

export type Settings = { variant: number; step: number; show: boolean };

export type AvatarSettingsProps = {
  settings: Settings;
  onChange: (settings: Settings) => void;
};
export const AvatarSettings: FunctionComponent<AvatarSettingsProps> = ({ settings, onChange }) => {
  const { variant, step, show } = settings;
  return (
    <Box alignItems="center" display="flex" sx={{ '& > *': { margin: 1 } }}>
      <Avatar.Border>
        <Avatar size={40} variant={variant} />
      </Avatar.Border>

      <ButtonGroup>
        <IconButton
          aria-label="Decrease"
          icon={DashIcon}
          onClick={() => onChange({ ...settings, step: step > 0 ? step - 1 : step })}
          type="button"
        />
        <TextInput readOnly sx={{ width: '3em', ...textInputStyle }} value={step + 1} />
        <IconButton
          aria-label="Increase"
          icon={PlusIcon}
          onClick={() => onChange({ ...settings, step: step < 17 ? step + 1 : step })}
          type="button"
        />
      </ButtonGroup>
      <FormControl>
        <Checkbox
          checked={show}
          onChange={(e) => onChange({ ...settings, show: e.target.checked })}
        />
        <FormControl.Label>Show</FormControl.Label>
      </FormControl>
    </Box>
  );
};
