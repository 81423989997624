import { FunctionComponent } from 'react';
import styled from 'styled-components';

const getStyle = (step: number) => {
  switch (step) {
    case 0:
      return { top: '5.5%', left: '25%' };
    case 1:
      return { top: '5.5%', left: '46.7%' };
    case 2:
      return { top: '5.5%', left: '60%' };
    case 3:
      return { top: '5.5%', left: '74%' };
    case 4:
      return { top: '19%', left: '87.5%' };
    case 5:
      return { top: '33%', left: '87.5%' };
    case 6:
      return { top: '46.5%', left: '87.5%' };
    case 7:
      return { top: '60%', left: '87.5%' };
    case 8:
      return { top: '74%', left: '87.5%' };
    case 9:
      return { top: '87.5%', left: '74%' };
    case 10:
      return { top: '87.5%', left: '60%' };
    case 11:
      return { top: '87.5%', left: '46.5%' };
    case 12:
      return { top: '87.5%', left: '33%' };
    case 13:
      return { top: '87.5%', left: '19.33%' };
    case 14:
      return { top: '74%', left: '5.5%' };
    case 15:
      return { top: '60%', left: '5.5%' };
    case 16:
      return { top: '46.33%', left: '5.5%' };
    case 17:
      return { top: '26%', left: '5.5%' };
    default:
      return { top: '26%', left: '5.5%' };
  }
};

const GridCell = styled('div')`
  display: flex;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
`;

const StepPositioner = styled('div')`
  position: absolute;
  transition: all 0.2s ease-in-out;
`;

const RelativeContainerForCount = styled('div')`
  position: relative;
`;

const Count = styled('div')`
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 2em;
  height: 2em;
  color: red;
  background-color: white;
  border: 2px solid red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;
`;

export type StepProps = {
  step: number;
  count?: number;
  isTurn?: boolean;
  children?: React.ReactNode;
};

export const BoardStep: FunctionComponent<StepProps> = ({ step, children, count = 0, isTurn }) => {
  return (
    <GridCell>
      <StepPositioner style={{ ...getStyle(step), zIndex: isTurn ? 1 : 'initial' }}>
        <RelativeContainerForCount>
          {children}
          {count > 1 && !isTurn && <Count>{count}</Count>}
        </RelativeContainerForCount>
      </StepPositioner>
    </GridCell>
  );
};
