import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingData, MenuData, ModalData } from '../models/modal.model';

const modalSlice = createSlice({
  name: 'modalSlice',
  initialState: {
    showModal: false,
    showMenu: false,
    loading: false,
    manualLoading: true,
    contactForm: false,
  } as ModalData & MenuData & LoadingData,
  reducers: {
    showModal: (state, action: PayloadAction<Omit<ModalData, 'showModal'>>) => {
      state.showModal = true;
      state.message = action.payload?.message;
      state.title = action.payload?.title;
      state.type = action.payload?.type;
    },
    closeModal: (state) => {
      state.showModal = false;
    },
    showMenu: (state) => {
      state.showMenu = true;
    },
    closeMenu: (state) => {
      state.showMenu = false;
    },
    setManualLoading: (state, action: PayloadAction<boolean>) => {
      state.manualLoading = action.payload;
    },
    showLoading: (state) => {
      state.loading = true;
    },
    closeLoading: (state) => {
      state.loading = false;
      state.manualLoading = false;
    },
    showContactForm: (state) => {
      state.contactForm = true;
    },
    closeContactForm: (state) => {
      state.contactForm = false;
    },
  },
});
export const {
  showModal,
  closeModal,
  showMenu,
  closeMenu,
  showLoading,
  closeLoading,
  setManualLoading,
  showContactForm,
  closeContactForm,
} = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
