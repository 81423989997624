import { FunctionComponent, useRef, useState } from 'react';
import { ActionList, ActionMenu } from '@primer/react';
import { SparkProps, getSparkDescription } from '../../utils/sparks';
import { SparkImg } from '../sparkImg/SparkImg';
import { SparkSelectorItem } from './SparkSelectorItem';
import { TriangleDownIcon } from '@primer/octicons-react';
import styled from 'styled-components';

const SparkDropdown = styled('div')`
display: flex;
width: 100%;
border-radius: 10px;
border: 2px solid #A792FF;
border-color: #A792FF;
color: #3B147A;
font-weight: 500,
justify-content: space-between;
align-content: center;
position: relative;
`;

const SparkContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 25px 16px 20px 16px;
`;

const SparkDescription = styled('div')`
  color: #3b147a;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
`;

type SparkSelectorProps = {
  sparks: SparkProps[];
  value: string;
  onChange: (value: string) => void;
};

export const SparkSelector: FunctionComponent<SparkSelectorProps> = ({
  sparks,
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const selectedSpark = sparks.find((spark) => spark.id === value);

  const sparksGroupedByType = sparks.reduce((acc, spark) => {
    const existingSpark = acc.find((a) => a.sparkType === spark.sparkType);
    if (!existingSpark) {
      return [...acc, { ...spark, count: 1 }];
    }
    existingSpark.count += 1;
    return acc;
  }, [] as (SparkProps & { count: number })[]);

  return (
    <>
      <SparkDropdown aria-expanded={open} onClick={() => setOpen(!open)} ref={anchorRef}>
        <SparkContainer>
          {selectedSpark ? (
            <>
              <SparkImg sparkType={selectedSpark.sparkType} />
              <SparkDescription>{getSparkDescription(selectedSpark.sparkType)}</SparkDescription>
            </>
          ) : (
            'Select a spark card'
          )}
        </SparkContainer>
        <div style={{ padding: '12px', position: 'absolute', right: '15px', top: '7px' }}>
          <TriangleDownIcon fill="#A792FF" size={30} />
        </div>
      </SparkDropdown>
      <ActionMenu anchorRef={anchorRef} onOpenChange={setOpen} open={open}>
        <ActionMenu.Overlay>
          <ActionList sx={{ width: 400, maxWidth: '120vw' }}>
            {sparksGroupedByType.map(({ count, ...spark }) => (
              <ActionList.Item key={spark.id} onSelect={() => onChange(spark.id)}>
                <SparkSelectorItem {...spark} count={count} />
              </ActionList.Item>
            ))}
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
    </>
  );
};
