import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteColyseusRoomMutation, useGetRoomStateQuery } from '../../redux/apis/colyseus';
import { useDeleteRoomMutation } from '../../redux/apis/rooms';
import { ENTER_GAME_PATH, LOBBY_PATH } from '../../utils/constants';
import { Room } from '../../redux/models/room.model';
import { useGetConfigQuery } from '../../redux/apis/config';
import purpleStar from '../../images/purpleStar.svg';
import { ReactComponent as LinkIcon } from '../../images/linkicon.svg';
import deleteIcon from '../../images/deleteIcon.svg';
import styled from 'styled-components';
import { useClipboard } from '../../hooks/useClipboard';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { showModal } from '../../redux/slices/modal.slice';
import { useAppDispatch } from '../../redux/hooks';
import { setMenu } from '../../redux/slices/menu.slice';
import { CircleSlashIcon } from '@primer/octicons-react';
import { Dialog, Box, Button, Text } from '@primer/react';
import { useGetGameCodeQuery } from '../../redux/apis/auth.api';

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 55px;
  width: 100%;
  border-top: 1px solid #999;
  cursor: pointer;
`;

const ColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const Icon = styled('img')`
  width: 40px;
  color: #ff7300;
  padding-right: 10px;
`;

const LinkIconStyled = styled(LinkIcon)`
  padding-right: 10px;
`;

const RoomTitle = styled('div')`
  color: #3b147a;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  height: 21px;
`;

const RoomSubTitle = styled('div')`
  color: #a792ff;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;
export const ListRoomItem: FunctionComponent<{
  readonly room: Room;
  readonly isEvent?: boolean;
}> = ({ room, isEvent = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { copy } = useClipboard();
  const { href } = useWindowLocation();
  const getRoomState = useGetRoomStateQuery(room.roomId, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    pollingInterval: 20000,
  });
  const [deleteRoom, deleteRoomMutation] = useDeleteRoomMutation();
  const [deleteColyseusRoom] = useDeleteColyseusRoomMutation();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const getConfig = useGetConfigQuery(undefined);

  useEffect(() => {
    if (getRoomState.isError) deleteRoom({ id: room.id });
  }, [getRoomState.isError]);

  useEffect(() => {
    if (deleteRoomMutation.isSuccess) deleteColyseusRoom(room.roomId);
  }, [deleteRoomMutation.isSuccess]);

  const pattern = new RegExp('\\sroom$', 'i');

  const [fetchCode, setFetchCode] = useState(false);
  const getGameCode = useGetGameCodeQuery({ roomId: room.roomId }, { skip: !fetchCode });

  useEffect(() => {
    if (getGameCode.isSuccess && fetchCode) {
      copy(window.location.origin + ENTER_GAME_PATH.replace(':code', getGameCode.data!.code));
      dispatch(showModal({ message: 'Link copied', type: 'success' }));
      setFetchCode(false);
    }
  }, [getGameCode.isSuccess, fetchCode]);

  return (
    <>
      {getRoomState.isSuccess ? (
        <Container key={room.roomId}>
          <Icon src={purpleStar} />
          <ColumnContainer
            onClick={async () => {
              navigate(LOBBY_PATH.replace(':roomId', room.roomId));
              dispatch(setMenu(undefined));
            }}
          >
            <RoomTitle>{`${room.name.trim().replace(pattern, '')}${
              isEvent ? '' : ' Room'
            }`}</RoomTitle>
            <RoomSubTitle>
              {getRoomState.isSuccess && getConfig.isSuccess
                ? `${
                    Object.values(getRoomState.data.state.players).filter((p) => p.isReady).length
                  }/${getConfig.data.MaxNoOfPlayersPerGame} players`
                : ''}
            </RoomSubTitle>
          </ColumnContainer>
          <LinkIconStyled fill="#3b147a" onClick={() => setFetchCode(true)} width="62px" />
          <Icon onClick={() => setIsDeleteOpen(true)} src={deleteIcon} style={{ width: '30px' }} />
        </Container>
      ) : null}
      <Dialog
        aria-labelledby="label"
        isOpen={isDeleteOpen}
        onDismiss={() => setIsDeleteOpen(false)}
        sx={{ height: 'initial !important' }}
      >
        <Dialog.Header>
          <CircleSlashIcon />
        </Dialog.Header>
        <Box p={3}>
          <Text>Are you sure you want to delete {room.name} game room?</Text>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button onClick={() => setIsDeleteOpen(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsDeleteOpen(false);
                deleteRoom({ id: room.id });
              }}
              variant="primary"
            >
              Yes, delete room
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
