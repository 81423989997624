import {
  ActionList,
  ActionMenu,
  Dialog,
  FormControl,
  Text,
  TextInput,
  Textarea,
} from '@primer/react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { CJRButton } from '../cjrButton/cjrButton';
import { colors, textInputStyle } from '../../theme/theme';
import { validateNonEmptyString } from '../../utils/validators';
import { useSendContactEmailMutation } from '../../redux/apis/user.api';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { closeContactForm, showModal } from '../../redux/slices/modal.slice';
import { isMobile } from 'react-device-detect';
import bowser from 'bowser';
import { TriangleDownIcon } from '@primer/octicons-react';

export const ContactForm = () => {
  const dispatch = useAppDispatch();
  const [subject, setSubject] = useState('');
  const [subjectErrorMessage, setSubjectErrorMessage] = useState<string | undefined>();
  const [content, setContent] = useState('');
  const [openType, setOpenType] = useState(false);
  const [type, setType] = useState('Report a problem');
  const [contentErrorMessage, setContentErrorMessage] = useState<string | undefined>();
  const [sendEmail, emailSent] = useSendContactEmailMutation();
  const state = useAppSelector((state) => state);
  const formState = useAppSelector((state) => state.modal.contactForm);
  const anchorRef = useRef(null);

  const handleSubjectChange = (value: string) => {
    setSubject(value);
    setSubjectErrorMessage(validateNonEmptyString(value, 'subject', 130));
  };

  const handleContentChange = (value: string) => {
    setContent(value);
    setContentErrorMessage(validateNonEmptyString(value, 'content', 1000));
  };

  const isValid = () => {
    const subjectError = validateNonEmptyString(subject, 'subject', 130);
    setSubjectErrorMessage(subjectError);
    const contentError = validateNonEmptyString(content, 'content', 1000);
    setContentErrorMessage(contentError);

    return !subjectError && !contentError;
  };

  const handleSendEmail = () => {
    if (!isValid()) return;

    sendEmail({
      subject,
      content: `<br>
      Type: ${type} <br>
      <br>
      Message: <br>
      <br>
      ${content} <br>
      <br>
      -------------- <br>
      Page: <br>
      ${window.location.href} <br>
      <br>
      -------------- <br>
      User-agent: <br>
      ${JSON.stringify((bowser.getParser(window.navigator.userAgent) as any).parsedResult)} <br>
      <br>
      -------------- <br>
      is Mobile? <br>
      ${isMobile} <br>
      Screen Resolution: <br>
      ${window.screen.width} x ${window.screen.height} <br>
      Window size: <br>
      ${window.innerWidth} x ${window.innerHeight} <br>
      <br>
      -------------- <br>
      Game State: <br>
      ${JSON.stringify(state.game)} <br>
      <br>
      -------------- <br>
      Player State: <br>
      ${JSON.stringify(state.player)} <br>
      <br>
      -------------- <br>
      User API: <br>
      ${JSON.stringify(state.userApi)} <br>
      <br>
      `,
    });
  };

  useEffect(() => {
    if (emailSent.isSuccess) {
      dispatch(closeContactForm());
      dispatch(showModal({ type: 'success', message: 'Message sent' }));
      setContent('');
      setSubject('');
    }
  }, [emailSent]);

  return (
    <Dialog
      aria-label="Contact Form"
      isOpen={formState}
      onDismiss={() => dispatch(closeContactForm())}
      sx={{ maxHeight: '90%', height: '680px' }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        What's up?
      </div>
      <div
        style={{
          position: 'relative',
          height: '85%',
          width: '100%',
          maxWidth: '700px',
          padding: '40px 40px 20px 40px',
          overflowY: 'scroll',
        }}
      >
        <FormControl sx={{ mb: 3 }}>
          <FormControl.Label>What kind of feedback are you sharing?*</FormControl.Label>
          <div
            aria-expanded={openType}
            onClick={() => setOpenType(!openType)}
            ref={anchorRef}
            style={{
              display: 'flex',
              width: '100%',
              ...textInputStyle,
              borderColor: '#A792FF',
              color: '#3B147A',
              fontWeight: 500,
              paddingTop: '6px',
              paddingBottom: '11px',
              paddingRight: '16px',
              paddingLeft: '16px',
              justifyContent: 'space-between',
              alignContent: 'center',
            }}
          >
            <>
              {type}
              <div>
                <TriangleDownIcon fill="#A792FF" size={30} />
              </div>
            </>
          </div>
          <ActionMenu anchorRef={anchorRef} onOpenChange={setOpenType} open={openType}>
            <ActionMenu.Overlay align="start" width="medium">
              <ActionList>
                <ActionList.Item key="1" onSelect={() => setType('Report a problem')}>
                  Report a problem
                  <ActionList.TrailingVisual>🐞</ActionList.TrailingVisual>
                </ActionList.Item>
                <ActionList.Item key="2" onSelect={() => setType('Feature request')}>
                  Feature request
                  <ActionList.TrailingVisual>🤞</ActionList.TrailingVisual>
                </ActionList.Item>
                <ActionList.Item key="3" onSelect={() => setType('General feedback')}>
                  General feedback
                  <ActionList.TrailingVisual>💭</ActionList.TrailingVisual>
                </ActionList.Item>
                <ActionList.Item key="4" onSelect={() => setType('Something else')}>
                  Something else
                  <ActionList.TrailingVisual>😯</ActionList.TrailingVisual>
                </ActionList.Item>
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>
        </FormControl>
        <FormControl sx={{ mb: 3 }}>
          <FormControl.Label>Subject*</FormControl.Label>
          <TextInput
            aria-describedby="lastName-validation"
            aria-invalid={!!subjectErrorMessage}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleSubjectChange(event.target.value)
            }
            sx={{ width: '100%', ...textInputStyle }}
            validationStatus={subjectErrorMessage ? 'error' : undefined}
            value={subject}
          />
          {subjectErrorMessage ? (
            <FormControl.Validation id="lastName-validation" variant="error">
              {subjectErrorMessage}
            </FormControl.Validation>
          ) : null}
        </FormControl>
        <FormControl sx={{ mb: 3 }}>
          <FormControl.Label>Explain in more detail*</FormControl.Label>
          <Textarea
            aria-describedby="lastName-validation"
            aria-invalid={!!contentErrorMessage}
            onChange={(event: ChangeEvent<any>) => handleContentChange(event.target.value)}
            sx={{ width: '100%', ...textInputStyle, height: '140px' }}
            validationStatus={contentErrorMessage ? 'error' : undefined}
            value={content}
          />
          {contentErrorMessage ? (
            <FormControl.Validation id="lastName-validation" variant="error">
              {contentErrorMessage}
            </FormControl.Validation>
          ) : null}
        </FormControl>

        <Text
          style={{ marginBottom: '5px' }}
          sx={{ color: colors.spark.dark, fontSize: '16px', mb: 2 }}
        >
          A quick note – in order to fix problems, some diagnostic details will be sent to us,
          including the page you’re on, the browser you’re using, and your user info. 👍
        </Text>

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px', gap: '10px' }}>
          <CJRButton onClick={() => dispatch(closeContactForm())} variant="realTertiary">
            Cancel
          </CJRButton>
          <CJRButton onClick={handleSendEmail}>Submit</CJRButton>
        </div>
      </div>
    </Dialog>
  );
};
