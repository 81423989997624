import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type MenuBarItems = 'rules' | 'howToPlay' | 'myGames' | 'onBoarding';

const menuSlice = createSlice({
  name: 'menuSlice',
  initialState: { selected: undefined } as { selected: MenuBarItems | undefined },
  reducers: {
    setMenu: (state, action: PayloadAction<MenuBarItems | undefined>) => {
      state.selected = action.payload;
    },
  },
});
export const { setMenu } = menuSlice.actions;

export const getMenu = (state: RootState): MenuBarItems | undefined => state.menu.selected;

export const menuReducer = menuSlice.reducer;
