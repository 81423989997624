import { FunctionComponent, useEffect, useState } from 'react';
import {
  useDeleteSubscriptionMutation,
  useGetSubscriptionStatusQuery,
  useReactivateSubscriptionMutation,
  useUpgradeSubscriptionMutation,
} from '../../redux/apis/payment';
import { showModal } from '../../redux/slices/modal.slice';
import { useAppDispatch } from '../../redux/hooks';
import { useListConfigsQuery, usePlanNamesQuery } from '../../redux/apis/config';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';
import { Dialog, Box } from '@primer/react';

interface DowngradeConfirmDialogProps {
  readonly originalPlan: string;
  readonly changePlan: string;
  readonly confirmChange: boolean;
  readonly setConfirmChange: (value: boolean) => void;
}

export const ConfirmDowngradeDialog: FunctionComponent<DowngradeConfirmDialogProps> = ({
  originalPlan,
  changePlan,
  confirmChange,
  setConfirmChange,
}) => {
  const dispatch = useAppDispatch();

  const sub = useGetSubscriptionStatusQuery(undefined);
  const configs = useListConfigsQuery(undefined);
  const [deleteSubscription, deletedSubscription] = useDeleteSubscriptionMutation();
  const [upgrade, upgradedPlan] = useUpgradeSubscriptionMutation();
  const [reactivateSubscription, reactivatedSubscription] = useReactivateSubscriptionMutation();
  const [allDone, setAllDone] = useState(false);
  const planNames = usePlanNamesQuery(undefined);

  useEffect(() => {
    setAllDone(false);
  }, []);

  useEffect(() => {
    if (upgradedPlan.isSuccess) {
      setAllDone(true);
      sub.refetch();
    }
  }, [upgradedPlan]);

  useEffect(() => {
    if (deletedSubscription.isSuccess) {
      setAllDone(true);
      sub.refetch();
    }
  }, [deletedSubscription]);

  useEffect(() => {
    if (reactivatedSubscription.isSuccess) {
      dispatch(showModal({ type: 'success', message: 'Subscription reactivated!' }));
    }
  }, [reactivatedSubscription]);

  if (!sub.data || !configs.data) return <></>;

  return !allDone ? (
    <Dialog
      aria-labelledby="label"
      isOpen={confirmChange}
      onDismiss={() => setConfirmChange(false)}
      sx={{
        height: 'initial !important',
        zIndex: 120,
      }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Are you sure?
      </div>
      <Box p={4}>
        <>
          <Box p={3}>Are you sure you want to change your plan?</Box>
          {parseInt(configs.data[changePlan]['MaxNoOfPlayersPerGame']) <
          parseInt(configs.data[originalPlan]['MaxNoOfPlayersPerGame']) ? (
            <Box p={3}>
              If you decide to downgrade to the <b>{planNames.data?.[changePlan]}</b> plan, you’ll
              lose the flexibility of hosting games of more{' '}
              {configs.data ? `than ${configs.data[changePlan]['MaxNoOfPlayersPerGame']} ` : ''}
              players and will only have access to limited editions.
            </Box>
          ) : null}
          <Box p={3}>
            Even if you choose to downgrade today,{' '}
            <b>
              your current plan access remains active until{' '}
              {sub.data && originalPlan
                ? `${new Date(
                    (sub.data?.find((p) => p.plan == originalPlan)?.endOfCurrentBillingPeriod ??
                      0) * 1000
                  ).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}`
                : 'the end of the current billing period'}
              .
            </b>
          </Box>
        </>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p={4}
          style={{
            gap: '15px',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <CJRButton onClick={() => setConfirmChange(false)} variant="juicyTertiary">
            Cancel
          </CJRButton>
          <CJRButton
            onClick={() => {
              if (changePlan == 'FREE') {
                deleteSubscription({ plan: originalPlan! });
              } else if (changePlan) {
                upgrade({ plan: originalPlan!, newPlan: changePlan, newQuantity: 1 }); //Downgrade. MANUAL: since Company plan is currently the only one the has more than 1 seat, any downgrade set the no of licenses back to 1
              }
            }}
            variant="juicyPrimary"
          >
            Confirm
          </CJRButton>
        </Box>
      </Box>
    </Dialog>
  ) : (
    <Dialog
      aria-labelledby="label"
      isOpen={confirmChange}
      onDismiss={() => setConfirmChange(false)}
      sx={{ height: 'initial !important' }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        All done!
      </div>
      <Box p={4}>
        <Box p={3}>
          Okey dokes, your subscription has been scheduled to downgrade to the{' '}
          <b>
            {planNames.data?.[changePlan]} plan on{' '}
            {sub.data && changePlan
              ? `${new Date(
                  (sub.data?.find((p) => p.plan == changePlan)?.endOfCurrentBillingPeriod ?? 0) *
                    1000
                ).toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}`
              : 'the end of the current billing period'}
            .
          </b>
        </Box>
        <Box p={3}>You’ll receive an email shortly confirming the update.</Box>
        <Box p={3}>
          If you downgraded by mistake, or you’ve changed your mind, simply click the{' '}
          <b>Reactivate</b> button below.
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p={4}
          style={{
            gap: '15px',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <CJRButton
            onClick={() => {
              reactivateSubscription({ plan: originalPlan });
              setConfirmChange(false);
            }}
            variant="juicyTertiary"
          >
            Reactivate
          </CJRButton>
          <CJRButton onClick={() => setConfirmChange(false)} variant="juicyPrimary">
            Close
          </CJRButton>
        </Box>
      </Box>
    </Dialog>
  );
};
