import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/hooks';
import { FunctionComponent } from 'react';
import { Flash, StyledOcticon } from '@primer/react';
import { AlertIcon, CheckIcon } from '@primer/octicons-react';
import { style } from './AlertModal.style';
import { closeModal } from '../../redux/slices/modal.slice';

export const AlertModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const modal = useAppSelector((state) => state.modal);

  if (!modal.showModal) return <></>;

  if (modal.showModal) {
    setTimeout(() => {
      dispatch(closeModal());
    }, 5 * 1000);
  }

  return (
    <div data-testid="alert-modal" style={style.container1}>
      <Flash style={style.container} sx={{ mt: 3 }} variant={modal.type}>
        <StyledOcticon icon={modal.type == 'danger' ? AlertIcon : CheckIcon} />
        {modal.message}
      </Flash>
    </div>
  );
};
