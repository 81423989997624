import { FunctionComponent, useEffect } from 'react';
import { Button } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import { useNavigate } from 'react-router-dom';
import { useCheckoutMutation, useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { useAppSelector } from '../../redux/hooks';
import styled from 'styled-components';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { buttonStyle, colors } from '../../theme/theme';
import { REGISTRATION_PATH } from '../../utils/constants';
import { stopConfettiInner } from '../../components/confetti/confetti';
import speech from '../../images/speech.svg';
import { selectAuth } from '../../redux/slices/auth.slice';

const MOBILE_WIDTH = 950;

const MainTitle = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 30px;
`;

const Container = styled('div')<{ columnDisplay: boolean; transparentBg?: boolean }>`
  display: flex;
  flex-direction: ${({ columnDisplay: isMobile }) => (isMobile ? 'column' : 'row')};
  width: 100%;
  justify-content: center;
  max-width: ${({ columnDisplay: isMobile }) => (isMobile ? '420px' : '1300px')};
  border-radius: 30px;
  background: ${({ transparentBg }) => (transparentBg ? 'none' : '#fff')};
  box-shadow: ${({ transparentBg }) =>
    transparentBg ? 'none' : '0px 0px 20px 0px rgba(0, 0, 0, 0.25)'};
`;

const AddOnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: auto;
  max-width: 420px;
`;

const RowBox = styled('div')`
  display: flex;
  flex-direction: row;
`;

const Title = styled('div')`
  color: #896dff;
  text-align: left;
  font-size: 24px;
  font-weight: 700;
`;

const SpeechIcon = styled('img')`
  padding-top: 30px;
  height: 70px;
  width: 60px;
`;

const Description = styled('div')`
  color: #440d9b;
  text-align: justify;
  font-size: 15px;
  font-weight: 400;
  min-height: 110px;
  padding-top: 20px;
`;

const Price = styled('div')`
  color: #440d9b;
  text-align: left;
  font-size: 30px;
  font-weight: 700;
  line-height: 90px;
`;

const PriceComplement = styled('div')`
  color: #440d9b;
  text-align: justify;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  height: 90px;
  padding-top: 40px;
  padding-left: 10px;
`;

const WhatsIncluded = styled('div')`
  color: #440d9b;
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  padding-top: 40px;
`;

const ItemText = styled('div')`
  color: #440d9b;
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
  text-align: left;
`;

const Item = ({ children }: any) => {
  return (
    <RowBox>
      <ItemText style={{ color: '#896DFF', paddingRight: '6px' }}>
        <b>✓</b>
      </ItemText>
      <ItemText>{children}</ItemText>
    </RowBox>
  );
};

export const AddOnsPage: FunctionComponent = () => {
  stopConfettiInner();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { setHref } = useWindowLocation();

  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);

  const sub = useGetSubscriptionStatusQuery(undefined);
  const [checkout, session] = useCheckoutMutation();

  useEffect(() => {
    if (session.isSuccess) setHref(session.data.url);
  }, [session]);

  return (
    <Layout bgWatermark>
      <MainTitle style={{ color: '#440D9B' }}>Up your Game!</MainTitle>
      {sub.data && sub.data.find((s) => s.plan == 'FREE' || s.plan == 'BASIC') ? (
        <Container columnDisplay={width < MOBILE_WIDTH} style={{ gap: '30px' }} transparentBg>
          <AddOnContainer>
            <Title style={{ fontSize: '20px', color: '#896dff', margin: '30px 30px 25px 30px' }}>
              One-Time Games
            </Title>
            <div style={{ borderTop: '1px solid  #896dff', padding: '30px' }}>
              <RowBox>
                <Price style={{ fontSize: '38px', lineHeight: '40px', color: '#896dff' }}>
                  $40
                </Price>
                <PriceComplement style={{ height: '40px', paddingTop: '12px', color: '#896dff' }}>
                  /game room
                </PriceComplement>
              </RowBox>
              <Description style={{ paddingTop: '8px', minHeight: 'unset' }}>
                Buy game rooms for up to 6 players. Choose any edition. Valid 3 months.
              </Description>
              <WhatsIncluded style={{ paddingTop: '14px' }}>Add to your plan</WhatsIncluded>
              <Item>
                Up to <b>8 players</b> in a game room
              </Item>
              <Item>
                Choose from <b>all available editions</b>
              </Item>
              <Item>
                Purchase this add-on multiple times <b>for events over 6 players</b>
              </Item>
              <Button
                disabled
                onClick={() => {
                  if (isLoggedIn && !isGuest) {
                    checkout({ plan: 'ONE_GAME' });
                  } else {
                    navigate(REGISTRATION_PATH);
                  }
                }}
                size="large"
                sx={{
                  width: '160px',
                  marginLeft: 'auto',
                  marginTop: '16px',
                  ...buttonStyle,
                  backgroundColor: colors.spark.light,
                  borderColor: colors.spark.other,
                  color: colors.spark.other,
                }}
                type="button"
                variant="primary"
              >
                Coming soon
              </Button>
            </div>
          </AddOnContainer>
        </Container>
      ) : null}

      {sub.data && sub.data.find((s) => s.plan == 'FREE') ? (
        <Container columnDisplay={width < MOBILE_WIDTH} style={{ gap: '30px' }} transparentBg>
          <AddOnContainer>
            <Title style={{ fontSize: '20px', margin: '30px 30px 25px 30px' }}>
              Taster add-ons
            </Title>
            <div style={{ borderTop: '1px solid  #896dff', padding: '30px' }}>
              <Price style={{ fontSize: '18px', lineHeight: '30px' }}>One-Time Games</Price>
              <RowBox>
                <Price style={{ fontSize: '26px', lineHeight: '40px' }}>$40</Price>
                <PriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                  /game room
                </PriceComplement>
              </RowBox>
              <Description style={{ paddingTop: '8px', minHeight: 'unset' }}>
                No subscription needed. Buy game rooms for up to 8 players in each room. Valid 3
                months.
              </Description>
              <WhatsIncluded style={{ paddingTop: '14px' }}>Add to your Taster plan</WhatsIncluded>
              <Item>
                <b>No subscription</b> needed
              </Item>
              <Item>
                Up to <b>8 players</b> in a game room
              </Item>
              <Item>
                Choose from <b>all available editions</b>
              </Item>
              <Item>
                Stack this add-on up to 3 times for <b>events up to 24 players</b>
              </Item>
              <Button
                disabled
                onClick={() => {
                  if (isLoggedIn && !isGuest) {
                    checkout({ plan: 'ONE_GAME' });
                  } else {
                    navigate(REGISTRATION_PATH);
                  }
                }}
                size="large"
                sx={{
                  width: '160px',
                  marginLeft: 'auto',
                  marginTop: '16px',
                  ...buttonStyle,
                  backgroundColor: colors.spark.light,
                  borderColor: colors.spark.other,
                  color: colors.spark.other,
                }}
                type="button"
                variant="primary"
              >
                Coming soon
              </Button>
            </div>
          </AddOnContainer>
          <AddOnContainer>
            <Title style={{ fontSize: '20px', margin: '30px 30px 25px 30px' }}>Pro add-ons</Title>
            <div style={{ borderTop: '1px solid  #896dff', padding: '30px' }}>
              <Price style={{ fontSize: '18px', lineHeight: '30px' }}>All Editions</Price>
              <RowBox>
                <Price style={{ fontSize: '26px', lineHeight: '40px' }}>$10</Price>
                <PriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                  /one month
                </PriceComplement>
              </RowBox>
              <Description style={{ paddingTop: '8px', minHeight: 'unset' }}>
                Increase your toolkit with access to all editions of Cozy Juicy Real. Valid for 1
                month.
              </Description>
              <WhatsIncluded style={{ paddingTop: '14px' }}>Add to your Pro plan</WhatsIncluded>
              <Item>
                Get access to <b>all editions</b> of Cozy Juicy Real
              </Item>
            </div>
            <div style={{ borderTop: '1px solid  #896dff', padding: '30px' }}>
              <Price style={{ fontSize: '18px', lineHeight: '30px' }}>Large Groups</Price>
              <RowBox>
                <Price style={{ fontSize: '26px', lineHeight: '40px' }}>$40</Price>
                <PriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                  /one month
                </PriceComplement>
              </RowBox>
              <Description style={{ paddingTop: '8px', minHeight: 'unset' }}>
                Increase player limit to 12 players in a game room. Valid for 1 month.
              </Description>
              <WhatsIncluded style={{ paddingTop: '14px' }}>Add to your Pro plan</WhatsIncluded>
              <Item>
                Up to <b>12 players</b> in a game room
              </Item>
            </div>
            <div style={{ borderTop: '1px solid  #896dff', padding: '30px' }}>
              <Price style={{ fontSize: '18px', lineHeight: '30px' }}>Large Events</Price>
              <RowBox>
                <Price style={{ fontSize: '26px', lineHeight: '40px' }}>$165</Price>
                <PriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                  /5 game rooms
                </PriceComplement>
              </RowBox>
              <Description style={{ paddingTop: '8px', minHeight: 'unset' }}>
                Event packs of 5, 10 or 20 extra game rooms. Buy multiple packs for large events.
                Valid for 3 months.
              </Description>
              <WhatsIncluded style={{ paddingTop: '14px' }}>Add to your Pro plan</WhatsIncluded>
              <Item>
                Host events up to <b>500 players</b>
              </Item>
              <Item>
                Add additional <b>game rooms</b>
              </Item>
              <Item>
                Open up to <b>100 game rooms</b> at once
              </Item>
              <Item>
                Access to <b>all editions</b>
              </Item>
              <Item>
                <b>Bulk create games rooms</b>
              </Item>
              <Item>
                <b>Bulk copy game room links</b>
              </Item>
              <Item>
                <b>Bulk delete game rooms</b>
              </Item>
            </div>
          </AddOnContainer>
          <AddOnContainer>
            <Title style={{ fontSize: '20px', margin: '30px 30px 25px 30px' }}>
              Company & Enterprise add-ons
            </Title>
            <div style={{ borderTop: '1px solid  #896dff', padding: '30px' }}>
              <Price style={{ fontSize: '18px', lineHeight: '30px' }}>All-Hands Events</Price>
              <RowBox>
                <Price style={{ fontSize: '26px', lineHeight: '40px' }}>$300</Price>
                <PriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                  /20 game rooms /account
                </PriceComplement>
              </RowBox>
              <Description style={{ paddingTop: '8px', minHeight: 'unset' }}>
                Facilitate large events that require 20+ breakout rooms. Valid for 3 months.
              </Description>
              <WhatsIncluded style={{ paddingTop: '14px' }}>Add to one user account</WhatsIncluded>
              <Item>
                Ability to host an event of <b>150+ players</b>
              </Item>
              <Item>
                Create up to <b>20 game rooms</b> at once
              </Item>
            </div>
            <div style={{ borderTop: '1px solid  #896dff', padding: '30px' }}>
              <Price style={{ fontSize: '18px', lineHeight: '30px' }}>
                Fully Facilitated Events
              </Price>
              <RowBox>
                <SpeechIcon
                  src={speech}
                  style={{
                    fontSize: '26px',
                    lineHeight: '40px',
                    paddingTop: '5px',
                    height: '40px',
                    width: '40px',
                  }}
                />
                <PriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                  Contact us for pricing
                </PriceComplement>
              </RowBox>
              <Description style={{ paddingTop: '8px', minHeight: 'unset' }}>
                Take off all the pressure of facilitating an event and leave it to us.
              </Description>
              <WhatsIncluded style={{ paddingTop: '14px' }}>What’s included</WhatsIncluded>
              <Item>
                <b>Hosted by skilled facilitators</b>
              </Item>
              <Item>
                <b>20 - 500 players</b> in breakout rooms
              </Item>
              <Item>
                <b>No preparation necessary</b>
              </Item>
            </div>
            <div style={{ borderTop: '1px solid  #896dff', padding: '30px' }}>
              <Price style={{ fontSize: '18px', lineHeight: '30px' }}>Custom Editions</Price>
              <RowBox>
                <SpeechIcon
                  src={speech}
                  style={{
                    fontSize: '26px',
                    lineHeight: '40px',
                    paddingTop: '5px',
                    height: '40px',
                    width: '40px',
                  }}
                />
                <PriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                  Contact us for pricing
                </PriceComplement>
              </RowBox>
              <Description style={{ paddingTop: '8px', minHeight: 'unset' }}>
                Encompass your company values in a customized edition of Cozy Juicy Real. Added to
                all accounts.
              </Description>
              <WhatsIncluded style={{ paddingTop: '14px' }}>Add to all user accounts</WhatsIncluded>
              <Item>
                <b>Custom edition created</b> by the founders of Cozy Juicy Real
              </Item>
              <Item>
                Questions and game design aligned to <b>your company brand and values</b>
              </Item>
              <Item>
                Added to <b>all company accounts</b>
              </Item>
              <Item>
                Ideal for <b>onboarding new team members and team integration</b>
              </Item>
            </div>
          </AddOnContainer>
        </Container>
      ) : null}
    </Layout>
  );
};
