import { Box, Button, Dialog, FormControl } from '@primer/react';
import { PlayerSelector } from '../playerSelector/PlayerSelector';
import { buttonStyle } from '../../theme/theme';
import { usePlayers } from '../../hooks/usePlayers';
import { useState } from 'react';
import { useMessageSender } from '../../hooks/useMessageSender';
import { showModal } from '../../redux/slices/modal.slice';
import { useAppDispatch } from '../../redux/hooks';
import { Bar } from '../bar/Bar';

export const RandomDialog = ({
  setIsOpen,
  isOpen,
  cardPoints,
}: {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  cardPoints: number;
}) => {
  const dispatch = useAppDispatch();
  const { players } = usePlayers();
  const { finishPlay, randomPoints } = useMessageSender();
  const [selectedPlayerId, setSelectedPlayerId] = useState('');

  const onClaimPoints = () => {
    if (selectedPlayerId == '') {
      return dispatch(showModal({ type: 'danger', message: 'You must select a player' }));
    }
    randomPoints({
      points: cardPoints,
      toPlayerId: selectedPlayerId,
    });
    finishPlay({
      pointsThisTurn: 0,
      stepsThisTurn: 0,
    });
  };

  return (
    <Dialog
      aria-labelledby="label"
      isOpen={isOpen}
      onDismiss={() => setIsOpen(false)}
      sx={{
        height: '80%',
        maxHeight: '350px',
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Award Random card
      </div>
      <Box p={5}>
        <FormControl sx={{ display: 'flex', alignItems: 'left', width: '100%' }}>
          <FormControl.Label sx={{ alignSelf: 'left', fontSize: '16px' }}>
            Choose a player
          </FormControl.Label>

          <PlayerSelector
            onChange={(playerId) => setSelectedPlayerId(playerId)}
            players={players.filter((p) => !p.isCurrentPlayer)}
            value={selectedPlayerId}
          />
        </FormControl>
      </Box>

      <Bar />

      <Box display="flex" justifyContent="center" mt="auto" p={5}>
        <Button
          onClick={() => {
            onClaimPoints();
            setIsOpen(false);
          }}
          sx={{ width: '40%', ...buttonStyle, minWidth: '165px' }}
          type="button"
          variant="primary"
        >
          {`Award ${cardPoints} points`}
        </Button>
      </Box>
    </Dialog>
  );
};
