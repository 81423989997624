import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AvailableAddon from "./AvailableAddon";
import { AddonCardProps, allAddonsData } from "./addonData";
import { useGetSubscriptionStatusQuery } from "../../redux/apis/payment";

const AvailableAddonsSection: React.FC = () => {
  const subs = useGetSubscriptionStatusQuery(undefined);
  const [addons, setAddons] = useState<(AddonCardProps & { plan: string })[]>([]);

  useEffect(() => {
    if (!subs.isSuccess) return;
    const availableAddOnNames = new Set(
      subs.data
        ?.filter(
          (sub) =>
            sub.isSubscriptionActive &&
            sub.allowedAddOns?.length > 0 &&
            !sub.isTemporaryAddOnSubscription
        )
        .map((sub) => sub.allowedAddOns).flat()
    );

    const availableAddOns = [] as (AddonCardProps & { plan: string })[]
    availableAddOnNames.forEach((addon) => {
      const addonData = allAddonsData[addon] ?? { title: addon, price: "", description: "", includes: "", features: [], buttonType: "bookCall" };
      availableAddOns.push({ plan: addon, ...addonData });
    });
    setAddons(availableAddOns);
  }, [subs.data]);

  if (addons.length === 0) return (<></>)

  return (
    <SectionWrapper>
      <Container itemCount={addons.length}>
        <SectionTitle>Available Add-ons</SectionTitle>
        <CardsContainer>
          <CardsGrid itemCount={addons.length}>
            {addons.map((addon, index) => (
              <CardColumn key={index}>
                <AvailableAddon {...addon} />
              </CardColumn>
            ))}
          </CardsGrid>
        </CardsContainer>
      </Container>
    </SectionWrapper>
  );
};

// Styled Components
const SectionWrapper = styled("section")`
  background: #ede9ff;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 60px 0;
  position: relative;
  top: 60px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 0;
  }
`;

const Container = styled("div")<{ itemCount: number }>`
  width: ${({ itemCount }) => (itemCount === 2 ? "800px" : "1200px")};
  max-width: 100%;
  transition: width 0.3s ease;

  @media (max-width: 991px) {
    max-width: 90%;
  }
`;

const SectionTitle = styled("h1")`
  color: #333;
  text-align: center;
  margin: 0 0 20px;
  font: 700 32px "Town 80 Text", sans-serif;
`;

const CardsContainer = styled("div")`
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CardsGrid = styled("div")<{ itemCount: number }>`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(
    ${({ itemCount }) => (itemCount === 1 ? 1 : itemCount === 2 ? 2 : 3)},
    minmax(280px, 1fr)
  );
  justify-content: center;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  & > div:nth-last-child(1) {
    grid-column: ${({ itemCount }) =>
      itemCount % 3 === 1 ? "1 / -1" : "auto"};
    justify-self: center;
  }
`;

const CardColumn = styled("div")`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

export default AvailableAddonsSection;
