import React from 'react';
import styled from 'styled-components';
import { CJRButton as BaseCJRButton } from '../../../basicComponents/cjrButton/cjrButton';
import PricingMatrix from './PriceMatrix';
import expandIcon from '../../../images/pricingPage/expandIcon.png';
import minusIcon from '../../../images/pricingPage/minusIcon.png';
import plusIcon from '../../../images/pricingPage/plusIcon.png';

interface ComparisonButtonProps {
  readonly isOpen: boolean;
  readonly setIsOpen: (open: boolean) => void;
}

const ComparisonButton: React.FC<ComparisonButtonProps> = ({ isOpen, setIsOpen }) => {
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ComparisonWrapper id="compare-features-section">
      {/* Compare plan features button */}
      <CustomCJRButton onClick={handleToggle} variant="realPrimary">
        Compare plan features
      </CustomCJRButton>

      {/* Expand icon - make it clickable */}
      <ToggleIcon
        alt={isOpen ? 'Close' : 'Open'}
        src={isOpen ? minusIcon : plusIcon}
        onClick={handleToggle} // Add the same toggle functionality here
      />

      {/* Pricing Matrix */}
      {isOpen && (
        <PricingMatrixWrapper>
          <PricingMatrix />
        </PricingMatrixWrapper>
      )}
    </ComparisonWrapper>
  );
};

// Styled components
const ComparisonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const CustomCJRButton = styled(BaseCJRButton)`
  min-width: 300px;
  max-width: 300px;
  font-size: 18px;
  font-weight: 700;
  padding: 12px 24px;
  transition: background-color 0.3s ease;
`;

const ToggleIcon = styled('img')`
  margin-top: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer; /* Ensure it's clickable */
`;

const ExpandIcon = styled('img')<{ isOpen: boolean }>`
  width: 30px;
  height: 30px;
  margin-top: 23px;
  transition: transform 0.3s ease;
  transform: rotate(${(props) => (props.isOpen ? '180deg' : '0deg')});
`;

const PricingMatrixWrapper = styled('div')`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export default ComparisonButton;