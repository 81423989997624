import { FunctionComponent, useEffect } from 'react';
import { Box, Heading } from '@primer/react';
import { useCreateUserMutation } from '../../redux/apis/user.api';

import { Layout } from '../../components/layout/Layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authLocalActions } from '../../redux/slices/auth.slice';
import {
  closeLoading,
  closeModal,
  setManualLoading,
  showLoading,
} from '../../redux/slices/modal.slice';
import { ON_BOARDING_PATH } from '../../utils/constants';
import { ProfileForm } from '../../basicComponents/profileForm/ProfileForm';
import { useLogout } from '../../hooks/useLogout';

export const RegistrationPage: FunctionComponent = () => {
  const [createUser, createUserRes] = useCreateUserMutation();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutGlobal = useLogout();

  useEffect(() => {
    if (createUserRes.isSuccess) {
      const authState = { ...createUserRes.data.auth, isGuest: false };
      dispatch(authLocalActions.setAuth(authState));
      dispatch(closeModal());
      navigate(
        ON_BOARDING_PATH + (queryParams.has('plan') ? `?plan=${queryParams.get('plan')}` : '')
      );
    }
    if (!createUserRes.isLoading) {
      dispatch(closeLoading());
    }
  }, [createUserRes]);

  useEffect(() => {
    logoutGlobal();
  }, []);

  return (
    <Layout>
      <Layout.Panel style={{ width: '100%', maxWidth: '520px' }}>
        <Heading as="h1" sx={{ fontSize: 3, mb: 3, textAlign: 'center' }}>
          Get ready to get cozy
        </Heading>
        <Box maxWidth="500px" width="100%">
          <ProfileForm
            handleSubmit={(user) => {
              dispatch(setManualLoading(true));
              dispatch(showLoading());

              createUser(user);
            }}
          />
        </Box>
      </Layout.Panel>
    </Layout>
  );
};
