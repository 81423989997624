/**
 * This code was generated by Builder.io.
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import minusIcon from '../../../images/pricingPage/minusIcon.png';
import plusIcon from '../../../images/pricingPage/plusIcon.png';

interface FAQItemProps {
  readonly question: string;
  readonly answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FAQItemWrapper>
      <QuestionWrapper onClick={() => setIsOpen(!isOpen)}>
        <Question>{question}</Question>
        <ToggleIcon alt={isOpen ? 'Close' : 'Open'} src={isOpen ? minusIcon : plusIcon} />
      </QuestionWrapper>
      {isOpen ? <Answer>{answer}</Answer> : null}
    </FAQItemWrapper>
  );
};

const FAQItemWrapper = styled('div')`
  border-radius: 12px;
  background-color: var(--White, #fff);
  margin-top: 24px;
`;

const QuestionWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 24px;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const Question = styled('h3')`
  color: var(--Dark, #333);
  font: 700 18px/2 Town 80 Text, sans-serif;
  letter-spacing: -0.25px;
  margin: 0;

  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

const ToggleIcon = styled('img')`
  width: 24px;
  height: 24px;
`;

const Answer = styled('p')`
  color: var(--Dark, #333);
  font: 500 16px/1.5 Town 80 Text, sans-serif;
  padding: 0 24px 26px;

  @media (max-width: 991px) {
    padding: 0 20px 20px;
  }
`;

export default FAQItem;
