import { FunctionComponent, useEffect, useState } from 'react';
import { Layout } from '../../components/layout/Layout';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButtom } from '../../basicComponents/backButtom/BackButtom';
import linkIcon from '../../images/linkicon.svg';
import { Heading, Tooltip, Text, themeGet, Box, Button, Dialog } from '@primer/react';
import { useClipboard } from '../../hooks/useClipboard';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { showModal } from '../../redux/slices/modal.slice';
import { authSessionActions, selectAuth } from '../../redux/slices/auth.slice';
import { RoomError } from '../../components/room/RoomError';
import { MINI_GAME_CREATE_PATH } from '../../utils/constants';
import purpleStarLight from '../../images/purpleStarLight.svg';
import styled from 'styled-components';
import { useGetShuffledDecksQuery } from '../../redux/apis/cards';
import { useCardImage } from '../../hooks/useCardImage';
import { DeckType } from '../../redux/models/colyseumSchemas/Deck';
import { Card } from '../../basicComponents/card/Card';
import { Card as CardModel } from '../../redux/models/card.model';
import { Bar } from '../../basicComponents/bar/Bar';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';
import { useGetGuestTokenQuery } from '../../redux/apis/auth.api';
import { CircleSlashIcon } from '@primer/octicons-react';
import React from 'react';

const Container = styled('div')`
  height: 60px;
  display: block;
  position: relative;
`;

const ContainerCardsOpened = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 500px;
  width: 100%;
  row-gap: 10px;
  padding-bottom: 10px;
  padding-top: 40px;
`;

const ContainerCards = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 500px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  row-gap: 10px;
  padding-bottom: 10px;
  padding-top: 40px;
`;

const Icon = styled('img')`
  min-width: 60px;
  width: 62px;
  height: 60px;
  border: 2.5px solid #896dff;
  border-radius: 50%;
  padding: 8px 6px;
  position: absolute;
  top: 0px;
  background-color: white;
`;

const Instruction = styled('div')`
  max-width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  border-radius: 30px;
  color: #440d9b;
  background-color: #ede9ff;
  padding-left: 80px;
  padding-right: 60px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  position: relative;
  font-size: 15px;
`;

type StyledCardButtonProps = {
  deck: DeckType;
  backgroundImage?: string;
};

const StyledCardButton = styled('button')<StyledCardButtonProps>`
  width: 100%;
  max-width: 320px;
  max-height: 180px;
  flex: 1;
  --card-color: ${({ deck }) => themeGet(`colors.${deck}.dark`)};
  --card-outline-color: ${({ deck }) => themeGet(`colors.${deck}.light`)};

  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1em;
  margin-bottom: 15px;
  border: 5px solid var(--card-color);
  box-shadow: ${themeGet('shadows.cardButton.shadow')};

  transition-property: transform, box-shadow;
  transition-duration: 0.3s;

  &:enabled {
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      transform: rotate(0) scale(1.05) translateZ(0);
      box-shadow: ${themeGet('shadows.cardButton.focusShadow')};
    }

    &:focus-visible {
      outline: 3px solid var(--card-outline-color);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const Title = styled('img')<StyledCardButtonProps>`
  transform: scale(1.2);
  margin-top: 26px;
  margin-bottom: 45px;
`;

const Tag = styled('div')`
  border-radius: 4px;
  top: 5px;
  left: 0px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  display: table;
  height: 22px;
  padding-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(0, 0, 0);
`;

export const MiniGamePage: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);

  const { copy } = useClipboard();
  const { href } = useWindowLocation();
  const { code } = useParams();
  const getToken = useGetGuestTokenQuery({ code: code! }, { skip: !code });

  const getCards = useGetShuffledDecksQuery(getToken.data?.expansion ?? '', {
    skip: !getToken.isSuccess || !isLoggedIn,
  });
  const [showCard, setShowCard] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [skips, setSkips] = useState(3);
  const returnFocusRef = React.useRef(null);

  const [card, setCard] = useState<CardModel>({
    id: '1',
    points: 0,
    deck: 'cozy',
    text: '',
  });

  const [index, setIndex] = useState({
    cozy: 0,
    juicy: 0,
    real: 0,
    random: 0,
    lightning: 0,
  });

  const copyLink = () => {
    copy(`${href}`);
    dispatch(showModal({ message: 'Link copied', type: 'success' }));
  };

  useEffect(() => {
    if (getToken.isSuccess)
      dispatch(
        authSessionActions.setAuth({
          accessToken: getToken.data?.accessToken,
          expiresAt: getToken.data?.expiresAt,
          isGuest: true,
        })
      );
  }, [getToken.isSuccess]);

  const backgroundImg_cozy = useCardImage('cozy', 'patternFull');
  const titleImg_cozy = useCardImage('cozy', 'title');
  const backgroundImg_juicy = useCardImage('juicy', 'patternFull');
  const titleImg_juicy = useCardImage('juicy', 'title');
  const backgroundImg_real = useCardImage('real', 'patternFull');
  const titleImg_real = useCardImage('real', 'title');

  if (!getToken.data?.expansion || getToken.data!.expansion === ':expansion')
    return <Layout alignItems="center" flexDirection="column" />;
  if (getCards.isError) return <RoomError msg="Error loading cards" />;

  return (
    <>
      <Layout alignItems="center" flexDirection="column">
        <Layout.Panel style={{ maxWidth: '510px', position: 'relative' }}>
          {isGuest ? null : <BackButtom goBack={() => navigate(MINI_GAME_CREATE_PATH)} />}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Heading
              as="h1"
              data-testid="room-name-label"
              sx={{ fontSize: 3, textAlign: 'center', mb: 3, color: '#3B147A' }}
            >
              <Text>{getToken.data!.expansion.trim()}</Text> Edition
            </Heading>
            <Tooltip aria-label="Copy link" direction="e">
              <img
                alt="Cozy Juicy Real"
                onClick={copyLink}
                src={linkIcon}
                style={{
                  marginLeft: '10px',
                  marginTop: '-3px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </div>

          <Container>
            <Instruction>
              {showCard
                ? 'Read the question aloud and answer it. Then click ‘Return to decks’.'
                : 'Click on any card deck below...'}
            </Instruction>
            <Icon src={purpleStarLight} />
          </Container>

          {showCard ? (
            <ContainerCardsOpened style={{ paddingBottom: '0px' }}>
              <Card {...(card as any)} noPoints>
                {card.text}
              </Card>
              <Bar style={{ position: 'relative', bottom: 'unset' }} />

              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                mt={4}
                sx={{ gap: '10px' }}
                width="100%"
              >
                <CJRButton
                  onClick={() => {
                    if (skips > 0) setIsOpen(true);
                  }}
                  style={{
                    width: 'fit-content',
                    minWidth: 'fit-content',
                    margin: '0px 10px',
                    flexGrow: 1,
                  }}
                  variant={skips > 0 ? 'realTertiary' : 'realDisabled'}
                >
                  Skip card {skips > 0 ? `(${skips}/3)` : ''}
                </CJRButton>
                <CJRButton
                  onClick={() => setShowCard(false)}
                  style={{
                    width: 'fit-content',
                    minWidth: 'fit-content',
                    margin: '0px 10px',
                    flexGrow: 1,
                  }}
                  variant="juicyPrimary"
                >
                  Return to decks
                </CJRButton>
              </Box>
            </ContainerCardsOpened>
          ) : null}
          <ContainerCards style={{ display: showCard ? 'none' : 'flex' }}>
            {['cozy' as DeckType, 'juicy' as DeckType, 'real' as DeckType].map((deck) => (
              <StyledCardButton
                backgroundImage={
                  deck == ('cozy' as const)
                    ? backgroundImg_cozy
                    : deck == ('juicy' as const)
                    ? backgroundImg_juicy
                    : backgroundImg_real
                }
                deck={deck}
                key={deck}
                onClick={() => {
                  setShowCard(true);
                  setIndex({ ...index, [deck]: index[deck] + 1 });
                  setCard(getCards?.data![deck][index[deck] % getCards?.data![deck].length]);
                }}
              >
                <Tag
                  style={{
                    backgroundColor:
                      deck == ('cozy' as const)
                        ? '#DFFFED'
                        : deck == ('juicy' as const)
                        ? '#FFF9D6'
                        : '#FFEAE4',
                    color:
                      deck == ('cozy' as const)
                        ? '#009B88'
                        : deck == ('juicy' as const)
                        ? '#FF7300'
                        : '#DB004F',
                  }}
                >
                  {deck == ('cozy' as const)
                    ? 'Get started'
                    : deck == ('juicy' as const)
                    ? 'Deeper'
                    : 'Deepest'}
                </Tag>
                <Title
                  deck={deck}
                  src={
                    deck == ('cozy' as const)
                      ? titleImg_cozy
                      : deck == ('juicy' as const)
                      ? titleImg_juicy
                      : titleImg_real
                  }
                />
              </StyledCardButton>
            ))}
          </ContainerCards>
        </Layout.Panel>
      </Layout>

      <Dialog
        aria-labelledby="label"
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        returnFocusRef={returnFocusRef}
        sx={{ height: 'initial !important' }}
      >
        <Dialog.Header>
          <CircleSlashIcon />
        </Dialog.Header>
        <Box p={3}>
          <Text id="label">
            You have {skips} card skips remaining per game. Are you sure you want to skip this card?
          </Text>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button onClick={() => setIsOpen(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
                setIndex({ ...index, [card.deck]: index[card.deck] + 1 });
                setCard(
                  getCards?.data![card.deck][index[card.deck] % getCards?.data![card.deck].length]
                );
                setSkips(skips - 1);
              }}
              variant="primary"
            >
              Yes, skip card
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
