import React, { useState } from 'react';
import styled from 'styled-components';
import FAQItem from './FAQItem';
import contactIcon from '../../../images/pricingPage/contactIcon.svg';

// FAQ Data
const faqData = [
  {
    question: 'What\'s the difference between an "admin" and a "player"?',
    answer:
      'An "admin" is the account holder and has the capability to set up and manage games – like a facilitator or team lead. "Players" are invited by an admin to play games – they simply join the game to play and have fun. Admins pay for platform access; players don’t.',
  },
  {
    question: 'How much does it cost per player?',
    answer:
      "It's super affordable. For example, with the Company plan, if an admin invites just 10 players a month, it comes out to roughly $2 /player /year! 😮",
  },
  {
    question: 'How do I know which plan is best for me?',
    answer:
      "Take a look at the features matrix above to see what each plan offers. If you have questions, or you're deciding between the Company and Enterprise plans, just book a call—we're here to help. 👍",
  },
  {
    question: 'Can I change my plan later?',
    answer:
      "Yup, easy peasy – you can upgrade or downgrade at any time. When you upgrade, you'll pay only a prorated amount. If you choose to downgrade, the change will take effect after your current plan ends.",
  },
  {
    question: 'What if none of the plans fit my needs?',
    answer:
      "We get it—one size doesn't always fit all. That's why we offer a variety of add-ons to tailor your experience. Explore them in the features matrix to craft your ideal plan.",
  },
  {
    question: "What are my payment options? Why can't I see a monthly plan?",
    answer:
      "Paid plans are billed annually. We don't offer monthly plans because Cozy Juicy Real isn't meant to be a one-time or one-month experience – its purpose is to create lasting change. Building a foundation of connection, playfulness and belonging requires the consistency that an annual plan provides.",
  },
  {
    question: 'Do you offer a free trial?',
    answer:
      'No free trials, sorry. Instead we invite companies to book a demo call for up to four people so you can try it out for yourself and ask questions. We also offer the Taster plan – a way to test the platform for free.',
  },
  {
    question: 'How does the Taster plan work?',
    answer:
      "The Taster plan gives you a mini version of Cozy Juicy Real, featuring a handful of questions for up to 4 players so you can get a feel for the full game experience. If you're curious for more and interested in either the Company or Enterprise plan, feel free to book a demo call with us to dive deeper and see how it works firsthand.",
  },
  {
    question: 'Do you give discounts to non-profit or education organizations?',
    answer: "We do - just book a call and we'll discuss how to make it work for your organization.",
  },
  {
    question: 'What currency do you bill in?',
    answer: 'We bill in US dollars.',
  },
  {
    question: 'Can I cancel my paid plan?',
    answer:
      "To cancel your plan, head to the Billing & Plans page in your Account menu. Click 'See plans' and switch to the free 'Taster' plan – super simple – the change will take effect after your current plan ends. If you want to delete your account entirely, click the 'Delete account' link on your Billing & Plans page.",
  },
  {
    question: 'Does Cozy Juicy Real work for remote, hybrid & in-person teams?',
    answer:
      'It does indeed. The platform is designed with remote, hybrid, and in-person teams in mind. Its responsive design means it works seamlessly on laptops, desktops and mobile devices, making it accessible anywhere.',
  },
  {
    question: "How do I get access to the themed-question editions if I'm on the Pro plan?",
    answer:
      'Check out our Add-ons, where  you can purchase All Editions - this will give you 1-month access to every edition we offer.',
  },
  {
    question: 'What\'s a "custom company values edition" in the Enterprise plan?',
    answer:
      'This is a custom edition of Cozy Juicy Real, based on your company values, or another theme of your choice. This is especially popular for onboarding to help new employees get to know each other while becoming acquainted with the values of their new company.',
  },
  {
    question: 'What counts as a "used" game?',
    answer: 'A game is considered “used” once a player passes the halfway point on the game board.',
  },
];

const FAQs: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState(''); // Store the search input value

  // Filter FAQs based on search query (checks both question and answer)
  const filteredFAQs = faqData.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <FAQsWrapper>
      <FAQsContent>
        <FAQsTitle>Not so Juicy, but frequently asked questions</FAQsTitle>
        <FAQsSubtitle>
        The FAQ's below are focused on plans and pricing. Go to our{' '}
        <a
          href="https://www.cozyjuicyreal.com/support-faqs"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#896dff' }}
        >
          FAQ's page
        </a>{' '}
        for more info.
      </FAQsSubtitle>

        {/* Search Bar */}
        <SearchBarWrapper>
          <SearchBar
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
            type="text"
            value={searchQuery}
          />
        </SearchBarWrapper>

        {/* FAQ List */}
        {filteredFAQs.length > 0 ? (
          filteredFAQs.map((faq, index) => (
            <FAQItem answer={faq.answer} key={index} question={faq.question} />
          ))
        ) : (
          <NoResults>Hmmm...there's no FAQ's matching that.</NoResults>
        )}

        <ContactInfo>
          <ContactIcon src={contactIcon} />
          <ContactText>
            Didn't find the answer you are looking for?{' '}
            <ContactLink
              href="https://cozyjuicyreal.com/contact-us"
              rel="noreferrer"
              target="_blank"
            >
              Send us a message
            </ContactLink>{' '}
            – a human will reply super soon! 🙋‍♀️
          </ContactText>
        </ContactInfo>
      </FAQsContent>
    </FAQsWrapper>
  );
};

// Styled Components
const FAQsWrapper = styled('section')`
  background-color: var(--Light, #f9f9f9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 107px 80px;
  margin-top: 90px;

  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 100px 20px;
  }
`;

const FAQsContent = styled('div')`
  width: 100%;
  max-width: 1140px;
`;

const FAQsTitle = styled('h2')`
  color: var(--Dark, #333);
  text-align: center;
  font-size: 36px;
  font-weight: 700;

  @media (max-width: 991px) {
    font-size: 32px;
  }
`;

const FAQsSubtitle = styled('p')`
  color: var(--Dark, #666);
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 40px;

  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

// Search Bar Wrapper for centering
const SearchBarWrapper = styled('div')`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

// Search bar styling (50% width, centered, rounded, and matching the font)
const SearchBar = styled('input')`
  width: 40%;
  padding: 10px;
  font-size: 16px;
  font-weight: 350;
  border: 1px solid #896dff;
  border-radius: 30px; /* Fully rounded edges */
  color: var(--Dark, #333);
  &:focus {
    outline: none; /* Removes the default blue outline */
    border: 2px solid #896dff;
  }
  @media (max-width: 991px) {
    width: 80%; /* Adjust for smaller screens */
  }
`;

const NoResults = styled('p')`
  color: var(--Dark, #333);
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
`;

const ContactInfo = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding: 12px 11px;
`;

const ContactIcon = styled('img')`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const ContactText = styled('p')`
  color: var(--Spark-Colors-Spark-2, #896dff);
  text-align: center;
  font-size: 17px;
  font-weight: 500;
`;

const ContactLink = styled('a')`
  color: var(--Spark-Colors-Spark-2, #896dff);
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;

export default FAQs;
