import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { Avatar as PrimerAvatar, AvatarProps as PrimerAvatarProps } from '@primer/react';

import defaultAvatarSrc from '../../images/avatars/defaultAvatar.png';
import Fire from '../../images/avatars/GamePiece - Campfire.svg';
import Cassette from '../../images/avatars/GamePiece - Cassette.svg';
import Caticorn from '../../images/avatars/GamePiece - Caticorn.svg';
import Dino from '../../images/avatars/GamePiece - Dino.svg';
import Pug from '../../images/avatars/GamePiece - Spacepug.svg';
import Lion from '../../images/avatars/GamePiece - LionBiker.svg';
import Rocket from '../../images/avatars/GamePiece - Rocket.svg';
import Panda from '../../images/avatars/GamePiece - NinjaPanda.svg';
import Sloth from '../../images/avatars/GamePiece - Sloth.svg';
import Sunshine from '../../images/avatars/GamePiece - Sunshine.svg';
import Coconut from '../../images/avatars/GamePiece - Coconut.svg';
import Rainbow from '../../images/avatars/GamePiece - Rainbow.svg';
import Cherries from '../../images/avatars/GamePiece - Cherries.svg';
import Cloud from '../../images/avatars/GamePiece - Cloud.svg';
import Controller from '../../images/avatars/GamePiece - Controller.svg';
import Daisy from '../../images/avatars/GamePiece - Daisy.svg';
import Dove from '../../images/avatars/GamePiece - Dove.svg';
import Flower from '../../images/avatars/GamePiece - Flower.svg';
import Heart from '../../images/avatars/GamePiece - Heart.svg';
import Icecream from '../../images/avatars/GamePiece - Icecream.svg';
import Monster from '../../images/avatars/GamePiece - Monster.svg';
import Mushroom from '../../images/avatars/GamePiece - Mushroom.svg';
import Peace from '../../images/avatars/GamePiece - Peace.svg';
import Phone from '../../images/avatars/GamePiece - Phone.svg';

const avatars = [
  { src: Fire, alt: 'Campfire' },
  { src: Cassette, alt: 'Cassette' },
  { src: Caticorn, alt: 'Caticorn' },
  { src: Dino, alt: 'Dino' },
  { src: Pug, alt: 'Pug' },
  { src: Lion, alt: 'CampfLionire' },
  { src: Rocket, alt: 'Rocket' },
  { src: Panda, alt: 'Panda' },
  { src: Sloth, alt: 'Sloth' },
  { src: Sunshine, alt: 'Sun' },
  { src: Coconut, alt: 'Coconut' },
  { src: Rainbow, alt: 'Rainbow' },
  { src: Cherries, alt: 'Cherries' },
  { src: Cloud, alt: 'Cloud' },
  { src: Controller, alt: 'Controller' },
  { src: Daisy, alt: 'Daisy' },
  { src: Dove, alt: 'Dove' },
  { src: Flower, alt: 'Flower' },
  { src: Heart, alt: 'Heart' },
  { src: Icecream, alt: 'Icecream' },
  { src: Monster, alt: 'Monster' },
  { src: Mushroom, alt: 'Mushroom' },
  { src: Peace, alt: 'Peace' },
  { src: Phone, alt: 'Phone' },
];

const defaultAvatar = {
  src: defaultAvatarSrc,
  alt: 'Default avatar',
};

const Border = styled('div')`
  background-color: white;
  border-radius: 50%;
  border: 2px solid #412683;
  box-shadow: #41268320 0px 1px 3px 0px, #00000050 0px 1px 2px 0px;
`;

type AvatarComposition = {
  Border: typeof Border;
};

export type AvatarProps = Omit<PrimerAvatarProps, 'src'> & { variant: number };
export const Avatar: FunctionComponent<AvatarProps> & AvatarComposition = ({
  variant,
  ...rest
}) => {
  const variantProps = avatars[variant] ?? defaultAvatar;

  const props = {
    size: 100,
    ...variantProps,
    ...rest,
  };

  return <PrimerAvatar data-testid={`avatar-${variant}`} {...props} />;
};

Avatar.Border = Border;
