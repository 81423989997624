import { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { Button, FormControl, TextInput, Checkbox, Box, Text } from '@primer/react';
import { validateEmail, validateNonEmptyString, validatePassword } from '../../utils/validators';
import { UserCreateUpdateDto } from '../../redux/models/user.model';
import { UserResponseDto } from '../../redux/models/user.model';
import { buttonStyle, checkboxStyle, textInputStyle } from '../../theme/theme';
import { Bar } from '../bar/Bar';
import { usePlanNamesQuery } from '../../redux/apis/config';
import { useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { useSearchParams } from 'react-router-dom';

export const ProfileForm: FunctionComponent<{
  readonly handleSubmit: (user: UserCreateUpdateDto) => any;
  readonly user?: UserResponseDto;
  readonly isUpdate?: boolean;
  readonly sendVerificationEmail?: () => void;
}> = ({ handleSubmit, user, isUpdate, sendVerificationEmail }) => {
  const sub = useGetSubscriptionStatusQuery(undefined);
  const planNames = usePlanNamesQuery(undefined);

  const [firstName, setFirstName] = useState<string | undefined>();
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState<string | undefined>();
  const [org, setOrg] = useState<string | undefined>();
  const [orgErrorMsg, setOrgErrorMsg] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>();
  const [editPassword, setEditPassword] = useState(!isUpdate);
  const [oldPassword, setOldPassword] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [password2, setPassword2] = useState<string | undefined>(undefined);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>();
  // const phoneParts = user?.phoneNumber?.split(' ') ?? ['', ''];
  // const initialCountryCode = countryCodes.find((x) => x.text.includes(`(${phoneParts[0]})`));
  // const [country, setCountry] = useState<{ text: string; id: number } | undefined>();
  // const [countryCode, setCountryCode] = useState<string | undefined>(phoneParts[0]);
  // const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  // const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState<string | undefined>();
  const [resetEmailDisabled, setResetEmailDisabled] = useState(false);
  const [receiveEmails, setReceiveEmails] = useState<boolean>(true);

  useEffect(() => {
    setLastName(user?.lastName);
    setFirstName(user?.firstName);
    setOrg(user?.org);
    setEmail(user?.email);
    // const phoneParts2 = user?.phoneNumber?.split(' ') ?? ['', ''];
    // const initialCountryCode2 = countryCodes.find((x) => x.text.includes(`(${phoneParts[0]})`)) ?? {
    //   text: 'Spain (34)',
    //   id: 202,
    // };
    // setCountry(initialCountryCode2);
    // setCountryCode(/\(([^)]+)\)/.exec(initialCountryCode2.text)?.[1]);
    // setPhoneNumber(phoneParts2[1]);
    setReceiveEmails(user?.receiveEmails ?? true);
  }, [user]);

  const onFirstNameChange = (value: string) => {
    setFirstName(value);
    setFirstNameErrorMessage(validateNonEmptyString(value, 'first name'));
  };
  const onLastNameChange = (value: string) => {
    setLastName(value);
    setLastNameErrorMessage(validateNonEmptyString(value, 'last name'));
  };
  const onOrgChange = (value: string) => {
    setOrg(value);
    setOrgErrorMsg(validateNonEmptyString(value, 'organization', 100));
  };
  const onEmailChange = (value: string) => {
    setEmail(value);
    setEmailErrorMessage(validateEmail(value));
  };
  const onPasswordChange = (value: string) => {
    setPassword(value);
    setPasswordErrorMessage(validatePassword(value));
  };
  const onPassword2Change = (value: string) => {
    setPassword2(value);
    setPasswordErrorMessage(password == value ? validatePassword(value) : "Passwords don't match");
  };
  // const onPhoneNumberChange = (value: string) => {
  //   setPhoneNumber(value);
  //   setPhoneNumberErrorMessage(validatePhoneNumber(value));
  // };
  // const onCountrySelectedChange = (
  //   item: { text: string; id: number } | { text: string; id: number }[]
  // ) => {
  //   if (!item || !Array.isArray(item) || item.length == 0) return;
  //   setPhoneNumberErrorMessage(undefined);
  //   setCountry(item[0]);
  //   setCountryCode(/\(([^)]+)\)/.exec(item[0].text)?.[1]);
  // };

  const isValid = () => {
    const firstNameValidationError = validateNonEmptyString(firstName, 'first name');
    setFirstNameErrorMessage(firstNameValidationError);
    const lastNameValidationError = validateNonEmptyString(lastName, 'last name');
    setLastNameErrorMessage(lastNameValidationError);
    const orgValidationError = validateNonEmptyString(org, 'organization', 100);
    setOrgErrorMsg(orgValidationError);
    const emailValidationError = validateEmail(email);
    setEmailErrorMessage(emailValidationError);

    let passwordValidationError: string | undefined;
    if (password) passwordValidationError = validatePassword(password);
    if (!isUpdate && !password) passwordValidationError = 'Your password cannot be empty';
    if (password !== password2) passwordValidationError = "Passwords don't match";
    setPasswordErrorMessage(passwordValidationError);

    // const phoneNumberValidationError = validatePhoneNumber(phoneNumber ?? '');
    // const countryError = validateCountry(country?.text);
    // setPhoneNumberErrorMessage(phoneNumberValidationError ?? countryError);

    return (
      !firstNameValidationError &&
      !lastNameValidationError &&
      !orgValidationError &&
      !emailValidationError &&
      !passwordValidationError
      // !phoneNumberValidationError &&
      // !countryError
    );
  };

  const onSubmit = () => {
    if (!isValid()) return;
    handleSubmit({
      firstName,
      lastName,
      org,
      email: isUpdate ? undefined : email,
      password: password === '' ? undefined : password,
      // phoneNumber: `${countryCode} ${phoneNumber}`,
      oldPassword: isUpdate ? oldPassword : undefined,
      receiveEmails,
    });
    setPassword('');
    setPassword2('');
    setOldPassword('');
  };

  const [query] = useSearchParams();
  useEffect(() => {
    if (query.has('email')) setEmail(query.get('email') ?? '');
    if (query.has('firstName')) setFirstName(query.get('firstName') ?? '');
    if (query.has('lastName')) setLastName(query.get('lastName') ?? '');
    if (query.has('org')) setOrg(query.get('org') ?? '');
  }, [query]);

  return (
    <div style={{ position: 'relative' }}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <FormControl sx={{ mb: 3, mr: 1 }}>
          <FormControl.Label>First name*</FormControl.Label>
          <TextInput
            aria-describedby="firstName-validation"
            aria-invalid={!!firstNameErrorMessage}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onFirstNameChange(event.target.value)
            }
            sx={{ width: '100%', ...textInputStyle }}
            validationStatus={firstNameErrorMessage ? 'error' : undefined}
            value={firstName ?? user?.firstName}
          />
          {firstNameErrorMessage ? (
            <FormControl.Validation id="firstName-validation" variant="error">
              {firstNameErrorMessage}
            </FormControl.Validation>
          ) : null}
        </FormControl>
        <FormControl sx={{ mb: 3, ml: 1 }}>
          <FormControl.Label>Last name*</FormControl.Label>
          <TextInput
            aria-describedby="lastName-validation"
            aria-invalid={!!lastNameErrorMessage}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onLastNameChange(event.target.value)
            }
            sx={{ width: '100%', ...textInputStyle }}
            validationStatus={lastNameErrorMessage ? 'error' : undefined}
            value={lastName ?? user?.lastName}
          />
          {lastNameErrorMessage ? (
            <FormControl.Validation id="lastName-validation" variant="error">
              {lastNameErrorMessage}
            </FormControl.Validation>
          ) : null}
        </FormControl>
      </Box>
      <FormControl sx={{ mb: 3 }}>
        <FormControl.Label visuallyHidden>Organization*</FormControl.Label>
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <FormControl.Label>Organization*</FormControl.Label>
          <Text sx={{ fontSize: '12px', color: '#999999', alignSelf: 'flex-end' }}>
            If this doesn't apply, enter 'NA'
          </Text>
        </Box>
        <Box width="100%">
          <TextInput
            aria-describedby="org-validation"
            aria-invalid={!!orgErrorMsg}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onOrgChange(event.target.value)}
            sx={{ width: '100%', ...textInputStyle }}
            validationStatus={orgErrorMsg ? 'error' : undefined}
            value={org ?? user?.org}
          />
          {orgErrorMsg ? (
            <FormControl.Validation id="org-validation" variant="error">
              {orgErrorMsg}
            </FormControl.Validation>
          ) : null}
        </Box>
      </FormControl>
      <FormControl disabled={isUpdate ?? false} sx={{ mb: 3 }}>
        <FormControl.Label>Email*</FormControl.Label>
        <TextInput
          aria-describedby="email-validation"
          aria-invalid={!!emailErrorMessage}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onEmailChange(event.target.value)}
          sx={{ width: '100%', ...textInputStyle }}
          validationStatus={emailErrorMessage ? 'error' : undefined}
          value={email ?? user?.email}
        />
        {emailErrorMessage ? (
          <FormControl.Validation id="email-validation" variant="error">
            {emailErrorMessage}
          </FormControl.Validation>
        ) : null}
        {user && !user.emailVerified && sendVerificationEmail ? (
          <Button
            disabled={resetEmailDisabled}
            onClick={() => {
              sendVerificationEmail();
              setResetEmailDisabled(true);
            }}
            size="small"
            sx={{ mt: 3, width: '40%', minWidth: '200px', ...buttonStyle }}
            type="button"
            variant="default"
          >
            Resend verification email
          </Button>
        ) : null}
      </FormControl>

      {/* <FormControl sx={{ mb: 3 }}>
        <FormControl.Label id="autocompleteLabel-basic">Phone number</FormControl.Label>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box>
            <Autocomplete>
              <Autocomplete.Input
                onChange={(value) => {
                  const errorMsg = validateCountry(value.target.value);
                  if (errorMsg) {
                    setCountry(undefined);
                    setCountryCode(undefined);
                  }
                  setPhoneNumberErrorMessage(errorMsg);
                }}
                placeholder="Country"
                sx={{ width: '90px', marginRight: '15px', ...textInputStyle }}
                value={country?.text ?? initialCountryCode?.text}
              />
              <Autocomplete.Overlay maxHeight="xsmall">
                <Autocomplete.Menu
                  aria-labelledby="autocompleteLabel-basic"
                  items={countryCodes}
                  onSelectedChange={onCountrySelectedChange}
                  selectedItemIds={country?.id ? [country.id] : []}
                  selectionVariant="single"
                />
              </Autocomplete.Overlay>
            </Autocomplete>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextInput
              aria-describedby="phoneNumber-validation"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onPhoneNumberChange(event.target.value)
              }
              sx={{ width: '100%', ...textInputStyle }}
              type="tel"
              value={phoneNumber ?? phoneParts[1]}
            />
          </Box>
        </Box>
        {phoneNumberErrorMessage ? (
          <FormControl.Validation id="phoneNumber-validation" variant="error">
            {phoneNumberErrorMessage}
          </FormControl.Validation>
        ) : null}
      </FormControl> */}

      {isUpdate ? (
        <>
          <FormControl sx={{ mb: 3 }}>
            <Checkbox
              checked={editPassword}
              onChange={() => setEditPassword(!editPassword)}
              sx={{ ...checkboxStyle }}
            />
            <FormControl.Label>Change password?</FormControl.Label>
          </FormControl>
          {editPassword ? (
            <FormControl sx={{ mb: 3 }}>
              <FormControl.Label>Old Password</FormControl.Label>
              <TextInput
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setOldPassword(event.target.value)
                }
                sx={{ width: '100%', ...textInputStyle }}
                type="password"
                value={oldPassword}
              />
            </FormControl>
          ) : null}
        </>
      ) : null}
      {editPassword ? (
        <>
          <FormControl sx={{ mb: 3 }}>
            <FormControl.Label>Password*</FormControl.Label>
            <TextInput
              aria-describedby="password-validation"
              aria-invalid={!!passwordErrorMessage}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onPasswordChange(event.target.value)
              }
              sx={{ width: '100%', ...textInputStyle }}
              type="password"
              validationStatus={passwordErrorMessage ? 'error' : undefined}
              value={password}
            />
          </FormControl>
          <FormControl sx={{ mb: 3 }}>
            <FormControl.Label>Password confirmation*</FormControl.Label>
            <TextInput
              aria-describedby="password2-validation"
              aria-invalid={!!passwordErrorMessage}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onPassword2Change(event.target.value)
              }
              sx={{ width: '100%', ...textInputStyle }}
              type="password"
              validationStatus={passwordErrorMessage ? 'error' : undefined}
              value={password2}
            />
            {passwordErrorMessage ? (
              <FormControl.Validation id="password2-validation" variant="error">
                {passwordErrorMessage}
              </FormControl.Validation>
            ) : null}
          </FormControl>
        </>
      ) : null}

      <FormControl>
        <Checkbox
          checked={receiveEmails}
          onChange={() => setReceiveEmails(!receiveEmails)}
          sx={{ ...checkboxStyle }}
        />
        <FormControl.Label sx={{ fontWeight: 100, fontSize: '16px' }}>
          Keep me posted on quarterly updates and juicy news
        </FormControl.Label>
      </FormControl>

      {isUpdate ? (
        <>
          <div
            style={{
              paddingTop: '30px',
            }}
          >
            You have the plan(s):{' '}
            <b>
              {sub.data
                ?.filter((x) => x.isSubscriptionActive || x.plan == 'FREE')
                .sort((a) => (a.isTemporaryAddOnSubscription ? 1 : -1))
                .map((p) => `${planNames.data?.[p.plan]}`)
                .join(' and ')}
            </b>
            .
          </div>
          {/* <div>
          Go to Billing & Plans page for more info.
          </div> */}
        </>
      ) : null}

      <Bar style={{ bottom: isUpdate ? '50px' : '85px' }} />
      <Button
        onClick={onSubmit}
        size="medium"
        style={{ marginTop: '30px', marginBottom: '20px' }}
        sx={{
          width: '100%',
          maxWidth: '250px',
          ...buttonStyle,
          margin: 'auto',
        }}
        type="button"
        variant="primary"
      >
        {isUpdate ? 'Save changes' : 'Sign up'}
      </Button>
      {!isUpdate ? (
        <p
          style={{
            color: '#3B147A',
            textAlign: 'center',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            marginBottom: '0px',
          }}
        >
          By continuing, you agree to Cozy Juicy Real’s Privacy Policy and Terms of Service.
        </p>
      ) : null}
    </div>
  );
};
