import styled from 'styled-components';
import { DeckType } from '../../redux/models/colyseumSchemas/Deck';
import { DeckButton } from '../deckButton/DeckButton';

const Decks = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1em 0.3em;
  gap: 3vw;
  @media screen and (min-width: 548px) {
    gap: 16px;
  }
`;

export type DeckSelectorProps = {
  decks: DeckType[];
  onSelect: (variant: DeckType) => void;
};

export const DeckSelector = ({ decks, onSelect }: DeckSelectorProps) => {
  if (decks?.length && decks[0] === 'lightning') {
    const deck = decks[0];
    return <DeckButton deck={deck} key={deck} onClick={() => onSelect(deck)} />;
  }
  return (
    <Decks>
      {(['cozy', 'juicy', 'real', 'random'] as DeckType[]).map((deck) => (
        <DeckButton
          deck={deck}
          disabled={!decks?.includes(deck)}
          key={deck}
          onClick={() => onSelect(deck)}
        />
      ))}
    </Decks>
  );
};
