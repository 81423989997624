import { Client, Room } from 'colyseus.js';
import { RoomState } from '../redux/models/colyseumSchemas/RoomState';
import { env } from '../env';
import { useUpdateGameState } from './useUpdateGameState';
import { SparkType } from '../redux/models/colyseumSchemas/Spark';

const url = env.REACT_APP_WS;

const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export type RoomResult =
  | { status: 'awaiting'; room?: Room<RoomState> }
  | { status: 'joined'; room: Room<RoomState> }
  | { status: 'created'; room: Room<RoomState> }
  | { status: 'reconnected'; room: Room<RoomState> }
  | { status: 'error'; error: any; room?: Room<RoomState> };

export type RoomStaus = { clients: number; maxClients: number; roomId: string };

const DefaultSparkQuantities: Record<SparkType, number> = {
  aboutme: 1,
  aboutyou: 1,
  courage: 2,
  godeeper: 1,
  highfive: 2,
  hug: 1,
};

const client = new Client(url);

export const useColyseus = () => {
  const updateGameState = useUpdateGameState();

  function autoReconnect(room: Room) {
    return async (code: number) => {
      console.log('onLeave mit code: ', code);
      if (code !== 1005 && code !== 4000) {
        // 1005 and 4000 is when facilitator voluntarily leaves
        let count = 0;
        //location.reload();
        while (count < 10) {
          console.log('trying auto-reconnection');
          await sleep(count ** 2 * 1000);
          const reconnectRes = await reconnect(room.id, room.reconnectionToken);
          console.log('reconnectRes', reconnectRes);
          if (reconnectRes.status == 'reconnected' || reconnectRes.status == 'joined') break;
          count++;
        }
      }
    };
  }

  async function createRoom(
    name: string,
    maxNoOfPlayers: number,
    sparkQuantities?: Record<SparkType, number>
  ): Promise<RoomResult> {
    try {
      const room: Room<RoomState> = await client.create('cozy_room', {
        name,
        maxNoOfPlayers,
        sparkQuantities: sparkQuantities ?? DefaultSparkQuantities,
      });
      updateGameState({ status: 'created', room });
      room.onLeave(autoReconnect(room));
      return { status: 'created', room };
    } catch (error) {
      return { status: 'error', error };
    }
  }

  async function joinRoom(roomId: string): Promise<RoomResult> {
    try {
      const room: Room<RoomState> = await client.joinById(roomId);
      updateGameState({ status: 'joined', room });
      room.onLeave(autoReconnect(room));
      return { status: 'joined', room };
    } catch (error) {
      return { status: 'error', error };
    }
  }

  async function reconnect(roomId: string, reconnectionToken: string): Promise<RoomResult> {
    try {
      const room: Room<RoomState> = await client.reconnect(reconnectionToken);
      updateGameState({ status: 'reconnected', room });
      room.onLeave(autoReconnect(room));
      return { status: 'reconnected', room };
    } catch (error: any) {
      if (error.message?.includes('reconnection token invalid')) {
        console.log('reconnection token invalid, user will be recreated', { roomId });
        return await joinRoom(roomId);
      }
      return { status: 'error', error };
    }
  }

  async function joinOrReconnect(roomId: string, reconnectionToken?: string): Promise<RoomResult> {
    if (reconnectionToken) {
      console.log('reconnecting room', { roomId });

      return await reconnect(roomId, reconnectionToken);
    } else {
      console.log('joining room', { roomId });
      return await joinRoom(roomId);
    }
  }

  return {
    createRoom,
    joinOrReconnect,
  };
};
