import { useState } from 'react';
import { Box, Dialog, Tooltip } from '@primer/react';
import styled from 'styled-components';
import infobutton from '../../images/infobutton.svg';
import { sparkTypeValues } from '../../redux/models/colyseumSchemas/Spark';
import { getSparkExplanation, getSparkTitle } from '../../utils/sparks';
import { Bar } from '../../basicComponents/bar/Bar';
import { SparkButton } from '../../basicComponents/sparkButton/SparkButton';
import { SparkIcon } from '../../basicComponents/sparkIcon/SparkIcon';

const Text = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-top: 3px;
  padding-bottom: 18px;
`;

const ScrollContainer = styled('div')`
  height: 800px;
  overflow-y: scroll;
  padding: 30px;
`;

const Item = styled('div')`
  display: flex;
  flex-direction: row;
  padding-top: 18px;
  padding-bottom: 18px;
  border-top: 1px solid #999;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const Title = styled('div')`
  color: #3b147a;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 3px;
  padding-bottom: 3px;
`;

const Explanation = styled('div')`
  color: #3b147a;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3px;
`;

const SparkIconBox = styled('div')`
  max-height: 70px;
  max-width: 70px;
`;

export const SparkInfo = ({ playSparkCard }: { readonly playSparkCard: () => void }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Tooltip aria-label="Spark cards explained" direction="n">
        <img
          alt="Spark cards explained"
          onClick={() => setIsOpen(true)}
          src={infobutton}
          style={{
            cursor: 'pointer',
            marginTop: '5px',
            marginLeft: '15px',
            height: '30px',
          }}
        />
      </Tooltip>
      <Dialog
        aria-labelledby="label"
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        sx={{
          height: '80%',
          maxHeight: '628px',
          width: '450px',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        <div
          style={{
            height: '80px',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
            padding: '28px',
            display: 'flex',
            justifyContent: 'center',
            color: '#3B147A',
            textAlign: 'center',
            fontSize: '22px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
          }}
        >
          Spark cards explained
        </div>
        <ScrollContainer>
          <Text>
            Spark cards are how you interact with other players and influence the conversation.
          </Text>
          {sparkTypeValues.map((spark) => (
            <Item key={spark}>
              <SparkIconBox>
                <SparkIcon sparkType={spark} />
              </SparkIconBox>
              <TextContainer>
                <Title>{getSparkTitle(spark)}</Title>
                <Explanation>{getSparkExplanation(spark)}</Explanation>
              </TextContainer>
            </Item>
          ))}
          <Text style={{ borderTop: '1px solid #999', paddingTop: '20px' }}>
            When you give a spark card to another player, you’re giving them points.{' '}
            <b>Just watch out</b>– unused Spark cards at the end of the game will be subtracted from
            your final score.
          </Text>
        </ScrollContainer>

        <Bar />

        <Box display="flex" justifyContent="center" mt="auto" p={5}>
          <SparkButton
            onClick={() => {
              setIsOpen(false);
              playSparkCard();
            }}
          >
            Award Spark Card
          </SparkButton>
        </Box>
      </Dialog>
    </>
  );
};
