import styled from 'styled-components';
import { FunctionComponent, useState } from 'react';
import { Box, Checkbox, FormControl, Heading, Text } from '@primer/react';
import { Board } from '../../basicComponents/board/Board';
import { Layout } from '../../components/layout/Layout';
import { AvatarSettings, Settings } from './AvatarSettings';
import { DeckSelector } from '../../basicComponents/deckSelector/DeckSelector';
import { getDecksForStep } from '../../components/turn/stepsConfig';

const Form = styled('form')`
  margin: 0 1em 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    margin-left: 1em;
    margin-right: 1em;
  }
`;

const initalAvatarSettings: Settings[] = [
  { variant: 0, step: 0, show: true },
  { variant: 1, step: 0, show: true },
  { variant: 2, step: 0, show: true },
  { variant: 3, step: 0, show: true },
  { variant: 4, step: 7, show: true },
  { variant: 5, step: 7, show: true },
  { variant: 6, step: 10, show: true },
  { variant: 7, step: 14, show: true },
];

export const BoardExperimentPage: FunctionComponent = () => {
  const [showAvatars, setShowAvatars] = useState(true);
  const [showAvatarsSettings, setShowAvatarsSettings] = useState(true);
  const [avatarsSettings, setAvatarsSettings] = useState(initalAvatarSettings);
  const [turnAvatar, setTurnAvatar] = useState(0);

  return (
    <Layout>
      <Heading as="h1" sx={{ mb: 3 }}>
        Board Experiment
      </Heading>
      <Form>
        <Text sx={{ width: '100%' }} textAlign="center">
          Toggle visibility of elements on the board
        </Text>
        <FormControl>
          <Checkbox checked={showAvatars} onChange={(e) => setShowAvatars(e.target.checked)} />
          <FormControl.Label>Avatars</FormControl.Label>
        </FormControl>
        {showAvatars ? (
          <FormControl>
            <Checkbox
              checked={showAvatarsSettings}
              onChange={(e) => setShowAvatarsSettings(e.target.checked)}
            />
            <FormControl.Label>Step Settings</FormControl.Label>
          </FormControl>
        ) : null}
        {showAvatars && showAvatarsSettings ? (
          <>
            <Text sx={{ width: '100%', marginTop: '1em' }} textAlign="center">
              Avatars
            </Text>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              sx={{ maxWidth: '560px', '& > *': { mr: '1em', mb: '1em' } }}
            >
              {avatarsSettings.map((settings, index) => (
                <AvatarSettings
                  onChange={(newSettings) => {
                    const newAvatarsSettings = [...avatarsSettings];
                    newAvatarsSettings[index] = newSettings;
                    setAvatarsSettings(newAvatarsSettings);
                    setTurnAvatar(index);
                  }}
                  settings={settings}
                />
              ))}
            </Box>
          </>
        ) : null}
      </Form>
      <Board>
        <Board.Background />

        <Board.Middle>
          <DeckSelector
            decks={getDecksForStep(
              avatarsSettings.find((a) => a.variant === turnAvatar)?.step || 0
            )}
            onSelect={() => console.log('draw')}
          />
        </Board.Middle>
        {showAvatars
          ? avatarsSettings.map(({ step, variant }) => (
              <Board.Step
                count={avatarsSettings.reduce(
                  (count, item) => (step === item.step ? count + 1 : count),
                  0
                )}
                isTurn={turnAvatar === variant}
                key={variant}
                step={step}
              >
                <Board.Avatar variant={variant} />
              </Board.Step>
            ))
          : null}
      </Board>
    </Layout>
  );
};
