import React from 'react';
import styled from 'styled-components';
import testimonialIcon from '../../../images/pricingPage/testimonialIcon.jpeg';
import fiveStarsIcon from '../../../images/pricingPage/fiveStarsIcon.svg';

const Rating: React.FC = () => {
  return (
    <RatingWrapper>
      <RatingContent>
        <TestimonialSection>
          <TestimonialIcon src={testimonialIcon} />
          <TestimonialContent>
            <TestimonialQuote>
              "We had A LOT of laughs coupled with moments when people shared personal stories, showing vulnerability and openness. We learned so much about each other! Highly recommended to other teams."
            </TestimonialQuote>
            <TestimonialAuthor>
              Anna Brener – L&D Program Manager, Amazon
            </TestimonialAuthor>
          </TestimonialContent>
        </TestimonialSection>
        <TrustpilotSection>
          <TrustpilotScore>4.9</TrustpilotScore>
          <TrustpilotStars alt="Trustpilot rating" src={fiveStarsIcon} />
          <TrustpilotLabel>On Trustpilot</TrustpilotLabel>
        </TrustpilotSection>
      </RatingContent>
    </RatingWrapper>
  );
};

const RatingWrapper = styled('section')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: var(--White, #fff);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  margin: 100px auto;
  width: 70%;
  overflow: hidden;

  @media (max-width: 991px) {
    margin-top: 40px;
    width: 95%;
  }
`;

const RatingContent = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 60px 60px;
  align-items: center;

  @media (max-width: 991px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`;

const TestimonialSection = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 80%;

  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px; /* Adds gap below the testimonial section */
  }
`;

const TestimonialIcon = styled('img')`
  width: 130px;
  height: 130px;
  border-radius: 100px;

  @media (max-width: 991px) {
    margin-bottom: 20px; /* Adds space between icon and quote */
  }
`;

const TestimonialContent = styled('div')`
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    align-items: center; /* Centers the content horizontally */
  }
`;

const TestimonialQuote = styled('blockquote')`
  color: var(--Dark, #333);
  margin: 0;
  font: italic 700 20px 'Town 80 Text', sans-serif;

  @media (max-width: 991px) {
    text-align: center;
    font: italic 700 18px 'Town 80 Text', sans-serif;
    line-height: 1.3;
  }
`;

const TestimonialAuthor = styled('cite')`
  color: var(--Dark, #333);
  font: 350 18px 'Town 80 Text', sans-serif;
  margin-top: 20px;

  @media (max-width: 991px) {
    text-align: center;
    font: 350 16px 'Town 80 Text', sans-serif;
    line-height: 1.3;
  }
`;

const TrustpilotSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const TrustpilotScore = styled('div')`
  color: var(--Dark, #000);
  font: 700 42px 'Town 80 Text', sans-serif;
`;

const TrustpilotStars = styled('img')`
  width: 140px;
  margin-top: 10px;
`;

const TrustpilotLabel = styled('div')`
  color: var(--Text-Placeholder, #999);
  font: 500 18px 'Town 80 Text', sans-serif;
  letter-spacing: -0.25px;
  margin-top: 10px;
`;

export default Rating;
