import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { themeGet } from '@primer/react';
import { useSparks } from '../../hooks/useSparks';
import { SparkItem } from '../sparkItem/SparkItem';

const List = styled('ul')`
  list-style: none;
  width: 100%;
  color: ${themeGet('colors.spark.medium')};
  border: 2px dotted ${themeGet('colors.spark.medium')};
  border-radius: 10px;
  background-color: white;
  margin: 0px;
  padding: 0;
`;

const Item = styled('li')`
  margin: ${themeGet('space.1')};
`;

export const SparksList: FunctionComponent = () => {
  const { activeSparks } = useSparks();

  if (activeSparks.length === 0) {
    return null;
  }

  return (
    <List>
      {activeSparks.map((spark) => (
        <Item key={spark.id}>
          <SparkItem {...spark} />
        </Item>
      ))}
    </List>
  );
};
