import { Dialog } from '@primer/react';
import styled from 'styled-components';
import purpleStarLight from '../../images/purpleStarLight.svg';
import yellowStar from '../../images/yellowStar.svg';
import { colors } from '../../theme/theme';

const Text = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 18px;
  margin: 0 auto;
  width: 90%;
`;

const ScrollContainer = styled('div')`
  height: 800px;
  overflow-y: scroll;
  padding: 30px;
`;

const Item = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 18px;
  border-top: 1px solid #999;
`;

const Title = styled('div')`
  color: #3b147a;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 5px;
  padding-bottom: 2px;
  padding-left: 70px;
`;

const Explanation = styled('ul')`
  color: #3b147a;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3px;
  padding-left: 90px;
  padding-right: 30px;
  margin: 0;
`;

const TitleContainer = styled('div')`
  height: 60px;
`;

const Icon = styled('img')`
  min-width: 60px;
  width: 62px;
  height: 60px;
  border: 2.5px solid #896dff;
  border-radius: 50%;
  padding: 8px 6px;
  position: relative;
  top: -60px;
  background-color: white;
`;

const EditionTitle = styled('div')`
  height: 60px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  border-radius: 30px;
  color: #440d9b;
  background-color: #ede9ff;
  padding-left: 80px;
  padding-right: 60px;
  align-items: center;
  position: relative;
  font-size: 15px;
`;

export const EditionsDialog = ({ setIsOpen }: { readonly setIsOpen: Function }) => {
  return (
    <Dialog
      aria-labelledby="label"
      isOpen
      onDismiss={() => setIsOpen(false)}
      sx={{
        height: '80%',
        maxHeight: '628px',
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Editions of Cozy Juicy Real
      </div>
      <ScrollContainer>
        <Text>
          There are many editions of Cozy Juicy Real, each with a set of questions that create a
          unique vibe.{' '}
          <a
            href="https://go.cozyjuicyreal.com/example-questions"
            rel="noreferrer"
            style={{ color: '#3B147A', textDecoration: 'subscribed' }}
            target="_blank"
          >
            <b>Download this PDF</b>
          </a>{' '}
          for examples.
        </Text>

        <Item key="1">
          <TitleContainer>
            <EditionTitle>Available to Pro, Company and Enterprise plans</EditionTitle>
            <Icon src={purpleStarLight} />
          </TitleContainer>

          <TextContainer style={{ border: 'none' }}>
            <Title>Level 1</Title>
            <Explanation>
              <li>Classic team-building  </li>
              <li> For teams playing Cozy Juicy Real for the first time</li>
            </Explanation>
          </TextContainer>
          <TextContainer>
            <Title>Level 2</Title>
            <Explanation>
              <li>Classic team-building  </li>
              <li>For teams playing Cozy Juicy Real for the second time</li>
            </Explanation>
          </TextContainer>
          <TextContainer>
            <Title>Agile Mindset</Title>
            <Explanation>
              <li>For agile teams </li>
              <li>Helps teams explore and explain their Agile mindset</li>
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="2">
          <TitleContainer>
            <EditionTitle style={{ backgroundColor: '#FFF9D6', color: colors.juicy.darker }}>
              Available to Company and Enterprise plans + All Editions add-on
            </EditionTitle>
            <Icon src={yellowStar} style={{ borderColor: '#FF7300' }} />
          </TitleContainer>

          <TextContainer style={{ border: 'none' }}>
            <Title style={{ color: colors.juicy.darker }}>DEI / DIB</Title>
            <Explanation style={{ color: colors.juicy.darker }}>
              <li>
                A mix of team-building questions and questions to help teams explore diversity,
                inclusion and belonging
              </li>
            </Explanation>
          </TextContainer>
          <TextContainer>
            <Title style={{ color: colors.juicy.darker }}>Creativity</Title>
            <Explanation style={{ color: colors.juicy.darker }}>
              <li>
                A mix of team-building questions and questions to help teams explore their creative
                side
              </li>
            </Explanation>
          </TextContainer>
          <TextContainer>
            <Title style={{ color: colors.juicy.darker }}>Leadership</Title>
            <Explanation style={{ color: colors.juicy.darker }}>
              <li>For teams working in - or aspiring to - leadership positions</li>
            </Explanation>
          </TextContainer>
          <TextContainer>
            <Title style={{ color: colors.juicy.darker }}>Level 3</Title>
            <Explanation style={{ color: colors.juicy.darker }}>
              <li>Classic team-building</li>
              <li>For teams playing Cozy Juicy Real for the third time</li>
            </Explanation>
          </TextContainer>
          <TextContainer>
            <Title style={{ color: colors.juicy.darker }}>Spanish</Title>
            <Explanation style={{ color: colors.juicy.darker }}>
              <li>Classic team-building editions, translated into Spanish</li>
            </Explanation>
          </TextContainer>
          <TextContainer>
            <Title style={{ color: colors.juicy.darker }}>German</Title>
            <Explanation style={{ color: colors.juicy.darker }}>
              <li>Classic team-building editions, translated into German</li>
            </Explanation>
          </TextContainer>
          <TextContainer>
            <Title style={{ color: colors.juicy.darker }}>Greek</Title>
            <Explanation style={{ color: colors.juicy.darker }}>
              <li>Classic team-building editions, translated into Greek</li>
            </Explanation>
          </TextContainer>
        </Item>
      </ScrollContainer>
    </Dialog>
  );
};
