import { countryCodes } from '../basicComponents/profileForm/countryCodes';

export const validateEmail = (email: string | undefined) => {
  if (!email) return 'Your email cannot be empty';
  if (email.length > 60) return 'Your email is too long';
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return 'Your email is invalid';
  return;
};

export const validatePassword = (password: string) => {
  if (password == '') return;
  if (password.length < 8) return 'Keep going - your password has to be 8 characters or more';
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@\[\]\\^_`{|}~§°<>´])[A-Za-z\d@!"#$%&'()*+,-./:;<=>?@\[\]\\^_`{|}~§°<>´]{8,}$/;
  if (!passwordRegex.test(password))
    return 'Your password must contain at least one letter, one number and one special character';
  if (password.length > 100) return 'Your password is too long';
  return;
};

export const validateNonEmptyString = (
  string: string | undefined,
  fieldName: string,
  maxLength = 20
) => {
  if (!string || string == '') return `Your ${fieldName} cannot be empty`;
  if (string.length > maxLength)
    return `Your ${fieldName} cannot be longer than ${maxLength} characters`;
  return;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber === '') return 'Your phone number cannot be empty';
  if (!/^[0-9]+$/.test(phoneNumber)) return 'Phone number must contain only numbers';
  if (phoneNumber.length < 8) return 'Your phone number is too short';
  if (phoneNumber.length > 12) return 'Your phone number is too long';

  return;
};

export const validateCountry = (value: string | undefined) => {
  if (!value || !countryCodes.some((c) => c.text == value)) return 'Invalid country';
  return;
};
