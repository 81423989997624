import styled from 'styled-components';
import { FunctionComponent, useEffect, useState } from 'react';
import { Heading } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import { DeckSelector } from '../../basicComponents/deckSelector/DeckSelector';
import { Card as CardModel } from '../../redux/models/card.model';
import { Card } from '../../basicComponents/card/Card';
import { useGetAllCardsQuery } from '../../redux/apis/cards';
import { DeckType } from '../../redux/models/colyseumSchemas/Deck';

const CardList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 400px;
  width: 90%;
`;

export const CardExperimentPage: FunctionComponent = () => {
  const getAllCards = useGetAllCardsQuery(undefined);

  const [card, setCard] = useState<CardModel>({
    id: '1',
    points: 0,
    deck: 'cozy',
    text: '',
  });
  const [currentVariant, setCurrentVariant] = useState('cozy');
  const [index, setIndex] = useState({
    cozy: 0,
    juicy: 0,
    real: 0,
    random: 0,
    lightning: 0,
  });

  useEffect(() => {
    if (getAllCards.isSuccess) {
      setCard(getAllCards.data.cozy[0]);
    }
  }, [getAllCards.isSuccess]);

  if (!getAllCards.isSuccess) return <Layout />;

  const getCard = (variant: DeckType) => {
    if (currentVariant == variant) setIndex({ ...index, [variant]: index[variant] + 1 });
    setCard(getAllCards?.data[variant][index[variant] % getAllCards?.data[variant].length]);
    setCurrentVariant(variant);
  };

  return (
    <Layout>
      <Heading as="h1" sx={{ mb: 3 }}>
        Cards Experiment
      </Heading>

      <div style={{ width: '20%', display: 'flex', flexDirection: 'column' }}>
        <DeckSelector decks={['cozy', 'juicy', 'real', 'random']} onSelect={getCard} />
        <DeckSelector decks={['lightning']} onSelect={getCard} />
      </div>

      <CardList>
        <Card {...(card as any)}>{card.text}</Card>
      </CardList>
    </Layout>
  );
};
