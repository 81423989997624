import { Dialog } from '@primer/react';
import styled from 'styled-components';
import purpleStarLight from '../../images/purpleStarLight.svg';

const Text = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 18px;
`;

const ScrollContainer = styled('div')`
  height: 800px;
  overflow-y: scroll;
  padding: 30px;
`;

const Item = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 18px;
  padding-bottom: 18px;
  border-top: 1px solid #999;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const Title = styled('div')`
  color: #3b147a;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 1px;
  padding-bottom: 6px;
`;

const Explanation = styled('div')`
  color: #3b147a;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3px;
`;

const Icon = styled('img')`
  min-width: 60px;
  padding-right: 10px;
`;

export const TopTipsDialog = ({ setIsOpen }: { readonly setIsOpen: Function }) => {
  return (
    <Dialog
      aria-labelledby="label"
      isOpen
      onDismiss={() => setIsOpen(false)}
      sx={{
        height: '80%',
        maxHeight: '628px',
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Top 3 recommendations
      </div>
      <ScrollContainer>
        <Text>Before you start playing, check out our top recommendations below.</Text>

        <Item key="1">
          <Icon src={purpleStarLight} />

          <TextContainer>
            <Title>Choosing your video tool</Title>
            <Explanation>
              In order to see and hear the other players in your group, you’ll need to connect using
              your favorite video call app – for example – Zoom, Teams, Google Meet, Whatsapp etc.
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="2">
          <Icon src={purpleStarLight} />

          <TextContainer>
            <Title>Use your Spark Cards</Title>
            <Explanation>
              Spark Cards are part of the magic of Cozy Juicy Real. Plus, it's easier to win if you
              use them. Watch the game rules video to learn what they are.
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="3">
          <Icon src={purpleStarLight} />

          <TextContainer>
            <Title>It’s always ok to pass</Title>
            <Explanation>
              If you come across a question you don’t want to answer, that’s fine - just click the
              skip card button beneath the game board.
            </Explanation>
          </TextContainer>
        </Item>
      </ScrollContainer>
    </Dialog>
  );
};
