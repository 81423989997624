import { FunctionComponent, useEffect, useState } from 'react';
import { useDeleteEventMutation } from '../../redux/apis/rooms';
import { Room } from '../../redux/models/room.model';
import yellowStar from '../../images/yellowStar.svg';
import { ReactComponent as LinkIcon } from '../../images/linkicon.svg';
import deleteIcon from '../../images/deleteIcon.svg';
import styled from 'styled-components';
import { showModal } from '../../redux/slices/modal.slice';
import { useAppDispatch } from '../../redux/hooks';
import { ListRoomItem } from './ListRoomItem';
import { ENTER_GAME_PATH } from '../../utils/constants';
import { useClipboard } from '../../hooks/useClipboard';
import { CircleSlashIcon } from '@primer/octicons-react';
import { Dialog, Box, Button, Text } from '@primer/react';
import { useDeleteColyseusRoomMutation } from '../../redux/apis/colyseus';
import { useGetGameCodeQuery } from '../../redux/apis/auth.api';

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 55px;
  width: 100%;
  border-top: 1px solid #999;
  cursor: pointer;
`;

const ColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const Icon = styled('img')`
  width: 40px;
  color: #ff7300;
  padding-right: 10px;
`;

const LinkIconStyled = styled(LinkIcon)`
  padding-right: 10px;
`;

const RoomTitle = styled('div')`
  color: #992f20;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  height: 21px;
`;

const RoomSubTitle = styled('div')`
  color: #ff7300;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`;

const RoomsContainer = styled('div')`
  padding-left: 20px;
`;

export const ListEventItem: FunctionComponent<{
  readonly rooms: Room[];
  readonly eventName: string;
}> = ({ rooms, eventName }) => {
  const dispatch = useAppDispatch();
  const { copy } = useClipboard();
  const [deleteEvent, deleteEventMutation] = useDeleteEventMutation();
  const [deleteColyseusRoom] = useDeleteColyseusRoomMutation();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (deleteEventMutation.isSuccess) rooms.map((room) => deleteColyseusRoom(room.roomId));
  }, [deleteEventMutation.isSuccess]);

  const pattern = new RegExp('\\sevent$', 'i');

  const [fetchCodes, setFetchCodes] = useState(false);
  const getGameCodes = useGetGameCodeQuery(
    { roomIds: rooms.map((r) => r.roomId) },
    { skip: !fetchCodes }
  );

  useEffect(() => {
    if (getGameCodes.isSuccess && fetchCodes) {
      let links = '';
      let i = 1;
      for (const room of rooms.sort(sortAlphabetically)) {
        links +=
          `Room ${i} - ` +
          window.location.origin +
          ENTER_GAME_PATH.replace(
            ':code',
            (getGameCodes.data! as { [roomId: string]: string })[room.roomId]
          ) +
          '\n\n';
        i++;
      }
      copy(`${links}`);
      dispatch(showModal({ message: 'All links copied', type: 'success' }));
      setFetchCodes(false);
    }
  }, [getGameCodes.isSuccess, fetchCodes]);

  return (
    <>
      <div>
        <Container key={eventName}>
          <Icon src={yellowStar} />
          <ColumnContainer
            onClick={async () => {
              setIsOpen(!isOpen);
            }}
          >
            <RoomTitle>{`${eventName.trim().replace(pattern, '')} Event`}</RoomTitle>
            <RoomSubTitle>{`${rooms.length} rooms`}</RoomSubTitle>
          </ColumnContainer>
          <LinkIconStyled fill="#992F20" onClick={() => setFetchCodes(true)} width="62px" />
          <Icon onClick={() => setIsDeleteOpen(true)} src={deleteIcon} style={{ width: '30px' }} />
        </Container>

        {isOpen ? (
          <RoomsContainer>
            {rooms.sort(sortAlphabetically).map((room) => (
              <ListRoomItem isEvent key={room.id} room={room} />
            ))}
          </RoomsContainer>
        ) : null}
      </div>
      <Dialog
        aria-labelledby="label"
        isOpen={isDeleteOpen}
        onDismiss={() => setIsDeleteOpen(false)}
        sx={{ height: 'initial !important' }}
      >
        <Dialog.Header>
          <CircleSlashIcon />
        </Dialog.Header>
        <Box p={3}>
          <Text>Are you sure you want to delete the event {eventName}?</Text>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button onClick={() => setIsDeleteOpen(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsDeleteOpen(false);
                deleteEvent({ name: eventName });
              }}
              variant="primary"
            >
              Yes, delete event
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

const sortAlphabetically = (a: any, b: any) => {
  const aa = a.name.split(' ');
  const bb = b.name.split(' ');
  if (parseInt(aa[aa.length - 1]) < parseInt(bb[bb.length - 1])) {
    return -1;
  }
  if (parseInt(aa[aa.length - 1]) < parseInt(bb[bb.length - 1])) {
    return 1;
  }
  return 0;
};
