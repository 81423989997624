import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './common';
import { Events, Room } from '../models/room.model';
import { DeckType } from '../models/colyseumSchemas/Deck';
import { Card } from '../models/card.model';

export const roomsApi = createApi({
  reducerPath: 'roomsApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Rooms', 'Events'],
  endpoints: (builder) => ({
    getRooms: builder.query<Room[], undefined>({
      query: () => `/rooms`,
      providesTags: ['Rooms'],
    }),
    getRoom: builder.query<Room & { expansions: string[]; isCreator: boolean }, string>({
      query: (roomId) => `/rooms/${roomId}`,
    }),
    getRoomDecks: builder.query<Record<DeckType, Card[]>, string>({
      query: (roomId) => `/rooms/${roomId}/decks`,
    }),
    createRooms: builder.mutation<
      { roomId: string },
      { name: string; roomId: string; expansions?: string[] }
    >({
      query: (body) => ({
        url: `/rooms`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Rooms'],
    }),
    deleteRoom: builder.mutation<undefined, { id: string }>({
      query: (room) => ({
        url: `/rooms/${room.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Events', 'Rooms'],
    }),
    getEvents: builder.query<Events, undefined>({
      query: () => `/rooms/events`,
      providesTags: ['Events'],
    }),
    createEvent: builder.mutation<
      { roomId: string }[],
      { name: string; roomIds: string[]; expansions?: string[] }
    >({
      query: (body) => ({
        url: `/rooms/events`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Rooms', 'Events'],
    }),
    deleteEvent: builder.mutation<undefined, { name: string }>({
      query: (event) => ({
        url: `/rooms/events/${event.name}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Rooms', 'Events'],
    }),
  }),
});

export const roomsApiReducer = roomsApi.reducer;

export const {
  useGetRoomsQuery,
  useGetRoomQuery,
  useCreateRoomsMutation,
  useDeleteRoomMutation,
  useGetRoomDecksQuery,
  useGetEventsQuery,
  useCreateEventMutation,
  useDeleteEventMutation,
} = roomsApi;
