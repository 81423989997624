import styled from 'styled-components';
import { Box } from '@primer/react';

export const OverlayBox = styled(Box)`
  width: 100%;
  display: grid;
  grid-template: 1fr / 1fr;

  & * {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
`;
