import { FunctionComponent, useState, useRef } from 'react';
import { SparkButton } from '../sparkButton/SparkButton';
import { SparksDialog } from '../../components/sparksDialog/SparksDialog';
import styled from 'styled-components';
import { SparkInfo } from '../../components/infoDialogs/SparkInfo';

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Spark: FunctionComponent = () => {
  const [isSparkDialogVisible, setIsSparkDialogVisible] = useState(false);
  const returnFocusRef = useRef(null);

  return (
    <>
      <Container>
        <SparkButton
          forwardedRef={returnFocusRef}
          onClick={() => setIsSparkDialogVisible(true)}
          size="medium"
          type="button"
        >
          Play Spark card
        </SparkButton>
        <SparkInfo playSparkCard={() => setIsSparkDialogVisible(true)} />
      </Container>
      <SparksDialog
        isOpen={isSparkDialogVisible}
        onDismiss={() => setIsSparkDialogVisible(false)}
        returnFocusRef={returnFocusRef}
      />
    </>
  );
};
