import React from 'react';
import styled from 'styled-components';
import { CJRButton as BaseCJRButton } from '../../../basicComponents/cjrButton/cjrButton';

const BookACall: React.FC = () => {
  return (
    <BookACallContainer>
      <BookACallText>Deciding between Company & Enterprise?</BookACallText>
      <a
        href="https://cozyjuicyreal.trafft.com/booking?t=s&uuid=e2dee4b0-a878-464f-a5c8-152da38b93f1"
        rel="noopener noreferrer"
        target="_blank"
      >
        <CustomCJRButton variant="sparkSecondary">Book a call</CustomCJRButton>
      </a>
    </BookACallContainer>
  );
};

const BookACallContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px; /* Adjust spacing between text and button */
  margin-top: 60px;
  font-family: Town 80 Text, sans-serif;
  font-weight: 700;
  padding: 0px 100px;
  width: 100%; /* Ensure container stretches across the page */

  @media (max-width: 991px) {
    margin: 10px 5px 0 0;
    padding: 0 20px;
    flex-direction: column; /* Stack vertically on smaller screens */
    gap: 20px;
  }
`;

const BookACallText = styled('p')`
  color: var(--Dark, #333);
  font-size: 32px;
  line-height: 1.4;
  letter-spacing: -0.44px;
  @media (max-width: 991px) {
    max-width: 100%;
    text-align: center;
    padding: 30px 0px 0px 0px;
    margin-bottom: 0px;
  }
`;

const CustomCJRButton = styled(BaseCJRButton)`
  max-width: 20%;
  border-radius: 30px; /* Override styles */
  background-color: var(--Spark-Colors-Spark-4, #ede9ff); /* Custom background */
  color: var(--Spark-Colors-Spark-1, #440d9b); /* Custom text color */
  font-size: 18px;
  font-weight: 700;

  /* Styles for the <a> tag */
  text-decoration: none;
  display: inline-block;
  color: inherit;
`;

export default BookACall;
