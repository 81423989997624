import { FunctionComponent } from 'react';
import { DeckType } from '../../redux/models/colyseumSchemas/Deck';
import { useMessageSender } from '../../hooks/useMessageSender';
import { usePlayers } from '../../hooks/usePlayers';
import { getDecksForStep } from './stepsConfig';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { showModal } from '../../redux/slices/modal.slice';
import { getGameState } from '../../redux/slices/game.slice';
import { useGetRoomDecksQuery } from '../../redux/apis/rooms';
import { themeGet } from '@primer/react';
import styled from 'styled-components';
import { useCardImage } from '../../hooks/useCardImage';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 500px;
  width: 100%;
  height: 500px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  row-gap: 10px;
  padding-bottom: 40px;
`;

type StyledCardButtonProps = {
  deck: DeckType;
  isPlayerTurn?: boolean;
  backgroundImage?: string;
};

const StyledCardButton = styled('button')<StyledCardButtonProps>`
  width: 100%;
  max-width: 260px;
  flex: 1;
  --card-color: ${({ deck }) => themeGet(`colors.${deck}.dark`)};
  --card-outline-color: ${({ deck }) => themeGet(`colors.${deck}.light`)};

  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1em;
  border: 5px solid var(--card-color);
  box-shadow: ${themeGet('shadows.cardButton.shadow')};

  transition-property: transform, box-shadow;
  transition-duration: 0.3s;

  &:enabled {
    cursor: ${({ isPlayerTurn }) => (isPlayerTurn ? 'pointer' : 'auto')};

    &:hover,
    &:focus,
    &:active {
      transform: rotate(0) scale(${({ isPlayerTurn }) => (isPlayerTurn ? '1.05' : '1')})
        translateZ(0);
      box-shadow: ${themeGet('shadows.cardButton.focusShadow')};
    }

    &:focus-visible {
      outline: 3px solid var(--card-outline-color);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const Title = styled('img')<StyledCardButtonProps>`
  width: 60%;
`;

export const AfterRandom: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { turnPlayer, isPlayerTurn } = usePlayers();
  const { deckIndexes, randomCardPlayed, room } = useAppSelector(getGameState);
  const getRooms = useGetRoomDecksQuery(room!.id);
  const { drawCard, claimAllSparks } = useMessageSender();

  const backgroundImg_cozy = useCardImage('cozy', 'patternFull');
  const titleImg_cozy = useCardImage('cozy', 'title');
  const backgroundImg_juicy = useCardImage('juicy', 'patternFull');
  const titleImg_juicy = useCardImage('juicy', 'title');
  const backgroundImg_real = useCardImage('real', 'patternFull');
  const titleImg_real = useCardImage('real', 'title');

  const onDraw = (deck: DeckType) => {
    if (!isPlayerTurn)
      return dispatch(
        showModal({
          type: 'danger',
          message: `It's not your turn, it's ${turnPlayer?.nickname}'s turn`,
        })
      );
    claimAllSparks();
    drawCard({ deck });
  };

  const getSelectableDecks = (): DeckType[] => {
    if (randomCardPlayed) {
      const randomCards = getRooms.data?.random || [];
      const index = (deckIndexes?.random || 0) % randomCards.length;
      return (
        (randomCards[index].deckSelectionAfterRandom?.split('_') as DeckType[]) ?? [
          'cozy',
          'juicy',
          'real',
        ]
      );
    }
    return getDecksForStep(turnPlayer?.steps || 0);
  };

  return (
    <Container>
      <p>
        It’s still {isPlayerTurn ? 'your' : turnPlayer?.nickname + "'s"} turn!{' '}
        {isPlayerTurn ? 'Now choose a...' : null}
      </p>
      {getSelectableDecks().map((deck) => (
        <StyledCardButton
          backgroundImage={
            deck == ('cozy' as const)
              ? backgroundImg_cozy
              : deck == ('juicy' as const)
              ? backgroundImg_juicy
              : backgroundImg_real
          }
          deck={deck}
          isPlayerTurn={isPlayerTurn}
          key={deck}
          onClick={() => onDraw(deck)}
        >
          <Title
            deck={deck}
            src={
              deck == ('cozy' as const)
                ? titleImg_cozy
                : deck == ('juicy' as const)
                ? titleImg_juicy
                : titleImg_real
            }
          />
        </StyledCardButton>
      ))}
    </Container>
  );
};
