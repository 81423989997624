import { FunctionComponent } from 'react';
import styled from 'styled-components';
import background from '../../images/background.svg';

const BackgroundStyle = styled('img')<{ transparent?: boolean }>`
  min-width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  object-fit: cover;
  object-position: top;
  align-self: center;
  opacity: ${({ transparent }) => (transparent ? 0.15 : 1)};
`;

export const Background: FunctionComponent<{ readonly transparent?: boolean }> = ({
  transparent,
}) => <BackgroundStyle src={background} transparent={transparent} />;
