import { FunctionComponent } from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { CreateRoomPage } from './pages/createRoom/CreateRoomPage';
import { WelcomePage } from './pages/welcome/WelcomePage';
import { LobbyPage } from './pages/lobby/LobbyPage';
import { BoardExperimentPage } from './pages/experiments/BoardExperimentPage';
import { CardExperimentPage } from './pages/experiments/CardExperimentPage';
import { RegistrationPage } from './pages/registration/RegistrationPage';
import { useAppSelector } from './redux/hooks';
import { selectAuth } from './redux/slices/auth.slice';
import { RequireAuthOutlet } from './components/requiresAuth/RequireAuthOutlet';
import {
  LOGIN_PATH,
  REGISTRATION_PATH,
  CREATE_PATH,
  ROOMS_PATH,
  LOBBY_PATH,
  GAME_PATH,
  BOARD_PATH,
  CARD_PATH,
  PROFILE_PATH,
  GAME_FINISHED_PATH,
  RECOVER_PASSWORD_PATH,
  MY_GAMES_PATH,
  BILLING_PATH,
  PLANS_PATH,
  ADD_ONS_PATH,
  USER_MANAGEMENT_PATH,
  ON_BOARDING_PATH,
  MINI_GAME_CREATE_PATH,
  MINI_GAME_PATH,
  ENTER_GAME_PATH,
} from './utils/constants';
import { Room } from './components/room/Room';
import { RoomError } from './components/room/RoomError';
import { ProfilePage } from './pages/profile/ProfilePage';
import { GameFinishedPage } from './pages/game/GameFinishedPage';
import { ResetPasswordPage } from './pages/resetPassword/ResetPasswordPage';
import { env } from './env';
import { MyGamesPage } from './pages/myGames/MyGamesPage';
import { BillingStatusPage } from './pages/billingStatus/BillingStatusPage';
import { AddOnsPage } from './pages/addOns/addOnsPage';
import { GameStartedPage } from './pages/game/GameStartedPage';
import { UserManagementPage } from './pages/userManagement/UserManagementPage';
import { OnBoardingPage } from './pages/onBoarding/OnBoardingPage';
import { CreateMinigamePage } from './pages/createRoom/CreateMinigamePage';
import { MiniGamePage } from './pages/minigame/MiniGamePage';
import { EnterGamePage } from './pages/game/EnterGamePage';
import PricingMatrixPage from './pages/plansAndPricing/PricingMatrixPage';

export const App: FunctionComponent = () => {
  const { isLoggedIn, isGuest, isAuthLoaded } = useAppSelector(selectAuth);

  if (!isAuthLoaded) return null;

  return (
    <BrowserRouter>
      <Routes>
        {!isLoggedIn ? <Route element={<WelcomePage />} path={LOGIN_PATH} /> : null}

        <Route element={<RegistrationPage />} path={REGISTRATION_PATH} />
        <Route element={<ResetPasswordPage />} path={RECOVER_PASSWORD_PATH} />
        <Route element={<PricingMatrixPage />} path={PLANS_PATH} />

        <Route element={<RequireAuthOutlet isLoggedIn={isLoggedIn} />}>
          {!isGuest ? <Route element={<ProfilePage />} path={PROFILE_PATH} /> : null}
          {!isGuest ? <Route element={<OnBoardingPage />} path={ON_BOARDING_PATH} /> : null}
          {!isGuest ? <Route element={<BillingStatusPage />} path={BILLING_PATH} /> : null}
          {!isGuest ? <Route element={<MyGamesPage />} path={MY_GAMES_PATH} /> : null}
          {!isGuest ? <Route element={<CreateRoomPage />} path={CREATE_PATH} /> : null}
          {!isGuest ? (
            <Route element={<CreateMinigamePage />} path={MINI_GAME_CREATE_PATH} />
          ) : null}
          {!isGuest ? <Route element={<AddOnsPage />} path={ADD_ONS_PATH} /> : null}
          {!isGuest ? <Route element={<UserManagementPage />} path={USER_MANAGEMENT_PATH} /> : null}
        </Route>

        <Route element={<EnterGamePage />} path={ENTER_GAME_PATH} />
        <Route element={<MiniGamePage />} path={MINI_GAME_PATH} />

        <Route element={<Room />} errorElement={<RoomError />} path={ROOMS_PATH}>
          <Route element={<LobbyPage />} path={LOBBY_PATH} />
          <Route element={<RequireAuthOutlet isLoggedIn={isLoggedIn} />}>
            <Route element={<GameStartedPage />} path={GAME_PATH} />
            <Route element={<GameFinishedPage />} path={GAME_FINISHED_PATH} />
          </Route>
        </Route>

        {env.REACT_APP_ENV == 'development' ? (
          <>
            <Route element={<BoardExperimentPage />} path={BOARD_PATH} />
            <Route element={<CardExperimentPage />} path={CARD_PATH} />
          </>
        ) : null}

        <Route
          element={
            isLoggedIn ? (
              isGuest ? (
                <Navigate to={ROOMS_PATH} />
              ) : (
                <Navigate to={MY_GAMES_PATH} />
              )
            ) : (
              <Navigate to={LOGIN_PATH} />
            )
          }
          path="*"
        />
      </Routes>
    </BrowserRouter>
  );
};
