import { useAppSelector } from '../redux/hooks';
import { getDeckState } from '../redux/slices/deck.slice';
import { getGameState } from '../redux/slices/game.slice';
import { DeckType } from '../redux/models/colyseumSchemas/Deck';

export const useCard = () => {
  const { decks, status } = useAppSelector(getDeckState);
  const gameState = useAppSelector(getGameState);
  const deck = gameState.deck || 'cozy';
  const deckIndexes = gameState.deckIndexes;

  if (status === 'loaded') {
    const cards = decks[deck as DeckType] || [];
    const index = (deckIndexes?.[deck as DeckType] || 0) % cards.length;
    const card = cards[index];

    return { card, status };
  }
  return { status };
};
