import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Box, Button, Dialog, Text } from '@primer/react';
import { useDeleteUserMutation } from '../../redux/apis/user.api';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/slices/modal.slice';
import { AlertIcon } from '@primer/octicons-react';
import { buttonStyle, colors } from '../../theme/theme';
import { useLogout } from '../../hooks/useLogout';

export const DeleteAccountButton: FunctionComponent<{
  readonly button?: boolean;
}> = ({ button = true }) => {
  const dispatch = useDispatch();
  const logout = useLogout();

  const [deleteUser, deleteUserRes] = useDeleteUserMutation();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  useEffect(() => {
    if (deleteUserRes.isSuccess) {
      dispatch(showModal({ message: 'Account deleted', type: 'danger' }));
      logout();
    }
  }, [deleteUserRes]);

  return (
    <>
      {button ? (
        <Button
          onClick={() => setDeleteConfirmOpen(true)}
          size="medium"
          sx={{
            mt: 3,
            width: '100%',
            ...buttonStyle,
            borderColor: '#DB004F',
            backgroundColor: 'white',
            color: '#DB004F',
            marginRight: '7px',
            fontWeight: '100',
          }}
          type="button"
          variant="danger"
        >
          Delete Account
        </Button>
      ) : (
        <a
          onClick={() => setDeleteConfirmOpen(true)}
          style={{
            color: colors.real.medium,
            display: 'block',
            width: '100%',
            textDecoration: 'underline',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          Delete Account
        </a>
      )}

      <Dialog
        aria-labelledby="label"
        isOpen={deleteConfirmOpen}
        onDismiss={() => setDeleteConfirmOpen(false)}
        returnFocusRef={useRef(null)}
        sx={{ height: 'initial !important' }}
      >
        <Dialog.Header>
          <AlertIcon />
        </Dialog.Header>
        <Box p={4}>
          <Text id="label">
            Are you sure you want to delete your account? This will also cancel immediately any
            active subscription. This action is irreversible.
          </Text>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button onClick={() => setDeleteConfirmOpen(false)} sx={{ mr: 1, ...buttonStyle }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setDeleteConfirmOpen(false);
                deleteUser(undefined);
              }}
              sx={{ ...buttonStyle }}
              variant="danger"
            >
              Yes, delete account
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
