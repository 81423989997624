import React from 'react';
import styled from 'styled-components';
import featureIcon2 from '../../../images/pricingPage/featureIcon2.svg';

interface AddonFeatureProps {
  readonly text: string;
}

export const AddonFeature: React.FC<AddonFeatureProps> = ({ text }) => {
  return (
    <FeatureWrapper>
      <FeatureIcon src={featureIcon2} />
      <FeatureText>{text}</FeatureText>
    </FeatureWrapper>
  );
};

const FeatureWrapper = styled('li')`
  display: flex;
  gap: 12px;
  letter-spacing: 0.18px;
  margin-top: 16px;
`;

const FeatureIcon = styled('img')`
  width: 24px;
  aspect-ratio: 1;
  object-fit: contain;
  align-self: flex-start;
`;

const FeatureText = styled('span')`
  flex-grow: 1;
`;
