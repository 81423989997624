import { FunctionComponent, useState } from 'react';
import { Box, Button } from '@primer/react';
import { useCard } from '../../hooks/useCard';
import { useMessageSender } from '../../hooks/useMessageSender';
import { usePlayers } from '../../hooks/usePlayers';
import { Card, getPoints } from '../../basicComponents/card/Card';
import { SkipCardButton } from './SkipCardButton';
import { useAppSelector } from '../../redux/hooks';
import { getGameState } from '../../redux/slices/game.slice';
import { DeckType } from '../../redux/models/colyseumSchemas/Deck';
import { buttonStyle } from '../../theme/theme';
import { RandomDialog } from '../../basicComponents/radomDialog/RandomDialog';
import { RandomGuessDialog } from '../../basicComponents/radomDialog/RandomGuessDialog';

export const PlayPhase: FunctionComponent = () => {
  const { isPlayerTurn, nextPlayer, previousPlayer, turnPlayer } = usePlayers();
  const { status, card } = useCard();
  const { finishPlay } = useMessageSender();
  const [randomDialogOpen, setRandomDialogOpen] = useState(false);
  const [randomGuessDialogOpen, setRandomGuessDialogOpen] = useState(false);

  if (status !== 'loaded') {
    return <LoadingCard />;
  }

  const cardPoints = card.points || getPoints(card.deck);
  const cardSteps = card.steps || 0;

  const onClaimPoints = () => {
    (card.deck != 'random' && card.deck != 'lightning') ||
    (card.deck == 'random' && card.playerGetThePointsDirectly)
      ? finishPlay({
          pointsThisTurn: cardPoints,
          stepsThisTurn: cardSteps,
        })
      : (card.deck == 'random' && card.playerGetThePoints) || card.deck == 'lightning'
      ? setRandomGuessDialogOpen(true)
      : setRandomDialogOpen(true);
  };

  return (
    <>
      <Card {...card}>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: card?.text
              .replace(/\{\{CURRENT_PLAYER\}\}/g, `<b>${turnPlayer?.nickname ?? 'Current Player'}</b>`)
              .replace(/\{\{NEXT_PLAYER\}\}/g, `<b>${nextPlayer?.nickname ?? 'Next Player'}</b>`)
              .replace(/\{\{PREVIOUS_PLAYER\}\}/g, `<b>${previousPlayer?.nickname ?? 'Previous Player'}</b>`),
          }}
        />
      </Card>

      {isPlayerTurn ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 3,
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '14px',
            }}
          >
            <>
              <SkipCardButton />
              <p style={{ color: '#7A0032', fontWeight: 600, marginBottom: '8px' }}>OR</p>
              <Button
                onClick={onClaimPoints}
                sx={{ width: '40%', ...buttonStyle, minWidth: '165px' }}
                type="button"
                variant="primary"
              >
                {(card.deck != 'random' && card.deck != 'lightning') ||
                (card.deck == 'random' && card.playerGetThePointsDirectly)
                  ? `Finish Turn`
                  : (card.deck == 'random' && card.playerGetThePoints) || card.deck == 'lightning'
                  ? `Right or wrong?`
                  : `Award ${cardPoints} points`}
              </Button>
              <RandomDialog
                cardPoints={cardPoints}
                isOpen={randomDialogOpen}
                setIsOpen={setRandomDialogOpen}
              />
              <RandomGuessDialog
                cardPoints={cardPoints}
                isOpen={randomGuessDialogOpen}
                setIsOpen={setRandomGuessDialogOpen}
              />
            </>
          </Box>
        </>
      ) : null}
    </>
  );
};

const LoadingCard = () => {
  const { deck = 'cozy' } = useAppSelector(getGameState);

  return <Card deck={deck as DeckType} />;
};
