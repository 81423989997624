import { useState, useEffect } from 'react';
import { useAppSelector } from '../redux/hooks';
import { getDeckState } from '../redux/slices/deck.slice';
import { DeckType } from '../redux/models/colyseumSchemas/Deck';

const getCardImg = async (
  deck: DeckType,
  type: 'pattern' | 'back' | 'patternFull' | 'title',
  expansion?: string
) => {
  try {
    const module = await import(`../images/cards/${type}/${expansion ?? 'Taster'}_${deck}.svg`);
    return module;
  } catch {
    try {
      return await import(`../images/cards/${type}/Taster_${deck}.svg`);
    } catch {
      return '';
    }
  }
};

export const useCardImage = (
  deck: DeckType,
  type: 'pattern' | 'back' | 'patternFull' | 'title'
) => {
  const [backgroundImg, setBackgroundImg] = useState('');
  const decks = useAppSelector(getDeckState);

  useEffect(() => {
    getCardImg(deck, type, decks.expansion).then((img) => {
      setBackgroundImg(img.default);
    });
  }, [deck, decks, type]);

  return backgroundImg;
};
