import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { showModal } from './slices/modal.slice';
import { env } from '../env';
import { authSessionActions, authLocalActions } from './slices/auth.slice';
import { authApi } from './apis/auth.api';
import { cardsApi } from './apis/cards';
import { paymentApi } from './apis/payment';
import { roomsApi } from './apis/rooms';
import { userApi } from './apis/user.api';

export const errorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (action.meta?.arg?.endpointName == 'getSubscriptionStatus') return next(action);
  if (action.meta?.arg?.endpointName == 'getRoomState') return next(action);
  if (action.meta?.arg?.endpointName == 'getRoomState') return next(action);
  if (action.meta?.arg?.endpointName == 'deleteColyseusRoom') return next(action);
  if (action.meta?.arg?.endpointName == 'planNames') return next(action);

  if (isRejectedWithValue(action)) {
    const statusCode = action.payload.data?.statusCode;
    if (statusCode == 401) {
      api.dispatch(authApi.util.resetApiState());
      api.dispatch(userApi.util.resetApiState());
      api.dispatch(cardsApi.util.resetApiState());
      api.dispatch(roomsApi.util.resetApiState());
      api.dispatch(paymentApi.util.resetApiState());
      api.dispatch(authLocalActions.setAuth({}));
      api.dispatch(authSessionActions.setAuth({}));
    }
    let title = 'Alert';

    if (statusCode == 500) title = 'Internal Error';

    if (statusCode == 500) {
      console.log(action);
    }

    let message = action.payload.data?.error ? action.payload.data.error[0].message : null;
    if (!message) message = 'Ops, something went wrong!';

    if (env.REACT_APP_ENV != 'production')
      console.log(
        action.payload?.data?.error ? action.payload?.data?.error[0] : action.payload?.data
      );

    api.dispatch(showModal({ title, message, type: 'danger' }));
  }

  return next(action);
};
