import { FunctionComponent, useEffect, useState } from 'react';
import { Button, ButtonGroup, FormControl } from '@primer/react';
import { useClipboard } from '../../hooks/useClipboard';
import { buttonStyle, textInputStyle } from '../../theme/theme';
import { useGetGameCodeQuery } from '../../redux/apis/auth.api';
import { ENTER_GAME_PATH } from '../../utils/constants';
import { useParams } from 'react-router-dom';

export const ShareRoomLink: FunctionComponent = () => {
  const { copy } = useClipboard();
  const { roomId } = useParams();

  const getGameCode = useGetGameCodeQuery({ roomId }, { skip: !roomId || roomId == ':roomId' });
  const [link, setLink] = useState('');

  useEffect(() => {
    if (getGameCode.isSuccess) {
      setLink(window.location.origin + ENTER_GAME_PATH.replace(':code', getGameCode.data!.code));
    }
  }, [getGameCode.isSuccess]);

  return (
    <FormControl>
      <FormControl.Label htmlFor="share-link-input">Share room link</FormControl.Label>
      <ButtonGroup sx={{ mb: 3, display: 'flex', width: '100%' }}>
        <input
          id="share-link-input"
          readOnly
          style={{
            flex: 1,
            ...textInputStyle,
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            borderColor: '#3B147A',
            borderBottom: '4px solid #3B147A',
            borderRight: '0px',
            paddingLeft: '10px',
            fontSize: '16px',
            color: '#3B147A',
            width: '100%',
          }}
          value={`${link}`}
        />
        <Button
          onClick={() => copy(`${link}`)}
          sx={{
            ...buttonStyle,
            borderBottomWidth: '4px',
            borderLeftWidth: '2px',
            borderColor: '#FF7300',
            paddingTop: '8px',
            paddingBottom: '8px',
            height: '40px',
          }}
        >
          copy
        </Button>
      </ButtonGroup>
    </FormControl>
  );
};
