import React from 'react';
import styled from 'styled-components';

interface FeatureSectionProps {
  readonly title: string;
  readonly children: React.ReactNode;
}

const FeatureSection: React.FC<FeatureSectionProps> = ({ title, children }) => {
  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{title}</SectionTitle>
      </SectionHeader>
      {children}
    </SectionContainer>
  );
};

const SectionContainer = styled('section')`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const SectionHeader = styled('header')`
  background: #fff;
  min-height: 80px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SectionTitle = styled('h2')`
  color: #333;
  align-self: stretch;
  margin: auto 0;
  padding: 40px 10px 20px 30px;
  font: 700 24px 'Town 80 Text', sans-serif;
  border-top: 2px solid #e0dfe5;

  @media (max-width: 991px) {
    padding: 40px 10px 20px 20px;
  }
`;

export default FeatureSection;
