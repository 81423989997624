import { Box, Button, Dialog } from '@primer/react';
import { buttonStyle } from '../../theme/theme';
import { useMessageSender } from '../../hooks/useMessageSender';

export const RandomGuessDialog = ({
  setIsOpen,
  isOpen,
  cardPoints,
}: {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  cardPoints: number;
}) => {
  const { finishPlay } = useMessageSender();

  const onClaimPoints = (points: number) => {
    finishPlay({
      pointsThisTurn: points,
      stepsThisTurn: 0,
    });
  };

  return (
    <Dialog
      aria-labelledby="label"
      isOpen={isOpen}
      onDismiss={() => setIsOpen(false)}
      sx={{
        height: '80%',
        maxHeight: '240px',
        width: '400px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
      }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        How did you guess?
      </div>

      <Box alignItems="center" display="flex" flexDirection="column" p={5}>
        <Button
          onClick={() => {
            onClaimPoints(cardPoints);
            setIsOpen(false);
          }}
          sx={{ width: '40%', ...buttonStyle, minWidth: '200px', marginBottom: '20px' }}
          type="button"
          variant="primary"
        >
          I guessed right 😃
        </Button>
        <Button
          onClick={() => {
            onClaimPoints(0);
            setIsOpen(false);
          }}
          sx={{ width: '40%', ...buttonStyle, minWidth: '200px' }}
          type="button"
          variant="default"
        >
          I guessed wrong ☹️
        </Button>
      </Box>
    </Dialog>
  );
};
