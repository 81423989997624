import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './common';
import { Card } from '../models/card.model';
import { DeckType } from '../models/colyseumSchemas/Deck';
import { SparkType } from '../models/colyseumSchemas/Spark';

export const cardsApi = createApi({
  reducerPath: 'cardsApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Cards'],
  endpoints: (builder) => ({
    getAvailableExpansions: builder.query<
      {
        expansions: {
          name: string;
          subtext: string;
          disabled: boolean;
          sparkQuantities: Record<SparkType, number>;
        }[];
      },
      { miniGame?: boolean }
    >({
      query: (input) => `/cards/expansions` + (input.miniGame ? `?miniGame=true` : ''),
    }),
    getAllCards: builder.query<Record<DeckType, Card[]>, undefined>({
      query: () => `/cards`,
    }),
    getShuffledDecks: builder.query<Record<DeckType, Card[]>, string>({
      query: (expansion) => `/cards/shuffled?expansion=${expansion}`,
    }),
  }),
});

export const configApiReducer = cardsApi.reducer;

export const { useGetAvailableExpansionsQuery, useGetAllCardsQuery, useGetShuffledDecksQuery } =
  cardsApi;
