// Being used in the pricing matrix
export const addonData = [
  {
    title: 'One-off large events',
    price: { amount: '165', currency: '$' },
    contactUs: false,
    features: [
      'Add 5, 10, or 20 extra games',
      'Host events for 50 - 500 players',
      'Access all themed editions',
    ],
  },
  {
    title: 'Add a facilitator',
    price: { amount: '400', currency: '$' },
    contactUs: false,
    features: [
      'A skilled facilitator to host event',
      'Events for 20 - 500 players',
      'We take care of everything',
    ],
  },
  {
    title: 'Get a custom edition',
    contactUs: true,
    features: [
      'Custom to your company values',
      'All users get access to edition',
      'Created by the founders of CJR',
    ],
  },
];

export type AddonCardProps = {
  title: string;
  price: string;
  priceSuffix?: string;
  description: string;
  includes: string;
  features: { text: string; isBold?: boolean }[];
  showContactIcon?: boolean;
  buttonType?: 'buyNow' | 'bookCall';
};

// All addOns data, used for the billing page addOn cards
export const allAddonsData: Record<string, AddonCardProps> = {
  FIVE_GAME_ROOMS: {
    title: 'Five Extra Games',
    price: '$165',
    description: 'Add 5 extra game rooms to your plan. Valid for 1 month.',
    includes: 'Add to your plan:',
    features: [
      { text: 'Add an extra 5 game rooms', isBold: false },
      { text: 'Host an event for ~30 players', isBold: false },
      { text: 'Manage up to 5 rooms at a time', isBold: false },
      { text: 'Access to all editions', isBold: false },
    ],
  },
  TEN_GAME_ROOMS: {
    title: 'Ten Extra Games',
    price: '$260',
    description: 'Add 10 extra game rooms to your plan. Valid for 1 month.',
    includes: 'Add to your plan:',
    features: [
      { text: 'Add an extra 10 game rooms', isBold: false },
      { text: 'Host an event for ~60 players', isBold: false },
      { text: 'Manage up to 10 rooms at a time', isBold: false },
      { text: 'Access to all editions', isBold: false },
    ],
  },
  TWENTY_GAME_ROOMS:{
    title: 'Twenty Extra Games',
    price: '$380',
    description: 'Add 20 extra game rooms to your plan. Valid for 1 month.',
    includes: 'Add to your plan:',
    features: [
      { text: 'Add an extra 20 game rooms', isBold: false },
      { text: 'Host an event for ~120 players', isBold: false },
      { text: 'Manage up to 10 rooms at a time', isBold: false },
      { text: 'Access to all editions', isBold: false },
    ],
  },
  LARGE_GROUPS: {
    title: '12-Player Game Rooms',
    price: '$40',
    priceSuffix: '/month',
    description: 'Host up to 12 players in a single game room.',
    includes: 'Increase your plan:',
    features: [
      { text: 'Invite up to 12 players in every game', isBold: false },
    ],
  },
  ALL_EDITIONS: {
    title: 'All Question Editions',
    price: '$40',
    priceSuffix: '/month',
    description: 'Boost your toolkit with access to all editions of Cozy Juicy Real. Valid for 1 month.',
    includes: 'Add all editions to your plan:',
    features: [
      { text: 'Team Building Level 1', isBold: false },
      { text: 'Team Building Level 2', isBold: false },
      { text: 'Team Building Level 3', isBold: false },
      { text: 'Agile Mindset', isBold: false },
      { text: 'Diversity, Inclusivity & Belonging', isBold: false },
      { text: 'Leadership', isBold: false },
      { text: 'Introverts', isBold: false },
      { text: 'Networking', isBold: false },
      { text: 'Creativity', isBold: false },
      { text: 'Women Empowerment', isBold: false },
      { text: 'Holiday', isBold: false },
      { text: 'German', isBold: false },
      { text: 'Spanish', isBold: false },
    ],
  },
  CUSTOM_EDITION: {
    title: 'Custom Edition',
    price: '',
    priceSuffix: 'Contact us',
    description: 'Custom edition for your company.',
    includes: 'Includes:',
    features: [
      { text: 'Custom questions based on your company values', isBold: false },
    ],
    showContactIcon: true,
    buttonType: 'bookCall',
  },
  HOSTED_EVENTS: {
    title: 'Hosted Event',
    price: '',
    priceSuffix: 'Contact us',
    description: 'We\’ll take care of your event, so you can just show up and enjoy.',
    includes: 'What\’s included:',
    features: [
      { text: 'An event hosted by skilled facilitators', isBold: false },
      { text: '20 - 500 players divided into breakout rooms', isBold: false },
      { text: 'No preparation necessary', isBold: false },
      { text: 'All team members get to play', isBold: false },
    ],
    showContactIcon: true,
    buttonType: 'bookCall',
  },
  ALL_LANGUAGES: {
    title: 'Languages',
    price: '',
    priceSuffix: 'Contact us',
    description: 'Access to decks in other languages.',
    includes: 'What\’s included:',
    features: [
      { text: '', isBold: false },
    ],
    showContactIcon: true,
    buttonType: 'bookCall',
  },
};
