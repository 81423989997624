import { Heading, Text } from '@primer/react';

export type RoomNameProps = {
  name: string;
};

const pattern = new RegExp('\\sroom$', 'i');

export const RoomName = ({ name }: RoomNameProps) => {
  return (
    <Heading
      as="h1"
      data-testid="room-name-label"
      sx={{ fontSize: 3, textAlign: 'center', mb: 3, color: '#3B147A' }}
    >
      <Text>{name.trim().replace(pattern, '')}</Text>
      {name.trim().replace(pattern, '').includes('Room') ? '' : ' Room'}
    </Heading>
  );
};
