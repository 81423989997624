import { useDispatch } from 'react-redux';
import { useMessageSender } from './useMessageSender';
import { authApi } from '../redux/apis/auth.api';
import { cardsApi } from '../redux/apis/cards';
import { paymentApi } from '../redux/apis/payment';
import { roomsApi } from '../redux/apis/rooms';
import { userApi } from '../redux/apis/user.api';
import { authLocalActions, authSessionActions } from '../redux/slices/auth.slice';

export const useLogout = () => {
  const dispatch = useDispatch();
  const { leaveGame } = useMessageSender();

  return () => {
    dispatch(authApi.util.resetApiState());
    dispatch(userApi.util.resetApiState());
    dispatch(cardsApi.util.resetApiState());
    dispatch(roomsApi.util.resetApiState());
    dispatch(paymentApi.util.resetApiState());
    dispatch(authLocalActions.setAuth({}));
    dispatch(authSessionActions.setAuth({}));
    leaveGame();
  };
};
