import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getMenu, setMenu } from '../../redux/slices/menu.slice';
import { stopConfettiInner } from '../../components/confetti/confetti';
import { VideoOverlay } from '../videoOverlay/VideoOverlay';

export const HowToPlayOverlay = () => {
  stopConfettiInner();
  const menu = useAppSelector(getMenu);
  const dispatch = useAppDispatch();

  return menu == 'howToPlay' ? (
    <VideoOverlay
      setOpen={() => dispatch(setMenu(undefined))}
      title="Game rules"
      videoId={1006273486}
    />
  ) : null;
};
