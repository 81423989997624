import styled from 'styled-components';
import { SparkType } from '../../redux/models/colyseumSchemas/Spark';
import { getSparkIcon } from '../../utils/sparks';

const Img = styled('img')`
  width: 100%;
`;

export type SparkImgProps = {
  sparkType: SparkType;
};

export const SparkIcon = ({ sparkType }: SparkImgProps) => (
  <Img alt={`${sparkType} card`} src={getSparkIcon(sparkType)} />
);
