import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeckType } from '../models/colyseumSchemas/Deck';
import { Card } from '../models/card.model';
import { RootState } from '../store';

export type CardsStoreStatus = 'initial' | 'loaded' | 'error';

type DecksState = {
  status: CardsStoreStatus;
  decks: Record<DeckType, Card[]>;
  expansion?: string;
};

function setDecks(state: DecksState, action: PayloadAction<Record<DeckType, Card[]>>) {
  state.decks = action.payload;
}

function setStatus(state: DecksState, action: PayloadAction<CardsStoreStatus>) {
  state.status = action.payload;
}

function setExpansion(state: DecksState, action: PayloadAction<string>) {
  state.expansion = action.payload;
}

export const deckSlice = createSlice({
  name: 'deck',
  initialState: {
    status: 'initial' as const,
    decks: {
      cozy: [],
      juicy: [],
      real: [],
      random: [],
      lightning: [],
    } as Record<DeckType, Card[]>,
  },
  reducers: { setDecks, setStatus, setExpansion },
});

export const getDeckState = (state: RootState): DecksState => state.deck;
export const deckActions = deckSlice.actions;
