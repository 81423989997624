import { getSparksArray } from '../utils/sparks';
import { usePlayers } from './usePlayers';
import { useAppSelector } from '../redux/hooks';
import { getGameState } from '../redux/slices/game.slice';

export const useSparks = () => {
  const { youPlayer } = usePlayers();
  const gameState = useAppSelector(getGameState);
  const sparks = getSparksArray(gameState.sparks);

  const youPlayerInitialSparks = sparks.filter(
    (spark) => spark.fromPlayerId === youPlayer?.id && spark.toPlayerId === undefined
  );

  const activeSparks = sparks.filter(
    (spark) => spark.phase === 'active' //&& spark.toPlayerId === turnPlayer?.id
  );

  const sparkToBeAccepted = sparks.find(
    (s) => s.phase == 'pending' && s.toPlayerId == youPlayer?.id
  );

  return { sparks, youPlayerInitialSparks, activeSparks, sparkToBeAccepted };
};
