import { CSSProperties } from 'react';

export const style: { [key: string]: CSSProperties } = {
  container1: {
    position: 'fixed',
    width: '100%',
    boxSizing: 'border-box',
    height: 124,
    bottom: 75,
    alignSelf: 'center',
    zIndex: 1000,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    animation: 'modal 0.4s',
  },
  container: {
    position: 'relative',
    width: '400px',
    maxWidth: '80%',
    boxSizing: 'border-box',
    left: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    right: 'auto',
    alignSelf: 'center',
    zIndex: 100,
  },
  messageFont: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: 16,
    color: 'grey',
    lineHeight: '24px',
    letterSpacing: '4%',
    marginTop: 10,
    verticalAlign: 'middle',
  },
  titleFont: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: 16,
    color: 'blue',
    lineHeight: '24px',
    letterSpacing: '4%',
    marginTop: 0,
    marginBottom: 0,
    display: 'inline-block',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
  },
};

export const animation: Keyframe = {};
