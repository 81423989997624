import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogProps, FormControl } from '@primer/react';
import { useSparks } from '../../hooks/useSparks';
import { useMessageSender } from '../../hooks/useMessageSender';
import { usePlayers } from '../../hooks/usePlayers';
import { exhaustiveCheck } from '../../utils/exhaustiveCheck';
import { SparkSelector } from '../../basicComponents/sparkSelector/SparkSelector';
import { PlayerSelector } from '../../basicComponents/playerSelector/PlayerSelector';
import { buttonStyle } from '../../theme/theme';
import styled from 'styled-components';
import { Bar } from '../../basicComponents/bar/Bar';

const ScrollContainer = styled('div')`
  overflow-y: auto;
  padding: 10px 30px 30px 30px;
`;

export type SparkDialogProps = Pick<DialogProps, 'onDismiss' | 'isOpen' | 'returnFocusRef'>;

export const SparksDialog = ({ onDismiss, isOpen, returnFocusRef }: SparkDialogProps) => {
  const [selectedSparkId, setSelectedSparkId] = useState('');
  const [msgSpark, setMsgSpark] = useState('');
  const [selectedPlayerId, setSelectedPlayerId] = useState('');
  const [msgPlayer, setMsgPlayer] = useState('');
  const { youPlayerInitialSparks } = useSparks();
  const { turnPlayer, youPlayer, players } = usePlayers();
  const { playSparkCard } = useMessageSender();

  useEffect(() => {
    setSelectedSparkId('');
    setSelectedPlayerId('');
  }, [isOpen]);

  useEffect(() => {
    if (selectedSparkId != '') setMsgSpark('');
    if (selectedSparkId == youPlayerInitialSparks.find((x) => x.sparkType == 'aboutme')?.id) {
      setSelectedPlayerId(youPlayer?.id || '');
    } else if (
      selectedSparkId == youPlayerInitialSparks.find((x) => x.sparkType == 'aboutyou')?.id
    ) {
      const filtered = players.filter((p) => !p.isCurrentPlayer);
      setSelectedPlayerId(
        filtered.length >= 1 ? filtered[Math.floor(Math.random() * (players.length - 1))].id : ''
      );
    } else {
      setSelectedPlayerId(turnPlayer?.id || '');
    }
  }, [selectedSparkId]);

  useEffect(() => {
    if (selectedPlayerId != '') setMsgPlayer('');
  }, [selectedPlayerId]);

  const onPlaySparkCard = () => {
    const spark = youPlayerInitialSparks.find((spark) => spark.id === selectedSparkId);
    if (!spark) return setMsgSpark('You must select a Spark card');
    if (
      spark.sparkType != 'aboutme' &&
      (selectedPlayerId == '' ||
        !players
          .filter((p) => !p.isCurrentPlayer)
          .map((x) => x.id)
          .includes(selectedPlayerId))
    )
      return setMsgPlayer('You must select a Player');

    switch (spark.sparkType) {
      case 'aboutme':
        playSparkCard({
          sparkId: spark.id,
          toPlayerId: youPlayer?.id || '',
        });
        onDismiss?.();
        break;
      case 'aboutyou':
      case 'godeeper':
      case 'courage':
      case 'highfive':
      case 'hug':
        playSparkCard({
          sparkId: spark.id,
          toPlayerId: selectedPlayerId,
        });
        onDismiss?.();
        break;
      default:
        exhaustiveCheck(spark.sparkType);
    }
  };

  return (
    <Dialog
      aria-labelledby="label"
      isOpen={isOpen}
      onDismiss={onDismiss}
      returnFocusRef={returnFocusRef}
      sx={{
        height: '80%',
        maxHeight: '620px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Award a Spark card
      </div>
      <ScrollContainer>
        <FormControl sx={{ display: 'flex', alignItems: 'left' }}>
          <FormControl.Label sx={{ alignSelf: 'left', fontSize: '16px' }}>
            Choose a Spark card
          </FormControl.Label>
          <SparkSelector
            onChange={(sparkId) => setSelectedSparkId(sparkId)}
            sparks={youPlayerInitialSparks}
            value={selectedSparkId}
          />

          {msgSpark ? (
            <FormControl.Validation id="input-validation" variant="error">
              {msgSpark}
            </FormControl.Validation>
          ) : null}
        </FormControl>
        {selectedSparkId &&
        selectedSparkId != youPlayerInitialSparks.find((x) => x.sparkType == 'aboutme')?.id ? (
          <FormControl sx={{ display: 'flex', alignItems: 'left', width: '100%', mt: 3 }}>
            <FormControl.Label sx={{ alignSelf: 'left', fontSize: '16px' }}>
              Choose a player
            </FormControl.Label>

            <PlayerSelector
              onChange={(playerId) => setSelectedPlayerId(playerId)}
              players={players.filter((p) => !p.isCurrentPlayer)}
              value={selectedPlayerId}
            />

            {msgPlayer ? (
              <FormControl.Validation id="input-validation" variant="error">
                {msgPlayer}
              </FormControl.Validation>
            ) : null}
          </FormControl>
        ) : null}
      </ScrollContainer>

      <Bar />

      <Box display="flex" justifyContent="center" mt="auto" padding="16px 32px 32px 32px">
        <Button
          onClick={onPlaySparkCard}
          size="medium"
          sx={{ mt: 3, width: '200px', ...buttonStyle, marginLeft: '7px' }}
          type="button"
          variant="primary"
        >
          Award Spark Card
        </Button>
      </Box>
    </Dialog>
  );
};
