import { FunctionComponent, useEffect, useState } from 'react';
import { DeckType } from '../../redux/models/colyseumSchemas/Deck';
import { useMessageSender } from '../../hooks/useMessageSender';
import { usePlayers } from '../../hooks/usePlayers';
import { DeckSelector } from '../../basicComponents/deckSelector/DeckSelector';
import { GameBoard } from '../gameBoard/GameBoard';
import { getDecksForStep } from './stepsConfig';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { showModal } from '../../redux/slices/modal.slice';
import { getGameState } from '../../redux/slices/game.slice';
import { useGetRoomDecksQuery } from '../../redux/apis/rooms';
import { Text } from '@primer/react';

export const DrawPhase: FunctionComponent = () => {
  const { turnPlayer, isPlayerTurn } = usePlayers();
  const dispatch = useAppDispatch();
  const { deckIndexes, randomCardPlayed, room, playersArr, deck } = useAppSelector(getGameState);
  const [showLightningMsg, setShowLightningMsg] = useState(false);
  const [lightningMsgShown, setLightningMsgShown] = useState(false);
  const getRooms = useGetRoomDecksQuery(room!.id);
  const { drawCard, claimAllSparks } = useMessageSender();

  useEffect(() => {
    if (playersArr.find((x) => x.steps >= 17) && deck !== 'lightning' && !lightningMsgShown) {
      setShowLightningMsg(true);
      setLightningMsgShown(true);
      setTimeout(() => setShowLightningMsg(false), 10000);
    }
  }, [playersArr]);

  const onDraw = (deck: DeckType) => {
    if (!isPlayerTurn)
      return dispatch(
        showModal({
          type: 'danger',
          message: `It's not your turn, it's ${turnPlayer?.nickname}'s turn`,
        })
      );
    claimAllSparks();
    drawCard({ deck });
  };

  const getSelectableDecks = (): DeckType[] => {
    if (randomCardPlayed) {
      const randomCards = getRooms.data?.random || [];
      const index = (deckIndexes?.random || 0) % randomCards.length;
      return (
        (randomCards[index].deckSelectionAfterRandom?.split('_') as DeckType[]) ?? [
          'cozy',
          'juicy',
          'real',
        ]
      );
    }
    return getDecksForStep(turnPlayer?.steps || 0);
  };

  return (
    <GameBoard>
      {showLightningMsg ? (
        <Text
          as="p"
          sx={{
            maxWidth: '250px',
            width: '80%',
            borderRadius: '15px',
            border: '2px dashed #009B88',
            background: '#FFF',
          }}
          textAlign="center"
        >
          You’ve made it to the Lightning round – each player takes a turn drawing a Lightning card,
          then the game ends!
        </Text>
      ) : (
        <DeckSelector decks={getSelectableDecks()} onSelect={onDraw} />
      )}
    </GameBoard>
  );
};
