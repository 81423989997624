import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Dialog, Text, Tooltip } from '@primer/react';
import { colors } from '../../theme/theme';
import profileIcon from '../../images/profile.svg';
import profileBlackIcon from '../../images/profileblack.svg';
import { isMobile } from 'react-device-detect';
import deleteIcon from '../../images/deleteIcon.svg';
import deleteIconDisabled from '../../images/deleteIconDisabled.svg';
import refreshIcon from '../../images/refresh.svg';
import { useDeleteChildMutation, useResendInvitationMutation } from '../../redux/apis/user.api';
import { useAppDispatch } from '../../redux/hooks';
import {
  closeLoading,
  setManualLoading,
  showContactForm,
  showLoading,
  showModal,
} from '../../redux/slices/modal.slice';
import { useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { usePlanNamesQuery } from '../../redux/apis/config';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';

export const UserListItem: FunctionComponent<{
  readonly userName: string;
  readonly firstName: string;
  readonly parentAddedAtSeconds?: number;
  readonly email: string;
  readonly isOwner: boolean;
  readonly isPending: boolean;
}> = ({ userName, email, parentAddedAtSeconds, isOwner, isPending, firstName }) => {
  const dispatch = useAppDispatch();
  const [deleteChild, deletedChild] = useDeleteChildMutation();
  const [resendEmail, sentEmail] = useResendInvitationMutation();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isCantDeleteOpen, setIsCantDeleteOpen] = useState(false);
  const sub = useGetSubscriptionStatusQuery(undefined);
  const planNames = usePlanNamesQuery(undefined);
  const [mainPlan, setMainPlan] = useState('');

  useEffect(() => {
    if (deletedChild.isSuccess || deletedChild.isError) {
      dispatch(closeLoading());
    }
    if (deletedChild.isSuccess) {
      dispatch(
        showModal({
          type: 'success',
          message: 'User removed successfully',
        })
      );
    }
  }, [deletedChild]);

  useEffect(() => {
    if (sentEmail.isSuccess || sentEmail.isError) {
      dispatch(closeLoading());
    }
    if (sentEmail.isSuccess) {
      dispatch(
        showModal({
          type: 'success',
          message: 'Invitation resent successfully',
        })
      );
    }
  }, [sentEmail]);

  useEffect(() => {
    if (sub.isSuccess && planNames.isSuccess) {
      const main = sub.data!.find(
        (p) =>
          !p.isTemporaryAddOnSubscription &&
          p.allowChildren &&
          p.isSubscriptionActive &&
          !p.fromParent
      )!;
      if (main) setMainPlan(planNames.data![main.plan]);
    }
  }, [sub, planNames]);

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="18px">
        <Box
          backgroundColor={isPending ? '#eeeeee' : colors.spark.muted}
          borderRadius="30px"
          display="flex"
          flexDirection="row"
          height="60px"
          justifyContent="space-between"
          paddingRight="5px"
          width="100%"
        >
          <img
            src={isPending ? profileBlackIcon : profileIcon}
            style={{
              position: 'relative',
              top: '-5px',
              left: '-5px',
              height: '70px',
              width: '70px',
            }}
          />
          <Box
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            marginLeft="4px"
          >
            <Tooltip aria-label={userName} direction="n">
              <Text
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  fontWeight: '600',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  color: isPending ? '#000000' : 'unset',
                  //maxWidth: '190px',
                }}
              >
                {userName.length > 15 ? `${userName.slice(0, 13)}...` : userName}
              </Text>
            </Tooltip>
            {isOwner ? (
              <Text style={{ fontSize: '14px', textAlign: 'center', fontStyle: 'italic' }}>
                You (owner)
              </Text>
            ) : null}
            {isPending ? (
              <Text
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  fontStyle: 'italic',
                  color: '#000000',
                }}
              >
                Pending...
              </Text>
            ) : null}
          </Box>
          {!isMobile ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              marginLeft="auto"
              marginRight="10px"
            >
              <Tooltip aria-label={email} direction="n">
                <Text
                  style={{
                    fontSize: '14px',
                    color: isPending ? '#000000' : colors.spark.btn,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {email.length > 20 ? `${email.slice(0, 18)}...` : email}
                </Text>
              </Tooltip>
            </Box>
          ) : (
            <div style={{ marginLeft: 'auto' }} />
          )}
          {isPending ? (
            <Tooltip aria-label="Resend invitation" direction="n">
              <Box
                alignItems="center"
                backgroundColor="#FFFFFF"
                borderRadius="25px"
                boxShadow="0px 0px 10px 0px #dddd"
                display="flex"
                flexDirection="column"
                height="50px"
                justifyContent="center"
                marginTop="5px"
                onClick={() => {
                  dispatch(setManualLoading(true));
                  dispatch(showLoading());
                  resendEmail({ childEmail: email });
                }}
                style={{ cursor: 'pointer' }}
                width="50px"
              >
                <img src={refreshIcon} style={{ width: '30px' }} />
              </Box>
            </Tooltip>
          ) : null}
        </Box>
        <Tooltip aria-label={isOwner ? 'Cannot delete account holder' : 'Remove'} direction="n">
          <Box
            alignItems="center"
            borderRadius="30px"
            boxShadow="0px 0px 10px 0px #dddd"
            display="flex"
            flexDirection="column"
            height="60px"
            justifyContent="center"
            marginLeft="10px"
            onClick={() => {
              if (isOwner) return;
              if (
                parentAddedAtSeconds &&
                (parentAddedAtSeconds + 45 * 24 * 60 * 60) * 1000 > new Date().getTime()
              ) {
                return setIsCantDeleteOpen(true);
              }
              setIsDeleteOpen(true);
            }}
            style={{ cursor: isOwner ? 'not-allowed' : 'pointer' }}
            width="60px"
          >
            <img src={isOwner ? deleteIconDisabled : deleteIcon} style={{ width: '30px' }} />
          </Box>
        </Tooltip>
      </Box>

      <Dialog
        aria-labelledby="label"
        isOpen={isDeleteOpen}
        onDismiss={() => setIsDeleteOpen(false)}
        sx={{ height: 'initial !important' }}
      >
        <div
          style={{
            height: '80px',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
            padding: '28px',
            display: 'flex',
            justifyContent: 'center',
            color: '#3B147A',
            textAlign: 'center',
            fontSize: '22px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
          }}
        >
          Remove {firstName}?
        </div>
        <Box p={4}>
          <Box p={3}>Are you sure you want to remove {firstName}?</Box>
          <Box p={3}>
            If you click ‘Remove member’, {firstName} will lose access to all {mainPlan} plan
            features and be transferred to the free Taster plan.
          </Box>
          <Box p={3}>
            You can of course re-invite {firstName} at any time (providing you have an available
            spot). 😉
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            p={4}
            style={{
              gap: '15px',
              boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
            }}
          >
            <CJRButton
              onClick={() => setIsDeleteOpen(false)}
              style={{ mr: 1 }}
              variant="juicyTertiary"
            >
              Cancel
            </CJRButton>
            <CJRButton
              onClick={() => {
                setIsDeleteOpen(false);
                dispatch(setManualLoading(true));
                dispatch(showLoading());
                deleteChild({ childEmail: email });
              }}
              variant="juicyPrimary"
            >
              Remove {firstName}
            </CJRButton>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        aria-labelledby="label"
        isOpen={isCantDeleteOpen}
        onDismiss={() => setIsCantDeleteOpen(false)}
        sx={{ height: 'initial !important' }}
      >
        <div
          style={{
            height: '80px',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
            padding: '28px',
            display: 'flex',
            justifyContent: 'center',
            color: '#3B147A',
            textAlign: 'center',
            fontSize: '22px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
          }}
        >
          Oops! Too soon...
        </div>
        <Box p={4}>
          <Box p={3}>
            After a member accepts their invitation, the earliest they can be removed is 45 days
            later.
          </Box>
          <Box p={3}>
            Since {firstName} accepted their invitation on{' '}
            {new Date((parentAddedAtSeconds ?? 0) * 1000).toLocaleDateString()}, you’ll be able to
            remove them in{' '}
            {Math.ceil(
              (45 * 24 * 60 * 60 * 1000 -
                new Date().getTime() +
                (parentAddedAtSeconds ?? 0) * 1000) /
                (1000 * 60 * 60 * 24)
            )}{' '}
            day(s).
          </Box>
          <Box p={3}>
            If {firstName} was invited by mistake, please contact us and we’ll review your account –
            we may be able to remove {firstName} manually.
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            p={4}
            style={{
              gap: '15px',
              boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
            }}
          >
            <CJRButton
              onClick={() => setIsCantDeleteOpen(false)}
              style={{ mr: 1 }}
              variant="juicyTertiary"
            >
              Cancel
            </CJRButton>
            <CJRButton
              onClick={() => {
                setIsCantDeleteOpen(false);
                dispatch(showContactForm());
              }}
              variant="juicyPrimary"
            >
              Contact us
            </CJRButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
