import { Dispatch, FunctionComponent, SetStateAction, useEffect, useRef, useState } from 'react';
import { ActionList, ActionMenu, Box, Dialog, FormControl } from '@primer/react';
import { useAppDispatch } from '../../redux/hooks';
import {
  SubscriptionStatus,
  useGetPriceChangeQuery,
  useGetSubscriptionStatusQuery,
  useUpgradeSubscriptionMutation,
} from '../../redux/apis/payment';
import { usePlanNamesQuery } from '../../redux/apis/config';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';
import { TriangleDownIcon } from '@primer/octicons-react';
import { colors, textInputStyle } from '../../theme/theme';
import { useGetChildrenQuery } from '../../redux/apis/user.api';
import { showModal } from '../../redux/slices/modal.slice';

export const ChangeTeamSizeDialog: FunctionComponent<{
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();

  const sub = useGetSubscriptionStatusQuery(undefined);
  const planNames = usePlanNamesQuery(undefined);
  const [licenses, setLicenses] = useState(4);
  const [openQty, setOpenQty] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [mainSub, setMainSub] = useState<SubscriptionStatus>({
    plan: 'FREE',
    isSubscriptionActive: false,
    isTemporaryAddOnSubscription: false,
    allowChildren: false,
    allowedAddOns: [],
    playedThisMonth: 0,
    maxNoOfChildren: 0,
    endOfCurrentBillingPeriod: 0,
    fromParent: false,
    paymentCard: '',
  });
  const currentPrice = useGetPriceChangeQuery(
    { plan: mainSub.plan },
    { skip: mainSub.plan == 'FREE', refetchOnMountOrArgChange: true }
  );
  const newPrice = useGetPriceChangeQuery(
    { plan: mainSub.plan, newQuantity: licenses },
    { skip: mainSub.plan == 'FREE', refetchOnMountOrArgChange: true }
  );
  const [upgrade, upgradedSub] = useUpgradeSubscriptionMutation();
  const getKids = useGetChildrenQuery(undefined);

  useEffect(() => {
    if (upgradedSub.isSuccess) {
      dispatch(showModal({ type: 'success', message: 'Team size updated successfully' }));
      getKids.refetch();
    }
  }, [upgradedSub]);

  useEffect(() => {
    if (sub.isSuccess && planNames.isSuccess) {
      const main = sub.data!.find(
        (p) =>
          !p.isTemporaryAddOnSubscription &&
          p.allowChildren &&
          p.isSubscriptionActive &&
          !p.fromParent
      )!;
      if (main) {
        setMainSub(main);
        setLicenses(Math.max(main.maxNoOfChildren + 2, 4));
      }
    }
  }, [sub, planNames]);

  return (
    <Dialog
      aria-labelledby="label"
      isOpen={isOpen}
      onDismiss={() => setIsOpen(false)}
      sx={{ height: 'initial !important' }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Change team size
      </div>
      <Box p={4}>
        <FormControl sx={{ mb: 3 }}>
          <FormControl.Label>Licenses</FormControl.Label>
          <div
            aria-expanded={openQty}
            onClick={() => setOpenQty(!openQty)}
            ref={anchorRef}
            style={{
              display: 'flex',
              width: '100%',
              ...textInputStyle,
              borderColor: '#A792FF',
              color: '#3B147A',
              fontWeight: 500,
              paddingTop: '6px',
              paddingBottom: '11px',
              paddingRight: '16px',
              paddingLeft: '16px',
              justifyContent: 'space-between',
              alignContent: 'center',
            }}
          >
            <>
              {licenses} members
              <div>
                <TriangleDownIcon fill="#A792FF" size={30} />
              </div>
            </>
          </div>
          <ActionMenu anchorRef={anchorRef} onOpenChange={setOpenQty} open={openQty}>
            <ActionMenu.Overlay align="start" width="medium">
              <ActionList>
                {Array(98)
                  .fill(0)
                  .map((_, i) => (
                    <ActionList.Item
                      disabled={i + 4 == mainSub.maxNoOfChildren + 1}
                      key={i}
                      onSelect={() => setLicenses(i + 4)}
                    >
                      {i + 4} members
                    </ActionList.Item>
                  ))}
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>
        </FormControl>

        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={3}
        >
          <Box
            mr={2}
            style={{
              border: '2px solid #9999',
              borderRadius: '8px',
              padding: '12px',
              lineHeight: '18px',
              color: '#9999',
            }}
            width="50%"
          >
            <p style={{ fontWeight: 'bold', margin: '0px' }}>Current</p>
            <ul style={{ paddingLeft: '25px', margin: '0px' }}>
              <li>{mainSub.maxNoOfChildren + 1} members</li>
              <li>
                ${parseInt(currentPrice.data?.price ?? '1') * (mainSub.maxNoOfChildren + 1)} per{' '}
                {currentPrice.data?.interval.split(' ')[0] !== '1' ? `${currentPrice.data?.interval}s` : `${currentPrice.data?.interval.split(' ')[1]}`}
              </li>
            </ul>
          </Box>
          <Box
            ml={2}
            style={{
              border: '2px solid #A792FF',
              borderRadius: '8px',
              padding: '15px',
              lineHeight: '18px',
            }}
            width="50%"
          >
            <p style={{ fontWeight: 'bold', margin: '0px' }}>Updated</p>
            <ul style={{ paddingLeft: '25px', margin: '0px' }}>
              <li>{licenses} members</li>
              <li>
                ${parseInt(newPrice.data?.newPrice ?? '1')} per {newPrice.data?.interval.split(' ')[0] !== '1' ? `${newPrice.data?.interval}s` : `${newPrice.data?.interval.split(' ')[1]}`}
              </li>
            </ul>
          </Box>
        </Box>

        {licenses > mainSub.maxNoOfChildren + 1 ? (
          <Box>
            These changes increase your plan cost to{' '}
            <strong>${newPrice.data?.priceDiffThisMonth ?? 0}</strong> (prorated).{' '}
            {parseInt(newPrice.data?.startingBalance ?? '0') > 0
              ? 'You currently have a pre-existing credit of $' +
                newPrice.data?.startingBalance +
                ', which will be applied. '
              : ''}
            Upon continuing, you will get immediate access to your additional licenses. Your plan
            will auto-renew on{' '}
            {new Date(mainSub.endOfCurrentBillingPeriod! * 1000).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}{' '}
            at ${parseInt(newPrice.data?.newPrice ?? '0').toFixed(0)}/{newPrice.data?.interval.split(' ')[0] !== '1' ? `${newPrice.data?.interval}s` : `${newPrice.data?.interval.split(' ')[1]}`}.
          </Box>
        ) : (getKids.data?.children.length ?? 1) + 1 <= licenses ? (
          <Box>
            You're about to deduct {mainSub.maxNoOfChildren + 1 - licenses} licenses from your
            account. A credit of{' '}
            <strong>${(-parseInt(newPrice.data?.priceDiffThisMonth ?? '0')).toFixed(2)}</strong>{' '}
            (prorated) will be applied to your account and will automatically be deducted when your
            plan renews on{' '}
            {new Date(mainSub.endOfCurrentBillingPeriod! * 1000).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}.
          </Box>
        ) : (
          <Box style={{ color: colors.real.medium }}>
            You currently have {(getKids.data?.children.length ?? 1) + 1} members allocated on your
            team.{' '}
            <strong>
              To decrease team size you'll need to first remove{' '}
              {(getKids.data?.children.length ?? 1) + 1 - licenses} member(s) from the team.
            </strong>
          </Box>
        )}
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p={4}
          style={{
            gap: '15px',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <CJRButton onClick={() => setIsOpen(false)} style={{ mr: 1 }} variant="juicyTertiary">
            Go back
          </CJRButton>
          <CJRButton
            disabled={(getKids.data?.children.length ?? 1) + 1 > licenses}
            onClick={() => {
              setIsOpen(false);
              upgrade({ plan: mainSub.plan, newQuantity: licenses });
            }}
            variant="juicyPrimary"
          >
            Continue
          </CJRButton>
        </Box>
      </Box>
    </Dialog>
  );
};
