import styled from 'styled-components';
import { FunctionComponent, useEffect, useState } from 'react';
import { Player } from '../player/Player';
import { PlayerProps } from '../../redux/slices/game.slice';
import { useClipboard } from '../../hooks/useClipboard';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { useGetRoomPlayerQuery } from '../../redux/apis/players';
import outOfRoomIcon from '../../images/outOfRoomIcon.svg';
import { Box, Button, Dialog, Tooltip, Text } from '@primer/react';
import { useMessageSender } from '../../hooks/useMessageSender';
import { usePlayers } from '../../hooks/usePlayers';
import { useGetRoomQuery } from '../../redux/apis/rooms';
import { CircleSlashIcon } from '@primer/octicons-react';

const List = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const Item = styled('li')`
  margin: 8px 0px 8px 0px;
  display: flex;
  flex-direction: row;
`;

export type PlayerListProps = {
  readonly players: PlayerProps[];
  readonly withBackground?: boolean;
  readonly roomId?: string;
};

export const PlayerList: FunctionComponent<PlayerListProps> = ({ players }) => (
  <List>
    {players.map((player) => (
      <Item key={player.id}>
        <Player {...player} />
      </Item>
    ))}
  </List>
);

export const PlayerListWithPoints: FunctionComponent<PlayerListProps> = ({
  players,
  withBackground = false,
  roomId,
}) => {
  const [playerId, setPlayerId] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState({ open: false, playerId: '', name: '' });
  const playersData = useGetRoomPlayerQuery(
    { roomId: roomId ?? '', playerId },
    { skip: roomId === undefined || roomId === ':roomId' || !playerId || playerId === '' }
  );
  const { youPlayer } = usePlayers();
  const { copy } = useClipboard();
  const { href } = useWindowLocation();
  const { removePlayer } = useMessageSender();
  const getRoom = useGetRoomQuery(roomId!, { skip: roomId === undefined || roomId === ':roomId' });

  useEffect(() => {
    if (playerId && playerId != '') playersData.refetch();
  }, [playerId]);

  useEffect(() => {
    if (playersData.isSuccess && playerId && roomId) {
      const player = playersData.data.find((p) => p.playerId == playerId);
      if (player) {
        copy(href + `?reconnectionToken=${player.reconnectionToken}&playerId=${playerId}` ?? '');
        setPlayerId('');
      }
    }
  }, [playersData]);

  return (
    <>
      <List>
        {players
          .filter((p) => p.isReady)
          .map((player) => (
            <Item key={player.id}>
              <div
                onClick={() => {
                  if (!roomId) return;
                  setPlayerId(player.id);
                }}
                style={{
                  cursor: playersData?.data && playersData.data.length > 0 ? 'pointer' : 'default',
                  width: '100%',
                }}
              >
                <Player {...player} isShowingPoints withBackground={withBackground} />
              </div>
              {youPlayer?.id !== player.id && getRoom.data?.isCreator ? (
                <Tooltip aria-label="Remove player" direction="n">
                  <Box
                    onClick={() => {
                      if (!roomId) return;
                      setIsDeleteOpen({ open: true, playerId: player.id, name: player.nickname });
                    }}
                    style={{
                      backgroundColor: '#FFEAE4',
                      height: '42px',
                      width: '42px',
                      margin: '4px 0px 4px 8px',
                      borderRadius: '12px',
                      cursor: 'pointer',
                    }}
                  >
                    <img src={outOfRoomIcon} style={{ margin: '1px', width: '40px' }} />
                  </Box>
                </Tooltip>
              ) : null}
            </Item>
          ))}
      </List>
      <Dialog
        aria-labelledby="label"
        isOpen={isDeleteOpen.open}
        onDismiss={() => setIsDeleteOpen({ open: false, playerId: '', name: '' })}
        sx={{ height: 'initial !important' }}
      >
        <Dialog.Header>
          <CircleSlashIcon />
        </Dialog.Header>
        <Box p={3}>
          <Text>
            Are you sure you want to permanently remove {isDeleteOpen.name}? If you confirm they
            lose their place and points in the game. If they rejoin, they will start over.
          </Text>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              onClick={() => setIsDeleteOpen({ open: false, playerId: '', name: '' })}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsDeleteOpen({ open: false, playerId: '', name: '' });
                removePlayer({ playerId: isDeleteOpen.playerId });
              }}
              variant="primary"
            >
              Yes, remove player
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export const Highscores: FunctionComponent<PlayerListProps> = ({ players }) => {
  return (
    <List>
      {players
        .filter((p) => p.isReady)
        .sort((a, b) => b.points - a.points)
        .map((player) => (
          <Item key={player.id}>
            <Player {...player} isShowingPoints />
          </Item>
        ))}
    </List>
  );
};
