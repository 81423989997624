import { FunctionComponent } from 'react';
import { Avatar, AvatarProps } from '../avatar/Avatar';

export const BoardAvatar: FunctionComponent<AvatarProps> = (props) => (
  <Avatar.Border>
    <Avatar
      size={40}
      {...props}
      sx={{ width: '8vw', height: '8vw', maxWidth: 44, maxHeight: 44 }}
    />
  </Avatar.Border>
);
