import styled from 'styled-components';

export const Bar = styled('div')`
  height: 10px;
  width: 160%;
  box-shadow: inset 0px -4px 5px 0px #d3d3d3;
  position: absolute;
  bottom: 90px;
  left: -40px;
`;
