import styled from 'styled-components';
import { themeGet } from '@primer/react';
import { VisuallyHidden } from '../visuallyHidden/VisuallyHidden';
import { DeckType } from '../../redux/models/colyseumSchemas/Deck';
import { usePlayers } from '../../hooks/usePlayers';
import { useCardImage } from '../../hooks/useCardImage';

type StyledCardButtonProps = {
  deck: DeckType;
  isPlayerTurn: boolean;
  backgroundImage: string;
};

const StyledCardButton = styled('button')<StyledCardButtonProps>`
  aspect-ratio: 1 / 1;
  width: 42%;
  --card-color: ${({ deck }) => themeGet(`colors.${deck}.dark`)};
  --card-outline-color: ${({ deck }) => themeGet(`colors.${deck}.light`)};

  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1em;
  border: 3px solid var(--card-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${themeGet('shadows.cardButton.shadow')};

  transition-property: transform, box-shadow;
  transition-duration: 0.3s;

  &:enabled {
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      transform: rotate(0) scale(${({ isPlayerTurn }) => (isPlayerTurn ? 1.1 : 1)}) translateZ(0);
      box-shadow: ${themeGet('shadows.cardButton.focusShadow')};
    }

    &:focus-visible {
      outline: 3px solid var(--card-outline-color);
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;

type ButtonProps = {
  readonly onClick: () => void;
  readonly disabled?: boolean;
};
export type DeckButtonProps = Omit<StyledCardButtonProps, 'isPlayerTurn' | 'backgroundImage'> &
  ButtonProps;

export const DeckButton = ({ deck, ...props }: DeckButtonProps) => {
  const { isPlayerTurn } = usePlayers();
  const backgroundImg = useCardImage(deck, 'back');

  return (
    <StyledCardButton
      {...props}
      backgroundImage={backgroundImg}
      deck={deck}
      isPlayerTurn={isPlayerTurn}
      style={{
        animation: isPlayerTurn && !props.disabled ? 'pulse 0.5s 6' : '',
      }}
    >
      <VisuallyHidden>{`${deck} card`}</VisuallyHidden>
    </StyledCardButton>
  );
};
