import styled from 'styled-components';
import { Heading, themeGet } from '@primer/react';
import { usePlayers } from '../../hooks/usePlayers';
import { useState } from 'react';
import { TriangleDownIcon, TriangleUpIcon } from '@primer/octicons-react';
import { PlayerListWithPoints } from '../../basicComponents/playerList/PlayerList';

const BottomFixedBar = styled('div')`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  display: flex;
  align-items: center;
  margin: auto;
  background-color: #ede9ff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 260px;
  min-height: 30px;
  bottom: 0px;
  right: 0px;
  padding: 10px 20px 20px 20px;
  border-radius: 30px 0px 0px 0px;
`;

const Corner = styled('div')`
  position: fixed;
  z-index: 2;
  display: flex;
  align-items: center;
  margin: auto;
  background-color: #ede9ff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  max-width: 240px;
  max-height: 240px;
  bottom: -120px;
  right: -120px;
  padding: ${themeGet('space.2')};
  border-radius: 260px;
`;

const Points = styled('div')`
  position: absolute;
  top: 35px;
  left: 50px;
  padding: 9px 2px 2px 2px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
  border-radius: 23px;
  background-color: white;
  color: ${themeGet('colors.spark.dark')};
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  height: 46px;
  width: 46px;
`;

const Text = styled('div')`
  position: absolute;
  top: 90px;
  color: #3b147a;
  text-align: right;
  font-size: 15px;
  font-weight: 600;
`;

export const PlayerScoreBar = () => {
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const { youPlayer, players } = usePlayers();

  return (
    <>
      {isPanelVisible ? (
        <BottomFixedBar onClick={() => setIsPanelVisible(false)}>
          <Heading
            as="h2"
            sx={{ fontSize: 3, textAlign: 'center', mb: 2, color: '#3B147A', width: '100%' }}
          >
            Scores
          </Heading>
          <PlayerListWithPoints players={players} withBackground />
          <TriangleDownIcon fill="#A792FF" size={30} verticalAlign="middle" />
        </BottomFixedBar>
      ) : (
        <Corner onClick={() => setIsPanelVisible(true)}>
          <Points>{youPlayer?.points || 0}</Points>
          <Text>
            <TriangleUpIcon fill="#A792FF" size={30} verticalAlign="middle" /> All scores
          </Text>
        </Corner>
      )}
    </>
  );
};
