import { FunctionComponent } from 'react';
import { Board } from '../../basicComponents/board/Board';
import { usePlayers } from '../../hooks/usePlayers';

export const GameBoard: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const { players, turnPlayer } = usePlayers();

  return (
    <Board>
      <Board.Background />
      <Board.Middle>{children}</Board.Middle>
      {players
        .filter((p) => p.isReady)
        .map(({ id, steps, avatar }) => (
          <Board.Step
            count={players.reduce(
              (count, player) =>
                player.steps === steps ? (player.isReady ? count + 1 : count) : count,
              0
            )}
            isTurn={turnPlayer?.avatar === avatar}
            key={id}
            step={steps}
          >
            <Board.Avatar variant={avatar} />
          </Board.Step>
        ))}
    </Board>
  );
};
