import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { env } from '../../env';
import { RoomState } from '../models/colyseumSchemas/RoomState';

export const colyseusApi = createApi({
  reducerPath: 'colyseusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: (env.REACT_APP_WS ?? '').replace('ws', 'http'),
  }),
  endpoints: (builder) => ({
    getRoomState: builder.query<{ clients: any[]; maxClients: number; state: RoomState }, string>({
      query: (roomId) => `/colyseus/api/room?roomId=${roomId}`,
    }),
    deleteColyseusRoom: builder.mutation<undefined, string>({
      query: (body) => ({
        url: `/colyseus/api/room/call?roomId=${body}&method=disconnect&args=%5B%5D`,
        method: 'GET',
      }),
    }),
  }),
});

export const colyseusApiReducer = colyseusApi.reducer;

export const { useGetRoomStateQuery, useDeleteColyseusRoomMutation } = colyseusApi;
