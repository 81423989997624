import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BaseApiConfig } from './common';

export type ConfigType =
  | 'MaxNoOfPlayersPerGame'
  | 'MaxNoOfConcurrentRooms'
  | 'MaxNoOfGamesPerMonth'
  | 'ObserverFeature'
  | 'EventsFeature'
  | 'AdvancePlayersFeature'
  | 'CheckInQuestionsFeature';

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Config'],
  endpoints: (builder) => ({
    getConfig: builder.query<Record<ConfigType, any>, undefined>({
      query: () => `/config`,
    }),

    listConfigs: builder.query<Record<string, Record<ConfigType, any>>, undefined>({
      query: () => `/config/all`,
    }),

    planNames: builder.query<Record<string, string>, undefined>({
      query: () => `/config/plan-names`,
    }),
  }),
});

export const configApiReducer = configApi.reducer;

export const { useGetConfigQuery, useListConfigsQuery, usePlanNamesQuery } = configApi;
