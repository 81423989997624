import React from 'react';
import styled from 'styled-components';
import BuyNowBtn from './BuyNowBtn';

const PlanHeader: React.FC = () => {
  return (
    <HeaderContainer>
      <FeatureNameColumn>
        <FeatureName>Plan Features</FeatureName>
      </FeatureNameColumn>
      <PlanColumns>
        <PlanColumn backgroundColor="rgba(161, 139, 255, 0.05)">
          <PlanName>Pro</PlanName>
          <BuyNowBtn plan="PRO" />
        </PlanColumn>
        <PlanColumn backgroundColor="rgba(161, 139, 255, 0.1)">
          <PlanName>Company</PlanName>
          <BuyNowBtn plan="COMPANY" />
        </PlanColumn>
        <PlanColumn backgroundColor="rgba(161, 139, 255, 0.15)">
          <PlanName>Enterprise</PlanName>
          <BuyButton
            onClick={() => {
              window.open(
                'https://cozyjuicyreal.trafft.com/booking?t=s&uuid=5fc42dd7-af7f-4716-8c26-d690476ed6e4'
              );
            }}
          >
            Book a call
          </BuyButton>
        </PlanColumn>
      </PlanColumns>
    </HeaderContainer>
  );
};

const HeaderContainer = styled('header')`
  display: flex;
  min-height: 140px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 991px) {
    flex-direction: column; /* Stack FeatureNameColumn above PlanColumns */
    padding-left: 0px;
    width: 100%;
  }
`;

const FeatureNameColumn = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33.5%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const PlanColumns = styled('div')`
  display: flex;
  flex-direction: row;
  width: 66.5%;
  margin-left: 20px;

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

const FeatureName = styled('h2')`
  color: #333;
  align-self: stretch;
  padding: 0px 0px 0px 30px;
  margin-top: auto;
  margin-bottom: 25px;
  font: 700 24px 'Town 80 Text', sans-serif;

  @media (max-width: 991px) {
    padding: 20px 0px 0px 20px;
    margin-bottom: 10px;
  }
`;

const PlanColumn = styled('div')<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  font-family: 'Town 80 Text', sans-serif;
  font-weight: 700;
  text-align: center;
  width: 22.2%;
  padding: 0px 30px;

  @media (max-width: 991px) {
    padding: 10px 10px;
  }
`;

const PlanName = styled('h3')`
  color: #333;
  font-size: 24px;
  align-self: center;

  @media (max-width: 991px) {
    font-size: 20px;
  }
`;

const BuyButton = styled('button')`
  background: #896dff;
  border: 1px solid #440d9b;
  border-left: 4px solid #440d9b;
  border-bottom: 5px solid #440d9b;
  border-radius: 30px;
  font: 700 16px 'Town 80 Text', sans-serif;
  color: #fff;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background: #bfafff;
    border-color: #896dff;
  }

  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

export default PlanHeader;
