import { FunctionComponent, useRef, useState } from 'react';
import { Box, Button, Dialog, Text } from '@primer/react';
import { CircleSlashIcon } from '@primer/octicons-react';
import { useMessageSender } from '../../hooks/useMessageSender';
import { usePlayers } from '../../hooks/usePlayers';
import { buttonStyle } from '../../theme/theme';

export const SkipCardButton: FunctionComponent = () => {
  const returnFocusRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { turnPlayer, isPlayerTurn } = usePlayers();
  const { skipCard } = useMessageSender();

  const skips = turnPlayer?.skips || 0;
  const isSkipCardEnabled = isPlayerTurn && skips > 0;

  return (
    <>
      <Button
        disabled={!isSkipCardEnabled}
        onClick={() => setIsOpen(true)}
        sx={{
          width: '40%',
          ...buttonStyle,
          borderColor: '#7A0032',
          backgroundColor: 'white',
          color: '#DB004F',
          fontWeight: 100,
          minWidth: '165px',
        }}
        type="button"
        variant="outline"
      >
        {`Skip card (${skips}/3)`}
      </Button>

      <Dialog
        aria-labelledby="label"
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        returnFocusRef={returnFocusRef}
        sx={{ height: 'initial !important' }}
      >
        <Dialog.Header>
          <CircleSlashIcon />
        </Dialog.Header>
        <Box p={3}>
          <Text id="label">
            You have {skips} card skips remaining per game. Are you sure you want to skip this card?
          </Text>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button onClick={() => setIsOpen(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
                skipCard();
              }}
              variant="primary"
            >
              Yes, skip card
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
