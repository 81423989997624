import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { RootState } from '../store';
import { env } from '../../env';

const BACKEND_BASE_URL = env.REACT_APP_BACKEND;

export const BaseApiConfig: FetchBaseQueryArgs = {
  baseUrl: BACKEND_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.authLocal?.accessToken ?? state.authSession?.accessToken;
    if (token && token !== '') {
      headers.set('Authorization', 'Bearer ' + token);
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  fetchFn: async (input, init) => {
    //If the fetch fail due to an unavailability of the backend, it retries one more time after 5 seconds
    let response = new Response();
    const iterator = 0;
    response = await tryToFetch(response, input, init, iterator);
    return response;
  },
};

async function tryToFetch(
  response: Response,
  input: RequestInfo,
  init: RequestInit | undefined,
  iterator: number
) {
  try {
    response = await fetch(input, init);
  } catch (e: any) {
    if (iterator > 2) throw new Error();
    console.log('Backend unreachable, retrying in 3 seconds. Trying... ', iterator);
    if (e.message == 'Failed to fetch') {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      iterator++;

      response = await tryToFetch(response, input, init, iterator);
    }
  }
  return response;
}
