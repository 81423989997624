import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AuthData } from '../models/auth.model';
import { BaseApiConfig } from './common';

const LOGIN_API = '/users/login';
const GET_GUEST_TOKEN_API = '/users/guest-token';
const GET_GAME_CODE_API = '/users/game-code';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery(BaseApiConfig),
  tagTypes: ['Login'],
  endpoints: (builder) => ({
    login: builder.mutation<AuthData | undefined, { email: string; password: string }>({
      query: (body) => ({
        url: LOGIN_API,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Login'],
    }),

    getGuestToken: builder.query<
      (AuthData & { roomId?: string; expansion?: string }) | undefined,
      { code: string }
    >({
      query: (input) => GET_GUEST_TOKEN_API + `?code=${input.code}`,
    }),

    getGameCode: builder.query<
      { code: string } | { [roomId: string]: string },
      { roomId?: string; roomIds?: string[]; expansion?: string }
    >({
      query: (input) =>
        GET_GAME_CODE_API +
        '?' +
        (input.roomId ? `roomId=${input.roomId}&` : '') +
        (input.roomIds ? `roomIds=${input.roomIds.join(',')}&` : '') +
        (input.expansion ? `expansion=${input.expansion}` : ''),
    }),
  }),
});

export const authApiReducer = authApi.reducer;

export const { useLoginMutation, useGetGuestTokenQuery, useGetGameCodeQuery } = authApi;
