import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Box, Dialog, TextInput } from '@primer/react';
import {
  useGetPriceChangeQuery,
  useGetSubscriptionStatusQuery,
  useUpgradeSubscriptionMutation,
} from '../../redux/apis/payment';
import { stopConfettiInner } from '../confetti/confetti';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';
import { setManualLoading, showLoading } from '../../redux/slices/modal.slice';
import { useAppDispatch } from '../../redux/hooks';
import { UnsuccessfulDialog } from './UnsuccessfulDialog';
import { usePlanNamesQuery } from '../../redux/apis/config';
import { colors } from '../../theme/theme';

interface UpgradeConfirmDialogProps {
  readonly originalPlan: string;
  readonly changePlan: string;
  readonly confirmChange: boolean;
  readonly setConfirmChange: (value: boolean) => void;
}

export const ConfirmUpgradeDialog: FunctionComponent<UpgradeConfirmDialogProps> = ({
  originalPlan,
  changePlan,
  confirmChange,
  setConfirmChange,
}) => {
  const dispatch = useAppDispatch();

  stopConfettiInner();

  const [allDone, setAllDone] = useState(false);
  const [promoCodeBox, setPromoCodeBox] = useState(false);
  const [cupomCodeTemp, setCupomCodeTemp] = useState<string | undefined>(undefined);
  const [cupomCode, setCupomCode] = useState<string | undefined>(undefined);
  const sub = useGetSubscriptionStatusQuery(undefined);
  const planNames = usePlanNamesQuery(undefined);
  const priceChange = useGetPriceChangeQuery(
    {
      plan: originalPlan!,
      newPlan: changePlan!,
      newQuantity: changePlan == 'COMPANY' ? 4 : 1,
      cupomCode,
    },
    { skip: !changePlan || originalPlan == changePlan, refetchOnMountOrArgChange: true }
  );
  const [upgrade, upgradedPlan] = useUpgradeSubscriptionMutation();
  const ref = useRef(null);

  useEffect(() => {
    setAllDone(false);
  }, []);

  useEffect(() => {
    if (upgradedPlan.isSuccess) setAllDone(true);
  }, [upgradedPlan]);

  const onClick = () => {
    //MANUAL: for now we assume that Company plan is the only one who allows children and must have a minimum of 4 licenses. The rest all work with 1 license only.
    upgrade({
      plan: originalPlan!,
      newPlan: changePlan,
      newQuantity: changePlan == 'COMPANY' ? 4 : 1,
      cupomCode,
    });
    dispatch(setManualLoading(true));
    dispatch(showLoading());
  };

  if (!sub.data || !priceChange.data) return <></>;
  if (upgradedPlan.isError)
    return (
      <UnsuccessfulDialog open={confirmChange} setOpen={setConfirmChange} tryAgain={onClick} />
    );

  return !allDone ? (
    <Dialog
      aria-labelledby="label"
      isOpen={confirmChange}
      onDismiss={() => setConfirmChange(false)}
      returnFocusRef={ref}
      sx={{ height: 'initial !important' }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Confirm your upgrade
      </div>
      <Box p={4}>
        <>
          <Box p={3}>
            You’re about to upgrade to the <b>{`${planNames.data?.[changePlan]}`}</b> plan that will
            unlock a range of features and the ability to host games for 40+ players at a time.
          </Box>
          <Box p={3}>
            Your card ending in {`${sub.data!.find((p) => p.plan == originalPlan)!.paymentCard}`}{' '}
            will be charged <b>{`$${priceChange.data.priceDiffThisMonth}`}</b>, granting you
            immediate access to your new features.{' '}
            {parseInt(priceChange.data?.startingBalance ?? '0') > 0
              ? 'You currently have a pre-existing credit of $' +
                priceChange.data?.startingBalance +
                ', which will be applied. '
              : ''}
            Your plan will auto-renew at{' '}
            {`$${priceChange.data.newPrice}/${priceChange.data?.interval.split(' ')[0] !== '1' ? `${priceChange.data?.interval}s` : `${priceChange.data?.interval.split(' ')[1]}`}`}.
          </Box>
          <Box p={3}>
            Click <b>Continue</b> below to confirm...
          </Box>
        </>
      </Box>
      <Box
        style={{
          gap: '15px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        {promoCodeBox ? (
          <Box display="flex" flexDirection="row" p={4} pb={0}>
            <TextInput
              maxLength={20}
              onChange={(e) => setCupomCodeTemp(e.target.value)}
              placeholder="Enter promo code"
              sx={{
                height: '40px',
                borderColor: colors.accent.emphasis,
                backgroundColor: priceChange.isError ? '#FFCCCB' : 'white',
                width: '100%',
              }}
            />
            <CJRButton
              onClick={() => {
                setCupomCode(cupomCodeTemp);
              }}
              style={{ width: '160px', position: 'absolute', right: '25px' }}
              variant="juicySecondary"
            >
              Apply
            </CJRButton>
          </Box>
        ) : null}
        <Box display="flex" justifyContent="center" p={4}>
          {!promoCodeBox ? (
            <CJRButton
              onClick={() => setPromoCodeBox(true)}
              style={{ marginRight: '5px' }}
              variant="juicyTertiary"
            >
              Enter promo code
            </CJRButton>
          ) : null}
          <CJRButton
            disabled={priceChange.isError}
            onClick={onClick}
            style={{ marginLeft: '5px', maxWidth: '200px' }}
            variant="juicyPrimary"
          >
            Continue
          </CJRButton>
        </Box>
      </Box>
    </Dialog>
  ) : (
    <Dialog
      aria-labelledby="label"
      isOpen={confirmChange}
      onDismiss={() => setConfirmChange(false)}
      returnFocusRef={ref}
      sx={{ height: 'initial !important' }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        All done!
      </div>
      <Box p={4}>
        <Box p={3}>
          Okey dokes, your subscription has been upgraded to the{' '}
          <b>{`${planNames.data?.[changePlan]}`}</b> plan. 🙌
        </Box>
        <Box p={3}>You’ll receive an email shortly confirming the update.</Box>
        <Box p={3}>
          Enjoy your juicy new features and editions, and as always, feel free to reach out if you
          have any questions at <b>team@cozyjuicyreal.com</b>.
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p={4}
          style={{
            gap: '15px',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <CJRButton
            onClick={() => setConfirmChange(false)}
            style={{ maxWidth: '200px', margin: 'auto' }}
            variant="juicyPrimary"
          >
            Close
          </CJRButton>
        </Box>
      </Box>
    </Dialog>
  );
};
