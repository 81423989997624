import { Box, Dialog, FormControl, TextInput } from '@primer/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { CJRButton } from '../cjrButton/cjrButton';
import { colors, textInputStyle } from '../../theme/theme';
import {
  useGetUserQuery,
  useSendVerificationEmailMutation,
  useVerifyEmailMutation,
} from '../../redux/apis/user.api';
import { useAppDispatch } from '../../redux/hooks';
import {
  closeLoading,
  setManualLoading,
  showLoading,
  showModal,
} from '../../redux/slices/modal.slice';
import { Bar } from '../bar/Bar';
import { useLogout } from '../../hooks/useLogout';
import { useCheckoutMutation } from '../../redux/apis/payment';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { useSearchParams } from 'react-router-dom';
import { useGetConfigQuery } from '../../redux/apis/config';
import { useGetRoomsQuery } from '../../redux/apis/rooms';

const inputStyle = {
  height: '54px',
  maxWidth: '54px',
  justifyContent: 'center',
  paddingLeft: '7px',
  fontSize: '26px',
  color: '#3B147A',
};

export const VerificationBox = () => {
  const dispatch = useAppDispatch();
  const [emailVerify, verification] = useVerifyEmailMutation();
  const [sendVerificationEmail, sentEmailMutation] = useSendVerificationEmailMutation();
  const { setHref } = useWindowLocation();
  const [checkout, session] = useCheckoutMutation();
  const [queryParams] = useSearchParams();
  const logout = useLogout();

  const [digit1, setDigit1] = useState<string>('');
  const [digit2, setDigit2] = useState<string>('');
  const [digit3, setDigit3] = useState<string>('');
  const [digit4, setDigit4] = useState<string>('');
  const [digit5, setDigit5] = useState<string>('');
  const [digit6, setDigit6] = useState<string>('');

  const user = useGetUserQuery(undefined);
  const getRooms = useGetRoomsQuery(undefined);
  const getConfig = useGetConfigQuery(undefined);

  useEffect(() => {
    if (verification.isSuccess) {
      dispatch(showModal({ type: 'success', message: 'Verification successful' }));

      if (queryParams.has('plan')) {
        dispatch(setManualLoading(true));
        dispatch(showLoading());
        checkout({ plan: queryParams.get('plan')! });
      } else {
        user.refetch();
        getRooms.refetch();
        getConfig.refetch();
      }
    }
    if (!verification.isLoading) {
      dispatch(closeLoading());
    }
  }, [verification]);

  useEffect(() => {
    if (session.isSuccess) setHref(session.data.url);
    if (!session.isLoading) {
      dispatch(closeLoading());
    }
  }, [session]);

  useEffect(() => {
    if (sentEmailMutation.isSuccess) {
      dispatch(showModal({ type: 'success', message: 'Verification email resent' }));
    }
  }, [sentEmailMutation]);

  const handlePaste = (event: ClipboardEvent) => {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text').split('') ?? '';
    setDigit1(pastedText[0]);
    setDigit2(pastedText[1]);
    setDigit3(pastedText[2]);
    setDigit4(pastedText[3]);
    setDigit5(pastedText[4]);
    setDigit6(pastedText[5]);
  };

  useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  return (
    <Dialog aria-label="Verification time!" isOpen sx={{ maxHeight: '90%' }}>
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Verification time!
      </div>
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '700px',
          padding: '30px 40px 30px 40px',
          overflowX: 'hidden',
        }}
      >
        <Box p={3}>Hey {user.data?.firstName}, welcome to Cozy Juicy Real! 🎉</Box>
        <Box p={3}>
          In order to begin, you need to do that annoying task of verifying your email address
          – sorry.
        </Box>
        <Box p={3}>
          Head over to your email account, open the email we sent, and enter the verification code
          below.
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 5 }}>
          <FormControl sx={{ mr: 1 }}>
            <FormControl.Label visuallyHidden>code</FormControl.Label>
            <TextInput
              id="digit1"
              maxLength={1}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setDigit1(event.target.value);
                event.target.value && document.getElementById('digit2')?.focus();
              }}
              sx={{
                width: '100%',
                ...textInputStyle,
                ...inputStyle,
              }}
              value={digit1}
            />
          </FormControl>
          <FormControl sx={{ mr: 1 }}>
            <FormControl.Label visuallyHidden>code</FormControl.Label>
            <TextInput
              accept="number"
              id="digit2"
              maxLength={1}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setDigit2(event.target.value);
                event.target.value
                  ? document.getElementById('digit3')?.focus()
                  : document.getElementById('digit1')?.focus();
              }}
              sx={{ width: '100%', ...textInputStyle, ...inputStyle }}
              value={digit2}
            />
          </FormControl>
          <FormControl sx={{ mr: 1 }}>
            <FormControl.Label visuallyHidden>code</FormControl.Label>
            <TextInput
              accept="number"
              id="digit3"
              maxLength={1}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setDigit3(event.target.value);
                event.target.value
                  ? document.getElementById('digit4')?.focus()
                  : document.getElementById('digit2')?.focus();
              }}
              sx={{ width: '100%', ...textInputStyle, ...inputStyle }}
              value={digit3}
            />
          </FormControl>
          <FormControl sx={{ mr: 1 }}>
            <FormControl.Label visuallyHidden>code</FormControl.Label>
            <TextInput
              accept="number"
              id="digit4"
              maxLength={1}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setDigit4(event.target.value);
                event.target.value
                  ? document.getElementById('digit5')?.focus()
                  : document.getElementById('digit3')?.focus();
              }}
              sx={{ width: '100%', ...textInputStyle, ...inputStyle }}
              value={digit4}
            />
          </FormControl>
          <FormControl sx={{ mr: 1 }}>
            <FormControl.Label visuallyHidden>code</FormControl.Label>
            <TextInput
              accept="number"
              id="digit5"
              maxLength={1}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setDigit5(event.target.value);
                event.target.value
                  ? document.getElementById('digit6')?.focus()
                  : document.getElementById('digit4')?.focus();
              }}
              sx={{ width: '100%', ...textInputStyle, ...inputStyle }}
              value={digit5}
            />
          </FormControl>
          <FormControl sx={{ mr: 1 }}>
            <FormControl.Label visuallyHidden>code</FormControl.Label>
            <TextInput
              accept="number"
              id="digit6"
              maxLength={1}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setDigit6(event.target.value);
                !event.target.value && document.getElementById('digit5')?.focus();
              }}
              sx={{ width: '100%', ...textInputStyle, ...inputStyle }}
              value={digit6}
            />
          </FormControl>
        </Box>

        <Bar style={{ bottom: '130px' }} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '70px',
            gap: '10px',
          }}
        >
          <CJRButton onClick={() => sendVerificationEmail(undefined)} variant="juicyTertiary">
            Resend the code
          </CJRButton>
          <CJRButton
            onClick={() => {
              dispatch(setManualLoading(true));
              dispatch(showLoading());

              emailVerify(digit1 + digit2 + digit3 + digit4 + digit5 + digit6);
            }}
          >
            Enter
          </CJRButton>
        </div>
        <a
          onClick={() => logout()}
          style={{
            color: colors.real.medium,
            display: 'block',
            width: '100%',
            textDecoration: 'underline',
            textAlign: 'center',
            cursor: 'pointer',
            paddingTop: '15px',
          }}
        >
          Logout
        </a>
      </div>
    </Dialog>
  );
};
