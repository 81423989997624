import styled from 'styled-components';

export const ActiveAddon: React.FC<{
  title: string;
  expiryDate: string;
  usageInfo?: string;
}> = ({ title, expiryDate, usageInfo }) => {
  return (
    <CardWrapper>
      <CardHeader>{title}</CardHeader>
      <CardContent>
        {expiryDate}{'. '}<br/><br/>
        {usageInfo && <strong className="usage-info">{usageInfo}</strong>}
      </CardContent>
    </CardWrapper>
  );
};

// Styling
const CardWrapper = styled('article')`
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  width: 100%;
`;

const CardHeader = styled('header')`
  border-radius: 20px 20px 0 0;
  background: #db004f;
  color: #fff;
  font: 700 20px 'Town 80 Text', sans-serif;
  padding: 25px 25px;

  @media (max-width: 991px) {
    padding: 20px 20px;
  }
`;

const CardContent = styled('section')`
  border-radius: 0 0 20px 20px;
  background: #fff;
  color: #333;
  font: 350 16px 'Town 80 Text', sans-serif;
  padding: 25px 25px;

  .usage-info {
    font-weight: 700;
    color: #333;
  }

  @media (max-width: 991px) {
    padding: 20px 20px;
  }
`;
