import React from 'react';
import styled from 'styled-components';
import AddonCard from './AddonCard';
import { addonData } from '../../addOnCards/addonData';

const AddOns: React.FC = () => {
  return (
    <AddOnsWrapper>
      <Divider />
      <Title>Power up your plan with add-ons</Title>
      <Subtitle>See all available add-ons in the 'Compare plan features' matrix above.</Subtitle>
      <CardContainer>
  {addonData.map((addon, index) => (
    <AddonCard
      id={index} // Add this line
      contactUs={addon.contactUs}
      features={addon.features}
      key={index}
      price={addon.price}
      title={addon.title}
    />
  ))}
</CardContainer>
    </AddOnsWrapper>
  );
};

const AddOnsWrapper = styled('section')`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1442px;
  width: 100%;
  margin: 90px auto 0;
  padding: 0 1px 22px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Divider = styled('hr')`
  width: 100%;
  height: 1px;
  background-color: var(--Inactive, #eee);
  border: none;
`;

const Title = styled('h2')`
  color: var(--Dark, #333);
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  margin-top: 55px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Subtitle = styled('p')`
  color: var(--Dark, #333);
  text-align: center;
  opacity: 0.72;
  font-size: 18px;
  font-weight: 350;
  margin-top: 0px;
`;

const CardContainer = styled('div')`
  display: flex;
  gap: 20px;
  margin-top: 40px;
  width: 100%;
  max-width: 1140px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    margin-top: 40px;
  }
`;

export default AddOns;
