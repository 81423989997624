import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CJRButton as BaseCJRButton } from '../../basicComponents/cjrButton/cjrButton';
import infoIcon from '../../images/infoIconFFF.svg';
import upArrowIcon from '../../images/chevronUp.svg';
import contactIcon from '../../images/speech.svg';
import { ConfirmAddOnDialog } from '../plansAndBilling/ConfirmAddOnDialog';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { useGetSubscriptionStatusQuery, useCheckoutMutation } from '../../redux/apis/payment';

export const AvailableAddon: React.FC<{
  title: string;
  price: string;
  priceSuffix?: string; // Optional suffix like /month
  description: string;
  includes?: string; // Make optional
  features: { text: string; isBold?: boolean }[];
  plan: string; // Plan for the "Buy Now" button
  showContactIcon?: boolean; // New: To show contact icon instead of price
  buttonType?: 'buyNow' | 'bookCall'; // New: To select button type
}> = ({
  title,
  price,
  priceSuffix,
  description,
  includes,
  features,
  plan,
  showContactIcon = false, // Default to false
  buttonType = 'buyNow', // Default to "Buy Now"
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <CardWrapper>
      <CardHeader onClick={toggleExpand}>
        <CardTitle>{title}</CardTitle>
        <ExpandIcon src={isExpanded ? upArrowIcon : infoIcon} alt="Toggle Expand" />
      </CardHeader>
      <CardContent>
        <PriceSection>
          {showContactIcon ? (
            <ContactIconContainer>
              <ContactIcon src={contactIcon} alt="Contact Us" />
              {priceSuffix && <PriceSuffix>{priceSuffix}</PriceSuffix>}
            </ContactIconContainer>
          ) : (
            <PriceContainer>
              <Price>{price}</Price>
              {priceSuffix && <PriceSuffix>{priceSuffix}</PriceSuffix>}
            </PriceContainer>
          )}
          {buttonType === 'buyNow' ? (
            <BuyNowBtn plan={plan}/>
          ) : (
            <CustomCJRButton
              variant="sparkSecondary"
              onClick={() => window.open('https://cozyjuicyreal.trafft.com/booking?t=s&uuid=cdd9d434-e6b8-4d49-b28a-dc37ad536e46')}
            >
              Book a call
            </CustomCJRButton>
          )}
        </PriceSection>
        {isExpanded && (
          <>
            <Description>{description}</Description>
            {includes && <Included>{includes}</Included>}
            <FeatureList>
              {features.map((feature, index) => (
                <FeatureItem key={index} isBold={feature.isBold}>
                  {feature.text}
                </FeatureItem>
              ))}
            </FeatureList>
          </>
        )}
      </CardContent>
    </CardWrapper>
  );
};

function BuyNowBtn({ plan }: { readonly plan: string }) {
  const sub = useGetSubscriptionStatusQuery(undefined);
  const { setHref } = useWindowLocation();
  const [confirm, setConfirm] = useState(false);
  const [checkout, session] = useCheckoutMutation();

  useEffect(() => {
    if (session.isSuccess) setHref(session.data.url);
  }, [session]);

  const onClick = () => {
    if (
      !sub.data!.find(
        (s) => !s.isTemporaryAddOnSubscription && s.isSubscriptionActive && !s.fromParent
      )
    ) {
      checkout({ plan: plan });
    } else {
      setConfirm(true);
    }
  };

  return (
    <>
      <CustomCJRButton
        disabled={sub.data?.find((s) => s.isSubscriptionActive && s.plan === plan)}
        onClick={onClick}
        style={{
          width: '160px',
          marginLeft: 'auto',
          marginTop: '16px',
        }}
        variant={sub.data?.find((s) => s.isSubscriptionActive && s.plan === plan) ? 'sparkDisabled' : 'sparkPrimary'}
      >
        { sub.data?.find((s) => s.isSubscriptionActive && s.plan === plan) ? 'Add-on active' : 'Buy now' }
      </CustomCJRButton>

      {confirm ? (
        <ConfirmAddOnDialog confirm={confirm} plan={plan} setConfirm={setConfirm} />
      ) : null}
    </>
  );
}

// Styled Components
const CardWrapper = styled('article')`
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  width: 100%;
`;

const CardHeader = styled('header')`
  border-radius: 20px 20px 0 0;
  background: #896dff;
  color: #fff;
  font: 700 20px 'Town 80 Text', sans-serif;
  text-decoration: underline;
  padding: 25px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 20px 20px;
  }
`;

const CardTitle = styled('h3')`
  margin: 0;
  font-size: 20px;
`;

const ExpandIcon = styled('img')`
  width: 26px;
  height: 26px;
  transition: transform 0.3s ease;
`;

const CardContent = styled('section')`
  border-radius: 0 0 20px 20px;
  background: #fff;
  color: #333;
  font: 350 16px 'Town 80 Text', sans-serif;
  padding: 25px 25px;

  @media (max-width: 991px) {
    padding: 20px 20px;
  }
`;

const PriceSection = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
`;

const ContactIconContainer = styled('div')`
  display: flex;
  align-items: baseline;
  gap: 5px;
`;

const PriceContainer = styled('div')`
  display: flex;
  align-items: baseline;
`;

const Price = styled('span')`
  color: #333;
  font-size: 28px;
  font-weight: 700;
`;

const PriceSuffix = styled('span')`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
`;

const CustomCJRButton = styled(BaseCJRButton)`
  margin-left: auto;
  max-width: 200px;
`;

const ContactIcon = styled('img')`
  width: 30px;
  height: 30px;
`;

const Description = styled('p')`
  color: #333;
  font-size: 16px;
  margin-bottom: 20px;
`;

const Included = styled('h4')`
  color: #333;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const FeatureList = styled('ul')`
  padding: 0;
  list-style-type: none;
  margin: 0;
`;

const FeatureItem = styled('li')<{ isBold?: boolean }>`
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? '700' : '400')};
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &::before {
    content: '✔';
    color: #54ef9a;
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
  }
`;

export default AvailableAddon;
