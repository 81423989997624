import { Dialog } from '@primer/react';
import styled from 'styled-components';
import arrowIcon from '../../images/arrowIcon.svg';
import timesTable from '../../images/timesTable.png';

const Text = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 18px;
`;

const ScrollContainer = styled('div')`
  height: 800px;
  overflow-y: scroll;
  padding: 30px;
`;

const Item = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 18px;
  padding-bottom: 18px;
  border-top: 1px solid #999;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const Title = styled('div')`
  color: #3b147a;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 1px;
  padding-bottom: 6px;
`;

const Explanation = styled('div')`
  color: #3b147a;
  text-align: left;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3px;
`;

const Icon = styled('img')`
  min-width: 60px;
  max-width: 60px;
  padding-right: 10px;
`;

const Image = styled('img')`
  max-width: 220px;
  padding-top: 10px;
  padding-bottom: 2px;
  padding-right: 15px;
`;

export const GroupSizesDialog = ({ setIsOpen }: { readonly setIsOpen: Function }) => {
  return (
    <Dialog
      aria-labelledby="label"
      isOpen
      onDismiss={() => setIsOpen(false)}
      sx={{
        height: '80%',
        maxHeight: '628px',
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Group sizes & event lengths
      </div>
      <ScrollContainer>
        <Text>
          Below is a short guide to the number of players we recommend for a full game of Cozy Juicy
          Real.
        </Text>
        <Text>
          For sessions with more than the suggested number, it’s best to divide the group into
          multiple games (and use breakout rooms if online).
        </Text>

        <Item key="1">
          <Icon src={arrowIcon} />

          <TextContainer>
            <Title>The ideal group size</Title>
            <Explanation>
              We highly recommend 4-7 players in each breakout room. This size will create the most
              connecting and trust-building experience.
            </Explanation>
          </TextContainer>
        </Item>
        <Item key="2">
          <Icon src={arrowIcon} />

          <TextContainer>
            <Title>Here’s what we recommend:</Title>
            <Image alt="time table" src={timesTable} />
          </TextContainer>
        </Item>
        <Item key="3">
          <Icon src={arrowIcon} />

          <TextContainer>
            <Title>Large Group add-on</Title>
            <Explanation>
              With the Large Group add-on, you can put up to 12 people into a single game.{' '}
              <b>Just remember:</b> the size of your group will impact how long it takes to finish
              Cozy Juicy Real.
            </Explanation>
          </TextContainer>
        </Item>
      </ScrollContainer>
    </Dialog>
  );
};
