import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const logos = [
  'https://cozyjuicyreal.com/wp-content/uploads/2022/05/Adobe.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2022/02/Logo-002.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2022/02/Logo-005.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2022/02/Logo-001.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2022/02/Logo-004.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2022/02/Logo-020.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2022/12/Google.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2022/06/Hubspot.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2023/02/Indeed.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2022/02/Logo-006.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2022/02/Logo-009.png',
  'https://cozyjuicyreal.com/wp-content/uploads/2022/10/Logo-043.png',
];

const LogoCarousel: React.FC = () => {
  const trackRef = useRef<HTMLDivElement>(null);
  const [animationDuration, setAnimationDuration] = useState<number>(0);

  useEffect(() => {
    if (trackRef.current) {
      const trackWidth = trackRef.current.offsetWidth / 2; // Width of one set of logos
      const speed = 100; // Adjust this value to control speed (pixels per second)
      const duration = trackWidth / speed; // Calculate duration based on width and speed
      setAnimationDuration(duration);
    }
  }, []);

  return (
    <CarouselWrapper>
      <CarouselTrack
        animationDuration={animationDuration > 0 ? animationDuration : undefined}
        ref={trackRef}
      >
        {[...logos, ...logos].map((logo, index) => (
          <Logo alt={`Logo ${index + 1}`} key={index} src={logo} />
        ))}
      </CarouselTrack>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled('div')`
  overflow: hidden;
  width: 100%;
  background-color: #fff;
  padding: 40px 0;
`;

interface CarouselTrackProps {
  animationDuration?: number;
}

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const CarouselTrack = styled('div')<CarouselTrackProps>`
  display: flex;
  align-items: center;
  width: max-content;
  opacity: 50%;
  ${({ animationDuration }) =>
    animationDuration
      ? css`
          animation: ${scroll} ${animationDuration}s linear infinite;
        `
      : 'animation: none;'}
`;

const Logo = styled('img')`
  width: 150px;
  height: auto;
  margin: 0 20px;
  flex-shrink: 0;
  filter: grayscale(100%) contrast(50%) saturate(500%) brightness(80%);
`;

export default LogoCarousel;
