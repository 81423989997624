import { useCallback } from 'react';
import { useAppSelector } from '../redux/hooks';
import { getGameState } from '../redux/slices/game.slice';
import { getCurrentPlayer } from '../redux/slices/player.slice';

export const usePlayers = () => {
  const { playerId } = useAppSelector(getCurrentPlayer);
  const { playersArr, playerIdTurn } = useAppSelector(getGameState);

  const isPlayerTurn = playerIdTurn === playerId;
  const turnPlayerIndex = playersArr.findIndex((player) => player.id === playerIdTurn);
  const turnPlayer = playersArr[turnPlayerIndex];

  const playersArrReady = playersArr.filter((p) => p.isConnected && p.isReady);
  const turnPlayerIndexInReadyList = playersArrReady.findIndex(
    (player) => player.id === playerIdTurn
  );
  const previousPlayer =
    turnPlayerIndexInReadyList - 1 >= 0
      ? playersArrReady[turnPlayerIndexInReadyList - 1]
      : playersArrReady[playersArrReady.length - 1];
  const nextPlayer = playersArrReady[turnPlayerIndexInReadyList + 1] ?? playersArrReady[0];

  const youPlayer = playersArr.find((player) => player.id === playerId);
  const playersCount = playersArr.length;

  const getPlayerNickname = useCallback(
    (id: string) => {
      const player = playersArr.find((player) => player.id === id);
      return player?.nickname;
    },
    [playersArr]
  );

  return {
    players: playersArr,
    youPlayer,
    turnPlayer,
    nextPlayer,
    previousPlayer,
    isPlayerTurn,
    playersCount,
    getPlayerNickname,
  };
};
