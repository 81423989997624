import { Room } from 'colyseus.js';
import { useAppDispatch } from '../redux/hooks';
import { setGameState, setRoom, setConnectionStatus } from '../redux/slices/game.slice';
import { setRoomPlayer } from '../redux/slices/player.slice';
import { exhaustiveCheck } from '../utils/exhaustiveCheck';
import { RoomResult } from './useColyseus';
import { RoomState } from '../redux/models/colyseumSchemas/RoomState';

export const useUpdateGameState = () => {
  const dispatch = useAppDispatch();

  return (result: RoomResult) => {
    let room: Room<RoomState>;
    const { status } = result;
    switch (status) {
      case 'awaiting':
        break;
      case 'created':
      case 'joined':
      case 'reconnected':
        room = result.room;
        room.onStateChange((state) => {
          return dispatch(setGameState({ ...state }));
        });
        dispatch(setRoom(room));
        dispatch(setConnectionStatus('connected'));
        dispatch(
          setRoomPlayer({
            roomId: room.id,
            playerId: room.sessionId,
            reconnectionToken: room.reconnectionToken,
          })
        );
        break;
      case 'error':
        dispatch(setConnectionStatus('error'));
        break;
      default:
        exhaustiveCheck(status);
    }
  };
};
