import React from 'react';
import styled from 'styled-components';
import PricingColumn from './PricingColumn';
import BookACall from './BookACall';
import TasterColumn from './TasterColumn';
import NonProfitColumn from './NonProfitColumn';
import { CJRButton } from '../../../basicComponents/cjrButton/cjrButton';
import BuyNowBtn from './BuyNowBtn';
import userIcon from '../../../images/pricingPage/person-circle.svg';

interface PricingTablesProps {
  readonly onCompareClick: () => void; // Pass this down
}

const PricingTables: React.FC<PricingTablesProps> = ({ onCompareClick }) => {
  const pricingData = [
    {
      title: 'Pro',
      subtitle: 'For solopreneurs and small teams',
      price: '$180',
      period: '/year',
      btn: <BuyNowBtn plan="PRO" />,
      features: [
        { text: "1 admin", showIcon: true, iconSrc: userIcon, infoText: "'Admins' are account holders who set up games, inviting 'players' to join. Admins pay for platform access, players don't." },
        { text: "Events for up to 18 players", showIcon: true },
        { text: "Up to 6 players in a game", showIcon: true },
        { text: "Up to 3 games simultaneously", showIcon: true },
        { text: "6 games /month", showIcon: true },
        { text: "3 themed-question editions", showIcon: true },
        { text: "Add-ons to customize your plan", showIcon: true },
        { text: "", showIcon: false },
      ],
      isPopular: false,
      backgroundColor: 'rgba(161, 139, 255, 0.02)',
    },
    {
      title: 'Company',
      subtitle: 'For teams who want the best',
      price: '$240',
      period: '/admin /year',
      btn: <BuyNowBtn plan="COMPANY" />,
      features: [
        { text: "Minimum 4 admins", showIcon: true, iconSrc: userIcon, infoText: "'Admins' are account holders who set up games, inviting 'players' to join. Admins pay for platform access, players don't." },
        { text: "Events for up to 32 players /admin", showIcon: true },
        { text: "Up to 8 players in a game", showIcon: true },
        { text: "Up to 4 games simultaneously", showIcon: true },
        { text: "10 games /admin /month", showIcon: true },
        { text: "6 themed-question editions", showIcon: true },
        { text: "Check-in Questions", showIcon: true, infoText: "A quick, engaging way to connect during any meeting with fun, thought-provoking questions." },
        { text: "Live onboarding demo event", showIcon: true },
        { text: "", showIcon: false },
      ],
      isPopular: true,
      backgroundColor: 'rgba(161, 139, 255, 0.08)',
    },
    {
      title: 'Enterprise',
      subtitle: 'Everything, and then some',
      contactprice: 'Contact us',
      btn: (
        <CJRButton
          onClick={() => {
            window.open(
              'https://cozyjuicyreal.trafft.com/booking?t=s&uuid=5fc42dd7-af7f-4716-8c26-d690476ed6e4'
            );
          }}
          variant="sparkPrimary"
        >
          Let's talk
        </CJRButton>
      ),
      features: [
        { text: "Ideal for 10+ admins", showIcon: true, iconSrc: userIcon, infoText: "'Admins' are account holders who set up games, inviting 'players' to join. Admins pay for platform access, players don't." },
        { text: "Everything in Company, plus:", showIcon: true },
        { text: "Plan tailored to your needs", showIcon: true },
        { text: "Unlimited games /admin /month", showIcon: true },
        { text: "12 themed-question editions", showIcon: true, infoText: "Themed-question editions designed for deeper team discussions on topics like diversity & belonging, leadership and creativity." },
        { text: "2+ events hosted by us /year", showIcon: true, infoText: "Our expert facilitators will handle everything, ensuring a smooth and engaging event." },
        { text: "Custom company values edition", showIcon: true, infoText: "A custom edition of Cozy Juicy Real with questions based on your company’s values, or any theme of your choice." },
        { text: "Priority support", showIcon: true },
      ],
      isPopular: false,
      backgroundColor: 'rgba(161, 139, 255, 0.12)',
    },
  ];
  

  return (
    <StyledPricingTables>
      <PricingColumnsContainer>
        {pricingData.map((data, index) => (
          <PricingColumn
            key={index}
            {...data}
            onCompareClick={onCompareClick} // Pass down onCompareClick from parent
          />
        ))}
      </PricingColumnsContainer>

      <BookACall />
      <AdditionalOptionsContainer>
        <TasterColumn />
        <NonProfitColumn />
      </AdditionalOptionsContainer>
    </StyledPricingTables>
  );
};

const StyledPricingTables = styled('section')`
  display: flex;
  margin-top: 90px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 30px 80px 14px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const PricingColumnsContainer = styled('div')`
  display: flex;
  width: 100%;
  max-width: 1140px;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const AdditionalOptionsContainer = styled('div')`
  display: flex;
  gap: 20px;
  margin-top: 60px;
  width: 100%;
  max-width: 1140px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    margin-top: 40px;
  }
`;

export default PricingTables;
