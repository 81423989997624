import { FunctionComponent } from 'react';
import { Box } from '@primer/react';
import { LoginForm } from '../../components/login/LoginForm';
import styled from 'styled-components';
import welcome from '../../images/welcome.svg';
import { Background } from '../../basicComponents/background/Background';
import { TopMenu } from '../../components/layout/TopMenu';
import { BallonMenu } from '../../basicComponents/accountMenu/BallonMenu';

const Image = styled('img')`
  max-width: 100%;
`;

export const WelcomePage: FunctionComponent = () => {
  return (
    <Box display="flex" flexDirection="column" minHeight="850px">
      <Background />
      <TopMenu />
      <BallonMenu />
      <Box alignSelf="center" as="header" mb={2} mt={-3} zIndex={1}>
        <Image alt="Cozy Juicy Real" src={welcome} />
      </Box>
      <Box alignItems="center" as="main" display="flex" flex={1} flexDirection="column" zIndex={1}>
        <LoginForm />
      </Box>
    </Box>
  );
};
