import { FunctionComponent } from 'react';
import backButtom from '../../images/backButtom.svg';
import styled from 'styled-components';

const Buttom = styled('img')`
  height: 24px;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
`;

export const BackButtom: FunctionComponent<{ goBack: () => void }> = ({ goBack }) => {
  return <Buttom alt="back" onClick={goBack} src={backButtom} />;
};
