/*
 * Copyright (C) 2023 Rocket Bear Studio - All Rights Reserved
 * You may use and modify this code under the
 * terms of the LICENSE.md in this code repository.
 */

import { Flash, StyledOcticon } from '@primer/react';
import { AlertIcon } from '@primer/octicons-react';
import { Layout } from '../layout/Layout';
import { useNavigate } from 'react-router-dom';
import { MY_GAMES_PATH } from '../../utils/constants';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';

export const RoomError = ({
  msg,
  reloadBtn,
}: {
  readonly msg?: string;
  readonly reloadBtn?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Layout.Panel>
        <Flash sx={{ mt: 3, mb: 3 }} variant="danger">
          <StyledOcticon icon={AlertIcon} />
          {msg ?? 'There was an error connecting to the room'}
        </Flash>
        {reloadBtn ? (
          <CJRButton
            onClick={() => {
              window.location.reload();
            }}
            style={{ marginBottom: '10px' }}
            variant="juicyPrimary"
          >
            Try Again
          </CJRButton>
        ) : null}
        <CJRButton
          onClick={() => {
            navigate(MY_GAMES_PATH);
          }}
          //style={{ maxWidth: '200px', margin: 'auto' }}
          variant="juicySecondary"
        >
          Back
        </CJRButton>
      </Layout.Panel>
    </Layout>
  );
};
